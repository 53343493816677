import { takeLatest, call, put } from "redux-saga/effects";
import * as solicitudDeServiciosActions from '../actions/SolicitudDeServiciosActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {
    busquedaListaFoliosSeguimientoSSCall,cambiarEstadoFolioSeguimientoCall,listaServiciosConFallasCall,listaFallasPorServicioCall,obtenerUbicacionCall,obtenerTrazabilidadFolioCall,listaProveedoresByServicioCall, 

    listaTiposFallasBusquedaCall, estadoTipoFallaBusquedaCall, crearTipoFallaBusquedaCall, editarTipoFallaBusquedaCall, listaTiposFallasActivasCall,

    listaFallasBusquedaCall, estadoFallaBusquedaCall, crearFallaCall, editarFallaCall, exportarFallasExcelCall,

    crearSolicitudDeServicioCall, exportarSolicitudDeServicioCall, listaFoliosSSCall, listaFallasActivasCall,listaFallasActivasByIdServicioCall, ubicacionesSinInterioresCall, ubicacionesSinHijosCall, visualizarReporteCall, listaServiciosActivosConProveedoresCall, listaSSReporteadorAutoCall, listaPartidasByFallaCall, registrarTiempoLimiteCall,

    listaReporteBusquedaCall, exportarReporteBusquedaExcelCall, exportarReporteBusquedaPDFCall, obtenerProrrogasHistorialCall, crearProrrogaCall, obtenerTrasladosCall, listaPartidasByServicio4FallasCall,

    listaReporteDinamicoCall, exportarReporteDPDFCall, exportarReporteDExcelCall, listaReporteDinamicoAgrupamientoCall,
} from '../../services/SolicitudDeServiciosServices';
import { message } from "antd";
import moment from "moment";
import printJS from "print-js";

// Historial Prorrogas
function* obtenerProrrogas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        console.log('object :>> ', page, idSolicitudServicio );
        const response = yield call(obtenerProrrogasHistorialCall, token, idSolicitudServicio, page)
        console.log('obtenerProrrogas :>> ', response);

        if (response.status === 200) {
            let listaProrrogas = response.data.data;
            let listaProrrogasCantidad = response.data.cantidad;

            for (let i in listaProrrogas) {

              if (listaProrrogas[i].fechaCompromiso) {

                  listaProrrogas[i].fechaCompromiso = listaProrrogas[i].fechaCompromiso.split('T')[0].split('-').reverse().join('-') + " " + listaProrrogas[i].fechaCompromiso.split('T')[1].split(':')[0] + ":" + listaProrrogas[i].fechaCompromiso.split('T')[1].split(':')[1] ;
               }
              if (listaProrrogas[i].fechaAcuerdo) {
                  listaProrrogas[i].fechaAcuerdo = listaProrrogas[i].fechaAcuerdo.split('T')[0].split('-').reverse().join('-')
              } 
              listaProrrogas[i].tiempoRespuesta += ' minutos' 

            }
            
            yield put({ type: solicitudDeServiciosActions.LISTA_PRORROGA_SUCCESS, listaProrrogas, listaProrrogasCantidad})
        } else {
            yield put ({ type: solicitudDeServiciosActions.LISTA_PRORROGA_FAILURE})
        }
    } catch (error) {
        yield put ({ type: solicitudDeServiciosActions.LISTA_PRORROGA_FAILURE})
    }
}
export function* obtenerProrrogasSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_PRORROGA_REQUEST, obtenerProrrogas)
}

// Historial Traslados
function* obtenerTraslados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        console.log('page y solicitud :>> ', page, idSolicitudServicio );
        const response = yield call(obtenerTrasladosCall, token, idSolicitudServicio, page)
        console.log('obtenerTraslados :>> ', response);

        if (response.status === 200) {
            let listaTraslados = response.data.data;
            let listaTrasladosCantidad = response.data.cantidad;

            for (let i in listaTraslados) {
                if (listaTraslados[i].fechaEstado) {
                    listaTraslados[i].fechaEstado = listaTraslados[i].fechaEstado.split('T')[0].split('-').reverse().join('-') + " " + listaTraslados[i].fechaEstado.split('T')[1].split(':')[0] + ":" + listaTraslados[i].fechaEstado.split('T')[1].split(':')[1]
                }

                if (listaTraslados[i].ubicacionesByIdUbicacion) {
                    if (listaTraslados[i].ubicacionesByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        listaTraslados[i].ubicacion = listaTraslados[i].ubicacionesByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                    }

                    if (listaTraslados[i].ubicacionesByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        listaTraslados[i].ubicacion += ', ' + listaTraslados[i].ubicacionesByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                    }

                    if (listaTraslados[i].ubicacionesByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        listaTraslados[i].ubicacion += ', ' + listaTraslados[i].ubicacionesByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                    }

                    if (listaTraslados[i].ubicacionesByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                        listaTraslados[i].ubicacion += ', ' + listaTraslados[i].ubicacionesByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional
                    }

                   
                }
            }

            
            yield put({ type: solicitudDeServiciosActions.LISTA_TRASLADOS_SUCCESS, listaTraslados, listaTrasladosCantidad})
        } else {
            yield put ({ type: solicitudDeServiciosActions.LISTA_TRASLADOS_FAILURE})
        }
    } catch (error) {
        yield put ({ type: solicitudDeServiciosActions.LISTA_TRASLADOS_FAILURE})
    }
}
export function* obtenerTrasladosSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_TRASLADOS_REQUEST, obtenerTraslados)
}

// crear prorroga
function* crearProrroga(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let fechaCompromiso;
        let tiempoRespuesta;
        let fechaAcuerdo;

        if(action.formulario.fechaCompromiso){
            fechaCompromiso = action.formulario.fechaCompromiso.format('YYYY-MM-DDTHH:mm');
        }

        if(action.formulario.fechaAcuerdo){
            fechaAcuerdo = action.formulario.fechaAcuerdo.format('YYYY-MM-DD');
        }

        if(action.formulario.tiempoRespuesta){
            tiempoRespuesta = parseInt(action.formulario.tiempoRespuesta)
        }
        
        let data = {
            "idHistorialProrroga": null,
            "idSolicitudServicio": action.folioSeleccionadoProrroga.idSolicitudServicio,
            "idUsuario": null,
            "usuarioPyrs": action.formulario.usuarioPYRS,
            "observaciones": action.formulario.observaciones,
            "fechaCompromiso": fechaCompromiso ,
            "fechaAcuerdo": fechaAcuerdo,
            "tiempoRespuesta": tiempoRespuesta,
            "rutaArchivo": null,
            "fechaAlta": null
        };

        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);

        const response = yield call(crearProrrogaCall, token, formData);
        console.log('crearProrroga :>> ', response);

        if(response.status === 200) {
            const idSolicitudServicio = action.folioSeleccionadoProrroga.idSolicitudServicio
            const showSuccessMsg = true;
            const textMessage = "Se realizó la prorroga del folio de manera correcta";
            const showModalProrroga = false;


            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.CREAR_PRORROGA_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearProrroga'});
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_PRORROGA, showModalProrroga, limpiarClasificacion: true });
            yield put({ type: solicitudDeServiciosActions.LISTA_PRORROGA_REQUEST, idSolicitudServicio})
        } else {
            yield put({ type: solicitudDeServiciosActions.CREAR_PRORROGA_FAILURE });
        }

    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.CREAR_PRORROGA_FAILURE });
    }
}

export function* crearProrrogaSaga() {
    yield takeLatest(solicitudDeServiciosActions.CREAR_PRORROGA_REQUEST, crearProrroga)
}

// Filtro ubicaciones sin hijos :c
function* ubicacionesSinHijos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;
      // request api 
        const response = yield call(ubicacionesSinHijosCall,token, busqueda)
        console.log(response);

        if (response.status === 200) {
            let ubicacionesSinHijos = response.data;

            for(let i = 0; i < ubicacionesSinHijos.length; i++){
                ubicacionesSinHijos[i].key = ubicacionesSinHijos[i].idUbicacion;
                ubicacionesSinHijos[i].ubicacionCOA = ubicacionesSinHijos[i].areaFuncionalByIdAreaFuncional.codigoArea + ', ' + ubicacionesSinHijos[i].areaFuncionalByIdAreaFuncional.areaFuncional;

                if(ubicacionesSinHijos[i].unidadFuncionalByIdUnidadFuncional){
                    ubicacionesSinHijos[i].ubicacionCOA += ', ' + ubicacionesSinHijos[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                }

                if(ubicacionesSinHijos[i].interiorFuncionalByIdInteriorFuncional){
                    ubicacionesSinHijos[i].ubicacionCOA += ', ' + ubicacionesSinHijos[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                }

                if(ubicacionesSinHijos[i].subInteriorFuncionalByIdSubInteriorFuncional){
                    ubicacionesSinHijos[i].ubicacionCOA += ', ' + ubicacionesSinHijos[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                }
            }
            
            yield put({ type: "UBICACIONES_SIN_HIJOS_SUCCESS", ubicacionesSinHijos})
        } else {
            yield put ({ type: "UBICACIONES_SIN_HIJOS_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "UBICACIONES_SIN_HIJOS_FAILURE"})
    }
}
export function* ubicacionesSinHijosSaga() {
    yield takeLatest(solicitudDeServiciosActions.UBICACIONES_SIN_HIJOS_REQUEST, ubicacionesSinHijos)
}



// Busqueda folios para seguimiento Saga
function* busquedaListaFoliosSeguimientoSS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let orden = 'DESC';
        let campo = 'idSolicitudServicio';
        let param = ''

        if (action.busqueda.order) {
            orden = action.busqueda.order
        };

        if (action.busqueda.field) {
            campo = action.busqueda.field
        };

        if (action.busqueda.param) {
            param = action.busqueda.param
        };

        const response = yield call(busquedaListaFoliosSeguimientoSSCall, token, param, campo, orden, page);
        console.log('response busquedaListaFoliosSeguimientoSS', response);

        if (response.status === 200) {
            const listaSeguimientoFolioSS = response.data.data;
            const listaSeguimientoFolioSSCantidad = response.data.cantidad;

            for (let i in listaSeguimientoFolioSS) {
                listaSeguimientoFolioSS[i].estado = listaSeguimientoFolioSS[i].estado === 'Generada' ? 'Abierto' : listaSeguimientoFolioSS[i].estado === 'Abierto' ? 'Asignado' : listaSeguimientoFolioSS[i].estado === 'Terminada' ? 'Realizado' : listaSeguimientoFolioSS[i].estado;
                let fechaAlta = listaSeguimientoFolioSS[i].fechaAlta.split('T')[0];
                let horaAlta = listaSeguimientoFolioSS[i].fechaAlta.split('T')[1];
                listaSeguimientoFolioSS[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0] + ' ' + horaAlta.split(':')[0] + ':' + horaAlta.split(':')[1];

                if (listaSeguimientoFolioSS[i].fechaProceso) {
                    let fechaProcesoAlta = listaSeguimientoFolioSS[i].fechaProceso.split('T')[0];
                    let horaProcesoAlta = listaSeguimientoFolioSS[i].fechaProceso.split('T')[1];
                    listaSeguimientoFolioSS[i].fechaProcesoDate = fechaProcesoAlta.split('-')[2] + '-' + fechaProcesoAlta.split('-')[1] + '-' + fechaProcesoAlta.split('-')[0] + ' ' + horaProcesoAlta.split(':')[0] + ':' + horaProcesoAlta.split(':')[1];
                    listaSeguimientoFolioSS[i].fechaProceso = moment(listaSeguimientoFolioSS[i].fechaProcesoDate, 'DD-MM-YYYY HH:mm')
                }
                if(listaSeguimientoFolioSS[i].fechaEstimada){
                    listaSeguimientoFolioSS[i].fechaEstimada = listaSeguimientoFolioSS[i].fechaEstimada.split('T')[0].split('-').reverse().join('-');
                } else {
                    listaSeguimientoFolioSS[i].fechaEstimada = 'No hay fecha estimada'
                }

                if(!listaSeguimientoFolioSS[i].tipoReporte){
                    listaSeguimientoFolioSS[i].tipoReporte = "No hay tipo de reporte"
                } 

                if (listaSeguimientoFolioSS[i].fechaDiagnostico) {
                    listaSeguimientoFolioSS[i].fechaDiagnosticoF = listaSeguimientoFolioSS[i].fechaDiagnostico.split('T')[0].split('-').reverse().join('-') + " " + listaSeguimientoFolioSS[i].fechaDiagnostico.split('T')[1].split(':')[0] + ":" + listaSeguimientoFolioSS[i].fechaDiagnostico.split('T')[1].split(':')[1] ;
                    listaSeguimientoFolioSS[i].fechaDiagnostico = moment(listaSeguimientoFolioSS[i].fechaDiagnosticoF, 'DD-MM-YYYY HH:mm')
                }

                if (listaSeguimientoFolioSS[i].fechaTerminacion) {
                    listaSeguimientoFolioSS[i].fechaTerminacionF = listaSeguimientoFolioSS[i].fechaTerminacion.split('T')[0].split('-').reverse().join('-') + " " + listaSeguimientoFolioSS[i].fechaTerminacion.split('T')[1].split(':')[0] + ":" + listaSeguimientoFolioSS[i].fechaTerminacion.split('T')[1].split(':')[1] ;
                    listaSeguimientoFolioSS[i].fechaTerminacion = moment(listaSeguimientoFolioSS[i].fechaTerminacionF, 'DD-MM-YYYY HH:mm')
                }

                if (listaSeguimientoFolioSS[i].fechaEspera) {
                    listaSeguimientoFolioSS[i].fechaEsperaF = listaSeguimientoFolioSS[i].fechaEspera.split('T')[0].split('-').reverse().join('-') + " " + listaSeguimientoFolioSS[i].fechaEspera.split('T')[1].split(':')[0] + ":" + listaSeguimientoFolioSS[i].fechaEspera.split('T')[1].split(':')[1] ;
                    listaSeguimientoFolioSS[i].fechaEspera = moment(listaSeguimientoFolioSS[i].fechaEsperaF, 'DD-MM-YYYY HH:mm')
                }

                if (listaSeguimientoFolioSS[i].fechaCierre) {
                    listaSeguimientoFolioSS[i].fechaCierreF = listaSeguimientoFolioSS[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + " " + listaSeguimientoFolioSS[i].fechaCierre.split('T')[1].split(':')[0] + ":" + listaSeguimientoFolioSS[i].fechaCierre.split('T')[1].split(':')[1] ;
                    listaSeguimientoFolioSS[i].fechaCierre = moment(listaSeguimientoFolioSS[i].fechaCierreF, 'DD-MM-YYYY HH:mm')
                }

                if (listaSeguimientoFolioSS[i].fechaAbierto) {
                    listaSeguimientoFolioSS[i].fechaAbiertoF = listaSeguimientoFolioSS[i].fechaAbierto.split('T')[0].split('-').reverse().join('-') + " " + listaSeguimientoFolioSS[i].fechaAbierto.split('T')[1].split(':')[0] + ":" + listaSeguimientoFolioSS[i].fechaAbierto.split('T')[1].split(':')[1] ;
                    listaSeguimientoFolioSS[i].fechaAbierto = moment(listaSeguimientoFolioSS[i].fechaAbiertoF, 'DD-MM-YYYY HH:mm')
                }

                if (listaSeguimientoFolioSS[i].servicioByIdServicio) {
                    listaSeguimientoFolioSS[i].servicio = listaSeguimientoFolioSS[i].servicioByIdServicio.nombre
                } else {
                    listaSeguimientoFolioSS[i].servicio = "No tiene servicio"
                }

                if (listaSeguimientoFolioSS[i].fallaByIdFalla) {
                    listaSeguimientoFolioSS[i].falla = listaSeguimientoFolioSS[i].fallaByIdFalla.idFalla+'-'+listaSeguimientoFolioSS[i].fallaByIdFalla.nombre
                } else {
                    listaSeguimientoFolioSS[i].falla = "No tiene falla"
                }

                if (listaSeguimientoFolioSS[i].proveedorByIdProveedor) {
                    listaSeguimientoFolioSS[i].proveedor = listaSeguimientoFolioSS[i].proveedorByIdProveedor.nombre
                } else {
                    listaSeguimientoFolioSS[i].proveedor = "No tiene proveedor"
                }

                if (listaSeguimientoFolioSS[i].ubicacionByIdUbicacion) {
                    if (listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        listaSeguimientoFolioSS[i].area = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.idAreaFuncional +" - "+ listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                        listaSeguimientoFolioSS[i].areaDetalle = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                    } else {
                        listaSeguimientoFolioSS[i].area = "No tiene Area Funcional"
                    }

                    if (listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        listaSeguimientoFolioSS[i].unidad = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.idUnidadFuncional+" - "+ listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                        listaSeguimientoFolioSS[i].unidadDetalle = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                    } else {
                        listaSeguimientoFolioSS[i].unidad = "No tiene Unidad Funcional"
                        listaSeguimientoFolioSS[i].unidadDetalle ="No tiene Unidad Funcional"
                    }

                    if (listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        listaSeguimientoFolioSS[i].interior = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.idInteriorFuncional+" - "+ listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                        listaSeguimientoFolioSS[i].interiorDetalle = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                    } else {
                        listaSeguimientoFolioSS[i].interiorDetalle ="No tiene Interior Funcional"
                        listaSeguimientoFolioSS[i].interior = "No tiene Interior Funcional"
                    }
                    
                    if (listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                        listaSeguimientoFolioSS[i].subInterior = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.idSubInteriorFuncional+" - "+ listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional
                        listaSeguimientoFolioSS[i].subInteriorDetalle = listaSeguimientoFolioSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional
                    } else {
                        listaSeguimientoFolioSS[i].subInterior = "No tiene SubInterior Funcional"
                    }
                }

                if (listaSeguimientoFolioSS[i].usuarioByIdUsuarioReporta) {
                    listaSeguimientoFolioSS[i].usuarioReporta = listaSeguimientoFolioSS[i].usuarioByIdUsuarioReporta.nombre + ' ' + listaSeguimientoFolioSS[i].usuarioByIdUsuarioReporta.aPaterno
                } else {
                    listaSeguimientoFolioSS[i].usuarioReporta = listaSeguimientoFolioSS[i].nombreUsuario
                }

                if (action.idSolicitudServicio ) {
                    if (action.idSolicitudServicio === listaSeguimientoFolioSS[i].idSolicitudServicio) {
                        let folioSolicitudServicioSeleccionado = listaSeguimientoFolioSS[i]
                        yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS , showModalOpcionesSeguimientoFolio: true, folioSolicitudServicioSeleccionado, page: action.page, busqueda: action.busqueda})
                    }
                }
            }



            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_SUCCESS, listaSeguimientoFolioSS, listaSeguimientoFolioSSCantidad })
        } else {
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_FAILURE })
        }

    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_FAILURE })
    }
}

export function* busquedaListaFoliosSeguimientoSSSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, busquedaListaFoliosSeguimientoSS);
}

// Saga obtener ubicacion 
function* obtenerUbicacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const { idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional } = action;

        const response = yield call(obtenerUbicacionCall, token, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional);
        console.log('response obtenerUbicacion', response);

        if (response.status === 200) {
            let ubicacionSeguimiento = response.data;

            yield put({ type: solicitudDeServiciosActions.OBTENER_UBICACION_SUCCESS, ubicacionSeguimiento });
        } else {
            yield put({ type: solicitudDeServiciosActions.OBTENER_UBICACION_FAILURE });
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.OBTENER_UBICACION_FAILURE });
    }
}
export function* obtenerUbicacionSaga() {
    yield takeLatest(solicitudDeServiciosActions.OBTENER_UBICACION_REQUEST, obtenerUbicacion);
}

// Saga servicios activos con fallas
function* listaServiciosConFallas(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosConFallasCall, token);
        console.log('response listaServiciosConFallas', response);

        if (response.status === 200) {
            let listaServiciosConFallas = response.data;

            yield put({ type: solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_SUCCESS, listaServiciosConFallas });
        } else {
            yield put({ type: solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_FAILURE });
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_FAILURE });
    }
}
export function* listaServiciosConFallasSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_REQUEST, listaServiciosConFallas);
}


// Saga abierto seguimiento Folio SS
function* folioAbierto(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'abierto';
        let fechaAbierto = action.formulario.fechaAbierto.format('YYYY-MM-DDTHH:mm');
        console.log('fechaAbierto', fechaAbierto); 
        let observaciones;
        let hipotesis;
        action.formulario.observacionesF ? observaciones = action.formulario.observacionesF.trim() : observaciones = ""
        action.formulario.hipotesis ? hipotesis = action.formulario.hipotesis.trim() : hipotesis = ""
             
        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": observaciones,
            "diagnostico": null,
            "hipotesis": hipotesis,
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": "",
            "fechaHipotesis": fechaAbierto + ":00",
            "fechaAbierto": fechaAbierto + ":00",
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);

        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioAbierto', response);

        if (response.status === 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalOpcionesSeguimientoFolio = true;
            const showSuccessMsg = true;
            const textMessage = "El folio cambió de estado a 'Asignado' de forma correcta";
            action.folioSolicitudServicioSeleccionado.estado = "Abierto";
            const currentStatus = 0;
            console.log( page, busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.ABIERTO_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, page, busqueda, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus }); 
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.ABIERTO_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.ABIERTO_FOLIO_SS_FAILURE});
    }
}
export function* folioAbiertoSaga(){
    yield takeLatest(solicitudDeServiciosActions.ABIERTO_FOLIO_SS_REQUEST, folioAbierto);
}

// Saga traslado folio seguimiento
function* folioTraslado(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSeleccionadoTraslado.idSolicitudServicio;
        let selector = 'traslado';
        
        let listaTraslado = action.formulario.listaTraslado;
        
        let listTrasladoFolio = [];
        for (let i in listaTraslado) {
            let traslado = {
               "idTrasladoFolio": null,
               "idSolicitudServicio": null,
               "idUsuario": null,
               "idUbicacion": parseInt(listaTraslado[i].ubicacion.split('-')[0]),
               "tecnicoResponsable": listaTraslado[i].tecnicoResponsable,
               "observacion": listaTraslado[i].observaciones,
               "fechaEstado": listaTraslado[i].fechaTraslado.format('YYYY-MM-DDTHH:mm'),
               "fechaActualizacion": null
        }
        listTrasladoFolio.push(traslado);
        };
        console.log('listTrasladoFolio', listTrasladoFolio);


        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": "",
            "diagnostico": "",
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": "",
            "fechaHipotesis": null,
            "fechaSuspension": null,
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        
        formData.append('listTrasladoFolio', new Blob([JSON.stringify(listTrasladoFolio)], {
            type: "application/json"
        }));
        console.log('formData', formData);
        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioTraslado', response);

        if (response.status === 200) {
            let page = action.page;
            let busqueda = action.busqueda;
            const showSuccessMsg = true;
            const showModalTraslado = false;
            const showModalOpcionesSeguimientoFolio = true;
            const textMessage = "El Folio se trasladó de forma correcta";
            action.folioSeleccionadoTraslado.estado = "Traslado";
            const currentStatus = 0;
            console.log('page', page);
            console.log('busqueda', busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.TRASLADO_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_TRASLADO, showModalTraslado })
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, busqueda, page, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSeleccionadoTraslado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.LISTA_TRASLADOS_REQUEST, folioSolicitudServicioSeleccionado: action.folioSeleccionadoTraslado, page })
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSeleccionadoTraslado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus, cambiarVistaModalEstadoTerminado: false });
    

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.TRASLADO_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.TRASLADO_FOLIO_SS_FAILURE});
    }
}
export function* folioTrasladoSaga(){
    yield takeLatest(solicitudDeServiciosActions.TRASLADO_FOLIO_SS_REQUEST, folioTraslado);
}


// Saga proceso seguimiento Folio SS
function* folioProceso(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'proceso';
        let fechaProceso = action.formulario.fechaProceso.format('YYYY-MM-DDTHH:mm');
        console.log('fechaProceso', fechaProceso); 
        let observaciones;
        action.formulario.observacionesF ? observaciones = action.formulario.observacionesF.trim() : observaciones = ""
             

        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": observaciones,
            "diagnostico": null,
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": fechaProceso + ":00" ,
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": "",
            "fechaHipotesis": null,
            "fechaAbierto": "",
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);

        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioProceso', response);

        if (response.status === 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalOpcionesSeguimientoFolio = true;
            const showSuccessMsg = true;
            const textMessage = "El folio cambió de estado a 'En proceso' de forma correcta";
            action.folioSolicitudServicioSeleccionado.estado = "En Proceso";
            const currentStatus = 0;
            console.log( page, busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, page, busqueda, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page:1 });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus }); 
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_FAILURE});
    }
}
export function* folioProcesoSaga(){
    yield takeLatest(solicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_REQUEST, folioProceso);
}

// Saga Cerrar seguimiento Folio
function* folioCerrar(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'cierre';
        let fechaCierre = action.formulario.fechaCierre.format('YYYY-MM-DDTHH:mm');
        console.log('fechaCierre', fechaCierre); 
        let observaciones;
        action.formulario.observacionesF !== null ? observaciones = action.formulario.observacionesF : observaciones = action.folioSolicitudServicioSeleccionado.observaciones
 
        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": action.formulario.usuarioPYRS,
            "usuarioAuditoriaTecnica": action.formulario.usuarioAuditoriaTecnica,
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": observaciones,
            "diagnostico": null,
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": fechaCierre + ":00",
            "fechaHipotesis": null,
            "fechaSuspension": null,
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);
        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioCerrar', response);

        if (response.status === 200) {
            let page = action.page;
            let busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El folio cambió de estado a 'Cerrado' de forma correcta";
            action.folioSolicitudServicioSeleccionado.estado = "Cerrado"
            const showModalOpcionesSeguimientoFolio = true;
            const currentStatus = 0;
            console.log( page, busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.CERRAR_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, page, busqueda, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.CERRAR_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.CERRAR_FOLIO_SS_FAILURE});
    }
}
export function* folioCerrarSaga(){
    yield takeLatest(solicitudDeServiciosActions.CERRAR_FOLIO_SS_REQUEST, folioCerrar);
}

// Saga en espera seguimiento Folio SS
function* folioEnEspera(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'espera';
        let fechaEspera = action.formulario.fechaEspera.format('YYYY-MM-DDTHH:mm');
        console.log('fechaEspera', fechaEspera); 
        let observaciones;
        action.formulario.observacionesF !== null ? observaciones = action.formulario.observacionesF.trim() : observaciones = action.folioSolicitudServicioSeleccionado.observaciones

        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": observaciones,
            "diagnostico": null,
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": fechaEspera + ":00",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": "",
            "fechaHipotesis": null,
            "fechaSuspension": null,
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);

        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioEspera', response);

        if (response.status === 200) {
            let page = action.page;
            let busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El folio cambió de estado a 'En Espera' de forma correcta";
            action.folioSolicitudServicioSeleccionado.estado = "En Espera";
            const showModalOpcionesSeguimientoFolio = true;
            const currentStatus = 0;
            console.log( page, busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, page, busqueda, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_FAILURE});
    }
}
export function* folioEnEsperaSaga(){
    yield takeLatest(solicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_REQUEST, folioEnEspera);
}

// Saga cancelar seguimiento Folio SS
function* folioCancelar(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'cancelar';
        let observaciones;
        action.formulario.observacionesF !== null ? observaciones = action.formulario.observacionesF.trim() : observaciones = action.folioSolicitudServicioSeleccionado.observaciones

        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": observaciones,
            "diagnostico": null,
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": "",
            "fechaHipotesis": null,
            "fechaSuspension": null,
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);
    
        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioCancelar', response);

        if (response.status === 200) {
            let page = action.page;
            let busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El folio cambió de estado a 'Cancelado' de forma correcta";
            action.folioSolicitudServicioSeleccionado.estado = "Cancelado";
            const showModalOpcionesSeguimientoFolio = true;
            const currentStatus = 0;
            console.log( page, busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.CANCELAR_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, page, busqueda, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.CANCELAR_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.CANCELAR_FOLIO_SS_FAILURE});
    }
}
export function* folioCancelarSaga(){
    yield takeLatest(solicitudDeServiciosActions.CANCELAR_FOLIO_SS_REQUEST, folioCancelar);
}

//Saga Reasignar seguimiento folio
function* folioReasignar(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'reasignado';
        let observaciones;
        action.formulario.observacionesF !== null ? observaciones = action.formulario.observacionesF.trim() : observaciones = action.folioSolicitudServicioSeleccionado.observaciones

        let idServicio = action.formulario.servicio;
        let idFalla = parseInt(action.formulario.falla);
        let idUbicacion;
        let idProveedor = action.formulario.proveedor;
        let queja;
        let idPartida = null;
        console.log('primera vez', idServicio, idFalla, idUbicacion, idProveedor, queja);

        if(action.formulario.partida){
            idPartida = action.formulario.partida[0].split('-')[0];
        }

        if(isNaN(idServicio)){
            idServicio = action.folioSolicitudServicioSeleccionado.idServicio
        } 

        // if(isNaN(idFalla)){
        //     idFalla = action.folioSolicitudServicioSeleccionado.idFalla;
        // } 
        console.log('segunda vez', idServicio, idFalla, idUbicacion, idProveedor, queja);
        if(!action.ubicacionSeguimiento.length){
            idUbicacion = parseInt(action.folioSolicitudServicioSeleccionado.ubicacionByIdUbicacion.idUbicacion)
        }
        else{
           if((action.ubicacionSeguimiento[0].idInteriorFuncional === null && action.ubicacionSeguimiento[0].idUnidadFuncional === null) && action.ubicacionSeguimiento[0].idAreaFuncional === action.folioSolicitudServicioSeleccionado.ubicacionByIdUbicacion.idAreaFuncional) {
                console.log('ubicacion sin cambios')
                idUbicacion = parseInt(action.folioSolicitudServicioSeleccionado.ubicacionByIdUbicacion.idUbicacion)
            } else {
                console.log('entro aqui en ubicacionSeguimiento')
                idUbicacion = parseInt(action.ubicacionSeguimiento[0].idUbicacion); 
            } 
        }
        

        console.log('tercera vez', idServicio, idFalla, idUbicacion, idProveedor, queja);
        if(isNaN(idProveedor)){
            idProveedor = action.folioSolicitudServicioSeleccionado.idProveedor
        } 

        if( action.formulario.queja === undefined || action.formulario.queja === "ninguna"){
            queja = ""
        } else {
            queja = action.formulario.queja
        }
         
        console.log('cuarta', idServicio, idFalla, idUbicacion, idProveedor, queja);

        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": idServicio,
            "idFalla": idFalla,
            "idUbicacion": idUbicacion,
            "idProveedor": parseInt(idProveedor),
            "folio": "",
            "nombreUsuario": null,
            "queja": queja,
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": "",
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": "",
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": "",
            "observaciones": observaciones,
            "diagnostico": null,
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": "",
            "fechaTerminacion": "",
            "fechaCierre": "",
            "fechaHipotesis": null,
            "fechaSuspension": null,
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": "",
            "idPartida": idPartida,
        };
        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData', formData);
        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioReasignar', response);

        if (response.status === 200) {
            let page = action.page;
            let busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El Folio se reasignó de forma correcta";
            const currentStatus = 0;
            console.log( page, busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.REASIGNAR_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, page, busqueda, idSolicitudServicio });
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.REASIGNAR_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.REASIGNAR_FOLIO_SS_FAILURE});
    }
}
export function* folioReasignarSaga(){
    yield takeLatest(solicitudDeServiciosActions.REASIGNAR_FOLIO_SS_REQUEST, folioReasignar);
}

// Saga terminar folio seguimiento
function* folioTerminado(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSolicitudServicio = action.folioSolicitudServicioSeleccionado.idSolicitudServicio;
        let selector = 'terminacion';
        let fechaDiagnostico = action.formularioTerminado.fechaDiagnostico.format('YYYY-MM-DDTHH:mm');
        let fechaTerminacion = action.formularioTerminado.fechaTerminacion.format('YYYY-MM-DDTHH:mm');
        console.log('fechaDiagnosticoEnviar', fechaDiagnostico); 
        console.log('fechaTerminacionEnviar', fechaTerminacion); 
        
        
        let referencia;
        action.formulario.referencia !== null ? referencia = action.formulario.referencia : referencia = action.folioSolicitudServicioSeleccionado.referencia

        let insumosArreglo = action.formulario.insumos;
        let herramientasArreglo = action.formulario.herramientas;
        let eppArreglo = action.formulario.epp;

        let listInsumos = [];
        for(let i in insumosArreglo){
            let productoInsumo = {
                "idProducto": insumosArreglo[i].insumo.split(' - ')[0],
                "cantidad": insumosArreglo[i].cantidad
            }
            listInsumos.push(productoInsumo);
        };
        console.log('listInsumos', listInsumos);

        let listHerramientas = [];
        for(let i in herramientasArreglo){
            let herramienta = {
                "herramienta": herramientasArreglo[i].herramienta,
                "cantidad": herramientasArreglo[i].cantidad
            }
            listHerramientas.push(herramienta);
        };
        console.log('listHerramientas', listHerramientas);

        let listEpp = [];
        for(let i in eppArreglo){
            let epp = {
                "epp": eppArreglo[i].epp,
                "cantidad": eppArreglo[i].cantidad
            }
            listEpp.push(epp);
        };
        console.log('listEpp', listEpp);


        const data = {
            "idSolicitudServicio": "",
            "idUsuarioReporta": "",
            "idUsuarioRegistra": "",
            "idServicio": "",
            "idFalla": "",
            "idUbicacion": "",
            "idProveedor": "",
            "folio": "",
            "nombreUsuario": null,
            "queja": "",
            "usuarioPYRS": "",
            "usuarioAuditoriaTecnica": "",
            "personal": action.formularioTerminado.personal,
            "areaUsuario": null,
            "descripcionSolicitud": "",
            "tiempoCaptura": null,
            "estado": "",
            "actividadesRealizadas": action.formularioTerminado.actividadesRealizadas,
            "herramienta": "",
            "cantidadHerramienta": "",
            "insumos": "",
            "cantidadInsumos": "",
            "epp": "",
            "cantidadEpp": "",
            "rutaArchivo": null,
            "referencia": referencia,
            "observaciones": "",
            "diagnostico": action.formularioTerminado.diagnostico,
            "hipotesis": "",
            "evidencia": null,
            "tipoReporte": null,
            "fechaAlta": "",
            "fechaProceso": "",
            "fechaEspera": "",
            "fechaEstimada": null,
            "fechaCancelacion": "",
            "fechaConciliacion": null,
            "fechaDiagnostico": fechaDiagnostico + ":00",
            "fechaTerminacion": fechaTerminacion + ":00",
            "fechaCierre": "",
            "fechaHipotesis": null,
            "fechaSuspension": null,
            "fechaReasignacion": "",
            "fechaCompromiso": null,
            "impreso": ""
        };
        console.log('data', data)
        const formData = new FormData();
        // if (action.formularioTerminado.pdf) {
        //     formData.append('file', action.formularioTerminado.pdf.file.originFileObj);
        // }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('listHerramientas', new Blob([JSON.stringify(listHerramientas)], {
            type: "application/json"
        }));
        formData.append('listEpp', new Blob([JSON.stringify(listEpp)], {
            type: "application/json"
        }));
        formData.append('listInsumos', new Blob([JSON.stringify(listInsumos)], {
            type: "application/json"
        }));
        console.log('formData', formData);
        const response = yield call(cambiarEstadoFolioSeguimientoCall, token, idSolicitudServicio, selector, formData);
        console.log('response folioReasignar', response);

        if (response.status === 200) {
            let page = action.page;
            let busqueda = action.busqueda;
            const showSuccessMsg = true;
            const showModalOpcionesSeguimientoFolio = true;
            const textMessage = "El Folio se realizó de forma correcta";
            action.folioSolicitudServicioSeleccionado.estado = "Terminada";
            const currentStatus = 0;
            console.log('page', page);
            console.log('busqueda', busqueda);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.TERMINADO_FOLIO_SS_SUCCESS});
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST, busqueda, page, idSolicitudServicio});
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, folio: action.folioSolicitudServicioSeleccionado.folio, page: 1 });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS, showModalOpcionesSeguimientoFolio,folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado , page, busqueda }); 
            yield put({ type: solicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO, currentStatus, cambiarVistaModalEstadoTerminado: false });
    

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.TERMINADO_FOLIO_SS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.TERMINADO_FOLIO_SS_FAILURE});
    }
}
export function* folioTerminadoSaga(){
    yield takeLatest(solicitudDeServiciosActions.TERMINADO_FOLIO_SS_REQUEST, folioTerminado);
}

// Saga trazabilidad seguimiento
function* obtenerTrazabilidad(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let folio = action.folio;

        console.log('saga trazabilidad folio y page', folio, page);

        const response = yield call(obtenerTrazabilidadFolioCall, token, folio, page);
        console.log('response obtenerTrazabilidad', response);

        if (response.status === 200) {
            let listaTrazabilidad = response.data.data;
            let listaTrazabilidadCantidad = response.data.cantidad;

            for (let folio in listaTrazabilidad) {
                listaTrazabilidad[folio].estado = listaTrazabilidad[folio].estado === 'Generada' ? 'Abierto' : listaTrazabilidad[folio].estado === 'Abierto' ? 'Asignado' : listaTrazabilidad[folio].estado === 'Terminada' ? 'Realizado' : listaTrazabilidad[folio].estado;
                if (listaTrazabilidad[folio].fechaActualizacion) {
                    let fechaAct = listaTrazabilidad[folio].fechaActualizacion.split('T')[0];
                    let horaActualizacion = listaTrazabilidad[folio].fechaActualizacion.split('T')[1];
                    listaTrazabilidad[folio].fechaActualizacion = fechaAct.split('-')[2] + '-' + fechaAct.split('-')[1] + '-' + fechaAct.split('-')[0] + ' ' + horaActualizacion.split(':')[0] + ':' + horaActualizacion.split(':')[1];
                }

                if (listaTrazabilidad[folio].fechaEstado) {
                    let fechaEstado = listaTrazabilidad[folio].fechaEstado.split('T')[0];
                    let horaEstado = listaTrazabilidad[folio].fechaEstado.split('T')[1];
                    listaTrazabilidad[folio].fechaEstado = fechaEstado.split('-')[2] + '-' + fechaEstado.split('-')[1] + '-' + fechaEstado.split('-')[0] + ' ' + horaEstado.split(':')[0] + ':' + horaEstado.split(':')[1];
                }

                if (listaTrazabilidad[folio].usuariosByIdUsuario) {
                    listaTrazabilidad[folio].username = listaTrazabilidad[folio].usuariosByIdUsuario.nombre  + " " + listaTrazabilidad[folio].usuariosByIdUsuario.aPaterno;

                    if(listaTrazabilidad[folio].usuariosByIdUsuario.aMaterno){
                        listaTrazabilidad[folio].username += ' ' + listaTrazabilidad[folio].usuariosByIdUsuario.aMaterno;
                    }
                }

            }

            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_SUCCESS, listaTrazabilidad, listaTrazabilidadCantidad })
        } else {
            yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_FAILURE })
    }
}
export function* obtenerTrazabilidadSaga() {
    yield takeLatest(solicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST, obtenerTrazabilidad)
}

// Saga lista Proveedores By idServicio
function* listaProveedoresPorIdServicio(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = parseInt(action.idServicio);
        const response = yield call(listaProveedoresByServicioCall, token, idServicio);
        console.log('response listaProveedoresPorIdServicio', response);

        if (response.status === 200) {
            let listaProveedoresByServicio = response.data;
            console.log(listaProveedoresByServicio);
            for(let i in listaProveedoresByServicio){
                if (listaProveedoresByServicio[i].proveedorByIdProveedor) {
                    listaProveedoresByServicio[i].nombre = listaProveedoresByServicio[i].proveedorByIdProveedor.nombre;
                }
            }
           
            yield put({type: solicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_SUCCESS, listaProveedoresByServicio})
        } else {
            yield put({type: solicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_FAILURE})
        }
    } catch (error) {
        yield put({type: solicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_FAILURE})
    }
}
export function* listaProveedoresPorIdServicioSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_REQUEST, listaProveedoresPorIdServicio)
}

// TIPOS DE FALLAS
function* listaTiposFallasActivas(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaTiposFallasActivasCall, token)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaTiposFallasActivas = response.data;
            let listaTiposEOI = [];
            let listaTiposReit = [];

            for(let i in listaTiposFallasActivas){
                listaTiposEOI.push({ "idTipoFallaEOI": listaTiposFallasActivas[i].idTipoFalla, "nombre": listaTiposFallasActivas[i].nombre + ' EOI', "tipo": listaTiposFallasActivas[i].tipo, });

                listaTiposReit.push({ "idTipoFallaReit": listaTiposFallasActivas[i].idTipoFalla, "nombre": listaTiposFallasActivas[i].nombre + ' por Reiteración', "tipo": listaTiposFallasActivas[i].tipo, });
            }

            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_SUCCESS, listaTiposFallasActivas, listaTiposEOI, listaTiposReit });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_FAILURE})
    }
}
export function* listaTiposFallasActivasSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST, listaTiposFallasActivas)
}

function* listaTiposFallasBusqueda(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let busqueda = '';
        let page = action.page - 1; 

        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }
        const response = yield call(listaTiposFallasBusquedaCall, token, busqueda, page)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaTiposFallasBusqueda = response.data.data;
            const listaTiposFallasBusquedaCantidad = response.data.cantidad;
            
            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_SUCCESS, listaTiposFallasBusqueda, listaTiposFallasBusquedaCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_FAILURE})
    }
}
export function* listaTiposFallasBusquedaSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST, listaTiposFallasBusqueda)
}

function* estadoTipoFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idTipoFalla = action.idTipoFalla;
        const response = yield call(estadoTipoFallaBusquedaCall, token, idTipoFalla)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del tipo de falla fue cambiado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.ESTADO_TIPO_FALLA_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.ESTADO_TIPO_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.ESTADO_TIPO_FALLA_FAILURE})
    }
}
export function* estadoTipoFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.ESTADO_TIPO_FALLA_REQUEST, estadoTipoFalla)
}

function* nuevoTipoFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idTipoFalla": null,
            "clave": action.formulario.clave,
            "nombre": action.formulario.nombre,
            "descripcion": action.formulario.descripcion,
            'tipo': action.formulario.tipo,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        }
        console.log('data');
        console.log(data);
        const response = yield call(crearTipoFallaBusquedaCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El tipo de falla fue creado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.NUEVO_TIPO_FALLA_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_NUEVO_TIPO_FALLA, showModalNuevoTipoFalla: false, page: action.page, busqueda: action.busqueda});
            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearTipoFalla'});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.NUEVO_TIPO_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.NUEVO_TIPO_FALLA_FAILURE})
    }
}
export function* nuevoTipoFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.NUEVO_TIPO_FALLA_REQUEST, nuevoTipoFalla)
}

function* editarTipoFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idTipoFalla = action.tipoFallaSeleccionado.idTipoFalla;

        const data = {
            "idTipoFalla": idTipoFalla,
            "clave": action.formulario.clave,
            "nombre": action.formulario.nombre,
            "descripcion": action.formulario.descripcion,
            'tipo': action.formulario.tipo,
            "fechaAlta": action.tipoFallaSeleccionado.fechaAlta,
            "fechaActualizacion": action.tipoFallaSeleccionado.fechaActualizacion,
            "activo": action.tipoFallaSeleccionado.activo
        }
        console.log('data');
        console.log(data);
        const response = yield call(editarTipoFallaBusquedaCall, token, data, idTipoFalla)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El tipo de falla fue editado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EDITAR_TIPO_FALLA_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_EDITAR_TIPO_FALLA, showModalEditarTipoFalla: false, page: action.page, busqueda: action.busqueda});
            yield put({ type: solicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EDITAR_TIPO_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.EDITAR_TIPO_FALLA_FAILURE})
    }
}
export function* editarTipoFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.EDITAR_TIPO_FALLA_REQUEST, editarTipoFalla)
}


// CATÁLOGO DE FALLAS
function* listaFallasBusqueda(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = action.filtros.param;
        let idEstandares = null;
        let activos = action.filtros.activo;
        let visibles = action.filtros.visible;
        let {field, order} = action.orden;
        const page = (action.page - 1) * 10; 

        let idServicios = null;
        if(action.filtros.servicios){
            if(action.filtros.servicios[0]){
                idServicios = [];
                for(let i=0; i<action.filtros.servicios.length; i++){
                    idServicios.push(action.filtros.servicios[i].split('-')[0]);
                }
            }
        }
        if(action.filtros.estandares){
            if(action.filtros.estandares[0]){
                idEstandares = [];
                for(let i=0; i<action.filtros.estandares.length; i++){
                    idEstandares.push(action.filtros.estandares[i].split('-')[0]);
                }
            }
        }
        console.log('idServicios');
        console.log(idServicios);
        
        const data = {
            "param": param,
            "idServicios": idServicios,
            "idEstandares": idEstandares,
            "activos": activos,
            "visibles": visibles,
            "field": field,
            "order": order,
            "page": page,
            "offset":10
        }
        console.log('data');
        console.log(data);

        const response = yield call(listaFallasBusquedaCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaFallasBusqueda = response.data.data;
            const listaFallasBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaFallasBusqueda.length; i++){
                if(listaFallasBusqueda[i].nombreTipoFalla){
                    listaFallasBusqueda[i].tipoFalla = listaFallasBusqueda[i].nombreTipoFalla;
                }
                else{
                    listaFallasBusqueda[i].tipoFalla = 'Tipo de falla desactivado';
                }
                if(listaFallasBusqueda[i].nombreServicio){
                    listaFallasBusqueda[i].servicio = listaFallasBusqueda[i].nombreServicio;
                }
                else{
                    listaFallasBusqueda[i].servicio = 'Servicio desactivado';
                }
            }
            
            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_SUCCESS, listaFallasBusqueda, listaFallasBusquedaCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_FAILURE})
    }
}
export function* listaFallasBusquedaSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_REQUEST, listaFallasBusqueda)
}

function* exportarFallasExcel(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = action.filtros.param;
        let idEstandares = null;
        let activos = action.filtros.activo;
        let visibles = action.filtros.visible;
        let {field, order} = action.orden;
        const page = (action.page - 1) * 10; 

        let idServicios = null;
        if(action.filtros.servicios){
            if(action.filtros.servicios[0]){
                idServicios = [];
                for(let i=0; i<action.filtros.servicios.length; i++){
                    idServicios.push(action.filtros.servicios[i].split('-')[0]);
                }
            }
        }
        if(action.filtros.estandares){
            if(action.filtros.estandares[0]){
                idEstandares = [];
                for(let i=0; i<action.filtros.estandares.length; i++){
                    idEstandares.push(action.filtros.estandares[i].split('-')[0]);
                }
            }
        }
        console.log('idServicios');
        console.log(idServicios);
        
        const data = {
            "param": param,
            "idServicios": idServicios,
            "idEstandares": idEstandares,
            "activos": activos,
            "visibles": visibles,
            "field": field,
            "order": order,
            "page": page,
            "offset":10
        }
        console.log('data');
        console.log(data);

        const response = yield call(exportarFallasExcelCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Fallas_" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const showSuccessMsg = true;
            const textMessage = "Los datos se exportaron de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_FAILURE})
    }
}
export function* exportarFallasExcelSaga(){
    yield takeLatest(solicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_REQUEST, exportarFallasExcel)
}

function* estadoFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idFalla = action.idFalla;
        const response = yield call(estadoFallaBusquedaCall, token, idFalla)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado de la falla fue cambiado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.ESTADO_FALLA_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_REQUEST, page: action.page, filtros: action.filtros, orden: action.orden});
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.ESTADO_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.ESTADO_FALLA_FAILURE})
    }
}
export function* estadoFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.ESTADO_FALLA_REQUEST, estadoFalla)
}

function* nuevaFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idTipoFalla = null;
        for(let i=0; i<action.listaTiposFallasActivas.length; i++){
            if(action.formulario.idTipoFalla.split(' (')[0] === action.listaTiposFallasActivas[i].clave + ' - ' + action.listaTiposFallasActivas[i].nombre){
                idTipoFalla = action.listaTiposFallasActivas[i].idTipoFalla;
            }
        }

        let idsPartidas = [];

        for(let i in action.formulario.idsPartidas){
            idsPartidas.push(parseInt(action.formulario.idsPartidas[i]));
        }

        const data = {
            "idFalla":null,
            "nombre": action.formulario.nombre,
            "idTipoFalla": idTipoFalla,
            "idServicio": action.formulario.idServicio,
            "idEstandarDesempeno": action.formulario.idEstandarDesempeno,
            "conversion": action.formulario.conversion,
            "visible": action.formulario.visible,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "idsPartidas": idsPartidas
        }
        console.log('data', data);
        const response = yield call(crearFallaCall, token, data)
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La falla fue creada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.NUEVA_FALLA_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_NUEVA_FALLA, showModalNuevaFalla: false, page: action.page, busqueda: action.busqueda});
            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_REQUEST, page: action.page, filtros: action.busqueda.filtros, orden: action.busqueda.orden});
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearFalla'});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.NUEVA_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.NUEVA_FALLA_FAILURE})
    }
}
export function* nuevaFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.NUEVA_FALLA_REQUEST, nuevaFalla)
}

function* editarFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idTipoFalla = null;
        let idFalla = action.fallaSeleccionada.idFalla;
        for(let i=0; i<action.listaTiposFallasActivas.length; i++){
            if(action.formulario.tipoFallaEdit.split(' (')[0] === action.listaTiposFallasActivas[i].clave + ' - ' + action.listaTiposFallasActivas[i].nombre){
                idTipoFalla = action.listaTiposFallasActivas[i].idTipoFalla;
            }
        }

        let idsPartidas = [];
        for(let i in action.formulario.partidasEdit){
            idsPartidas.push(parseInt(action.formulario.partidasEdit[i]));
        }

        const data = {
            "idFalla": idFalla,
            "nombre": action.formulario.nombre,
            "idTipoFalla": idTipoFalla,
            "idServicio": action.formulario.servicioEdit.split(' - ')[0],
            "idEstandarDesempeno": action.formulario.estandarDesempenoEdit.split(' - ')[0],
            "conversion": action.formulario.conversion,
            "visible": action.formulario.visible,
            "activo": action.fallaSeleccionada.activo,
            "idsPartidas": idsPartidas
        }
        console.log('data');
        console.log(data);
        const response = yield call(editarFallaCall, token, data, idFalla)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La falla fue editada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EDITAR_FALLA_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_EDITAR_FALLA, showModalEditarFalla: false, page: action.page, busqueda: action.busqueda});
            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_REQUEST, page: action.page, filtros: action.busqueda.filtros, orden: action.busqueda.orden});
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarFalla'});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EDITAR_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.EDITAR_FALLA_FAILURE})
    }
}
export function* editarFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.EDITAR_FALLA_REQUEST, editarFalla)
}

// Lista partidas por idServicio
function* listaPartidasByServicio4Fallas(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idServiciosSeleccionados = '';

        if(action.idsServicios){
            if(typeof action.idsServicios === "array"){
                idServiciosSeleccionados.push(parseInt(action.idsServicios));  
            } else {
                idServiciosSeleccionados = parseInt(action.idsServicios);
            }
            
        } 
        else if(action.idServicio){
            idServiciosSeleccionados = action.idServicio
        }

        const response = yield call(listaPartidasByServicio4FallasCall, token, idServiciosSeleccionados);
        console.log('response', response);
        
        if (response.status === 200) {
            let listaPartidasByServicio4Fallas = response.data;

            console.log('listaPartidasByServicio4Fallas', listaPartidasByServicio4Fallas);

            yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS, listaPartidasByServicio4Fallas });

        } else {
            yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_FAILURE});
    }
}
export function* listaPartidasByServicio4FallasSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST, listaPartidasByServicio4Fallas);
}

// CU - Recepción y captura de Solicitud de Servicio
function* crearSolicitudDeServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let nombreUsuario = '';
        let areaUsuario = '';
        let idUsuarioReporta = null;
    
        if(action.usuarioIdentificacion){
            nombreUsuario = action.usuarioIdentificacion;
        }

        if(action.areaPertenece){
            areaUsuario = action.areaPertenece;
        }

        if(action.idUsuarioReporta){
            idUsuarioReporta = action.idUsuarioReporta;
        }

        let idsPartidas = null
        if(action.idsPartidas){
            idsPartidas = action.idsPartidas.split(' - ')[0];
        }
        // if(action.idsPartidas === undefined || !action.idsPartidas[0]){
        //     idsPartidas = []
        // } else {
        //     for(let i in action.idsPartidas){
        //         idsPartidas.push(parseInt(action.idsPartidas[i]));
        //     }
        // }

        const data = {
            "idUsuarioRegistra": action.idUsuarioRegistra,
            "idServicio": action.idServicio,
            "idFalla": action.idFalla,
            "idUbicacion": action.idUbicacion,
            "descripcionSolicitud": action.descripcionSolicitud,
            "tiempoCaptura": action.tiempoCaptura,
            "idUsuarioReporta": idUsuarioReporta,
            "nombreUsuario": nombreUsuario,
            "areaUsuario": areaUsuario,
            "idPartida": idsPartidas,
        }

        console.log('data', data);

        const response = yield call(crearSolicitudDeServicioCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            const showModalFolioSolicitud = true;
            const horaCreacion = action.horaCreacion;
            const fechaCreacion = action.fechaCreacion;
            const folio = response.data.message;
            const identificacion = true;

            yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_FOLIO_SOLICITUD, showModalFolioSolicitud,  horaCreacion ,fechaCreacion, folio, identificacion });
            yield put({ type: solicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_SUCCESS });
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_REQUEST, page: 1 });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearAltaSS'});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
    
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_FAILURE });
    }
}
export function* crearSolicitudDeServicioSaga() {
    yield takeLatest(solicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_REQUEST, crearSolicitudDeServicio);
}

function* exportarSolicitudDeServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
    
        let folio = action.folio;
    
        let response = yield call(exportarSolicitudDeServicioCall, token, folio);
        console.log('response', response)
 
        if (response.status == 200) {
            // Imprime directo, pero en chrome solamente, no descarga el archivo
            // const pdfFile = new Blob([response.data],{type: "application/pdf"});
            // var pdfUrl = URL.createObjectURL(pdfFile);
            // printJS(pdfUrl);

            // Descarga el archivo solamente, no manda a imprimir
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);

            link.download = action.folio + ' ' + new Date().getDate() + "" + month  + "" + + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            if(action.solicitudDeServicioSeleccionado){
                const solicitudDeServicioSeleccionado = action.solicitudDeServicioSeleccionado;
                solicitudDeServicioSeleccionado.impreso = false;
                yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_DETALLE_SS, showModalDetalleSS: true, solicitudDeServicioSeleccionado });
            }
            
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_SUCCESS, impreso: true });;
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_REQUEST, page: action.page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_FAILURE });
    }
}
export function* exportarSolicitudDeServicioSaga() {
    yield takeLatest(solicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_REQUEST, exportarSolicitudDeServicio);
}

function* listaFoliosSS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let field = 'DESC';
        let idServicio = '';
        let idUbicacion = '';
        if(action.idServicio){
            idServicio = 'idServicio=' +  action.idServicio + '&';
        }
        if(action.idUbicacion){
            idUbicacion = 'idUbicacion=' +  action.idUbicacion + '&';
        }
        const response = yield call(listaFoliosSSCall, token, page, field, idServicio, idUbicacion);
        console.log(response);
    
        if (response.status == 200) {
            let listaFoliosSS = response.data.data;
            let listaFoliosSSCantidad = response.data.cantidad;

            for(let i=0; i<listaFoliosSS.length; i++){
                listaFoliosSS[i].estado = listaFoliosSS[i].estado === 'Generada' ? 'Abierto' : listaFoliosSS[i].estado === 'Abierto' ? 'Asignado' : listaFoliosSS[i].estado === 'Terminada' ? 'Realizado' : listaFoliosSS[i].estado;
                let fechaAlta = listaFoliosSS[i].fechaAlta.split('T')[0];
                let horaAlta = listaFoliosSS[i].fechaAlta.split('T')[1]
                listaFoliosSS[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0] + ' ' + horaAlta.split(':')[0] + ':' + horaAlta.split(':')[1];

                let fechaCompromiso = listaFoliosSS[i].fechaCompromiso.split('T')[0];
                let horaCompromiso = listaFoliosSS[i].fechaCompromiso.split('T')[1]
                listaFoliosSS[i].fechaCompromiso = fechaCompromiso.split('-')[2] + '-' + fechaCompromiso.split('-')[1] + '-' + fechaCompromiso.split('-')[0] + ' ' + horaCompromiso.split(':')[0] + ':' + horaCompromiso.split(':')[1];

                listaFoliosSS[i].todoUbicacion = listaFoliosSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                listaFoliosSS[i].todoUbicacionCOA = listaFoliosSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional

                if(listaFoliosSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                    listaFoliosSS[i].todoUbicacion += ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                    listaFoliosSS[i].todoUbicacionCOA += ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional  
                }

                if(listaFoliosSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                    listaFoliosSS[i].todoUbicacion += ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                    listaFoliosSS[i].todoUbicacionCOA += ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional  
                }

                if(listaFoliosSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                    listaFoliosSS[i].todoUbicacion += ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                    listaFoliosSS[i].todoUbicacionCOA += ', ' + listaFoliosSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                }

                if(listaFoliosSS[i].servicioByIdServicio){
                    listaFoliosSS[i].servicio = listaFoliosSS[i].servicioByIdServicio.nombre
                }

                if(listaFoliosSS[i].fallaByIdFalla){
                    listaFoliosSS[i].nombreFalla = listaFoliosSS[i].fallaByIdFalla.nombre;
                }

                if(listaFoliosSS[i].usuarioByIdUsuarioRegistra){
                    listaFoliosSS[i].usuarioRegistra = listaFoliosSS[i].usuarioByIdUsuarioRegistra.nombre + ' ' + listaFoliosSS[i].usuarioByIdUsuarioRegistra.aPaterno;

                    if(listaFoliosSS[i].usuarioByIdUsuarioRegistra.aMaterno){
                        listaFoliosSS[i].usuarioRegistra += ' ' + listaFoliosSS[i].usuarioByIdUsuarioRegistra.aMaterno;
                    }
                }

                if(listaFoliosSS[i].usuarioByIdUsuarioReporta){
                    listaFoliosSS[i].usuarioReporta = listaFoliosSS[i].usuarioByIdUsuarioReporta.nombre + ' ' + listaFoliosSS[i].usuarioByIdUsuarioReporta.aPaterno;

                    if(listaFoliosSS[i].usuarioByIdUsuarioReporta.aMaterno){
                        listaFoliosSS[i].usuarioReporta += ' ' + listaFoliosSS[i].usuarioByIdUsuarioReporta.aMaterno;
                    }
                } else {
                    listaFoliosSS[i].usuarioReporta = listaFoliosSS[i].nombreUsuario;
                }
            }
            
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_SUCCESS, listaFoliosSS, listaFoliosSSCantidad });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_FOLIOS_SS_FAILURE });
    }
}
 export function* listaFoliosSSSaga() {
     yield takeLatest(solicitudDeServiciosActions.LISTA_FOLIOS_SS_REQUEST, listaFoliosSS);
 }

 function* listaFallasByServicio(action) {
    try {
            console.log(action);
            const token = sessionStorage.getItem('token');

            const response = yield call(listaFallasActivasByIdServicioCall, token, action.idServicio);
            console.log(response);

        if (response.status == 200) {
                const listaFallasByServicio = response.data;

                if(listaFallasByServicio.length === 0){
                    message.warning('El servicio seleccionado no cuenta con fallas asignadas.');
                }

                yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_SUCCESS, listaFallasByServicio });
        } else {
                yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_FAILURE });
    }
}
export function* listaFallasByServicioSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_REQUEST, listaFallasByServicio);
}

function* listaUbicacionesSinInterior(action) {
    try {
            console.log(action);
            const token = sessionStorage.getItem('token');

            const response = yield call(ubicacionesSinInterioresCall, token);
            console.log('response',response);

        if (response.status == 200) {
                const listaUbicacionesSinInterior = response.data;

                for(let i = 0; i < listaUbicacionesSinInterior.length; i++){
                    listaUbicacionesSinInterior[i].ubicacionCOA = listaUbicacionesSinInterior[i].claveUbicacion + ', ' + listaUbicacionesSinInterior[i].areaFuncionalByIdAreaFuncional.areaFuncional;

                    if(listaUbicacionesSinInterior[i].unidadFuncionalByIdUnidadFuncional){
                        listaUbicacionesSinInterior[i].ubicacionCOA += ', ' + listaUbicacionesSinInterior[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                }

                yield put({ type: solicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_SUCCESS, listaUbicacionesSinInterior });
        } else {
                yield put({ type: solicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_FAILURE });
    }
}
export function* listaUbicacionesSinInteriorSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_REQUEST, listaUbicacionesSinInterior);
}

//Lista Servicios Activos con Proveedor
function* listaServiciosActivosProveedores(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosActivosConProveedoresCall, token);
        console.log('response', response)

        if (response.status === 200) {
            let listaServiciosActivosProveedores = response.data;

            yield put({ type: solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_SUCCESS, listaServiciosActivosProveedores});
        } else {
            yield put({ type: solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_FAILURE});
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_FAILURE});
    }
}
export function* listaServiciosActivosProveedoresSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_REQUEST, listaServiciosActivosProveedores);
}

 // Lista Fallas Activas.
function* listaFallasActivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaFallasActivasCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaFallasActivas = response.data;


            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_SUCCESS, listaFallasActivas });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_FAILURE });
    }
}
export function* listaFallasActivasSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_REQUEST, listaFallasActivas);
}
// Lista partidas por idFalla
function* listaPartidasByFalla(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPartidasByFallaCall, token, action.idFalla)
        console.log('response listaPartidasByFalla', response);

        if (response.status === 200) {
            const listaPartidasByFalla = response.data;

            for(let i in listaPartidasByFalla){
                listaPartidasByFalla[i].partidas = listaPartidasByFalla[i].idPartida + ' - ' + listaPartidasByFalla[i].nomenclatura + ' ('+ listaPartidasByFalla[i].nombre + ')'
            }
            
            yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_SUCCESS, listaPartidasByFalla });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_FAILURE})
    }
}
export function* listaPartidasByFallaSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_REQUEST, listaPartidasByFalla)
}

// Registrar tiempo límite de alta de SS
function* registrarTiempoLimite(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const usuario = action.usuario;

        const response = yield call(registrarTiempoLimiteCall, token, usuario);
        console.log('response listaPartidasByFalla', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se excedió el tiempo límite, favor de identificarse de nuevo.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });

            yield put({ type: solicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_SUCCESS, registrarTiempoLimite });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_FAILURE })
        }
    } catch (error) {
        yield put({ type: solicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_FAILURE})
    }
}
export function* registrarTiempoLimiteSaga(){
    yield takeLatest(solicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_REQUEST, registrarTiempoLimite)
}

// CU - Reporteador Automático
function* listaSSDiarios(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let fechaVerificar = '';

        let response = '';
        
        if(action.fechaVerificar){
            fechaVerificar = action.fechaVerificar.split('-')[2] + '-' + action.fechaVerificar.split('-')[1] + '-' + action.fechaVerificar.split('-')[0];

            response = yield call(listaSSReporteadorAutoCall, token, fechaVerificar, page);
        }

        console.log('fechaVerificar', action.fechaVerificar);
        console.log(response);
    
         if (response.status == 200) {
                let listaSSDiarios = response.data.data;
                let listaSSDiariosCantidad = response.data.cantidad;
                let listaSSDiariosNoCancelados = [];
                let reporterBoolean = true;
                let ayer = new Date();
                ayer.setDate(ayer.getDate() - 1); // -1
                ayer = ayer.toLocaleDateString();

                let ayerGuion = ('0' + ayer.split('/')[0]).slice(-2) + '-' + ('0' + ayer.split('/')[1]).slice(-2) + '-' + ayer.split('/')[2];
                //console.log('ayerGuion', ayerGuion);

                for(let i=0; i<listaSSDiarios.length; i++){
                    listaSSDiarios[i].estado = listaSSDiarios[i].estado === 'Generada' ? 'Abierto' : listaSSDiarios[i].estado === 'Abierto' ? 'Asignado' : listaSSDiarios[i].estado === 'Terminada' ? 'Realizado' : listaSSDiarios[i].estado;
                    let fechaAlta = listaSSDiarios[i].fechaAlta.split('T')[0];
                    let horaAlta = listaSSDiarios[i].fechaAlta.split('T')[1];

                    listaSSDiarios[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];

                    listaSSDiarios[i].horaAlta = horaAlta.split(':')[0] + ':' + horaAlta.split(':')[1];

                    if(listaSSDiarios[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                        listaSSDiarios[i].area = listaSSDiarios[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    }

                    if(listaSSDiarios[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        listaSSDiarios[i].unidad = listaSSDiarios[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    } else {
                        listaSSDiarios[i].unidad = 'No cuenta con unidad';
                    }

                    if(listaSSDiarios[i].servicioByIdServicio){
                            listaSSDiarios[i].servicio = listaSSDiarios[i].servicioByIdServicio.nombre;
                    }

                    if(listaSSDiarios[i].fechaAlta === action.fechaVerificar){
                        if(listaSSDiarios[i].estado !== 'Realizado' && listaSSDiarios[i].estado !== 'Cerrada'){
                            listaSSDiariosNoCancelados.push(listaSSDiarios[i]);
                            reporterBoolean = false;
                        }

                        if(!listaSSDiariosNoCancelados[0]){ message.info('No hay folios pendientes generados el: ' + action.fechaVerificar); }
                    }
                }

                console.log('listaSSDiarios', listaSSDiarios)
                console.log('listaSSDiariosNoCancelados', listaSSDiariosNoCancelados)
              
              yield put({ type: solicitudDeServiciosActions.LISTA_SS_DIARIOS_SUCCESS, listaSSDiarios, listaSSDiariosCantidad, reporterBoolean });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_SS_DIARIOS_FAILURE });
        }
 
    } catch (error) {
         console.log(error);
         yield put({ type: solicitudDeServiciosActions.LISTA_SS_DIARIOS_FAILURE });
    }
}
export function* listaSSDiariosSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_SS_DIARIOS_REQUEST, listaSSDiarios);
}

function* visualizarReporte(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
    
        let fechaReporte = action.fechaReporte;
    
        let response = yield call(visualizarReporteCall, token, fechaReporte);
        console.log('response', response)
 
        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteAutomatico_" + fechaReporte + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: solicitudDeServiciosActions.VISUALIZAR_REPORTE_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'Se descargó el archivo correctamente' });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.VISUALIZAR_REPORTE_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.VISUALIZAR_REPORTE_FAILURE });
    }
}
export function* visualizarReporteSaga() {
    yield takeLatest(solicitudDeServiciosActions.VISUALIZAR_REPORTE_REQUEST, visualizarReporte);
}


//Reporte Solicitud Servicio
function* listaReportesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const servicio = [];
        let estatus = [];
        if(action.busqueda.estatus){
            if(action.busqueda.estatus[0]){
                estatus = action.busqueda.estatus;
            }
        }
        
        if(action.busqueda.servicios){
            if(action.busqueda.servicios[0]){
                for(let i=0; i<action.busqueda.servicios.length; i++){
                    servicio.push(action.busqueda.servicios[i].split('-')[0]);
                }
            }
        }
        const data = {
            "fechaInicial" : action.busqueda.fechaInicial,
            "fechaFinal" : action.busqueda.fechaFinal,
            "servicio" : servicio,
            "estatus" : estatus
        }
        console.log('data');
        console.log(data);

        const response = yield call(listaReporteBusquedaCall, token, page, data);
        console.log(response);

        if (response.status == 200) {
            const listaReporteBusqueda = response.data.data;
            const listaReporteBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaReporteBusqueda.length; i++){
                listaReporteBusqueda[i].estado = listaReporteBusqueda[i].estado === 'Generada' ? 'Abierto' : listaReporteBusqueda[i].estado === 'Abierto' ? 'Asignado' : listaReporteBusqueda[i].estado === 'Terminada' ? 'Realizado' : listaReporteBusqueda[i].estado;;
                if(listaReporteBusqueda[i].servicioByIdServicio){
                    listaReporteBusqueda[i].servicio = listaReporteBusqueda[i].servicioByIdServicio.nomenclatura + ' - ' + listaReporteBusqueda[i].servicioByIdServicio.nombre;
                }
                else{
                    listaReporteBusqueda[i].servicio = 'No tiene servicio';
                }

                if(listaReporteBusqueda[i].ubicacionByIdUbicacion){
                    if(listaReporteBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                        listaReporteBusqueda[i].ubicacion = listaReporteBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    }
                    if(listaReporteBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        listaReporteBusqueda[i].ubicacion += ', ' + listaReporteBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                    if(listaReporteBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                        listaReporteBusqueda[i].ubicacion += ', ' + listaReporteBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    }
                    if(listaReporteBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                        listaReporteBusqueda[i].ubicacion += ', ' + listaReporteBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    }
                }
                else{
                    listaReporteBusqueda[i].servicio = 'No tiene ubicación';
                }
                listaReporteBusqueda[i].fechaAlta = listaReporteBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaReporteBusqueda[i].fechaAlta.split('T')[1].split(':')[0] + ':' +listaReporteBusqueda[i].fechaAlta.split('T')[1].split(':')[1];

                if(listaReporteBusqueda[i].estado !== 'Cerrado'){
                    listaReporteBusqueda[i].actividadesRealizadas = 'No tiene actividades';
                }
                
                if(!listaReporteBusqueda[i].fechaTerminacion){
                    listaReporteBusqueda[i].fechaTerminacion = 'No tiene fecha de realizado'
                }
                else{
                    listaReporteBusqueda[i].fechaTerminacion = listaReporteBusqueda[i].fechaTerminacion.split('T')[0].split('-').reverse().join('-') + ' ' + listaReporteBusqueda[i].fechaTerminacion.split('T')[1].split(':')[0] + ':' +listaReporteBusqueda[i].fechaTerminacion.split('T')[1].split(':')[1];
                }
                if(!listaReporteBusqueda[i].fechaCierre){
                    listaReporteBusqueda[i].fechaCierre = 'No tiene fecha de cierre'
                }
                else{
                    listaReporteBusqueda[i].fechaCierre = listaReporteBusqueda[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + listaReporteBusqueda[i].fechaCierre.split('T')[1].split(':')[0] + ':' +listaReporteBusqueda[i].fechaCierre.split('T')[1].split(':')[1];
                }
            }

            yield put({ type: solicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_SUCCESS, listaReporteBusqueda, listaReporteBusquedaCantidad });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_FAILURE });
    }
}
export function* listaReportesBusquedaSaga() {
    yield takeLatest(solicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_REQUEST, listaReportesBusqueda);
}

function* exportarReporteExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const servicio = [];
        let estatus = [];
        if(action.busqueda.estatus){
            if(action.busqueda.estatus[0]){
                estatus = action.busqueda.estatus;
            }
        }
        
        if(action.busqueda.servicios){
            if(action.busqueda.servicios[0]){
                for(let i=0; i<action.busqueda.servicios.length; i++){
                    servicio.push(action.busqueda.servicios[i].split('-')[0]);
                }
            }
        }

        const data = {
            "fechaInicial" : action.busqueda.fechaInicial,
            "fechaFinal" : action.busqueda.fechaFinal,
            "servicio" : servicio,
            "estatus" : estatus
        }

        const response = yield call(exportarReporteBusquedaExcelCall, token, data);
        console.log(response);
 
        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Reporte_Solicitud_Servicio-" + new Date().getDate() + "" + + new Date().getMonth()  + "" + + new Date().getFullYear() + "-" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            if(action.solicitudDeServicioSeleccionado){
                const solicitudDeServicioSeleccionado = action.solicitudDeServicioSeleccionado;
                solicitudDeServicioSeleccionado.impreso = false;
                yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_DETALLE_SS, showModalDetalleSS: true, solicitudDeServicioSeleccionado });
            }
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'Se descargó el archivo correctamente' });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_FAILURE });
    }
}
export function* exportarReporteExcelSaga() {
    yield takeLatest(solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_REQUEST, exportarReporteExcel);
}

function* exportarReportePDF(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const servicio = [];
        let estatus = [];
        if(action.busqueda.estatus){
            if(action.busqueda.estatus[0]){
                estatus = action.busqueda.estatus;
            }
        }
        
        if(action.busqueda.servicios){
            if(action.busqueda.servicios[0]){
                for(let i=0; i<action.busqueda.servicios.length; i++){
                    servicio.push(action.busqueda.servicios[i].split('-')[0]);
                }
            }
        }

        const data = {
            "fechaInicial" : action.busqueda.fechaInicial,
            "fechaFinal" : action.busqueda.fechaFinal,
            "servicio" : servicio,
            "estatus" : estatus
        }

        const response = yield call(exportarReporteBusquedaPDFCall, token, data);
        console.log(response);
 
        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // function closePrint () {
            //     document.body.removeChild(this.__container__);
            // }

            // function setPrint () {
            //     this.contentWindow.__container__ = this;
            //     this.contentWindow.onbeforeunload = closePrint;
            //     this.contentWindow.onafterprint = closePrint;
            //     this.contentWindow.focus(); // Required for IE
            //     console.log(this.contentWindow);  
            //     this.contentWindow.print();
            // }

            // var oHiddFrame = document.createElement("iframe");
            // oHiddFrame.onload = setPrint;
            // oHiddFrame.style.visibility = "hidden";
            // oHiddFrame.style.position = "fixed";
            // oHiddFrame.style.right = "0";
            // oHiddFrame.style.bottom = "0";
            // oHiddFrame.src = url;
            // console.log('url');
            // console.log(url);
            // document.body.appendChild(oHiddFrame);
            
            const link = document.createElement('a');
            link.href = url;
            link.download = "Reporte_Solicitud_Servicio-" + new Date().getDate() + "" + + new Date().getMonth()  + "" + + new Date().getFullYear() + "-" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            if(action.solicitudDeServicioSeleccionado){
                const solicitudDeServicioSeleccionado = action.solicitudDeServicioSeleccionado;
                solicitudDeServicioSeleccionado.impreso = false;
                yield put({ type: solicitudDeServiciosActions.SHOW_MODAL_DETALLE_SS, showModalDetalleSS: true, solicitudDeServicioSeleccionado });
            }
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'Se descargó el archivo correctamente' });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_FAILURE });
    }
}
export function* exportarReportePDFSaga() {
    yield takeLatest(solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_REQUEST, exportarReportePDF);
}

//Lista Reporte Dinámico
function* listaReporteDinamico (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page === null ? action.page: action.page > 1 ? (action.page -1)*10 : action.page- 1;
        let {idServicio=null,idAreaFuncional=null,idUnidadFuncional=null,idInteriorFuncional=null,usuarioSolicito=null,
            usuarioRegistro=null,fechaAlta=null,fechaCierre=null,fechaTerminacion=null,estado=null, queja=null} = action.formulario;
        const {order, field} = action.order;

        idServicio = idServicio.map(servicio => parseInt(servicio));
        idAreaFuncional = idAreaFuncional ? parseInt(idAreaFuncional) : null;
        idUnidadFuncional = idUnidadFuncional ? parseInt(idUnidadFuncional) : null;
        idInteriorFuncional = idInteriorFuncional ? parseInt(idInteriorFuncional) : null;

        const agrupamiento = action.agrupamiento ? action.agrupamiento: null;
        const fechaAltaInicio = fechaAlta ? fechaAlta[0].format('YYYY-MM-DD') : null;
        const fechaAltaFin = fechaAlta ? fechaAlta[1].format('YYYY-MM-DD') : null;
        const fechaCierreInicio = fechaCierre ? fechaCierre[0].format('YYYY-MM-DD') : null;
        const fechaCierreFin = fechaCierre ? fechaCierre[1].format('YYYY-MM-DD') : null;
        const fechaTerminacionInicio = fechaTerminacion ? fechaTerminacion[0].format('YYYY-MM-DD') : null;
        const fechaTerminacionFin = fechaTerminacion ? fechaTerminacion[1].format('YYYY-MM-DD') : null;
        
        console.log('folios', action.folios);
        console.log('agrupamiento', agrupamiento)

        const data = {
            "idsServicios": agrupamiento === 'Servicio' ? [action.folios.identificador] : idServicio,
            "idGruposSer": agrupamiento === 'GrupoSer' ? [action.folios.identificador] : null,
            "idAreaFuncional": agrupamiento === 'Area' ? action.folios.identificador : idAreaFuncional,
            "idUnidadFuncional":idUnidadFuncional,
            "idInteriorFuncional":idInteriorFuncional,
            "queja": agrupamiento === 'Clasificacion' ? action.folios.etiqueta : queja,
            "usuarioSolicito": agrupamiento === 'UsuarioSolicitante' ? action.folios.etiqueta.trim() : usuarioSolicito,
            "usuarioRegistro": agrupamiento === 'UsuarioRegistra' ? action.folios.etiqueta.trim() : usuarioRegistro,
            "fechaAltaInicio": fechaAltaInicio,
            "fechaAltaFin": fechaAltaFin,
            "fechaCierreInicio": fechaCierreInicio,
            "fechaCierreFin": fechaCierreFin,
            "fechaTerminacionInicio": fechaTerminacionInicio,
            "fechaTerminacionFin": fechaTerminacionFin,
            "estado":estado,
            "page":page,
            "offset": 10,
            "order":order,
            "field": field,
            "agrupamiento": agrupamiento,
        }

        console.log('data', data)
        
        let response = yield call(listaReporteDinamicoCall, token, data);
        console.log(response);

        if (response.status == 200) {
            let listaReporteDinamicoBusqueda = response.data.data;
            const listaReporteDinamicoBusquedaCantidad = response.data.cantidad;

            for (let reporte of listaReporteDinamicoBusqueda) {
                reporte.fechaAlta = reporte.fechaAlta.split('T')[0].split('-').reverse().join('-') + '  ' + reporte.fechaAlta.split('T')[1].split('.')[0];
                reporte.fechaCierre = reporte.fechaCierre ? reporte.fechaCierre.split('T')[0].split('-').reverse().join('-') + '  ' + reporte.fechaCierre.split('T')[1].split('.')[0] : null;
                reporte.fechaTerminacion = reporte.fechaTerminacion ? reporte.fechaTerminacion.split('T')[0].split('-').reverse().join('-') +'  ' + reporte.fechaTerminacion.split('T')[1].split('.')[0] : null;
            }

            for(let i in listaReporteDinamicoBusqueda){
                listaReporteDinamicoBusqueda[i].queja ? listaReporteDinamicoBusqueda[i].queja = listaReporteDinamicoBusqueda[i].queja: listaReporteDinamicoBusqueda[i].queja = 'SS'; 
            }

            if(action.folios){
                const listaReporteDinamicoGrupo = action.folios.listaReporteDinamicoGrupo;
                const listaReporteDinamicoGrupoCantidad = action.folios.listaReporteDinamicoGrupoCantidad;
                for(let i in listaReporteDinamicoGrupo){
                    if(agrupamiento === 'Area' || agrupamiento === 'GrupoSer' || agrupamiento === 'Servicio'){
                        if(action.folios.identificador === listaReporteDinamicoGrupo[i].identificador){
                            listaReporteDinamicoGrupo[i].agrupados = response.data;
                            listaReporteDinamicoGrupo[i].agrupados.page = action.page;
                            for(let x = 0; x < listaReporteDinamicoGrupo[i].agrupados.data.length; x++){
                                listaReporteDinamicoGrupo[i].agrupados.data[x].id += x;
                            };
                        }
                    } else {
                        if(action.folios.etiqueta === listaReporteDinamicoGrupo[i].etiqueta){
                            listaReporteDinamicoGrupo[i].agrupados = response.data;
                            listaReporteDinamicoGrupo[i].agrupados.page = action.page;
                            for(let x = 0; x < listaReporteDinamicoGrupo[i].agrupados.data.length; x++){
                                listaReporteDinamicoGrupo[i].agrupados.data[x].id += x;
                            };
                        }
                    }
                }

                console.log(`listaReporteDinamicoGrupo cambio`, listaReporteDinamicoGrupo)
                yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_SUCCESS, listaReporteDinamicoGrupo, listaReporteDinamicoGrupoCantidad })
                yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_FAILURE });
            }
            else{
                yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_SUCCESS, listaReporteDinamicoBusqueda, listaReporteDinamicoBusquedaCantidad });
            }
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_FAILURE });
    }
}
export function* listaReporteDinamicoSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST, listaReporteDinamico)
}

function* exportarReporteDinamicoSSPDF (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {checkbox} = action;
        let {idServicio=null,idAreaFuncional=null,idUnidadFuncional=null,idInteriorFuncional=null,usuarioSolicito=null,
            usuarioRegistro=null,fechaAlta=null,fechaCierre=null,fechaTerminacion=null,estado=null, queja=null} = action.formulario;
        const {order, field} = action.order;
        const agrupamiento = action.agrupamiento ? action.agrupamiento: null;

        idServicio = idServicio.map(servicio => parseInt(servicio));
        idAreaFuncional = idAreaFuncional ? parseInt(idAreaFuncional) : null;
        idUnidadFuncional = idUnidadFuncional ? parseInt(idUnidadFuncional) : null;
        idInteriorFuncional = idInteriorFuncional ? parseInt(idInteriorFuncional) : null;
        queja = queja ? queja : null;
        fechaAlta = fechaAlta ? fechaAlta.format('YYYY-MM-DD') : null;
        fechaCierre = fechaCierre ? fechaCierre.format('YYYY-MM-DD') : null;
        fechaTerminacion = fechaTerminacion ? fechaTerminacion.format('YYYY-MM-DD') : null;

        const data = {
            "idsServicios":idServicio,
            "idGruposSer":null,
            "idAreaFuncional":idAreaFuncional,
            "idUnidadFuncional":idUnidadFuncional,
            "idInteriorFuncional":idInteriorFuncional,
            "queja": queja,
            "usuarioSolicito":usuarioSolicito,
            "usuarioRegistro":usuarioRegistro,
            "fechaAlta":fechaAlta,
            "fechaCierre":fechaCierre,
            "fechaTerminacion":fechaTerminacion,
            "estado":estado,
            "page":null,
            "offset": null,
            "order": order,
            "field": field,
            "agrupamiento": agrupamiento,
            "servicioC": checkbox.nombreServicio,
            "folioC": checkbox.folioSolicitudServicio,
            "quejaC": checkbox.queja,
            "descripcionFallaC": checkbox.descripcionFalla,
            "areaC": checkbox.areaFuncional,
            "unidadC": checkbox.unidadFuncional,
            "interiorC": checkbox.interiorFuncional,
            "usuarioSolicitoC": checkbox.usuarioSolicitoName,
            "usuarioRegistroC": checkbox.usuarioRegistro,
            "fechaAltaC": checkbox.fechaAlta,
            "fechaCierreC": checkbox.fechaCierre,
            "fechaTerminacionC": checkbox.fechaTerminacion,
            "estadoC": checkbox.estado,
            "actividadesRealizadasC": checkbox.actividadesRealizadas
        }
        
        let response = yield call(exportarReporteDPDFCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El reporte dinámico se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteDinamico" + new Date().getDate() + "-" + new Date().getMonth()  + "-" + + new Date().getFullYear() + "" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_SUCCESS});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_FAILURE });
    }
}
export function* exportarReporteDinamicoSSPDFSaga(){
    yield takeLatest(solicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_REQUEST, exportarReporteDinamicoSSPDF)
}

function* exportarReporteDinamicoSSExcel (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const { checkbox} = action;
        let {idServicio=null,idAreaFuncional=null,idUnidadFuncional=null,idInteriorFuncional=null,usuarioSolicito=null,
            usuarioRegistro=null,fechaAlta=null,fechaCierre=null,fechaTerminacion=null,estado=null,queja=null} = action.formulario;
        const { order, field } = action.order;
        const agrupamiento = action.agrupamiento ? action.agrupamiento: null;

        idServicio = idServicio.map(servicio => parseInt(servicio));
        idAreaFuncional = idAreaFuncional ? parseInt(idAreaFuncional) : null;
        idUnidadFuncional = idUnidadFuncional ? parseInt(idUnidadFuncional) : null;
        idInteriorFuncional = idInteriorFuncional ? parseInt(idInteriorFuncional) : null;
        queja = queja ? queja: null;
        fechaAlta = fechaAlta ? fechaAlta.format('YYYY-MM-DD') : null;
        fechaCierre = fechaCierre ? fechaCierre.format('YYYY-MM-DD') : null;
        fechaTerminacion = fechaTerminacion ? fechaTerminacion.format('YYYY-MM-DD') : null;

        const data = {
            "idsServicios":idServicio,
            "idGruposSer":null,
            "idAreaFuncional":idAreaFuncional,
            "idUnidadFuncional":idUnidadFuncional,
            "idInteriorFuncional":idInteriorFuncional,
            "queja": queja,
            "usuarioSolicito":usuarioSolicito,
            "usuarioRegistro":usuarioRegistro,
            "fechaAlta":fechaAlta,
            "fechaCierre":fechaCierre,
            "fechaTerminacion":fechaTerminacion,
            "estado":estado,
            "page":null,
            "offset": null,
            "order": order,
            "field": field,
            "agrupamiento": agrupamiento,
            "servicioC": checkbox.nombreServicio,
            "quejaC": checkbox.queja,
            "folioC": checkbox.folioSolicitudServicio,
            "descripcionFallaC": checkbox.descripcionFalla,
            "areaC": checkbox.areaFuncional,
            "unidadC": checkbox.unidadFuncional,
            "interiorC": checkbox.interiorFuncional,
            "usuarioSolicitoC": checkbox.usuarioSolicitoName,
            "usuarioRegistroC": checkbox.usuarioRegistro,
            "fechaAltaC": checkbox.fechaAlta,
            "fechaCierreC": checkbox.fechaCierre,
            "fechaTerminacionC": checkbox.fechaTerminacion,
            "estadoC": checkbox.estado,
            "actividadesRealizadasC": checkbox.actividadesRealizadas
        }
        
        const response = yield call(exportarReporteDExcelCall, token, data);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El reporte dinámico se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteDinamico" + new Date().getDate() + "-" + new Date().getMonth()  + "-" + + new Date().getFullYear() + "" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_SUCCESS });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_FAILURE });
    }
}
export function* exportarReporteDinamicoSSExcelSaga(){
    yield takeLatest(solicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_REQUEST, exportarReporteDinamicoSSExcel)
} 

function* listaReporteDinamicoAgrupamiento (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page > 1 ? (action.page -1)*10 : action.page- 1;
        let {idServicio=null,idAreaFuncional=null,idUnidadFuncional=null,idInteriorFuncional=null,usuarioSolicito=null,
            usuarioRegistro=null,fechaAlta=null,fechaCierre=null,fechaTerminacion=null,estado=null, queja=null} = action.formulario;
        const {order, field} = action.order;

        idServicio = idServicio.map(servicio => parseInt(servicio));
        idAreaFuncional = idAreaFuncional ? parseInt(idAreaFuncional) : null;
        idUnidadFuncional = idUnidadFuncional ? parseInt(idUnidadFuncional) : null;
        idInteriorFuncional = idInteriorFuncional ? parseInt(idInteriorFuncional) : null;

        const agrupamiento = action.agrupamiento ? action.agrupamiento: null;
        const fechaAltaInicio = fechaAlta ? fechaAlta[0].format('YYYY-MM-DD') : null;
        const fechaAltaFin = fechaAlta ? fechaAlta[1].format('YYYY-MM-DD') : null;
        const fechaCierreInicio = fechaCierre ? fechaCierre[0].format('YYYY-MM-DD') : null;
        const fechaCierreFin = fechaCierre ? fechaCierre[1].format('YYYY-MM-DD') : null;
        const fechaTerminacionInicio = fechaTerminacion ? fechaTerminacion[0].format('YYYY-MM-DD') : null;
        const fechaTerminacionFin = fechaTerminacion ? fechaTerminacion[1].format('YYYY-MM-DD') : null;
        
        const data = {
            "idsServicios":idServicio,
            "idGruposSer": null,
            "idAreaFuncional":idAreaFuncional,
            "idUnidadFuncional":idUnidadFuncional,
            "idInteriorFuncional":idInteriorFuncional,
            "queja": queja,
            "usuarioSolicito":usuarioSolicito,
            "usuarioRegistro":usuarioRegistro,
            "fechaAltaInicio": fechaAltaInicio,
            "fechaAltaFin": fechaAltaFin,
            "fechaCierreInicio": fechaCierreInicio,
            "fechaCierreFin": fechaCierreFin,
            "fechaTerminacionInicio": fechaTerminacionInicio,
            "fechaTerminacionFin": fechaTerminacionFin,
            "estado": estado,
            "page": page,
            "offset": 10,
            "order":order,
            "field": field,
            "agrupamiento": agrupamiento
        }

        let response = yield call(listaReporteDinamicoAgrupamientoCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            let listaReporteDinamicoGrupo = response.data.data;
            const listaReporteDinamicoGrupoCantidad = response.data.cantidad;
            const quejasArray = ['SNP', 'queja', 'SS', 'Prorroga'];

            for(let i in listaReporteDinamicoGrupo){
                if(listaReporteDinamicoGrupo[i].etiquetaLarga){
                    listaReporteDinamicoGrupo[i].etiquetaTabla = listaReporteDinamicoGrupo[i].etiquetaLarga;
                } else {
                    listaReporteDinamicoGrupo[i].etiquetaTabla = listaReporteDinamicoGrupo[i].etiqueta;
                }
                    if( agrupamiento === 'Servicio') {
                        for(let j in action.formulario.idServicio){
                            if(listaReporteDinamicoGrupo[i].identificador === parseInt(action.formulario.idServicio[j])){
                                const dataAgrupado = {
                                    "idsServicios": [parseInt(action.formulario.idServicio[j])],
                                    "idGruposSer": null,
                                    "idAreaFuncional":idAreaFuncional,
                                    "idUnidadFuncional":idUnidadFuncional,
                                    "idInteriorFuncional":idInteriorFuncional,
                                    "queja": queja,
                                    "usuarioSolicito":usuarioSolicito,
                                    "usuarioRegistro":usuarioRegistro,
                                    "fechaAltaInicio": fechaAltaInicio,
                                    "fechaAltaFin": fechaAltaFin,
                                    "fechaCierreInicio": fechaCierreInicio,
                                    "fechaCierreFin": fechaCierreFin,
                                    "fechaTerminacionInicio": fechaTerminacionInicio,
                                    "fechaTerminacionFin": fechaTerminacionFin,
                                    "estado": estado,
                                    "page": 0,
                                    "offset": 10,
                                    "order":order,
                                    "field": field,
                                    "agrupamiento": agrupamiento,
                                }
                                console.log('dataAgrupado', dataAgrupado)
                        
                                const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado);
                                if(responseAgrupado.status === 200){
                                    listaReporteDinamicoGrupo[i].agrupados = responseAgrupado.data;
                                    listaReporteDinamicoGrupo[i].agrupados.page = 1;
                                    for(let x = 0; x < listaReporteDinamicoGrupo[i].agrupados.data.length; x++){
                                        listaReporteDinamicoGrupo[i].agrupados.data[x].id += x;
                                        
                                    };
                                }
                                else{
                                    const showErrorMsg = true;
                                    const textMessage = responseAgrupado.message;
        
                                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                                    yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
                                }
                            }
                        }
                    }
                    else if( agrupamiento === 'GrupoSer') {
                        for(let k in action.listaGruposServicios){
                            if(listaReporteDinamicoGrupo[i].etiqueta === action.listaGruposServicios[k].nombre){
                                const dataAgrupado = {
                                    "idsServicios": idServicio,
                                    "idGruposSer": [action.listaGruposServicios[k].idGrupoSer],
                                    "idAreaFuncional":idAreaFuncional,
                                    "idUnidadFuncional":idUnidadFuncional,
                                    "idInteriorFuncional":idInteriorFuncional,
                                    "queja": queja,
                                    "usuarioSolicito":usuarioSolicito,
                                    "usuarioRegistro":usuarioRegistro,
                                    "fechaAltaInicio": fechaAltaInicio,
                                    "fechaAltaFin": fechaAltaFin,
                                    "fechaCierreInicio": fechaCierreInicio,
                                    "fechaCierreFin": fechaCierreFin,
                                    "fechaTerminacionInicio": fechaTerminacionInicio,
                                    "fechaTerminacionFin": fechaTerminacionFin,
                                    "estado": estado,
                                    "page": 0,
                                    "offset": 10,
                                    "order":order,
                                    "field": field,
                                    "agrupamiento": agrupamiento,
                                }
                        
                                const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado);
                                if(responseAgrupado.status === 200){
                                    listaReporteDinamicoGrupo[i].agrupados = responseAgrupado.data;
                                    listaReporteDinamicoGrupo[i].agrupados.page = 1;
                                    for(let x in listaReporteDinamicoGrupo[i].agrupados.data){
                                        listaReporteDinamicoGrupo[i].agrupados.data[x].id = x + 1;
                                        
                                    };
                                }
                                else{
                                    const showErrorMsg = true;
                                    const textMessage = responseAgrupado.message;
        
                                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                                    yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
                                }
                            }
                        }
                    }
                    else if( agrupamiento === 'Area')  {
                        for(let k in action.listaAreasFuncionalesActivas){
                            if(listaReporteDinamicoGrupo[i].etiquetaLarga === action.listaAreasFuncionalesActivas[k].areaFuncional){
                                const dataAgrupado = {
                                    "idsServicios": idServicio,
                                    "idGruposSer": null,
                                    "idAreaFuncional": action.listaAreasFuncionalesActivas[k].idAreaFuncional,
                                    "idUnidadFuncional":idUnidadFuncional,
                                    "idInteriorFuncional":idInteriorFuncional,
                                    "queja": queja,
                                    "usuarioSolicito":usuarioSolicito,
                                    "usuarioRegistro":usuarioRegistro,
                                    "fechaAltaInicio": fechaAltaInicio,
                                    "fechaAltaFin": fechaAltaFin,
                                    "fechaCierreInicio": fechaCierreInicio,
                                    "fechaCierreFin": fechaCierreFin,
                                    "fechaTerminacionInicio": fechaTerminacionInicio,
                                    "fechaTerminacionFin": fechaTerminacionFin,
                                    "estado": estado,
                                    "page": 0,
                                    "offset": 10,
                                    "order":order,
                                    "field": field,
                                    "agrupamiento": agrupamiento,
                                }
                        
                                const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado);
                                if(responseAgrupado.status === 200){
                                    listaReporteDinamicoGrupo[i].agrupados = responseAgrupado.data;
                                    listaReporteDinamicoGrupo[i].agrupados.page = 1;
                                    for(let x in listaReporteDinamicoGrupo[i].agrupados.data){
                                        listaReporteDinamicoGrupo[i].agrupados.data[x].id = x + 1;
                                        
                                    };
                                }
                                else{
                                    const showErrorMsg = true;
                                    const textMessage = responseAgrupado.message;
        
                                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                                    yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
                                }
                            }
                        }
                    }
                    else if( agrupamiento === 'Clasificacion') {
                        for(let k in quejasArray){
                            if(quejasArray[k] === listaReporteDinamicoGrupo[i].etiqueta){
                                const dataAgrupado = {
                                    "idsServicios": idServicio,
                                    "idGruposSer": null,
                                    "idAreaFuncional": idAreaFuncional,
                                    "idUnidadFuncional":idUnidadFuncional,
                                    "idInteriorFuncional":idInteriorFuncional,
                                    "queja": quejasArray[k],
                                    "usuarioSolicito":usuarioSolicito,
                                    "usuarioRegistro":usuarioRegistro,
                                    "fechaAltaInicio": fechaAltaInicio,
                                    "fechaAltaFin": fechaAltaFin,
                                    "fechaCierreInicio": fechaCierreInicio,
                                    "fechaCierreFin": fechaCierreFin,
                                    "fechaTerminacionInicio": fechaTerminacionInicio,
                                    "fechaTerminacionFin": fechaTerminacionFin,
                                    "estado": estado,
                                    "page": 0,
                                    "offset": 10,
                                    "order":order,
                                    "field": field,
                                    "agrupamiento": agrupamiento,
                                }
                                const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado);
                                if(responseAgrupado.status === 200){
                                    listaReporteDinamicoGrupo[i].agrupados = responseAgrupado.data;
                                    listaReporteDinamicoGrupo[i].agrupados.page = 1;
                                    for(let x in listaReporteDinamicoGrupo[i].agrupados.data){
                                        listaReporteDinamicoGrupo[i].agrupados.data[x].id = x + 1;
                                        
                                    };
                                }
                                else{
                                    const showErrorMsg = true;
                                    const textMessage = responseAgrupado.message;
        
                                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                                    yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
                                }
                            }
                        }
                    }
                    else if( agrupamiento === 'UsuarioRegistra') {
                        const dataAgrupado = {
                            "idsServicios": idServicio,
                            "idGruposSer": null,
                            "idAreaFuncional": idAreaFuncional,
                            "idUnidadFuncional":idUnidadFuncional,
                            "idInteriorFuncional":idInteriorFuncional,
                            "queja": queja,
                            "usuarioSolicito":usuarioSolicito,
                            "usuarioRegistro": listaReporteDinamicoGrupo[i].etiqueta.trim(),
                            "fechaAltaInicio": fechaAltaInicio,
                            "fechaAltaFin": fechaAltaFin,
                            "fechaCierreInicio": fechaCierreInicio,
                            "fechaCierreFin": fechaCierreFin,
                            "fechaTerminacionInicio": fechaTerminacionInicio,
                            "fechaTerminacionFin": fechaTerminacionFin,
                            "estado": estado,
                            "page": 0,
                            "offset": 10,
                            "order":order,
                            "field": field,
                            "agrupamiento": agrupamiento,
                        }
                        const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado);
                        if(responseAgrupado.status === 200){
                            listaReporteDinamicoGrupo[i].agrupados = responseAgrupado.data;
                            listaReporteDinamicoGrupo[i].agrupados.page = 1;
                            for(let x in listaReporteDinamicoGrupo[i].agrupados.data){
                                listaReporteDinamicoGrupo[i].agrupados.data[x].id = x + 1;
                                
                            };
                        }
                        else{
                            const showErrorMsg = true;
                            const textMessage = responseAgrupado.message;

                            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
                        }
                    }
                    else if( agrupamiento === 'UsuarioSolicitante') {
                        const dataAgrupado = {
                            "idsServicios": idServicio,
                            "idGruposSer": null,
                            "idAreaFuncional": idAreaFuncional,
                            "idUnidadFuncional":idUnidadFuncional,
                            "idInteriorFuncional":idInteriorFuncional,
                            "queja": queja,
                            "usuarioSolicito":listaReporteDinamicoGrupo[i].etiqueta.trim(),
                            "usuarioRegistro": usuarioRegistro,
                            "fechaAltaInicio": fechaAltaInicio,
                            "fechaAltaFin": fechaAltaFin,
                            "fechaCierreInicio": fechaCierreInicio,
                            "fechaCierreFin": fechaCierreFin,
                            "fechaTerminacionInicio": fechaTerminacionInicio,
                            "fechaTerminacionFin": fechaTerminacionFin,
                            "estado": estado,
                            "page": 0,
                            "offset": 10,
                            "order":order,
                            "field": field,
                            "agrupamiento": agrupamiento,
                        }
                        const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado);
                        if(responseAgrupado.status === 200){
                            listaReporteDinamicoGrupo[i].agrupados = responseAgrupado.data;
                            listaReporteDinamicoGrupo[i].agrupados.page = 1;
                            for(let x in listaReporteDinamicoGrupo[i].agrupados.data){
                                listaReporteDinamicoGrupo[i].agrupados.data[x].id = x + 1;
                                
                            };
                        }
                        else{
                            const showErrorMsg = true;
                            const textMessage = responseAgrupado.message;

                            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
                        }
                    }
            }

            // if(agrupamiento === 'Clasificacion'){
            //     for(let i in listaReporteDinamicoGrupo){
            //         if(!listaReporteDinamicoGrupo[i].etiqueta){
            //             listaReporteDinamicoGrupo[i].etiquetaTabla = 'SS';
            //             listaReporteDinamicoGrupo[i].etiquetaLarga = 'SS';
            //         }
            //     }
            // }

            function compare(attr, obj1, obj2) {
                return obj1[attr].localeCompare(obj2[attr]);
                }

            function generaComparador(attr) {
                return function (a,b) {
                    return compare(attr,a,b);
                }
            }

            listaReporteDinamicoGrupo = listaReporteDinamicoGrupo.sort(generaComparador('etiquetaLarga'));

            console.log('listaReporteDinamicoGrupo', listaReporteDinamicoGrupo)

            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_SUCCESS, listaReporteDinamicoGrupo, listaReporteDinamicoGrupoCantidad });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE });
    }
}
export function* listaReporteDinamicoAgrupamientoSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_REQUEST, listaReporteDinamicoAgrupamiento)
}

//Lista Reporte Dinámico
function* listaReporteDinamicoWithoutPagination (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page === null ? action.page: action.page > 1 ? (action.page -1)*10 : action.page- 1;
        let {idServicio=null,idAreaFuncional=null,idUnidadFuncional=null,idInteriorFuncional=null,usuarioSolicito=null,
            usuarioRegistro=null,fechaAlta=null,fechaCierre=null,fechaTerminacion=null,estado=null, queja=null} = action.formulario;
        const {order, field} = action.order;

        idServicio = idServicio.map(servicio => parseInt(servicio));
        idAreaFuncional = idAreaFuncional ? parseInt(idAreaFuncional) : null;
        idUnidadFuncional = idUnidadFuncional ? parseInt(idUnidadFuncional) : null;
        idInteriorFuncional = idInteriorFuncional ? parseInt(idInteriorFuncional) : null;

        const agrupamiento = action.agrupamiento ? action.agrupamiento: null;
        const fechaAltaInicio = fechaAlta ? fechaAlta[0].format('YYYY-MM-DD') : null;
        const fechaAltaFin = fechaAlta ? fechaAlta[1].format('YYYY-MM-DD') : null;
        const fechaCierreInicio = fechaCierre ? fechaCierre[0].format('YYYY-MM-DD') : null;
        const fechaCierreFin = fechaCierre ? fechaCierre[1].format('YYYY-MM-DD') : null;
        const fechaTerminacionInicio = fechaTerminacion ? fechaTerminacion[0].format('YYYY-MM-DD') : null;
        const fechaTerminacionFin = fechaTerminacion ? fechaTerminacion[1].format('YYYY-MM-DD') : null;
        
        const data = {
            "idsServicios":idServicio,
            "idGruposSer": null,
            "idAreaFuncional":idAreaFuncional,
            "idUnidadFuncional":idUnidadFuncional,
            "idInteriorFuncional":idInteriorFuncional,
            "queja": queja,
            "usuarioSolicito":usuarioSolicito,
            "usuarioRegistro":usuarioRegistro,
            "fechaAltaInicio": fechaAltaInicio,
            "fechaAltaFin": fechaAltaFin,
            "fechaCierreInicio": fechaCierreInicio,
            "fechaCierreFin": fechaCierreFin,
            "fechaTerminacionInicio": fechaTerminacionInicio,
            "fechaTerminacionFin": fechaTerminacionFin,
            "estado":estado,
            "page":page,
            "offset": null,
            "order":order,
            "field": field,
            "agrupamiento": agrupamiento,
        }
        
        let response = yield call(listaReporteDinamicoCall, token, data);
        console.log(response);

        if (response.status == 200) {
            let listaReporteDinamicoBusquedaWithout = response.data.data;
            const listaReporteDinamicoBusquedaCantidadWithout = response.data.cantidad;

            for (let reporte of listaReporteDinamicoBusquedaWithout) {
                reporte.fechaAlta = reporte.fechaAlta.split('T')[0].split('-').reverse().join('-') + '  ' + reporte.fechaAlta.split('T')[1].split('.')[0];
                reporte.fechaCierre = reporte.fechaCierre ? reporte.fechaCierre.split('T')[0].split('-').reverse().join('-') + '  ' + reporte.fechaCierre.split('T')[1].split('.')[0] : null;
                reporte.fechaTerminacion = reporte.fechaTerminacion ? reporte.fechaTerminacion.split('T')[0].split('-').reverse().join('-') +'  ' + reporte.fechaTerminacion.split('T')[1].split('.')[0] : null;
            }

            for(let i in listaReporteDinamicoBusquedaWithout){
                listaReporteDinamicoBusquedaWithout[i].queja ? listaReporteDinamicoBusquedaWithout[i].queja = listaReporteDinamicoBusquedaWithout[i].queja: listaReporteDinamicoBusquedaWithout[i].queja = 'SS'; 
            }

            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_SUCCESS, listaReporteDinamicoBusquedaWithout, listaReporteDinamicoBusquedaCantidadWithout });
        }
        else {
            yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_FAILURE });
    }
}
export function* listaReporteDinamicoWithoutPaginationSaga(){
    yield takeLatest(solicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_REQUEST, listaReporteDinamicoWithoutPagination)
}