import React, {Component} from 'react';
import { Row, Col, Switch, Table,  Card } from 'antd';
import { EditOutlined} from '@ant-design/icons';
import { connect } from "react-redux";
import ModalEditarSettings from './ModalEditarSettings'
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ListaSettings extends Component {
    componentDidMount(){
        this.props.onRequestListaSettings() 
    }

    render() {
        const { 
            fetchingListaSettings,
            listaSettings,
        } = this.props
       
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onEditar = (key) => {
            console.log(key);
            this.props.onModalEditarSetting(key);
        }

        const columns = [{
                title: 'Vigencia del link para cambio de contraseña (Hrs)',
                dataIndex: 'vigenciaLink',
                key: 'vigenciaLink',
            },{
                title: 'Intentos para bloqueo de Usuario',
                dataIndex: 'numeroIntentos',
                key: 'numeroIntentos',
            },{
                title: 'Días de vigencia de contraseña',
                dataIndex: 'vigenciaPass',
                key: 'vigenciaPass',  
            },{
                title: 'Vigencia de token de sesión (Hrs)',
                dataIndex: 'recordarToken',
                key: 'recordarToken',
            },{
                title: '¿Considerar la vigencia de token?',
                dataIndex: 'considerRecordarToken',
                key: 'considerRecordarToken',
                render: (text, record) => (
                    <Switch checked={text} disabled={true} checkedChildren="Si" unCheckedChildren="No" />
                ),
            },{
                title: 'Contraseñas anteriores para validación',
                dataIndex: 'numeroPass',
                key: 'numeroPass',
            },{
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarConfiguraciones} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];

        return (
            <Card className="cardCatalogos" title={<div className='headerLista'>Configuraciones</div>}>
                <Row justify='space-between'>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="vigenciaLink"
                            columns={columns}
                            dataSource={listaSettings}
                            loading={fetchingListaSettings}
                            locale={{ emptyText: 'Sin datos' }}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <ModalEditarSettings />
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaSettings: state.ConfiguracionYSeguridadReducer.fetchingListaSettings,
        listaSettings: state.ConfiguracionYSeguridadReducer.listaSettings,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRequestListaSettings: () => {
            dispatch({ type: "ON_REQUEST_SETTINGS"})
        }, 
        onModalEditarSetting: (settingSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_SETTINGS", showModalEditarSettings: true, settingSeleccionado: settingSeleccionado})
        },     
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaSettings) 