import React, { Component } from 'react';
import { Row, Col, Alert, Modal, Form, Input, Button} from 'antd';
import Alerta from '../../containers/Alerta';
import { connect } from "react-redux";
import { LockOutlined } from '@ant-design/icons';

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

class SesionExpirar extends Component{
     render() { 
          const {errorLogin, errorMensaje, fetchingLogin, onRequestLogin, showExpirarSesion} = this.props;
          
          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){
               userInfo =JSON.parse(userInfo);
          }

          const onFinish = values => {
               values.userName = userInfo[0].username;
               console.log('Success:', values);
               onRequestLogin(values);
          };
           
          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          function ErrorLoginAlert(props) {
               if (!props.errorLogin) {
                   return null;
               }   
               return (
                   <Alert message={errorMensaje} type="error" showIcon />
               );
          }

          return(
               <Modal
               visible = { showExpirarSesion }
               title= "SESIÓN EXPIRADA"
               footer = { false }
               closable= { false }
               >
                    <Form
                    {...layout}
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    
                    >                             
                         <Row justify="space-around">
                              <Col xs={24} md={24}><ErrorLoginAlert errorLogin={errorLogin} /></Col>
                         </Row>

                         <Row justify="space-around">
                              <Col xs={24} md={24}>
                                   <Form.Item
                                        label="Contraseña"
                                        name="password"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                        <Input.Password prefix={<LockOutlined/>} placeholder="Ingrese contraseña" />
                                   </Form.Item>
                              </Col>
                              <Col span={8}>
                                   <Button className='btnLogin' htmlType="submit" loading={fetchingLogin}>
                                        Ingresar
                                   </Button>
                              </Col>
                         </Row>
                         <Alerta/>
                    </Form>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingLogout: state.LoginReducer.fetchingLogout,
          errorLogin: state.LoginReducer.errorLogin,
          errorMensaje: state.LoginReducer.errorMensaje,
          showExpirarSesion: state.LoginReducer.showExpirarSesion,
          fetchingLogin: state.LoginReducer.fetchingLogin,
	     usuario: state.LoginReducer.usuario,
     };
};
 
const mapDispatchToProps = dispatch => {
     return {
          onRequestLogin: (loginInfo) => {
               dispatch({ type: "LOGIN_API_CALL_REQUEST", loginInfo: loginInfo});
          },
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(SesionExpirar);