import React, { Component } from 'react';
import {Modal, Button, Row, Col, Form, Spin, Input} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import '../../stylesheets/Login/Login.css';

import { connect } from "react-redux";
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class Signup extends Component {
    render() {
        const {showModalReset, onCloseModal, fetchingReinciar, onResetPassword} = this.props;
        
        const onFinish = values => {
            console.log('Success:', values);
            onResetPassword(values);
        };
        
        
        
        const handleCancel = () => {    
            onCloseModal();
        };
    
        return (
            <Modal
                visible={showModalReset}
                title='Olvidé mi contraseña'
                onCancel={handleCancel}
                footer={false}
                >
                <Spin spinning={fetchingReinciar}>
                    <Form {...layout}
                        name="resetPassword"
                        onFinish={onFinish}
                        
                    >
                        <Row justify="space-around">
                            <p>Para reiniciar tu contraseña, ingresa tu correo y te enviaremos un email para actualizar tu contraseña.</p>
                            <Col span={24}>
                                <Form.Item
                                    label="Usuario"
                                    name="userName"
                                    rules={[{ required: true, message: 'Favor de utilizar un correo válido', type: 'email'}]}
                                >
                                    <Input prefix={<UserOutlined/>} placeholder="ejemplo@appcontroller.com"/>
                                </Form.Item>
                            </Col>
                            
                            <Col span={12}>
                                <Button size="large" className="btnLogin" htmlType="submit">Recuperar Contraseña</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        showModalReset: state.LoginReducer.showModalReset,
        fetchingReinciar: state.LoginReducer.fetchingReinciar,
	};
 
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseModal: () => {
            dispatch({ type: "MODAL_RESET_PASSWORD" , showModalReset:false});
        },
        onResetPassword: (fetchingReinciar) => {
            dispatch({ type: 'RESET_PASSWORD_REQUEST', fetchingReinciar: fetchingReinciar})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);