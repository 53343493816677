import React, {useState, useRef} from 'react';
import {connect} from 'react-redux';
import { Row, Col, Button, Table, Spin, Modal, Select, Form} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { confirm } = Modal;

const ModalEditarFolio = (props) => {
    const formRef = useRef();
    const {folioConciliacionSeleccionado,showModalEditarFolioConciliacion,fetchingEditarFolioConciliacion, fetchingSSByFolio, listaSSByFolio, listaSSByFolioCantidad, page, busqueda, limpiarFormulario,
        onGetTicketsByFolio,onShowModalEditarFolio, onLimpiarFormulario, onEliminarTicket, onEditarFolio
    } = props;

    const [intPage, setIntPage] = useState(1);

    const columns = [
        {title: 'Eliminar', key: 'eliminar',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono'  onClick={() => eliminarTicket(record)} ><DeleteOutlined /></a>
        },
        {title: 'Folio solicitud de servicio', dataIndex: 'folio', key: 'folio',width:'7%'},
        {title: 'Servicio', dataIndex: 'nombreServicio', key: 'nombreServicio'},
        {title: 'Descripción falla', dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud'},
        {title: 'Fecha alta', dataIndex: 'fechaAltaHora', key: 'fechaAltaHora', width:'12%'},
        {title: 'Fecha terminación', dataIndex: 'fechaTerminacionHora', key: 'fechaTerminacionHora', width:'12%'},
        {title: 'Fecha cierre', dataIndex: 'fechaCierreHora',key: 'fechaCierreHora', width:'12%'},
    ];

    if(limpiarFormulario && limpiarFormulario === 'EFC') {
        onLimpiarFormulario();
        formRef.current.resetFields();
    }

    const eliminarTicket = record => {
        const {idSolicitudesServicioFolioConciliacion, idFolioConciliacion} = record;
        onEliminarTicket (idSolicitudesServicioFolioConciliacion, idFolioConciliacion, intPage);
    }

    const handleTableChange = (pagination) => {
        const pageTable = pagination.current;
        setIntPage(pageTable);
        const {idFolioConciliacion} = folioConciliacionSeleccionado;
        onGetTicketsByFolio(idFolioConciliacion, pageTable);
    }

    const onFinish = formulario => {
        const nombresValidacion = formulario.participantes.join();
        const numeroTickets = listaSSByFolioCantidad;
        onEditarFolio(nombresValidacion,folioConciliacionSeleccionado, numeroTickets, page, busqueda)
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    const handleCancel = () => {
        onShowModalEditarFolio();
        onLimpiarFormulario();
        formRef.current.resetFields();
    }

    return (
    <>
        <Modal
            visible={showModalEditarFolioConciliacion}
            title='Editar Folio De Conciliación'
            onCancel={handleCancel}
            footer={false}
            width='80%'
        >
            <Spin spinning={fetchingEditarFolioConciliacion}>
                {folioConciliacionSeleccionado && 
                <>
                <Form 
                    name='formulario'
                    onFinish={onFinish}
                    
                    initialValues={folioConciliacionSeleccionado}
                    ref={formRef}
                >
                    <Row justify='space-between'>
                        <Col span={16}>
                            <Form.Item
                                label='Participantes'
                                name='participantes'
                            >
                                <Select
                                    mode='tags'
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona a los participantes"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7} >
                            <Button disabled={false} className="btnFiltrar" style={{ width: '100%'}} htmlType="submit" >Editar Folio de Conciliación</Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="folio"
                            columns={columns}
                            dataSource={listaSSByFolio}
                            loading={fetchingSSByFolio}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={(pagination) => handleTableChange(pagination)}
                            pagination={{ total: listaSSByFolioCantidad, simple: true, current: intPage }}
                            scroll={{"x": 1600}}
                        />
                    </Col>
                </Row>
                </>
                }
            </Spin>

        </Modal>
    
    </>)   
}

const mapStateToProps = (state) => {
    return {
        folioConciliacionSeleccionado: state.MecanismosDePagosReducer.folioConciliacionSeleccionado,
        showModalEditarFolioConciliacion: state.MecanismosDePagosReducer.showModalEditarFolioConciliacion,
        fetchingEditarFolioConciliacion: state.MecanismosDePagosReducer.fetchingEditarFolioConciliacion,
        fetchingSSByFolio: state.MecanismosDePagosReducer.fetchingSSByFolio,
        listaSSByFolio: state.MecanismosDePagosReducer.listaSSByFolio,
        listaSSByFolioCantidad: state.MecanismosDePagosReducer.listaSSByFolioCantidad,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarFolio: () => {
            dispatch({ type:'MODAL_EDITAR_FOLIO_CONCILIACION', showModalEditarFolioConciliacion: false, folioConciliacionSeleccionado: null, listaSSByFolio:[]})
        },
        onGetTicketsByFolio: (idFolioConciliacion, page) => {
            dispatch({type:'SS_BY_FOLIO_REQUEST', idFolioConciliacion, page})
        }, 
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onEliminarTicket: (idSolicitudesServicioFolioConciliacion, idFolioConciliacion, page) => {
            dispatch({ type: 'ELIMINAR_SS_FC_REQUEST',idSolicitudesServicioFolioConciliacion,idFolioConciliacion, page})
        },
        onEditarFolio: (nombresValidacion, folioConciliacionSeleccionado, numeroTickets, page, busqueda) => {
            dispatch({ type: 'EDITAR_FOLIO_CONCILIACION_REQUEST', nombresValidacion, folioConciliacionSeleccionado, numeroTickets, page, busqueda})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarFolio);