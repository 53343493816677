import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import CatalogoDeDominios from './CatalogoDeDominios/CatalogoDeDominios';
import CatalogoDeContratos from './CatalogoDeContratos/CatalogoDeContratos';
import CatalogoDeExpedientes from './CatalogoDeExpedientes/CatalogoDeExpedientes';
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';

import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import PartidasContainer from './CatalogoDePartidas/PartidasContainer';

import imaSearch from '../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";

function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}
class CatalogosProveedorContainer extends React.Component {
    state={
        catalogo: 'Selecciona un Catálogo',
        colLista: 8,
        colTabla: 15,
        maximizar: false
    }
    render() {
        const { onChangeCatalogo, catalogoProveedor } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const listaCatalogos = [];
        if(permisosUsuario.buscarDominio){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Dominios',
            })
        }
        if(permisosUsuario.buscarContrato){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Contratos',
            })
        }
        if(permisosUsuario.buscarExpediente){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Expedientes',
            })
        }
        if(permisosUsuario.buscarPartida){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Partidas',
            })
        }
        function ShowInfo(props) {
            if (isEmpty(catalogoProveedor)) {
                return (
                    <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>);
            }
            
	        return (
                <div>
                    <CatalogoApp/>
                </div>
            );
        }

        function CatalogoApp() {
            if(catalogoProveedor === 'Catálogo de Dominios'){
                return(
                    <div>
                        <CatalogoDeDominios />
                    </div>
                );
            }
            else if(catalogoProveedor === 'Catálogo de Contratos'){
                return(
                    <div>
                        <CatalogoDeContratos />
                    </div>
                );
            }
            else if(catalogoProveedor === 'Catálogo de Expedientes'){
                return(
                    <div>
                        <CatalogoDeExpedientes />
                    </div>
                );
            }
            else if(catalogoProveedor === 'Catálogo de Partidas'){
                return(
                    <div>
                        <PartidasContainer />
                    </div>
                );
            }
			return <div>  </div>;
        };
        
        const changeCatalogo = (catalogo) => {
            this.setState({
                catalogo: catalogo.catalogo,
            })
            onChangeCatalogo(catalogo.catalogo);
        }
        const onExpandir = () => {
            if(!this.state.maximizar){
                this.setState({
                    colLista: 0,
                    colTabla: 24,
                    maximizar: true
                });
            }
            else{
                this.setState({
                    colLista: 8,
                    colTabla: 15,
                    maximizar: false,
                });
            }
        }

        return (
            <div className="imgCorp">
                <Row justify='space-between'>
                    <Col span={this.state.colLista}>
                        <List
                            header={
                                <div className='headerLista'>Catálogos de Proveedor</div>
                            }
                            className="listaCatalogos"
                            bordered
                            dataSource={listaCatalogos}
                            renderItem={item => (
                            <List.Item className='listaHover'
                                actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                            >
                                <div>{item.catalogo}</div>
                            </List.Item>
                            )}
                        />                    
                    </Col>
                    <Col span={this.state.colTabla}>
                        <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                            <ShowInfo info={catalogoProveedor} />
                        </Card> 
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        catalogoProveedor: state.InformacionOperativaReducer.catalogoProveedor,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onChangeCatalogo: (catalogoProveedor) => {
            dispatch({ type: "CAMBIAR_CATALOGO_PROVEEDOR" , catalogoProveedor: catalogoProveedor});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogosProveedorContainer);