import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalNuevoServicioPublico from './ModalNuevoServicioPublico';
import ModalEditarServicioPublico from './ModalEditarServicioPublico';

const confirm = Modal.confirm;
const { Search } = Input;
class CatalogoServiciosPublicosContainer extends React.Component {
    componentDidMount(){
        this.props.onGetServiciosPublicos(1, '');
    }
    state={
        page: 1,
        busqueda: null,
    }
    render() {
        const { fetchingListaServiciosPublicosBusqueda, listaServiciosPublicosBusqueda, onEstadoServicioPublico, 
            onGetServiciosPublicos, onModalEditarServicioPublico, onModalNuevoServicioPublico, listaServiciosPublicosBusquedaCantidad,
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarServicioPublico = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetServiciosPublicos(1, busqueda);
        }

        const columns = [{
                title: 'Nombre Servicio Público',
                dataIndex: 'nombre',
                key: 'nombre',
            },{
                title: 'Nombre Depósito Almacenamiento',
                dataIndex: 'nombreDeposito',
                key: 'nombreDeposito',
            },{
                title: 'Cantidad Depósito Almacenamiento',
                dataIndex: 'cantidadDeposito',
                key: 'cantidadDeposito',
                align: 'center',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.servicioPublicoActivoinactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarServicioPublico} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log('servicio público seleccionado');
            console.log(key);
            onModalEditarServicioPublico(key, this.state.page, this.state.busqueda);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            const busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del servicio público "'+ key.nombre + '"?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoServicioPublico(key.idServicioPublico, page, busqueda)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetServiciosPublicos(pagination.current, this.state.busqueda);
        }
        
        const onNuevoServicioPublico = () => {
            onModalNuevoServicioPublico(this.state.page, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa el nombre del servicio público" onSearch={value => onBuscarServicioPublico(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoServicioPublico} disabled={!permisosUsuario.crearServicioPublico} className='nuevoUsuario'>Nuevo Servicio Público</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Servicio Público' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoServicioPublico} disabled={!permisosUsuario.crearServicioPublico} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idServicioPublico" 
                            columns={columns}
                            dataSource={listaServiciosPublicosBusqueda}
                            loading={fetchingListaServiciosPublicosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{total: listaServiciosPublicosBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoServicioPublico/>
                <ModalEditarServicioPublico/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaServiciosPublicosBusqueda: state.ServiciosPublicosReducer.listaServiciosPublicosBusqueda,
        fetchingListaServiciosPublicosBusqueda: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosBusqueda,
        listaServiciosPublicosBusquedaCantidad: state.ServiciosPublicosReducer.listaServiciosPublicosBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetServiciosPublicos: (page, busqueda) => {
	    	dispatch({ type: "LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoServicioPublico: (idServicioPublico, page, busqueda) => {
	    	dispatch({ type: "ESTADO_SERVICIO_PUBLICO_REQUEST", idServicioPublico, page, busqueda });
        },
        onModalNuevoServicioPublico: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_SERVICIO_PUBLICO", showModalNuevoServicioPublico: true, page, busqueda });
        },
        onModalEditarServicioPublico: (servicioPublicoSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_SERVICIO_PUBLICO", showModalEditarServicioPublico: true, servicioPublicoSeleccionado, page, busqueda });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoServiciosPublicosContainer);