import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Spin, InputNumber, TimePicker, Tooltip, Input, Switch } from 'antd';
import { connect } from "react-redux";
import { FieldNumberOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

function isEmpty(obj) {
     for(var prop in obj) {
         if(obj.hasOwnProperty(prop))
             return false;
     }
     return true;
}

class ModalEditarProgramacion extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          page: 1,
          numeroLectura: null,
          checkedVariables: true,
          todasVariablesNombre: [],
          Primera: null,
          Segunda: null, segundoCondicion: false,
          Tercera: null, terceraCondcion: false,
          Cuarta: null, cuartaCondicion: false,
          Quinta: null, quintaCondicion: false,
          Sexta: null, sextaCondicion: false,
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               showModalEditarProgramacion,
               onCloseModalEditarProgramacion,
               listaServiciosPublicosActivos,
               fetchingListaServiciosPublicosActivos,
               fetchingListaVariablesMedicionActivos,
               listaVariablesMedicionActivos,
               fetchingEditarProgramacion,
               programacionSeleccionada,
               onEditarProgramacion,
               cantidadHorarios,
               page, 
               busqueda,
               onEditarCantidad
          } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);

               let horario = [];

               onEditarProgramacion(formulario, programacionSeleccionada, page, busqueda, listaServiciosPublicosActivos, horario, listaVariablesMedicionActivos, !this.state.checkedVariables)
          };

          if (limpiarFormulario && limpiarFormulario === 'editarProgramacion') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
               this.setState({ checkedVariables: true, Primera: null,
                    Segunda: null, segundoCondicion: false,
                    Tercera: null, terceraCondcion: false,
                    Cuarta: null, cuartaCondicion: false,
                    Quinta: null, quintaCondicion: false,
                    Sexta: null, sextaCondicion: false, });
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onCloseModalEditarProgramacion();
               this.setState({ checkedVariables: true, Primera: null,
                    Segunda: null, segundoCondicion: false,
                    Tercera: null, terceraCondcion: false,
                    Cuarta: null, cuartaCondicion: false,
                    Quinta: null, quintaCondicion: false,
                    Sexta: null, sextaCondicion: false, });
               this.formRef.current.resetFields();
          };

          const onNumeroLecturas = (value) => {
               onEditarCantidad(programacionSeleccionada, page, busqueda, value, !this.state.checkedVariables);
               this.setState({ numeroLectura: value });
          };

          const onTodasVariables = (checked) => {
               const variablesNombre = [listaVariablesMedicionActivos.length];
               const todasVariablesSeleccionadas = [listaVariablesMedicionActivos.length];

               for(let i = 0; i < listaVariablesMedicionActivos.length; i++){
                    variablesNombre[i] = listaVariablesMedicionActivos[i].nombre;
                    todasVariablesSeleccionadas[i] = listaVariablesMedicionActivos[i];
               }

               console.log('checked', checked)
               onEditarCantidad(programacionSeleccionada, page, busqueda, cantidadHorarios, checked);

               if(!checked){
                    console.log('entra a if')
                    this.formRef.current.setFieldsValue({'variablesMedicion': []});
                    this.setState({ checkedVariables: true, todasVariablesNombre: [] });
               } else {
                    this.setState({ checkedVariables: !checked, todasVariablesNombre: variablesNombre });
                    
                    this.formRef.current.setFieldsValue({'variablesMedicion': variablesNombre});
               }
          };

          const onPrimera = (time) => {
               this.setState({ Primera: time });

               if(time){
                    this.setState({ segundoCondicion: false });
               } else {
                    this.setState({ segundoCondicion: true, terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                    //this.formRef.current.resetFields(['horarioLectura1', 'horarioLectura2', 'horarioLectura3', 'horarioLectura4', 'horarioLectura5']);
                    this.formRef.current.setFieldsValue({'horarioLectura1': null, 'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura1')){
                    if(time >= this.formRef.current.getFieldValue('horarioLectura1')){
                         message.warning('La hora que se intenta ingresar no debe ser mayor a la hora de la siguiente lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura0': null})
                    } 
                    else {
                         message.info('Por favor, ingrese nuevamente las siguientes lecturas.');
                         this.setState({ terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                         this.formRef.current.setFieldsValue({'horarioLectura1': null, 'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    }
               }
          };

          const onSegunda = (time) => {

               if(time <= this.state.Primera){
                    this.formRef.current.resetFields(['horarioLectura1']);
                    message.warning('El horario ingresado no puede ser menor o igual al anterior.');
               }

               if(time || !(this.formRef.current.getFieldValue('horarioLectura1'))){
                    this.setState({ terceraCondcion: false });
               } else {
                    this.setState({ terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
          
                    this.formRef.current.setFieldsValue({'horarioLectura1': null, 'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
               }

               console.log('object', this.formRef.current.getFieldValue('horarioLectura2'))

               if(this.formRef.current.getFieldValue('horarioLectura2')){
                    if(this.formRef.current.getFieldValue('horarioLectura2') <= time){
                         message.warning('El horario ingresado debe ser menor al siguiente');

                         this.setState({ terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
     
                         this.formRef.current.setFieldsValue({'horarioLectura1': null, 'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    } 
                    else {
                         message.info('Por favor, ingrese nuevamente las siguientes lecturas.');
                         this.setState({ cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                         this.formRef.current.setFieldsValue({'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    }

                    if(time <= this.formRef.current.getFieldValue('horarioLectura0')){
                         message.warning('La hora que se intenta ingresar no debe ser menor a la hora de la anterior lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura1': null})
                    }
               }

               this.setState({ Segunda: time });
          };

          const onTercera = (time) => {
               if(time <= this.state.Segunda){
                    this.formRef.current.resetFields(['horarioLectura2']);
                    message.warning('El horario ingresado no puede ser menor o igual al anterior.');
               }

               if(time){
                    this.setState({ cuartaCondicion: false });
               } else {
                    this.setState({ cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                    //this.formRef.current.resetFields(['horarioLectura3', 'horarioLectura4', 'horarioLectura5']);
                    this.formRef.current.setFieldsValue({'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura3')){
                    if(this.formRef.current.getFieldValue('horarioLectura3') <= time){
                         message.warning('El horario ingresado debe ser menor al siguiente');

                         this.setState({ cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
     
                         this.formRef.current.setFieldsValue({'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    }
                    else {
                         message.info('Por favor, ingrese nuevamente las siguientes lecturas.');
                         this.setState({ quintaCondicion: true, sextaCondicion: true });
                         this.formRef.current.setFieldsValue({'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    }

                    if(time <= this.formRef.current.getFieldValue('horarioLectura1')){
                         message.warning('La hora que se intenta ingresar no debe ser menor a la hora de la anterior lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura2': null})
                    }
               }

               this.setState({ Tercera: time });
          };

          const onCuarta = (time) => {
               if(time <= this.state.Tercera){
                    this.formRef.current.resetFields(['horarioLectura3']);
                    message.warning('El horario ingresado no puede ser menor o igual al anterior.');
               }

               if(time){
                    this.setState({ quintaCondicion: false });
               } else {
                    this.setState({ quintaCondicion: true, sextaCondicion: true });
                    //this.formRef.current.resetFields(['horarioLectura4', 'horarioLectura5']);
                    this.formRef.current.setFieldsValue({'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura4')){
                    if(this.formRef.current.getFieldValue('horarioLectura4') <= time){
                         message.warning('El horario ingresado debe ser menor al siguiente');

                         this.setState({ quintaCondicion: true, sextaCondicion: true });
     
                         this.formRef.current.setFieldsValue({'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    }
                    else {
                         message.info('Por favor, ingrese nuevamente las siguientes lecturas.');
                         this.setState({ sextaCondicion: true });
                         this.formRef.current.setFieldsValue({'horarioLectura4': null, 'horarioLectura5': null});
                    }

                    if(time <= this.formRef.current.getFieldValue('horarioLectura2')){
                         message.warning('La hora que se intenta ingresar no debe ser menor a la hora de la anterior lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura3': null})
                    }
               }

               this.setState({ Cuarta: time });
          };

          const onQuinta = (time) => {
               if(time <= this.state.Cuarta){
                    this.formRef.current.resetFields(['horarioLectura4']);
                    message.warning('El horario ingresado no puede ser menor o igual al anterior.');
               }

               if(time){
                    this.setState({ sextaCondicion: false });
               } else {
                    this.setState({ sextaCondicion: true });
                    //this.formRef.current.resetFields(['horarioLectura5']);
                    this.formRef.current.setFieldsValue({'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura5')){
                    if(this.formRef.current.getFieldValue('horarioLectura5') <= time){
                         message.warning('El horario ingresado debe ser menor al siguiente');

                         this.setState({ sextaCondicion: true });
     
                         this.formRef.current.setFieldsValue({'horarioLectura4': null, 'horarioLectura5': null});
                    }
                    else {
                         message.info('Por favor, ingrese nuevamente las siguientes lecturas.');
                         this.formRef.current.setFieldsValue({'horarioLectura5': null});
                    }

                    if(time <= this.formRef.current.getFieldValue('horarioLectura3')){
                         message.warning('La hora que se intenta ingresar no debe ser menor a la hora de la anterior lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura4': null})
                    }
               }

               this.setState({ Quinta: time });
          };

          const onSexta = (time) => {
               if(time <= this.state.Quinta){
                    this.formRef.current.resetFields(['horarioLectura5']);
                    message.warning('El horario ingresado no puede ser menor o igual al anterior.');

               }

               if(time <= this.formRef.current.getFieldValue('horarioLectura4')){
                    message.warning('La hora que se intenta ingresar no debe ser menor a la hora de la anterior lectura.');
                    this.formRef.current.setFieldsValue({'horarioLectura5': null})
               }
               
               this.setState({ Sexta: time });
          };

          const onVencimiento = (value) => {};

          return (
               <Modal
                    visible={showModalEditarProgramacion}
                    title='Editar Programación y Medición de Consumos'
                    onCancel={handleCancel}
                    footer={false}
                    width={'45%'}
               >
                    {programacionSeleccionada ? ( 
                    <Spin spinning={fetchingEditarProgramacion}>
                         <Form {...layout}
                         name='formulario'
                         initialValues={programacionSeleccionada}
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                              <Row type='flex' justify='space-between'>
                                   
                                   <Col span={14}>
                                        <Form.Item
                                             name='servicioPublico'
                                             label='Servicio Público'
                                             rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Select
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Seleccione un servicio público"
                                                  loading={fetchingListaServiciosPublicosActivos}
                                             >
                                                  {listaServiciosPublicosActivos.map(option => <Option key={option.nombre}>{ option.nombre}</Option>)}
                                             </Select>    
                                        </Form.Item>
                                   </Col>

                                   <Col span={4}>
                                        <Tooltip placement="right" title="Vencimiento de la programación en minutos." >
                                             <Form.Item
                                                  label="Vencimiento"
                                                  name="vencimientoMinutos"
                                                  rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <InputNumber
                                                       prefix={<FieldNumberOutlined />}
                                                       min={1}
                                                       onChange={onVencimiento}
                                                       defaultValue={1}
                                                  />
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>
                                   
                                   <Col span={4}>
                                        <Form.Item
                                             label="No. Lecturas"
                                             name="NumLecturas"
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                             <InputNumber
                                                  prefix={<FieldNumberOutlined />}
                                                  placeholder={'No.'}
                                                  max={6}
                                                  min={1}
                                                  defaultValue={1}
                                                  onChange={onNumeroLecturas}
                                             />
                                        </Form.Item>
                                   </Col>
                                   
                                   { cantidadHorarios === 1 || cantidadHorarios === 2 || cantidadHorarios === 3 || cantidadHorarios === 4 || cantidadHorarios === 5 || cantidadHorarios === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Primera Lectura"
                                                       name="horarioLectura0"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                  >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onPrimera}/>
                                                  {/*  */}
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   { cantidadHorarios === 2 || cantidadHorarios === 3 || cantidadHorarios === 4 || cantidadHorarios === 5 || cantidadHorarios === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Segunda Lectura"
                                                       name="horarioLectura1"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                  >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onSegunda} disabled={this.state.segundoCondicion}/>
                                                  {/*  */}
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   
                                   { cantidadHorarios === 3 || cantidadHorarios === 4 || cantidadHorarios === 5 || cantidadHorarios === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Tercera Lectura"
                                                       name="horarioLectura2"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onTercera} disabled={this.state.terceraCondcion}/>
                                                  {/*  */}
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   { cantidadHorarios === 4 || cantidadHorarios === 5 || cantidadHorarios === 6  ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Cuarta Lectura"
                                                       name="horarioLectura3"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onCuarta} disabled={this.state.cuartaCondicion}/>
                                                  {/*  */}
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   
                                   { cantidadHorarios === 5 || cantidadHorarios === 6  ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Quinta Lectura"
                                                       name="horarioLectura4"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onQuinta} disabled={this.state.quintaCondicion}/>
                                                  {/*  */}
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   
                                   { cantidadHorarios === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Sexta Lectura"
                                                       name="horarioLectura5"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onSexta} disabled={this.state.sextaCondicion}/>
                                                  {/*  */}
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   <Col span={19}>
                                        <Form.Item
                                             name='variablesMedicion'
                                             label='Variable de Medición'
                                             rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             { this.state.checkedVariables ? (
                                                  <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione una variable de medición"
                                                       loading={fetchingListaVariablesMedicionActivos}
                                                  >
                                                       {listaVariablesMedicionActivos.map(option => <Option key={option.nombre}>{ option.nombre}</Option>)}
                                                  </Select>
                                             ): (
                                                  <Select
                                                       mode="multiple"
                                                       style={{ width: '100%' }}
                                                       defaultValue={this.state.todasVariablesNombre}
                                                       disabled={true}
                                                  />
                                             )}  
                                        </Form.Item>
                                   </Col>

                                   <Col span={4}>
                                        <Form.Item
                                             label=' '
                                             name='todasVariables'
                                        >
                                             <Switch
                                                  checkedChildren={'TODAS'}
                                                  unCheckedChildren={'TODAS'}
                                                  disabled={!listaVariablesMedicionActivos[0]}
                                                  size={'default'}
                                                  onChange={onTodasVariables}
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Form.Item
                                             name="descripcion"
                                             label='Descripción'
                                             rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Input
                                                  placeholder='Descripción de programación y medición de consumo'
                                                  pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:'\s\-,]+$"
                                                  title="Descripción sin carácteres especiales." 
                                                  maxLength={250} 
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Button className="btnPrueba" htmlType="submit" style={{ width: '100%' }}>
                                             Guardar
                                        </Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
                    ): false }
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalEditarProgramacion: state.ServiciosPublicosReducer.showModalEditarProgramacion,
          page: state.ServiciosPublicosReducer.page,
          busqueda: state.ServiciosPublicosReducer.busqueda,
          listaServiciosPublicosActivos: state.ServiciosPublicosReducer.listaServiciosPublicosActivos,
          fetchingListaServiciosPublicosActivos: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosActivos,
          fetchingListaVariablesMedicionActivos: state.ServiciosPublicosReducer.fetchingListaVariablesMedicionActivos,
          listaVariablesMedicionActivos: state.ServiciosPublicosReducer.listaVariablesMedicionActivos,
          fetchingEditarProgramacion: state.ServiciosPublicosReducer.fetchingEditarProgramacion,
          programacionSeleccionada: state.ServiciosPublicosReducer.programacionSeleccionada,
          cantidadHorarios: state.ServiciosPublicosReducer.cantidadHorarios,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onCloseModalEditarProgramacion: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION', showModalEditarProgramacion: false });
          },
          onEditarProgramacion: (formulario, programacionSeleccionada, page, busqueda, listaServiciosPublicosActivos, horario, listaVariablesMedicionActivos, todasVariables) => {
               dispatch({ type: 'EDITAR_PROGRAMACION_MEDICION_REQUEST', formulario, programacionSeleccionada, page, busqueda, listaServiciosPublicosActivos, horario, listaVariablesMedicionActivos, todasVariables });
          },
          onEditarCantidad: (programacionSeleccionada, page, busqueda, cantidadHorarios, todasVariables) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION', showModalEditarProgramacion: true, programacionSeleccionada, page, busqueda ,cantidadHorarios, todasVariables });
          },    
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarProgramacion);