import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Select, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Usuarios.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class VerificarCorreo extends Component {
    formRef = React.createRef();
    state={
        dominio: null,
    }
    render() {
        const { onVerificarUsuario, fetchingListaDominiosActivos, listaDominiosActivos, 
            verificarCorreo, fetchingVerificarUsuario, onLimpiarFormulario, limpiarFormulario,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onVerficiar = verificar => {
            if(this.state.dominio){
                console.log('Success:', verificar);
                onVerificarUsuario(verificar.username, this.state.dominio, listaDominiosActivos);
            }
            else{
                message.error('Favor de seleccionar un dominio');
            }
        };
        
        const onVerificarFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };
        
        const onSelectDominio = (value) => {
            this.setState({
                dominio: value,
            });
        };

        if(limpiarFormulario && limpiarFormulario === 'verificarCorreo'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        };

        return (
            <Spin spinning={fetchingVerificarUsuario}>
                <Form {...layout}
                name="verificar"
                onFinish={onVerficiar}
                onFinishFailed={onVerificarFailed}
                ref={this.formRef}
                >
                    <Form.Item
                        label="Usuario"
                        name="username"
                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                    >
                        <Input maxLength={50} disabled={verificarCorreo} addonAfter={
                            <div>
                                @
                                <Select disabled={verificarCorreo} style={{ width: 200 }} placeholder="Selecciona el dominio"
                                    loading={fetchingListaDominiosActivos} onChange={onSelectDominio}
                                >
                                    {listaDominiosActivos.map(option => <Option key={option.idDominio}>{option.dominioCompleto}</Option>)}
                                </Select>
                            </div>
                            } 
                            placeholder="Ingresa el usuario" />
                    </Form.Item>
                    
                    <Col span={12}>
                        <Button disabled={verificarCorreo} loading={fetchingVerificarUsuario} className="btnFiltrar" htmlType="submit">Verificar Correo</Button>
                    </Col>
                </Form>
            </Spin>
        );
    }
}
        
const mapStateToProps = state => {
    return {
        fetchingListaDominiosActivos: state.ConfiguracionYSeguridadReducer.fetchingListaDominiosActivos,
        listaDominiosActivos: state.ConfiguracionYSeguridadReducer.listaDominiosActivos,
        verificarCorreo: state.ConfiguracionYSeguridadReducer.verificarCorreo,
        fetchingVerificarUsuario: state.ConfiguracionYSeguridadReducer.fetchingVerificarUsuario,
        username: state.ConfiguracionYSeguridadReducer.username,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVerificarUsuario: (username, dominio, listaDominiosActivos) => {
            dispatch({ type: "VERIFICAR_USUARIO_REQUEST" , username: username, dominio: dominio, listaDominiosActivos: listaDominiosActivos });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificarCorreo);