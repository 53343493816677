import React, { Component } from 'react';
import {Button,Col,Row,Table, message, Tooltip, Divider, Spin} from 'antd';
import { EditOutlined, ArrowLeftOutlined, SaveOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalModificarRegistroNuevoFolio from './ModalModificarRegistroNuevoFolio';

class ListaProductosSeleccionados extends Component {
    render() { 
        const {
            listaSeleccionados, formularioNuevoFolio,
        } = this.props;
       
        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }
        
        const onGenerarFolio = () => {
            let valido = true;
            for (let i = 0; i < listaSeleccionados.length; i++) {
                if(!listaSeleccionados[i].cantidad){
                    message.warning('Debe ingresar cantidad del producto con código de almacén "'+ listaSeleccionados[i].codigoAdicional + '" antes de generar folio') 
                    i = listaSeleccionados.length
                    valido = false;
                } else if(!listaSeleccionados[i].idUbicacionProductoDestino && formularioNuevoFolio.idTipoFolio.split('-')[1] !== "Resta"){
                    message.warning('Debe ingresar ubicacion destino del producto con código de almacén "'+ listaSeleccionados[i].codigoAdicional + '" antes de generar folio') 
                    i = listaSeleccionados.length
                    valido = false;
                }
                else if( typeof listaSeleccionados[i].productoByIdProducto !== 'undefined'){
                    if(listaSeleccionados[i].productoByIdProducto.caracteristicaLote){
                        if(!listaSeleccionados[i].lote){
                            message.warning('Debe ingresar lote del producto con código de almacén "'+ listaSeleccionados[i].codigoAdicional + '" antes de generar folio')
                            i = listaSeleccionados.length
                            valido = false; 
                        }  
                    } 
                }      
            }
            if(valido){
                this.props.onGenerarFolioRequest(formularioNuevoFolio, listaSeleccionados, userInfo, this.props.page, this.props.busqueda);
            }
        }
        
        const columns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        }, {
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        }, {
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        }, {
            title: 'Número de serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            align: 'center',
            render: (text, record) => (
                formularioNuevoFolio?.idTipoFolio.split('-')[1] === "Resta" || formularioNuevoFolio?.idTipoFolio.split('-')[1] === "Ambas" ? (record.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? text : 'No aplica') : 'No aplica'
            ),
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        },{
            title: 'Costo unitario',
            dataIndex: 'precioTabla',
            key: 'precioTabla',
            align: 'right',
        },{
            title: 'Tipo cambio',
            dataIndex: 'tipoCambio',
            key: 'tipoCambio',
            align: 'right',
        },{
            title: 'Cantidad   ',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'right',
        },{
            title: 'Costo total',
            dataIndex: 'costoTotal',
            key: 'costoTotal',
            align: 'right',
        },{
            title: 'Lote',
            dataIndex: 'lote',
            key: 'lote',
            align: 'center',
        },{
            title: ' Modificar ',
            key: 'editar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={formularioNuevoFolio?.idTipoFolio.split('-')[1] === "Resta" ? (record.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? true : false) : (formularioNuevoFolio?.idTipoFolio.split('-')[1] === "Ambos" ? (record.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? true : false): false) } onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        }];

        const onEditar=key=> {
            console.log('key', key);
            console.log('formulario nuevo', formularioNuevoFolio)
            key.listaSerieNum = key.listaSerieNum ?  key.listaSerieNum : [];
            //validar que tenga destino 
            if(formularioNuevoFolio.idTipoFolio.split('-')[1] === "Resta"){
                this.props.onShowModalModificar(key);
            } else if(typeof formularioNuevoFolio.idAlmacenDestino !== 'undefined'){
                if(formularioNuevoFolio.idAlmacenDestino.split('-')[0]){
                    console.log('tiene destino')
                    this.props.onShowModalModificar(key);
                    this.props.onGetUbicacionDestino(1, formularioNuevoFolio.idAlmacenDestino.split('-')[0])
               }
            }
            
            
        };
        const vistaAnterior = () => {
            console.log('this.props.tipoActivos vista 3', this.props.tipoActivos)
            this.props.onCambiarVista(2, formularioNuevoFolio, listaSeleccionados, this.props.tipoActivos);
            // if(formularioNuevoFolio.idTipoFolio.split('-')[1] === "Ambas" && !formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma"){
            //     this.props.onBorrarUbicacionesDestino();
            // } 
            this.props.onBorrarUbicacionesDestino();
        }

        return ( 
            <Spin spinning={this.props.fetchingNuevoFolio}>
                <Row justify="space-around">
                    <Col span={20}>
                        <Divider orientation="left">Generar Folio</Divider>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Paso Anterior'>
                            <Button className="iconTer" shape="circle" onClick={vistaAnterior} icon={<ArrowLeftOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Generar Folio'>
                            <Button className="iconTer" shape="circle" onClick={onGenerarFolio} icon={<SaveOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="idProducto"
                            columns={columns}
                            dataSource={listaSeleccionados}
                            locale={{ emptyText: 'Sin datos' }}
                            pagination={false}
                            scroll={{"x": 1000 | true, "y": 1000}}
                        >

                        </Table>  
                        <br />
                    </Col>  
                </Row> 
                <ModalModificarRegistroNuevoFolio/>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
        formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        fetchingNuevoFolio: state.AlmacenesReducer.fetchingNuevoFolio,
        tipoActivos: state.AlmacenesReducer.tipoActivos
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalModificar: (registroProductoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_NUEVOFOLIO", showModalModificarRegistroNuevoFolio: true, registroProductoSeleccionado: registroProductoSeleccionado})
        },
        onCambiarVista: (vistaModalNuevoFolio, formularioNuevoFolio, listaSeleccionados, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio, formularioNuevoFolio, listaSeleccionados, tipoActivos });
        }, 
        onGenerarFolioRequest: (formularioNuevoFolio, listaSeleccionados, userInfo, page, busqueda) => {
            dispatch({ type: "NUEVO_FOLIO_REQUEST", formularioNuevoFolio, listaSeleccionados, userInfo, page, busqueda});
        },
        onGetUbicacionDestino: (page, idAlmacen) => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onBorrarUbicacionesDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null })
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps )(ListaProductosSeleccionados);