import React, { Component } from 'react';
import '../stylesheets/Login/Login.css';
import { Row, Col, Alert} from 'antd';

import ResetPasswordModal from '../components/login/ResetPasswordModal';
import Alerta from './Alerta';
import LoginCmp from "../components/login/LoginForm";
import logo from "../assets/LOGO-PAPANTLA-vertical.svg";
import fondoLogin from '../assets/fondoLogin.svg';
import fondoLoginDemo from '../assets/fondo-login-demo.png';
//import logoDemo from '../assets/APPCONTROLLER.svg';
import logoDemo from '../assets/LogoAppControllerVertical.svg';
// import torre from '../assets/torre.svg';

import { Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import ReactVivus from "react-vivus";

class Login extends Component {
    render() { 
        const {isAuthenticated,errorLogin, errorMensaje} = this.props;

        if (isAuthenticated) {
            return (
                <Redirect to='/dashboard' />
            )
        }

        function ErrorLoginAlert(props) {
            if (!props.errorLogin) {
                return null;
            }

            return (
                <Alert message={errorMensaje} type="error" showIcon />
            );
        }

        return (
            <div className="login-form">
                <Row justify="space-between">
                    <Col span={12} className='fondoLogin'>
                        <div className='torre-box'>
                            <ReactVivus 
                                id="torre"
                                option={{
                                    file: '/torre.svg',
                                    animTimingFunction: "EASE_OUT_BOUNCE",
                                    type: "delayed",
                                    start: 'autostart'
                                }}
                                className='torre'
                            />
                            {/* <img src={torre} className='torre' /> */}
                        </div>
                        <div className='fondo-box'>
                            <ReactVivus 
                                id="fondoLogin"
                                option={{
                                    file: '/fondoLogin.svg',
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                    start: 'autostart'
                                }}
                                className='fondo'
                            />
                        </div>
                        
                    </Col>
                    <Col span={12}>
                        <Row justify="space-around">
                            <Col xs={22} md={16} ><img alt="sitPapantla" src={logoDemo} className="login-logo"/></Col>
                        </Row>
                        <Row justify="space-around">
                            <Col xs={22} md={16}>
                                <h1 style={{textAlign: 'center'}}>Bienvenido a tu Administrador</h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row justify="space-around">
                            <Col xs={22} md={16}><ErrorLoginAlert errorLogin={errorLogin} /></Col>
                        </Row>

                        <Row justify="space-around">
                            <Col xs={22} md={16}><LoginCmp/></Col>
                        </Row>
                    </Col>
                </Row>
                <ResetPasswordModal/>
                <Alerta/>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
	    isAuthenticated: state.LoginReducer.isAuthenticated,
        errorLogin: state.LoginReducer.errorLogin,
        errorMensaje: state.LoginReducer.errorMensaje,
	};
};

export default connect(mapStateToProps) (Login);

