import React from 'react';
import { Form, Spin, Row, Col, Table, Tooltip, Menu, Result, Select, Button} from 'antd';
import { connect } from "react-redux"; 
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {  FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { SubMenu } = Menu;
const {Option} = Select;

class DeduccionAplicable extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ this.props.getResumen(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP); }

     state={ }
 
     render() {
          const {  } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const columns = [
               { title: 'Tipo de incidencia o falla', dataIndex: 'falla', key: 'falla', align: 'center' },
               { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'right' },
               { title: 'Porcentaje', dataIndex: 'porcentajeTabla', key: 'porcentajeTabla', align: 'right' },
               { title: 'Importes', dataIndex: 'importeTabla', key: 'importeTabla', align: 'right' },
          ];

          const onExportPDF = (e) => {
               if(e === 'pdf'){
                    this.props.getPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.getEXCEL(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
               
          };

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    <Row justify='space-around'>
                         <Col span={24}>
                              <Table
                                   title={ () => (
                                        <>
                                             <Row justify='space-around' gutter={[8,8]} type="flex" align='middle'>
                                                  <Col span={20}>
                                                       <div style={{textAlign:'center'}}>
                                                       <h3>RESUMEN DE INCIDENCIAS PARA <b>{ this.props.mesTexto.toUpperCase() } {this.props.anioSeleccionadoRMDP}</b></h3>
                                                       </div>
                                                  </Col>
                                                  <Col span={1}>
                                                       <Tooltip title='Exportar resumen de incidencias .pdf'>
                                                            <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } loading={ this.props.fetchingPDFResumenDeduccionAplicable } onClick={(e) => onExportPDF('pdf')}/>
                                                       </Tooltip>
                                                  </Col>
                                                  <Col span={1}>
                                                       <Tooltip title='Exportar resumen de incidencias .xls'>
                                                            <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={ this.props.fetchingEXCELResumenDeduccionAplicable } onClick={ (e) => onExportPDF('xls') }/>
                                                       </Tooltip>
                                                  </Col>
                                             </Row>
                                        </>)
                                   }
                                   size="small"
                                   rowKey="falla"
                                   columns={ columns }
                                   dataSource={ this.props.resumenDeduccionAplicable}
                                   loading={ this.props.fetchingResumenDeduccionAplicable}
                                   pagination={false}
                              />
                         </Col>
                    </Row>
               </Form>
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,

          resumenDeduccionAplicable: state.RMDPReducer.resumenDeduccionAplicable,
          fetchingResumenDeduccionAplicable: state.RMDPReducer.fetchingResumenDeduccionAplicable,

          fetchingPDFResumenDeduccionAplicable: state.RMDPReducer.fetchingPDFResumenDeduccionAplicable,
          fetchingEXCELResumenDeduccionAplicable: state.RMDPReducer.fetchingEXCELResumenDeduccionAplicable,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          getResumen: (idMes, anio) => {
               dispatch({ type: 'DEDUCCION_APLICABLE_RESUMEN_REQUEST', idMes, anio });
          },
          getPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_DEDUCCION_APLICABLE_RESUMEN_REQUEST', idMes, anio, mesTxt });
          },
          getEXCEL: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_DEDUCCION_APLICABLE_RESUMEN_REQUEST', idMes, anio, mesTxt });
          }
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(DeduccionAplicable);