import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Radio} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class TableSection extends React.Component {
    formRef = React.createRef();
    componentDidMount(){ 
        this.props.onGetGruposServicios(); this.props.onGetAreasFuncionales(); this.props.onGetUsuarioRes();
    }

    state={ agrupamientoLocal: null, titleAgrupamiento: '', pageExpandible: 1, orden: {
        field: 'sse.Folio',
        order: 'ASC'
    } }

    render() {
            const {fetchingReporteDinamicoBusqueda, listaReporteDinamicoBusqueda, listaReporteDinamicoBusquedaCantidad, fetchingExportarReporteDinamicoExcel, fetchingExportarReporteDinamicoPDF,  formularioRD,listaServiciosActivosF, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, onGetReporteDinamicoAgrupado, onGetReporteDinamico, onGetReporteDinamicoWithoutPagination,
            } = this.props;
            
            let permisosUsuario = sessionStorage.getItem('permisosUsuario');
            if(permisosUsuario[0]){
                permisosUsuario = JSON.parse(permisosUsuario);
            }

            const {checkbox, checkArray, state, order} = data;
            const {handleTableChange, handleSteps} = handlers;
            
            const onHandleTableChange = (pagination, filter, orden) => {
                handleTableChange(pagination, filter, orden);
            }

            const onHandleSteps = (value) => {
                handleSteps(value);
            }
            
            const descargarReporte = (value) => {
                if (value === 'pdf') { 
                    onDescargarReportePDF(formularioRD, checkbox, this.state.orden, this.state.agrupamientoLocal); 
                }  else { 
                    onDescargarReporteExcel(formularioRD, checkbox, this.state.orden, this.state.agrupamientoLocal); 
                }
            }

            const onHandleTableChangeExpandible = (pagination) => {
                this.setState({ pageExpandible: pagination.current });
                onGetReporteDinamicoAgrupado(formularioRD, pagination.current, order, this.state.agrupamientoLocal, listaServiciosActivosF, this.props.listaGruposServicios, this.props.listaAreasFuncionalesActivas, this.props.listaUsuariosActivos);
            }

            const dateWidth = checkArray.length>4 ? '170px' : '170px';
            
            const columns = [
                {title: 'Servicio',dataIndex: 'nombreServicio',key: 'nombreServicio',sorter: true}, 
                {title: 'Folio',dataIndex: 'folioSolicitudServicio', key: 'folioSolicitudServicio',sorter: true, width: dateWidth},
                {title: 'Clasificación',dataIndex: 'queja',key: 'queja',sorter: true},
                {title: 'Descripción de Falla',dataIndex: 'descripcionFalla',key: 'descripcionFalla',sorter: true},
                {title: 'Área funcional',dataIndex: 'areaFuncional',key: 'areaFuncional',sorter: true},
                {title: 'Unidad funcional',dataIndex: 'unidadFuncional',key: 'unidadFuncional',sorter: true},
                {title: 'Interior funcional',dataIndex: 'interiorFuncional',key: 'interiorFuncional',sorter: true},
                {title: 'Usuario que solicitó',dataIndex: 'usuarioSolicitoName',key: 'usuarioSolicitoName',sorter: true},
                {title: 'Usuario CCO',dataIndex: 'usuarioRegistro',key: 'usuarioRegistro',sorter: true},
                {title: 'Fecha alta',dataIndex: 'fechaAlta',key: 'fechaAlta',sorter: true, width: dateWidth},
                {title: 'Fecha cierre',dataIndex: 'fechaCierre',key: 'fechaCierre',sorter: true,width: dateWidth},
                {title: 'Fecha terminación',dataIndex: 'fechaTerminacion',key: 'fechaTerminacion',sorter: true,width: dateWidth}, 
                {title: 'Estado',dataIndex: 'estado',key: 'estado',sorter: true},
                {title: 'Actividades realizadas',dataIndex: 'actividadesRealizadas',key: 'actividadesRealizadas',sorter: true}
            ];
            
            const columnsSelected = columns.filter( field => checkArray.includes(field.dataIndex));
            const scroll = columnsSelected.length > 4 ? {x: columnsSelected.length * 200} : {x: 700}

            const radioButton = (value) => {
                let valueRadio = value.target.value;
                let otraStr = 'Grupo de Servicios';

                if(valueRadio !== 'GrupoSer') {
                    if(valueRadio === 'Area'){
                        otraStr = 'Área';
                    } else if(valueRadio === 'Clasificacion'){
                        otraStr = 'Clasificación';
                    } else if(valueRadio === 'UsuarioRegistra'){
                        otraStr = 'Usuario CCO';
                    } else {
                        otraStr = valueRadio.replace(/([a-z])([A-Z])/g, '$1 $2'); 
                    }
                    
                }
                onGetReporteDinamicoAgrupado(formularioRD, 1, order, valueRadio, listaServiciosActivosF, this.props.listaGruposServicios, this.props.listaAreasFuncionalesActivas, this.props.listaUsuariosActivos);
                this.setState({ agrupamientoLocal: valueRadio,  titleAgrupamiento: otraStr, pageExpandible: 1 });

                if(valueRadio !== 'SinAgrupar'){
                    onGetReporteDinamicoWithoutPagination(formularioRD, null, order, valueRadio);
                }
            };

            const onChangeAgrupado = (record, pagination, filter, orden) => {
                console.log(`record, e`, record, pagination, filter, orden);
                const orderFields = {nombreServicio:'ser.Nombre',folioSolicitudServicio:'sse.Folio',descripcionFalla:'fe.Nombre',areaFuncional:'afe.AreaFuncional',
                    unidadFuncional:'ufe.UnidadFuncional',interiorFuncional: 'ife.InteriorFuncional',usuarioSolicitoName:'us.Nombre',usuarioRegistro:' usu.Nombre',
                    fechaAlta: 'FechaAlta',fechaCierre:'sse.FechaCierre,',fechaTerminacion:'sse.FechaTerminacion',estado:'sse.Estado',actividadesRealizadas:'sse.ActividadesRealizadas', queja: 'sse.Queja',
                }
                record.listaReporteDinamicoGrupo = this.props.listaReporteDinamicoGrupo;
                record.listaReporteDinamicoGrupoCantidad = this.props.listaReporteDinamicoGrupoCantidad;
    
                if(orden) {
                    const field = orden.columnKey ? orderFields[orden.columnKey] : 'ser.Nombre';
                    const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                    const sorter = {field: field, order: orderField}
                    this.setState({orden: sorter})
                    return onGetReporteDinamico(formularioRD, pagination.current, sorter, this.state.agrupamientoLocal, record );
                }
                onGetReporteDinamico(formularioRD, pagination.current, this.state.orden, this.state.agrupamientoLocal, record);
            }

            const columnsAgrupamiento = [
                { title: this.state.titleAgrupamiento, dataIndex: 'etiquetaTabla', key: 'etiqueta' },
            ];

            return (
                <>
                    <Row justify='space-around'>
                        <Col span={24}>
                            <p style={{textAlign:'left'}} >Agrupamiento por: </p>
                            <Radio.Group defaultValue='SinAgrupar' onChange={radioButton} size="small">
                                <Radio.Button value="Servicio">Servicio</Radio.Button>
                                <Radio.Button value="GrupoSer">Grupo de Servicios</Radio.Button>
                                <Radio.Button value="Area">Área</Radio.Button>
                                <Radio.Button value="Clasificacion">Clasificación</Radio.Button>
                                <Radio.Button value="UsuarioSolicitante">Usuario Solicitante</Radio.Button>
                                <Radio.Button value="UsuarioRegistra">Usuario CCO</Radio.Button>
                                <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row justify='space-between'>
                        <Col span={11}>
                            <Button className="btnCargaArchivos" disabled={!permisosUsuario.exportarPdfReporteDinamico ? true : !listaReporteDinamicoBusqueda.length>0 || fetchingExportarReporteDinamicoPDF} loading={fetchingExportarReporteDinamicoPDF}
                            onClick={()=> descargarReporte('pdf')} style={{ width: '100%', marginTop: '20px' }}
                            >
                                Exportar Reporte PDF
                            </Button>
                        </Col>
                        <Col span={11}>
                            <Button className="btnCargaArchivos" disabled={!permisosUsuario.exportarExcelReporteDinámico ? true : !listaReporteDinamicoBusqueda.length>0 || fetchingExportarReporteDinamicoExcel} loading={fetchingExportarReporteDinamicoExcel}
                            onClick={()=> descargarReporte('excel')} style={{ width: '100%', marginTop: '20px' }}
                            >
                                Exportar Reporte Excel    
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        { this.state.agrupamientoLocal === null || this.state.agrupamientoLocal === 'SinAgrupar' ? (
                            <Col span={24}>
                                <Row style={{ paddingTop: '20px' }}>
                                    <Col span={24}>
                                        <Table
                                            size='small'
                                            rowKey='folioSolicitudServicio'
                                            columns={columnsSelected}
                                            dataSource={listaReporteDinamicoBusqueda}
                                            loading={fetchingReporteDinamicoBusqueda}
                                            locale={{ emptyText: 'Sin datos' }}
                                            onChange={onHandleTableChange}
                                            scroll={scroll}
                                            pagination={{ total: listaReporteDinamicoBusquedaCantidad, simple: true, current: state.page }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        ):(
                            <Col span={24}> <br/>
                                <Table
                                size='small'
                                rowKey='etiquetaLarga'
                                columns={columnsAgrupamiento}
                                expandable={{expandedRowRender: record => 
                                    
                                    <Table rowKey="id" columns={columns} loading={fetchingReporteDinamicoBusqueda} onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} dataSource={record.agrupados.data} pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} scroll={scroll}/>
                                    
                                }}
                                dataSource={this.props.listaReporteDinamicoGrupo}
                                loading={this.props.fetchingListaReporteDinamicoGrupo}
                                scroll={scroll}
                                onChange={onHandleTableChangeExpandible}
                                pagination={{ total: this.props.listaReporteDinamicoGrupoCantidad, simple: true, current: this.state.pageExpandible }}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row justify='start'>
                        <Col span={6}>
                            <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                        </Col>
                    </Row>
                </>
            );
        }
    }


const mapStateToProps = state => {
    return {
        fetchingReporteDinamicoBusqueda: state.SolicitudDeServiciosReducer.fetchingReporteDinamicoBusqueda,
        listaReporteDinamicoBusqueda: state.SolicitudDeServiciosReducer.listaReporteDinamicoBusqueda,
        listaReporteDinamicoBusquedaCantidad: state.SolicitudDeServiciosReducer.listaReporteDinamicoBusquedaCantidad,
        fetchingExportarReporteDinamicoExcel: state.SolicitudDeServiciosReducer.fetchingExportarReporteDinamicoExcel,
        fetchingExportarReporteDinamicoPDF: state.SolicitudDeServiciosReducer.fetchingExportarReporteDinamicoPDF,
        formularioRD: state.SolicitudDeServiciosReducer.formularioRD,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        listaReporteDinamicoGrupo: state.SolicitudDeServiciosReducer.listaReporteDinamicoGrupo,
        listaReporteDinamicoGrupoCantidad: state.SolicitudDeServiciosReducer.listaReporteDinamicoGrupoCantidad,
        fetchingListaReporteDinamicoGrupo: state.SolicitudDeServiciosReducer.fetchingListaReporteDinamicoGrupo,

        listaReporteDinamicoBusquedaWithout: state.SolicitudDeServiciosReducer.listaReporteDinamicoBusquedaWithout,
        listaReporteDinamicoBusquedaCantidadWithout: state.SolicitudDeServiciosReducer.listaReporteDinamicoBusquedaCantidadWithout,
        fetchingReporteDinamicoBusquedaWithout: state.SolicitudDeServiciosReducer.fetchingReporteDinamicoBusquedaWithout,

        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDescargarReportePDF: (formulario, checkbox, order, agrupamiento) => {
            dispatch({ type: 'EXPORTAR_PDF_REPORTE_DINAMICO_REQUEST', formulario, checkbox, order, agrupamiento});
        },
        onDescargarReporteExcel: (formulario, checkbox, order, agrupamiento) => {
            dispatch({ type: 'EXPORTAR_EXCEL_REPORTE_DINAMICO_REQUEST', formulario,checkbox, order, agrupamiento});
        },
        onGetReporteDinamicoAgrupado: (formulario, page, order, agrupamiento, listaServiciosActivosF, listaGruposServicios, listaAreasFuncionalesActivas, listaUsuariosActivos) => {
            dispatch({ type: 'LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_REQUEST', formulario, page, order, agrupamiento, listaServiciosActivosF, listaGruposServicios, listaAreasFuncionalesActivas, listaUsuariosActivos});
        },
        onGetReporteDinamico: (formulario, page, order, agrupamiento, folios) => {
            dispatch({ type: 'LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST', formulario, page, order, agrupamiento, folios});
        },

        onGetReporteDinamicoWithoutPagination: (formulario, page, order, agrupamiento) => {
            dispatch({ type: 'LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_REQUEST', formulario, page, order, agrupamiento});
        },
        onGetGruposServicios: () => {
            dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
        },
        onGetAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onGetUsuarioRes: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST', });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);