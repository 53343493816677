import React, { Component } from 'react';
import { Layout, Menu, Col, Row, Badge, Dropdown, Spin, Tooltip, Affix } from 'antd';
import moment from 'moment';
import DashboardContainer from './DashboardContainer';
import Alerta from './Alerta';
import { connect } from "react-redux";
import logo from '../assets/logo-horizontal.png';
import '../stylesheets/ConfiguracionYSeguridad/SideBar.css';
import '../stylesheets/ConfiguracionYSeguridad/Header.css';
import { LogoutOutlined, BellOutlined, DownOutlined, ClockCircleOutlined, CalendarOutlined, UserOutlined } from '@ant-design/icons';
import almacenes from '../assets/iconossidebar/almacenes.svg';
import base_de_conocimiento from '../assets/iconossidebar/bases_de_conocimiento.svg';
import configuracion_y_seguridad from '../assets/iconossidebar/configuracion_y_seguridad.svg';
import evaluacion_de_la_gestion from '../assets/iconossidebar/evaluacion_de_la_gestion.svg';
import gestion_de_calidad from '../assets/iconossidebar/gestion_de_calidad.svg';
import Informacion_operativa from '../assets/iconossidebar/Informacion_operativa.svg';
import informacion_proyecto from '../assets/iconossidebar/informacion_proyecto.svg';
import mecanismos_de_pago from '../assets/iconossidebar/mecanismos_de_pago.svg';
import monitoreo_integral from '../assets/iconossidebar/monitoreo_integral.svg';
import recursos_humanos from '../assets/iconossidebar/recursos_humanos.svg';
import RMDP from '../assets/iconossidebar/RMDP.svg';
import servicio_planificado from '../assets/iconossidebar/servicio_planificado.svg';
import servicios_publicos from '../assets/iconossidebar/servicios_publicos.svg';
import servicios_variables from '../assets/iconossidebar/servicios_variables.svg';
import solicitudes_de_servicio from '../assets/iconossidebar/solicitudes_de_servicio.svg';
import SesionCaducada from '../components/login/SesionCaducada';
import Catalogos_Generales from '../assets/iconossidebar/catalogos_generales.svg';
import Notificaciones from './Notificaciones';

//import logoDemo from '../assets/logoDemohorizontal.png';
import logoDemo from '../assets/LogoAppControllerHorizontal.svg';

const { Content, Sider } = Layout

class PapantlaApp extends Component {
    mount=0;
    componentDidMount(){
        this.mounted = true;
        var duration = moment.duration({
            'minutes': 5,
            'seconds': 0
        });
      
        var timestamp = new Date(0, 0, 0, 2, 10, 30);
        var interval = 1;
        const timer = setInterval(async () => {
            timestamp = new Date(timestamp.getTime() + interval * 1000);
      
            duration = moment.duration(duration.asSeconds() - interval, 'seconds');
            var min = duration.minutes();
            var sec = duration.seconds();
      
            sec -= 1;
            if (min < 0) return clearInterval(timer);
            if (min < 10 && min.length != 2) min = '0' + min;
            if (sec < 0 && min != 0) {
                min -= 1;
                sec = 59;
            } else if (sec < 10 && sec.length != 2) sec = '0' + sec;
      
            const tiempoRecarga = min + ':' + sec;
            this.props.onTiempoRecarga(tiempoRecarga);
        
            
            if (min == 0 && sec == 0)
                clearInterval(timer);
        }, 1000);

		try {
			this.mount = setInterval(async () => {
                const hoy = new Date();
                let dia = hoy.getDate();
                if(dia<10){
                    dia = '0' + dia;
                }
                let mes = hoy.getMonth() + 1;
                if(mes<10){
                    mes = '0' + mes;
                }
                const fecha = dia + '-' + mes + '-' + hoy.getFullYear();
                let segundos = hoy.getSeconds();
                if(segundos<10){
                    segundos = '0' + segundos;
                }
                let minutos = hoy.getMinutes();
                if(minutos<10){
                    minutos = '0' + minutos;
                }
                let horas = hoy.getHours();
                if(horas<10){
                    horas = '0' + horas;
                }
                const hora = horas + ':' + minutos + ':' + segundos;
                this.setState({
                    fecha: fecha,
                    hora: hora,
                    hoy: hoy,
                });
			}, 1000);
		}
		catch(e) {
			console.log('Error del intervalo' + e);
        }
        
        try {
			this.mount = setInterval(async () => {
                console.log('Entró al seInterval de 5 min');
                this.props.onImportarBiometrico(this.state.page, this.state.busqueda, this.state.filtros);
                this.props.onGetNotificacionesNoLeidas();
                this.props.onGetNotificaciones(1);
                var duration = moment.duration({
                    'minutes': 5,
                    'seconds': 0
                });
              
                var timestamp = new Date(0, 0, 0, 2, 10, 30);
                var interval = 1;
                const timer = setInterval(async () => {
                    timestamp = new Date(timestamp.getTime() + interval * 1000);
              
                    duration = moment.duration(duration.asSeconds() - interval, 'seconds');
                    var min = duration.minutes();
                    var sec = duration.seconds();
              
                    sec -= 1;
                    if (min < 0) return clearInterval(timer);
                    if (min < 10 && min.length != 2) min = '0' + min;
                    if (sec < 0 && min != 0) {
                        min -= 1;
                        sec = 59;
                    } else if (sec < 10 && sec.length != 2) sec = '0' + sec;
              
                    const tiempoRecarga = min + ':' + sec;
                    this.props.onTiempoRecarga(tiempoRecarga);
                
                    
                    if (min == 0 && sec == 0)
                        clearInterval(timer);
                }, 1000);
            }, 300000);
		}
		catch(e) {
			console.log('Error del intervalo' + e);
        }

        // try {
		// 	this.mount = setInterval(async () => {
        //         console.log('Entró al seInterval de 1 min');
        //         this.props.onGetNotificacionesNoLeidas();
        //         let duration = moment.duration({
        //             'minutes': 1,
        //             'seconds': 0
        //         });
              
        //         let timestamp = new Date(0, 0, 0, 2, 10, 30);
        //         let interval = 1;
        //         const timer = setInterval(async () => {
        //             timestamp = new Date(timestamp.getTime() + interval * 1000);
              
        //             duration = moment.duration(duration.asSeconds() - interval, 'seconds');
        //             let min = duration.minutes();
        //             let sec = duration.seconds();
              
        //             sec -= 1;
        //             if (min < 0) return clearInterval(timer);
        //             if (min < 10 && min.length != 2) min = '0' + min;
        //             if (sec < 0 && min != 0) {
        //                 min -= 1;
        //                 sec = 59;
        //             } else if (sec < 10 && sec.length != 2) sec = '0' + sec;
              
        //             const tiempoRecarga = min + ':' + sec;
        //             this.props.onTiempoRecarga(tiempoRecarga);
                
                    
        //             if (min == 0 && sec == 0)
        //                 clearInterval(timer);
        //         }, 1000);
        //     }, 60000);
		// }
		// catch(e) {
		// 	console.log('Error del intervalo' + e);
        // }

        this.props.onGetModulosBitacoraByIdModulo();
    }

    componentWillUnmount(){
        clearInterval(this.mount);
    }
    state = {
        collapsed: true,
        hora: '00:00:00',
        fecha: '01-01-2021',
        hoy: null,
    };
    
    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    render() {
        const { vistaDashboard, onCambiarVista, onCerrarSesion, fetchingLogout, onShowExpirarSesion, onShowNotificaciones } = this.props;
        let userInfo = sessionStorage.getItem('userInfo');
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        let hoy = sessionStorage.getItem('hoy');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const clickMenu = (e) => {
            console.log(e);
            if(e.key=='Salir'){
                onCerrarSesion();
            }
            else{
                sessionStorage.setItem('vistaDashboard', e.key)
                onCambiarVista(e.key);
                this.props.onGetModulosBitacoraByIdModulo();
            }
        }
        const clickMenuPerfil = (e) => {
            console.log(e);
            if(e.key=='Salir'){
                onCerrarSesion();
            }
            else{
                sessionStorage.setItem('vistaDashboard', e.key)
                onCambiarVista(e.key);
                this.props.onGetModulosBitacoraByIdModulo();
            }
        }
        const menu = (
            <Menu onClick={clickMenuPerfil}>
                <Menu.Item key="editarPerfil">Editar Contraseña</Menu.Item>
                <Menu.Divider />
                <Menu.Item key="Salir">Salir</Menu.Item>
            </Menu>
        );
        
        if(hoy){
            let minutos = moment(hoy).diff(moment(this.state.hoy), 'minutes');
            // Falten 2 minutos cierra sesión
            if(minutos <= 2){
                onCerrarSesion();
            }
            
            if(minutos <= 15){
                onShowExpirarSesion();
            }
        }

        const showNotificaciones = () => {
            onShowNotificaciones();
            if(this.props.cantidadNotificacionesNoLeidas > 0){
                this.props.onActualizarNotificaciones(this.props.listaNotificacionesNoLeidas);
            }
            // this.props.onGetNotificaciones(1);
        }

        return (
            <div>
                <SesionCaducada/>
                <Spin spinning={fetchingLogout}>
                    <Layout style={{ minHeight: '100vh' }}>
                        <Sider width={250} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                            {/* <div className="logo" /> */}
                            <Menu className='sideBarCollapsed' defaultSelectedKeys={[vistaDashboard]} mode="inline" onClick={clickMenu}>
                                {permisosUsuario.verConfiguracionYSeguridad  ? (
                                <Menu.Item key="Configuración y Seguridad">
                                    {this.state.collapsed ? (
                                    <div>
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={configuracion_y_seguridad}/>
                                        </span>
                                        <span>Configuración y Seguridad</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Configuración y Seguridad' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={configuracion_y_seguridad}/>
                                        </span>
                                        <span>Configuración y Seguridad</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.buscarProyectoMod2  ? (
                                <Menu.Item key="Información del Proyecto">
                                    {this.state.collapsed ? (
                                    <div>
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={informacion_proyecto}/>
                                        </span>
                                        <span>Información del Proyecto</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Información del Proyecto' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={informacion_proyecto}/>
                                        </span>
                                        <span>Información del Proyecto</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verInformacionOperativa  ? (
                                <Menu.Item key="Información Operativa">
                                    {this.state.collapsed ? (
                                    <div>
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={Informacion_operativa}/>
                                        </span>
                                        <span>Información Operativa</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Información Operativa' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={Informacion_operativa}/>
                                        </span>
                                        <span>Información Operativa</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.buscarDocumentoBase  ? (
                                <Menu.Item key="Base de Conocimiento">
                                {this.state.collapsed ? (
                                    <div>
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={base_de_conocimiento}/>
                                        </span>
                                        <span>Base de Conocimiento</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Base de Conocimiento' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={base_de_conocimiento}/>
                                        </span>
                                        <span>Base de Conocimiento</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verAlmacenes  ? (
                                <Menu.Item key="Almacenes">
                                {this.state.collapsed ? (
                                    <div>
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={almacenes}/>
                                        </span>
                                        <span>Almacenes</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Almacenes' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={almacenes}/>
                                        </span>
                                        <span>Almacenes</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verCatalogosGenerales  ? (
                                <Menu.Item key="Catálogos Generales">
                                {this.state.collapsed ? (
                                    <div>
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={Catalogos_Generales}/>
                                        </span>                            
                                        <span>Catálogos Generales</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Catálogos Generales' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={Catalogos_Generales}/>
                                        </span>                            
                                        <span>Catálogos Generales</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verRecursosHumanos  ? (
                                <Menu.Item key="Recursos Humanos">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={recursos_humanos}/>
                                        </span>
                                        <span>Recursos Humanos</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Recursos Humanos' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={recursos_humanos}/>
                                        </span>
                                        <span>Recursos Humanos</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verServiciosVariables  ? (
                                <Menu.Item key="Servicios Variables">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={servicios_variables}/>
                                        </span>
                                        <span>Servicios Variables</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Servicios Variables' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={servicios_variables}/>
                                        </span>
                                        <span>Servicios Variables</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verSolicitudDeServicios  ? (
                                <Menu.Item key="Solicitud de Servicios">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={solicitudes_de_servicio}/>
                                        </span>
                                        <span>Solicitud de Servicios</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Solicitud de Servicios' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={solicitudes_de_servicio}/>
                                        </span>
                                        <span>Solicitud de Servicios</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verServiciosPlanificados  ? ( 
                                <Menu.Item key="Servicios Planificados">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={servicio_planificado}/>
                                        </span>
                                        <span>Servicios Planificados</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Servicios Planificados' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={servicio_planificado}/>
                                        </span>
                                        <span>Servicios Planificados</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verServiciosPublicos  ? (
                                <Menu.Item key="Servicios Públicos">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={servicios_publicos}/>
                                        </span>
                                        <span>Servicios Públicos</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Servicios Públicos' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={servicios_publicos}/>
                                        </span>
                                        <span>Servicios Públicos</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verGestionDeCalidad  ? (
                                <Menu.Item key="Gestión de Calidad">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={gestion_de_calidad}/>
                                        </span>
                                        <span>Gestión de Calidad</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Gestión de Calidad' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={gestion_de_calidad}/>
                                        </span>
                                        <span>Gestión de Calidad</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verEvaluacionDeLaGestion  ? (
                                <Menu.Item key="Evaluación de la Gestión">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={evaluacion_de_la_gestion}/>
                                        </span>
                                        <span>Evaluación de la Gestión</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Evaluación de la Gestión' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={evaluacion_de_la_gestion}/>
                                        </span>
                                        <span>Evaluación de la Gestión</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verMonitoreoIntegral  ? ( 
                                <Menu.Item key="Monitoreo Integral">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={monitoreo_integral}/>
                                        </span>
                                        <span>Monitoreo Integral</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Monitoreo Integral' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={monitoreo_integral}/>
                                        </span>
                                        <span>Monitoreo Integral</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verMecanismosDePago  ? (
                                <Menu.Item key="Mecanismos de Pago">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={mecanismos_de_pago}/>
                                        </span>
                                        <span>Mecanismos de Pago</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Mecanismos de Pago' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={mecanismos_de_pago}/>
                                        </span>
                                        <span>Mecanismos de Pago</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}
                                {permisosUsuario.verRMDP  ? ( 
                                <Menu.Item key="RMDP">
                                {this.state.collapsed ? (
                                    <div>
                                       <span className="anticon">
                                            <img className="iconoSideBar" src={RMDP}/>
                                        </span>
                                        <span>RMDP</span>
                                    </div>):(
                                    <Tooltip placement="right" title='Reporte Mensual de Desempeño y Pagos' >
                                        <span className="anticon">
                                            <img className="iconoSideBar" src={RMDP}/>
                                        </span>
                                        <span>RMDP</span>
                                    </Tooltip>
                                    )}
                                </Menu.Item>):false}

                                <Menu.Item key="Salir">
                                    <LogoutOutlined />
                                    <span>Salir</span>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout className="contentLayout">
                            <Affix offsetTop={0.01}>
                                <Row className="headerPapantla" justify='space-between' align='middle'>
                                    <Col span={3} >
                                        <img src={logoDemo} className='logoHeader' />
                                    </Col>
                                    <Col span={13}>
                                        <Row justify='space-around' >
                                            <Col span={5} className='efectoHeader' >
                                                <Row justify="space-around" align="middle">
                                                    <Col span={3}>
                                                        <ClockCircleOutlined className='iconoHeader'/>
                                                    </Col>
                                                    <Col span={20} className='nombreHeader'>
                                                        {this.state.hora}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={5} className='efectoHeader'>
                                                <Row justify='space-around' align="middle">
                                                    <Col span={3}><CalendarOutlined className='iconoHeader'/></Col>
                                                    <Col span={20} className='nombreHeader'>{this.state.fecha}</Col>
                                                </Row>
                                            </Col>
                                            <Col span={5} className='notificacionHeader efectoHeader'>
                                                <Row justify='space-around' align="middle" onClick={showNotificaciones}>
                                                    <Col span={3}>
                                                        <Badge count={this.props.cantidadNotificacionesNoLeidas}>
                                                            <BellOutlined className='iconoHeader' />
                                                        </Badge>
                                                    </Col>
                                                    <Col span={20} className='nombreHeader notificacionBold'>Notificaciones</Col>
                                                </Row>
                                            </Col>
                                            <Col span={8} className="efectoHeader">
                                                <Row justify='space-around' align="middle">
                                                    <Col span={3}>
                                                        <UserOutlined className='iconoHeader'/>
                                                    </Col>
                                                    <Col span={20} className='elipsis'>
                                                        <Dropdown overlay={menu} trigger={['click']}>
                                                            <a className="dropdownHeader" onClick={e => e.preventDefault()}>
                                                            <strong>{userInfo[0].aMaterno ? (userInfo[0].nombre + ' ' + userInfo[0].aPaterno + ' ' + userInfo[0].aMaterno) : userInfo[0].nombre + ' ' + userInfo[0].aPaterno} </strong><DownOutlined />
                                                            </a>
                                                        </Dropdown>
                                                    </Col>    
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>   
                            </Affix>
                            <Content style={{padding: '6px', paddingTop: '1em', backgroundColor:'#f2f2f2'}}>
                            {/* <Content> */}
                                <Row>
                                    <Col span={24}>
                                        <DashboardContainer />
                                    </Col>
                                </Row>
                            </Content>
                        </Layout>
                        <Alerta/>
                    </Layout>
                </Spin>
                <Notificaciones />
            </div>
        );
    }    
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        fetchingLogout: state.LoginReducer.fetchingLogout,

        cantidadNotificacionesNoLeidas: state.ConfiguracionYSeguridadReducer.cantidadNotificacionesNoLeidas,
        listaNotificacionesNoLeidas: state.ConfiguracionYSeguridadReducer.listaNotificacionesNoLeidas,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaDashboard) => {
            dispatch({ type: "CAMBIAR_VISTA_DASHBOARD" , vistaDashboard:vistaDashboard});
        },
        onCerrarSesion: () => {
            dispatch({ type: 'LOG_OUT_REQUEST' });
        },
        onShowExpirarSesion: () => {
            dispatch({ type: 'MODAL_EXPIRAR_SESION', showExpirarSesion: true })
        },
        onImportarBiometrico: (page, busqueda, filtros) => {
            dispatch({ type: "IMPORTAR_BIOMETRICO_REQUEST", page, busqueda, filtros })
        },
        onTiempoRecarga: (tiempoRecarga) => {
            dispatch({ type: "TIEMPO_RECARGA", tiempoRecarga })
        },
        onShowNotificaciones: () => {
            dispatch({ type: "SHOW_NOTIFICACIONES" , showNotificaciones: true });
        },
        onGetModulosBitacoraByIdModulo: () => {
            dispatch({ type: 'LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST' });
        },
        onGetNotificacionesNoLeidas: () => {
			dispatch({type: "LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST"});
        },
        onActualizarNotificaciones: (listaNotificacionesNoLeidas) => {
            dispatch({ type: "ACTUALIZAR_NOTIFICACIONES_REQUEST", listaNotificacionesNoLeidas });
        },
        onGetNotificaciones: (page) => {
			dispatch({ type: "LISTA_NOTIFICACIONES_REQUEST", page });
		},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PapantlaApp);