import React, { Component } from 'react';
import { Form, Select, Button, Modal, Row, Col, Spin, Tooltip, Input, Divider, Typography, message, DatePicker, Tag, Collapse, Checkbox, Radio, Card } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { UserAddOutlined, UserDeleteOutlined, UserOutlined, UserSwitchOutlined, CheckCircleFilled, CloseCircleFilled, HighlightTwoTone } from '@ant-design/icons';
import moment from 'moment';

const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 }, };
const { Option } = Select;
const { Text, Paragraph } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

class ModalNuevoResponsable extends Component {
     formRef = React.createRef();
     state = { servicioSeleccionado: true, firma: false }

     render() {
          const { fetchingNuevoResponsable, showModalNuevoResponsable, limpiarFormulario } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);
               
               if(!formulario.responsableServicio ){
                    message.error('Debe de ingresarse al menos un responsable para el servicio');
               } else {
                    let repetidos = [];
                    let temporal = [];
                    let elementos = [];

                    for(let i in formulario.responsableServicio){  elementos.push(formulario.responsableServicio[i].rangoValidacion); }

                    if(formulario.responsableServicio.length === 1){
                         formulario.responsableServicio[0].firma = formulario.responsableServicio[0].firma ? formulario.responsableServicio[0].firma : "true";
                    } else if(formulario.responsableServicio.length > 1) {
                         for(let i = 0; i < formulario.responsableServicio.length; i++){
                              if(i === 0){
                                   formulario.responsableServicio[0].firma  = formulario.responsableServicio[0].firma ? formulario.responsableServicio[0].firma : "true";
                              } else {
                                   formulario.responsableServicio[i].firma  = formulario.responsableServicio[i].firma ? formulario.responsableServicio[i].firma : "false";
                              }
                         }
                    }

                    //Eliminar repetidos
                    elementos.forEach((value,index)=>{
                         temporal = Object.assign([],elementos); //Copiado de elemento
                         temporal.splice(index,1); //Se elimina el elemnto q se compara
                         // Se busca en temporal el elemento, y en repetido para ver si esta ingresado al array. indexOf retorna -1 si el elemento no se encuetra /
                         if(temporal.indexOf(value)!=-1 && repetidos.indexOf(value)==-1) {
                              repetidos.push(value);
                         }
                    });


                    let contadorFirma = 0
                    let respSuplenteIgual = false;
                    for(let i in formulario.responsableServicio){
                         if(formulario.responsableServicio[i].firma === "true") contadorFirma++;

                         if(formulario.responsableServicio[i].suplenteRS){
                              if(formulario.responsableServicio[i].suplenteRS === formulario.responsableServicio[i].responsableS){
                                   if(formulario.responsableServicio[i].collapsedRS){
                                        if(formulario.responsableServicio[i].collapsedRS.length === 1) respSuplenteIgual = true;
                                   }
                              }
                         }
                    }

                    if(formulario.responsableAT ){
                         for(let i in formulario.responsableAT ){
                              if(formulario.responsableAT[i].suplenteAT){
                                   if(formulario.responsableAT[i].suplenteAT === formulario.responsableAT[i].responsableAT){
                                        if(formulario.responsableAT[i].collapsedAT){
                                             if(formulario.responsableAT[i].collapsedAT.length === 1) respSuplenteIgual = true;
                                        } 
                                   }
                              }
                         }
                    }

                    if(formulario.responsablePYRS){
                         for(let i in formulario.responsablePYRS ){
                              if(formulario.responsablePYRS[i].suplentePYRS){
                                   if(formulario.responsablePYRS[i].suplentePYRS === formulario.responsablePYRS[i].responsablePYRS){
                                        if(formulario.responsablePYRS[i].collapsedPYRS){
                                             if(formulario.responsablePYRS[i].collapsedPYRS.length === 1) respSuplenteIgual = true;
                                        } 
                                   }
                              }
                         }
                    }  

                    if(repetidos.length > 0){
                         message.error('No pueden existir responsables de servicio con el mismo rango de validación asignado');
                    } else {
                         if(contadorFirma > 1){
                              message.error('Sólo puede existir un responsable de firma, favor de verificar');
                         } else {
                              if(contadorFirma === 0){
                                   message.error('Es necesario seleccionar un responsable de firma, favor de verificar');
                              } else {
                                   if(respSuplenteIgual){
                                        message.error('Existe al menos un responsable al que se asignó así mismo como suplencia, favor de verificar');
                                   } else {
                                        this.props.onNuevoResponsable(formulario, this.props.page, this.props.busqueda, this.props.orden);
                                   }
                              }
                         }
                    }
               }
          };
          

          const handleCancel = () => {
               this.props.onCloseModalNuevo();
               this.formRef.current.resetFields();
               this.props.onCleanPartidasByIdsServicios();
               this.setState({servicioSeleccionado: true});

               this.props.onCleanUsuariosByIdsServiciosTipoAT();
               this.props.onCleanUsuariosByIdsServiciosTipoPYRS();
               this.props.onCleanUsuariosByIdsServiciosTipoRS();
          }

          if(limpiarFormulario && limpiarFormulario === 'crearResponsable'){
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          };

          const servicioSeleccionado = (value) => {
               console.log('value', value)
               if(value.length > 0){
                    let idsServicios = []
                    for(let i in value){idsServicios.push(value[i].split(' - ')[0])}
                    this.props.onGetPartidasByIdsServicios(idsServicios);
                    // this.props.onGetUsuariosByIdsServicios(value);
                    this.props.onGetUsuariosByIdsServiciosTipoRS(value);
                    this.props.onGetUsuariosByIdsServiciosTipoAT(value);
                    this.props.onGetUsuariosByIdsServiciosTipoPYRS(value);
                    this.setState({ servicioSeleccionado: false });
                    this.formRef.current.setFieldsValue({'idPartidas': undefined, 'responsableServicio': undefined, 'responsableAT': undefined, 'responsablePYRS': undefined});
               } else {
                    this.setState({ servicioSeleccionado: true });
                    this.props.onCleanPartidasByIdsServicios();
                    this.formRef.current.setFieldsValue({'idPartidas': undefined, 'responsableServicio': undefined, 'responsableAT': undefined, 'responsablePYRS': undefined});
                    this.props.onCleanUsuariosByIdsServicios();
                    this.props.onCleanUsuariosByIdsServiciosTipoAT();
                    this.props.onCleanUsuariosByIdsServiciosTipoPYRS();
                    this.props.onCleanUsuariosByIdsServiciosTipoRS();
               }
          };

          const  disabledDate = (fecha) => {
               let a = new Date((new Date()).valueOf() - 1000*3600*24);
               return fecha && fecha < moment(a, "YYYY-MM-DD");

               // if( fecha && moment(fecha.format('YYYY-MM-DD')) < this.props.listaInfoProyecto.fechaInicioOperacion) {
               //     return fecha && moment(fecha.format('YYYY-MM-DD')) < this.props.listaInfoProyecto.fechaInicioOperacion;
               // } else {
               //     if(this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
               //      return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((this.props.listaInfoProyecto.vigencia +  parseInt(this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' + this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
               //      }
               // }
          };

          const onUsuarioSelected = (id, tipo) => {
               const formulario = this.formRef.current.getFieldsValue();

               if(formulario){
                    if(tipo === 'RS'){
                         if(formulario.responsableServicio.length > 1){
                              let repetidos = [];
                              let temporal = [];
                              let elementos = [];

                              for(let i in formulario.responsableServicio){  
                                   if(formulario.responsableServicio[i].responsableS){
                                        elementos.push(formulario.responsableServicio[i].responsableS.split('-')[0]); 
                                   }
                              }

                              //Eliminar repetidos
                              elementos.forEach((value,index)=>{
                                   temporal = Object.assign([],elementos); //Copiado de elemento
                                   temporal.splice(index,1); //Se elimina el elemnto q se compara
                                   // Se busca en temporal el elemento, y en repetido para ver si esta ingresado al array. indexOf retorna -1 si el elemento no se encuetra /
                                   if(temporal.indexOf(value)!=-1 && repetidos.indexOf(value)==-1) {
                                        repetidos.push(value);
                                   }
                              });

                              if(repetidos.length > 0){
                                   message.warn('Se asignaron al menos dos usuarios iguales para el mismo tipo de responsables');
                                   this.formRef.current.resetFields(['responsableServicio']);
                              }
                         }
                    } else if(tipo === 'AT'){
                         if(formulario.responsableAT.length > 1){
                              let repetidos = [];
                              let temporal = [];
                              let elementos = [];

                              for(let i in formulario.responsableAT){  
                                   if(formulario.responsableAT[i].responsableAT){
                                        elementos.push(formulario.responsableAT[i].responsableAT.split('-')[0]); 
                                   }
                              }

                              //Eliminar repetidos
                              elementos.forEach((value,index)=>{
                                   temporal = Object.assign([],elementos); //Copiado de elemento
                                   temporal.splice(index,1); //Se elimina el elemnto q se compara
                                   // Se busca en temporal el elemento, y en repetido para ver si esta ingresado al array. indexOf retorna -1 si el elemento no se encuetra /
                                   if(temporal.indexOf(value)!=-1 && repetidos.indexOf(value)==-1) {
                                        repetidos.push(value);
                                   }
                              });

                              if(repetidos.length > 0){
                                   message.warn('Se asignaron al menos dos usuarios iguales para el mismo tipo de responsables');
                                   this.formRef.current.resetFields(['responsableAT']);
                              }
                         }
                    } else if(tipo === 'PYRS'){
                         if(formulario.responsablePYRS.length > 1){
                              let repetidos = [];
                              let temporal = [];
                              let elementos = [];

                              for(let i in formulario.responsablePYRS){  
                                   if(formulario.responsablePYRS[i].responsablePYRS){
                                        elementos.push(formulario.responsablePYRS[i].responsablePYRS.split('-')[0]); 
                                   }
                              }

                              //Eliminar repetidos
                              elementos.forEach((value,index)=>{
                                   temporal = Object.assign([],elementos); //Copiado de elemento
                                   temporal.splice(index,1); //Se elimina el elemnto q se compara
                                   // Se busca en temporal el elemento, y en repetido para ver si esta ingresado al array. indexOf retorna -1 si el elemento no se encuetra /
                                   if(temporal.indexOf(value)!=-1 && repetidos.indexOf(value)==-1) {
                                        repetidos.push(value);
                                   }
                              });

                              if(repetidos.length > 0){
                                   message.warn('Se asignaron al menos dos usuarios iguales para el mismo tipo de responsables');
                                   this.formRef.current.resetFields(['responsablePYRS']);
                              }
                         }
                    }
               }
          };

          const onRangoSelected = (id) => {
               const formulario = this.formRef.current.getFieldsValue();

               if(formulario){
                    if(formulario.responsableServicio.length > 1){
                         let repetidos = [];
                         let temporal = [];
                         let elementos = [];

                         for(let i in formulario.responsableServicio){  
                              if(formulario.responsableServicio[i].rangoValidacion){
                                   elementos.push(formulario.responsableServicio[i].rangoValidacion); 
                              }
                         }

                         //Eliminar repetidos
                         elementos.forEach((value,index)=>{
                              temporal = Object.assign([],elementos); //Copiado de elemento
                              temporal.splice(index,1); //Se elimina el elemnto q se compara
                              // Se busca en temporal el elemento, y en repetido para ver si esta ingresado al array. indexOf retorna -1 si el elemento no se encuetra /
                              if(temporal.indexOf(value)!=-1 && repetidos.indexOf(value)==-1) {
                                   repetidos.push(value);
                              }
                         });

                         if(repetidos.length > 0){
                              message.error('No pueden existir responsables de servicio con el mismo rango de validación asignado');
                              this.formRef.current.resetFields(['responsableServicio']);
                         }
                    }
               }
          };

          const usuarioFirmaSelected = (e, field) => {
               console.log('e', e);
               console.log('field', field);
               const formulario = this.formRef.current.getFieldsValue();

               if(formulario.responsableServicio){
                    if(formulario.responsableServicio[0].firma === "false"){
                         this.setState({firma:"true"});
                    } else {
                         this.setState({firma:"true"});
                    }
               }

               // formulario.responsableServicio[field.key].firma = e.target.checked;

               // console.log('formulario', formulario)
               // if(formulario.responsableServicio.length > 1){
               //      let contador = 0;

               //      for(let i in formulario.responsableServicio){  
               //           if(formulario.responsableServicio[i].firma){
               //                contador++;
               //           }
               //      }

               //      if(contador > 1){
               //           message.warn('Sólo se puede asignar un usuario para firma');
               //      }
               // }

               // this.setState({formulario});
          };

          return (
               <Modal
                    visible={showModalNuevoResponsable}
                    title='Nuevo Responsable'
                    onCancel={handleCancel}
                    footer={false}
                    width={'65%'}
               >
                    <Spin spinning={fetchingNuevoResponsable}>
                         <Form {...layout} layout={'vertical'} name="formulario" onFinish={onFinish}  ref={this.formRef}>
                              <Row justify='space-around' gutter={[8,8]}>
                              <Col span={24}>
                              <Card className='cardResponsablesTitle'>
                                   <Col span={24}>
                                        <Form.Item name="idsServicios" label='Servicio' rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                             <Select
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Selecciona el servicio"
                                                  loading={this.props.fetchingListaServiciosActivosF}
                                                  showSearch
                                                  onChange={servicioSeleccionado}
                                                  mode='multiple'
                                             >
                                                  {this.props.listaServiciosActivosF.map(option => 
                                                  <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                       <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                            <Text style={{color:'whitesmoke'}}>{option.idServicio + ' - '}</Text>{option.nomenclatura + ': ' + option.nombre}
                                                       </Tooltip>
                                                  </Option>
                                                  )}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   { this.props.listaPartidasByIdsServicios.length > 0 &&
                                        <Col span={24}>
                                             <Form.Item label="Partida" name="idPartidas" rules={[{ required: this.props.listaPartidasByIdsServicios.length > 0 ? true : false, message: 'Favor de llenar el campo', }]} >
                                                  { this.props.listaPartidasByIdsServicios.length > 0 ? (
                                                       <Select 
                                                            allowClear 
                                                            style={{width: '100%'}}
                                                            placeholder="Selecciona la partida"
                                                            loading={this.props.fetchingListaPartidasByIdsServicios}
                                                            showSearch
                                                            mode='multiple'
                                                       >
                                                            {this.props.listaPartidasByIdsServicios.map(option => 
                                                                 <Option key={option.idPartida + '-' + option.nomenclatura + ': ' + option.nombre}>
                                                                      <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                           <Text style={{color:'whitesmoke'}}>{option.idPartida + '-'}</Text>{option.nomenclatura + ': ' + option.nombre}
                                                                      </Tooltip>
                                                                 </Option>)}
                                                       </Select>
                                                  ):(
                                                       <Input style={{textAlign:'left'}} disabled placeholder='Servicio sin partidas' />
                                                  ) }
                                             </Form.Item>
                                        </Col>
                                   }
                              </Card>
                              </Col>

                                   <Col span={24}>
                                        <Form.List name="responsableServicio">
                                             {(fields, { add, remove }) => ( <>
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                       <Col span={23}>
                                                            <Divider orientation='left'> <UserOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/> <Text style={{color:'#0e2c82'}} strong> Responsables de Servicio </Text></Divider>
                                                       </Col>

                                                       <Col span={1} style={{paddingTop:'1em'}}>
                                                            <Tooltip title='Agregar nuevo responsable'  placement="topRight">
                                                                 <Button  className="iconTer" shape="circle" icon={<UserAddOutlined />} onClick={() => add()}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row>

                                                  <Row justify='space-around' gutter={[8,8]}>
                                                       { fields.length === 0 &&
                                                            <Col span={24}>
                                                                 <Button  type="dashed" style={{borderRadius:'1em', width:'100%', height:'100%'}} onClick={() => add()}>
                                                                      <Row justify='center' style={{padding:'1em'}}>
                                                                           <UserAddOutlined className='iconTabPane'/>
                                                                           <Col span={24} style={{paddingTop:'1em'}}>
                                                                                <Text strong> Haz click para añadir al menos un responsable de servicio </Text>
                                                                           </Col>
                                                                      </Row>
                                                                 </Button>
                                                            </Col> 
                                                       }
                                                  </Row>

                                                  {fields.map(field => (
                                                  <Card className='cardResponsables' style={{ marginTop:'1em'}}>
                                                       <Row justify='center' key={field.key} gutter={[8,8]} align='middle'>
                                                            <Col span={23}>
                                                                 <Row justify='space-around' key={field.key} gutter={[8,8]}>
                                                                      <Col span={5} style={{paddingTop:'3em'}}> 
                                                                           <Form.Item
                                                                                {...field}
                                                                                name={[field.name, 'firma']}
                                                                                fieldKey={[field.fieldKey, 'firma']}
                                                                                rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                                                initialValue={false}
                                                                           >
                                                                                <Radio.Group defaultValue={fields.length === 1 ? "true" : field.key === 0 ? "true" : "false"} size="small" onChange={(e) => usuarioFirmaSelected(e, field)} disabled={ fields.length === 1 ? true : false }>
                                                                                     <Tooltip title='Usuario que aparecerá en formatos para firmar'>
                                                                                          <Radio.Button value={"true"}><HighlightTwoTone twoToneColor="#52c41a"/> Firma </Radio.Button>
                                                                                     </Tooltip>
                                                                                     <Radio.Button value={"false"}><HighlightTwoTone twoToneColor="#eb2f96"/> No firma </Radio.Button>
                                                                                </Radio.Group>
                                                                           </Form.Item>
                                                                      </Col> 

                                                                      <Col span={12}>
                                                                           <Form.Item
                                                                                {...field}
                                                                                label={'Nombre y Correo Electrónico'}
                                                                                name={[field.name, 'responsableS']}
                                                                                fieldKey={[field.fieldKey, 'responsableS']}
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                           >
                                                                                <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario o correo del responsable de servicio" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoRS} showSearch onChange={() => onUsuarioSelected(field, 'RS')}>
                                                                                     {this.props.listaUsuariosByIdsServiciosTipoRS.map(option => 
                                                                                          <Option key={option.aMaterno ? option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username : option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ': ' + option.username}>
                                                                                               {option.aMaterno ? 
                                                                                                    <>
                                                                                                         <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username }
                                                                                                    </>
                                                                                                    : 
                                                                                                    <>
                                                                                                         <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno +  ': ' + option.username }
                                                                                                    </>
                                                                                               }
                                                                                          </Option>
                                                                                     )}
                                                                                </Select>
                                                                           </Form.Item>
                                                                      </Col>

                                                                      <Col span={7}>
                                                                           <Form.Item
                                                                                {...field}
                                                                                label={'Rango de Validación'}
                                                                                name={[field.name, 'rangoValidacion']}
                                                                                fieldKey={[field.fieldKey, 'rangoValidacion']}
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                           >
                                                                                {this.props.listaActivosRangosValidacion &&
                                                                                <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el rango de validación" loading={this.props.fetchingListaActivosRangosValidacion} showSearch onChange={() => onRangoSelected(field)}>
                                                                                     {this.props.listaActivosRangosValidacion.map(option => 
                                                                                          <Option key={option.idRangoDeValidacion}>
                                                                                               {option.nombre + ' (' + option.rangInf + ' - ' + option.rangSup + ')'}
                                                                                          </Option>
                                                                                     )}
                                                                                </Select>
                                                                                }
                                                                           </Form.Item>
                                                                      </Col>

                                                                      
                                                            
                                                                      <Col span={24}>
                                                                           <Form.Item {...field} name={[field.name, 'collapsedRS']}  fieldKey={[field.fieldKey, 'collapsedRS']} >
                                                                           <Collapse bordered={false} expandIcon={({ isActive }) =>  isActive ? <Tooltip title='Suplente activo'> <UserSwitchOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/> </Tooltip> : <Tooltip title='Suplente inactivo'> <UserSwitchOutlined style={{fontSize:'1.5em', color: '#b5b5b5'}}/> </Tooltip> } style={{ borderTopRightRadius:'1em', borderTopLeftRadius:'1em'}} destroyInactivePanel>
                                                                                <Panel header={<Text style={{color:'#666666'}} strong> Suplencia </Text>} key="1">
                                                                                     <Row justify='space-around' gutter={[8,8]}>
                                                                                     <Col span={15}>
                                                                                          <Form.Item
                                                                                               {...field}
                                                                                               label={'Nombre y Correo Electrónico'}
                                                                                               name={[field.name, 'suplenteRS']}
                                                                                               fieldKey={[field.fieldKey, 'suplenteRS']}
                                                                                               rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                                          >
                                                                                               <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario o correo del suplente del responsable de servicio" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoRS} showSearch>
                                                                                                    {this.props.listaUsuariosByIdsServiciosTipoRS.map(option => 
                                                                                                         <Option key={option.aMaterno ? option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username : option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ': ' + option.username}>
                                                                                                              {option.aMaterno ? 
                                                                                                                   <>
                                                                                                                        <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username }
                                                                                                                   </>
                                                                                                                   : 
                                                                                                                   <>
                                                                                                                        <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno +  ': ' + option.username }
                                                                                                                   </>
                                                                                                              }
                                                                                                         </Option>
                                                                                                    )}
                                                                                               </Select>
                                                                                          </Form.Item>
                                                                                     </Col>

                                                                                     <Col span={9}>
                                                                                          <Form.Item
                                                                                               {...field}
                                                                                               label='Días de Suplencia'
                                                                                               name={[field.name, 'fechaSuplenciaRS']}
                                                                                               fieldKey={[field.fieldKey, 'fechaSuplenciaRS']}
                                                                                               rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                                          >
                                                                                               <RangePicker
                                                                                                    className='input'
                                                                                                    style={{width: '100%'}} 
                                                                                                    format='DD-MM-YYYY'
                                                                                                    disabledDate={disabledDate}
                                                                                                    placeholder={["Fecha inicio","Fecha fin"]}
                                                                                               />
                                                                                          </Form.Item>
                                                                                     </Col>
                                                                                     </Row>
                                                                                </Panel>
                                                                           </Collapse>
                                                                           </Form.Item>
                                                                      </Col>
                                                                 </Row>
                                                            </Col>

                                                            {fields.length > 1 && (
                                                                 <Col span={1}>
                                                                      <Form.Item>
                                                                           <UserDeleteOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                                      </Form.Item>
                                                                 </Col>
                                                            )}
                                                       </Row>
                                                  </Card>))}
                                             </> )}
                                        </Form.List>
                                   </Col>

                                   <Col span={24}>
                                        <Form.List name="responsableAT">
                                             {(fields, { add, remove }) => ( <>
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                  <Col span={23}>
                                                            <Divider orientation='left'> <UserOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/> <Text style={{color:'#0e2c82'}} strong> Responsables de AT </Text></Divider>
                                                       </Col>

                                                       <Col span={1} style={{paddingTop:'1em'}}>
                                                            <Tooltip title='Agregar nuevo responsable'  placement="topRight">
                                                                 <Button   className="iconTer" shape="circle" icon={<UserAddOutlined />} onClick={() => add()}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row>

                                                  <Row justify='space-around' gutter={[8,8]}>
                                                       { fields.length === 0 &&
                                                            <Col span={24}>
                                                                 <Button   type="dashed" style={{borderRadius:'1em', width:'100%', height:'100%'}} onClick={() => add()}>
                                                                      <Row justify='center' style={{padding:'1em'}}>
                                                                           <UserAddOutlined className='iconTabPane'/>
                                                                           <Col span={24} style={{paddingTop:'1em'}}>
                                                                                <Text strong> Haz click para añadir al menos un responsable de AT </Text>
                                                                           </Col>
                                                                      </Row>
                                                                 </Button>
                                                            </Col> 
                                                       }
                                                  </Row>

                                                  {fields.map(field => ( <Card className='cardResponsables' style={{ marginTop:'1em'}}>
                                                       <Row justify='center' key={field.key} gutter={[8,8]} align='middle'>
                                                            <Col span={23}>
                                                                 <Row justify='space-around' key={field.key} gutter={[8,8]}>
                                                                      <Col span={24}>
                                                                           <Form.Item
                                                                                {...field}
                                                                                label={'Nombre y Correo Electrónico'}
                                                                                name={[field.name, 'responsableAT']}
                                                                                fieldKey={[field.fieldKey, 'responsableAT']}
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                           >
                                                                                <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario o correo del responsable AT" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoAT} showSearch onChange={() => onUsuarioSelected(field, 'AT')}>
                                                                                     {this.props.listaUsuariosByIdsServiciosTipoAT.map(option => 
                                                                                          <Option key={option.aMaterno ? option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username : option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ': ' + option.username}>
                                                                                               {option.aMaterno ? 
                                                                                                    <>
                                                                                                         <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username }
                                                                                                    </>
                                                                                                    : 
                                                                                                    <>
                                                                                                         <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno +  ': ' + option.username }
                                                                                                    </>
                                                                                               }
                                                                                          </Option>
                                                                                     )}
                                                                                </Select>
                                                                           </Form.Item>
                                                                      </Col>

                                                                      <Col span={24}>
                                                                      <Form.Item {...field} name={[field.name, 'collapsedAT']}  fieldKey={[field.fieldKey, 'collapsedAT']} >
                                                                           <Collapse bordered={false} expandIcon={({ isActive }) =>  isActive ? <Tooltip title='Suplente activo'> <UserSwitchOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/> </Tooltip> : <Tooltip title='Suplente inactivo'> <UserSwitchOutlined style={{fontSize:'1.5em', color: '#b5b5b5'}}/> </Tooltip> } style={{ borderTopRightRadius:'1em', borderTopLeftRadius:'1em'}} destroyInactivePanel>
                                                                                <Panel header={<Text style={{color:'#666666'}} strong> Suplencia </Text>} key="1">
                                                                                     <Row justify='space-around' gutter={[8,8]}>
                                                                                     <Col span={15}>
                                                                                          <Form.Item
                                                                                               {...field}
                                                                                               label={'Nombre y Correo Electrónico'}
                                                                                               name={[field.name, 'suplenteAT']}
                                                                                               fieldKey={[field.fieldKey, 'suplenteAT']}
                                                                                               rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                                          >
                                                                                               <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario o correo del responsable AT" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoAT} showSearch>
                                                                                                    {this.props.listaUsuariosByIdsServiciosTipoAT.map(option => 
                                                                                                         <Option key={option.aMaterno ? option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username : option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ': ' + option.username}>
                                                                                                              {option.aMaterno ? 
                                                                                                                   <>
                                                                                                                        <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username }
                                                                                                                   </>
                                                                                                                   : 
                                                                                                                   <>
                                                                                                                        <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno +  ': ' + option.username }
                                                                                                                   </>
                                                                                                              }
                                                                                                         </Option>
                                                                                                    )}
                                                                                               </Select>
                                                                                          </Form.Item>
                                                                                     </Col>

                                                                                     <Col span={9}>
                                                                                          <Form.Item
                                                                                               {...field}
                                                                                               label='Días de Suplencia'
                                                                                               name={[field.name, 'suplenciaAT']}
                                                                                               fieldKey={[field.fieldKey, 'suplenciaAT']}
                                                                                               rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                                          >
                                                                                               <RangePicker
                                                                                                    className='input'
                                                                                                    style={{width: '100%'}} 
                                                                                                    format='DD-MM-YYYY'
                                                                                                    disabledDate={disabledDate}
                                                                                                    placeholder={["Fecha inicio","Fecha fin"]}
                                                                                               />
                                                                                          </Form.Item>
                                                                                     </Col>
                                                                                     </Row>
                                                                                </Panel>
                                                                           </Collapse>
                                                                           </Form.Item>
                                                                      </Col>
                                                                 </Row>
                                                            </Col>

                                                            {fields.length > 1 && (
                                                                 <Col span={1}>
                                                                      <Form.Item>
                                                                           <UserDeleteOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                                      </Form.Item>
                                                                 </Col>
                                                            )}
                                                       </Row>
                                                  </Card>))}
                                             </> )}
                                        </Form.List>
                                   </Col> 

                                   <Col span={24}>
                                        <Form.List name="responsablePYRS">
                                             {(fields, { add, remove }) => ( <>
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                       <Col span={23}>
                                                            <Divider orientation='left'> <UserOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/> <Text style={{color:'#0e2c82'}} strong> Responsables PYRS </Text></Divider>
                                                       </Col>

                                                       <Col span={1} style={{paddingTop:'1em'}}>
                                                            <Tooltip title='Agregar nuevo responsable'  placement="topRight">
                                                                 <Button   className="iconTer" shape="circle" icon={<UserAddOutlined />} onClick={() => add()}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row>

                                                  <Row justify='space-around' gutter={[8,8]}>

                                                       { fields.length === 0 &&
                                                            <Col span={24}>
                                                                 <Button   type="dashed" style={{borderRadius:'1em', width:'100%', height:'100%'}} onClick={() => add()}>
                                                                      <Row justify='center' style={{padding:'1em'}}>
                                                                           <UserAddOutlined className='iconTabPane'/>
                                                                           <Col span={24} style={{paddingTop:'1em'}}>
                                                                                <Text strong> Haz click para añadir al menos un responsable PYRS </Text>
                                                                           </Col>
                                                                      </Row>
                                                                 </Button>
                                                            </Col> 
                                                       }
                                                  </Row>

                                                  {fields.map(field => ( <Card className='cardResponsables' style={{ marginTop:'1em'}}>
                                                       <Row justify='center' key={field.key} gutter={[8,8]} align='middle'>
                                                            <Col span={23}>
                                                                 <Row justify='space-around' key={field.key} gutter={[8,8]}>
                                                                      <Col span={24}>
                                                                           <Form.Item
                                                                                {...field}
                                                                                label={'Nombre y Correo Electrónico'}
                                                                                name={[field.name, 'responsablePYRS']}
                                                                                fieldKey={[field.fieldKey, 'responsablePYRS']}
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                           >
                                                                                <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario o correo del responsable PYRS" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoPYRS} showSearch onChange={() => onUsuarioSelected(field, 'PYRS')}>
                                                                                     {this.props.listaUsuariosByIdsServiciosTipoPYRS.map(option => 
                                                                                          <Option key={option.aMaterno ? option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username : option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ': ' + option.username}>
                                                                                               {option.aMaterno ? 
                                                                                                    <>
                                                                                                         <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username }
                                                                                                    </>
                                                                                                    : 
                                                                                                    <>
                                                                                                         <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno +  ': ' + option.username }
                                                                                                    </>
                                                                                               }
                                                                                          </Option>
                                                                                     )}
                                                                                </Select>
                                                                           </Form.Item>
                                                                      </Col>

                                                                      <Col span={24}>
                                                                      <Form.Item {...field} name={[field.name, 'collapsedPYRS']}  fieldKey={[field.fieldKey, 'collapsedPYRS']} >
                                                                           <Collapse bordered={false} expandIcon={({ isActive }) =>  isActive ? <Tooltip title='Suplente activo'> <UserSwitchOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/> </Tooltip> : <Tooltip title='Suplente inactivo'> <UserSwitchOutlined style={{fontSize:'1.5em', color: '#b5b5b5'}}/> </Tooltip> } style={{ borderTopRightRadius:'1em', borderTopLeftRadius:'1em'}} destroyInactivePanel>
                                                                                <Panel header={<Text style={{color:'#666666'}} strong> Suplencia </Text>} key="1">
                                                                                     <Row justify='space-around' gutter={[8,8]}>
                                                                                     <Col span={15}>
                                                                                          <Form.Item
                                                                                               {...field}
                                                                                               label={'Nombre y Correo Electrónico'}
                                                                                               name={[field.name, 'suplentePYRS']}
                                                                                               fieldKey={[field.fieldKey, 'suplentePYRS']}
                                                                                               rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                                          >
                                                                                               <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario o correo del responsable PYRS" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoPYRS} showSearch>
                                                                                                    {this.props.listaUsuariosByIdsServiciosTipoPYRS.map(option => 
                                                                                                         <Option key={option.aMaterno ? option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username : option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno + ': ' + option.username}>
                                                                                                              {option.aMaterno ? 
                                                                                                                   <>
                                                                                                                        <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno + ': ' + option.username }
                                                                                                                   </>
                                                                                                                   : 
                                                                                                                   <>
                                                                                                                        <Text style={{color:'white'}}> {option.idUsuario + '-'} </Text> {option.nombre + ' ' + option.aPaterno +  ': ' + option.username }
                                                                                                                   </>
                                                                                                              }
                                                                                                         </Option>
                                                                                                    )}
                                                                                               </Select>
                                                                                          </Form.Item>
                                                                                     </Col>

                                                                                     <Col span={9}>
                                                                                          <Form.Item
                                                                                               {...field}
                                                                                               label='Días de Suplencia'
                                                                                               name={[field.name, 'suplenciaPYRS']}
                                                                                               fieldKey={[field.fieldKey, 'suplenciaPYRS']}
                                                                                               rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                                          >
                                                                                               <RangePicker
                                                                                                    className='input'
                                                                                                    style={{width: '100%'}} 
                                                                                                    format='DD-MM-YYYY'
                                                                                                    disabledDate={disabledDate}
                                                                                                    placeholder={["Fecha inicio","Fecha fin"]}
                                                                                               />
                                                                                          </Form.Item>
                                                                                     </Col>
                                                                                     </Row>
                                                                                </Panel>
                                                                           </Collapse>
                                                                      </Form.Item>
                                                                      </Col>
                                                                 </Row>
                                                            </Col>

                                                            {fields.length > 1 && (
                                                                 <Col span={1}>
                                                                      <Form.Item>
                                                                           <UserDeleteOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                                      </Form.Item>
                                                                 </Col>
                                                            )}
                                                       </Row>
                                                  </Card>))}
                                             </> )}
                                        </Form.List>
                                   </Col>

                                   <Col span={8}>
                                        <Button   style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Responsable</Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevoResponsable: state.ConfiguracionYSeguridadReducer.fetchingNuevoResponsable,
          showModalNuevoResponsable: state.ConfiguracionYSeguridadReducer.showModalNuevoResponsable,
          page: state.ConfiguracionYSeguridadReducer.page,
          busqueda: state.ConfiguracionYSeguridadReducer.busqueda,
          orden: state.ConfiguracionYSeguridadReducer.orden,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          
          listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
          fetchingListaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaPartidasByIdsServicios,

          listaUsuariosByIdsServicios: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServicios,
          fetchingListaUsuariosByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServicios,

          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

          listaActivosRangosValidacion: state.ConfiguracionYSeguridadReducer.listaActivosRangosValidacion,
          fetchingListaActivosRangosValidacion: state.ConfiguracionYSeguridadReducer.fetchingListaActivosRangosValidacion,

          listaUsuariosByIdsServiciosTipoRS: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServiciosTipoRS,
          fetchingListaUsuariosByIdsServiciosTipoRS: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServiciosTipoRS,

          listaUsuariosByIdsServiciosTipoAT: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServiciosTipoAT,
          fetchingListaUsuariosByIdsServiciosTipoAT: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServiciosTipoAT,

          listaUsuariosByIdsServiciosTipoPYRS: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServiciosTipoPYRS,
          fetchingListaUsuariosByIdsServiciosTipoPYRS: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServiciosTipoPYRS,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModalNuevo: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_RESPONSABLE', showModalNuevoResponsable: false })
          },
          onNuevoResponsable: ( formulario, page, busqueda, orden ) => {
               dispatch({ type: "NUEVO_RESPONSABLE_REQUEST" , formulario, page, busqueda, orden });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onGetPartidasByIdServicio: (idServicio) => {
               dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
          },
          onGetPartidasByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onCleanPartidasByIdsServicios: () => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS', listaPartidasByIdsServicios: [] });
          },
          onGetUsuariosByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onCleanUsuariosByIdsServicios: () => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_SUCCESS', listaUsuariosByIdsServicios: []  });
          },

          onGetUsuariosByIdsServiciosTipoRS: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST', idsServicios });
          },
          onCleanUsuariosByIdsServiciosTipoRS: () => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_SUCCESS', listaUsuariosByIdsServiciosTipoRS: []  });
          },
          onGetUsuariosByIdsServiciosTipoAT: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST', idsServicios });
          },
          onCleanUsuariosByIdsServiciosTipoAT: () => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_SUCCESS', listaUsuariosByIdsServiciosTipoAT: []  });
          },
          onGetUsuariosByIdsServiciosTipoPYRS: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST', idsServicios });
          },
          onCleanUsuariosByIdsServiciosTipoPYRS: () => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_SUCCESS', listaUsuariosByIdsServiciosTipoPYRS: []  });
          },
          
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoResponsable);