import React, {Component} from 'react';
import { Form,  Modal, Col, Spin,  Row , Button, Switch , InputNumber}  from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarSettings extends Component {
    formRef = React.createRef();
    render() {
        const {
            fetchingEditarSettings,
            settingSeleccionado,
            showModalEditarSettings,
            onShowModalEditarSetting,
            onEditarSetting,
            listaSettings,
        } = this.props;
         
        console.log('settingSeleccionado modal');
        console.log(settingSeleccionado);
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarSetting(formulario, settingSeleccionado, listaSettings);
            this.formRef.current.resetFields();
        };
        

        const handleCancel = () => {
            onShowModalEditarSetting()
        }
        return (
            <Modal
            visible={showModalEditarSettings}
            title='Editar Configuración'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingEditarSettings}>
                    {settingSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={settingSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Vigencia del link para cambio de contraseña (Hrs)"
                                    name="vigenciaLink"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber maxLength={11} style={{width: "100%"}} placeholder="Ingrese la Vigencia del link en horas" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Intentos para bloqueo de Usuario"
                                    name="numeroIntentos"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber maxLength={11} style={{width: "100%"}} placeholder="Numero de Intentos" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Días de vigencia de contraseña"
                                    name="vigenciaPass"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber maxLength={11} style={{width: "100%"}} placeholder="Vigencia de contraseña en días" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Vigencia de token de sesión (Hrs, máximo 480hrs)"
                                    name="recordarToken"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber max={480} style={{width: "100%"}} placeholder="Vigencia de token" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="¿Considerar la vigencia de token?"
                                    name="considerRecordarToken"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Switch defaultChecked={settingSeleccionado.considerVigenciaPass} checkedChildren="Si" unCheckedChildren="No" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Contraseñas anteriores para validación"
                                    name="numeroPass"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber maxLength={11} style={{width: "100%"}} placeholder="Numero Password" />
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" htmlType="submit">Editar configuración</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarSettings: state.ConfiguracionYSeguridadReducer.fetchingEditarSettings,
        showModalEditarSettings: state.ConfiguracionYSeguridadReducer.showModalEditarSettings,
        settingSeleccionado: state.ConfiguracionYSeguridadReducer.settingSeleccionado,
        listaSettings: state.ConfiguracionYSeguridadReducer.listaSettings,
        fetchingListaSettings: state.ConfiguracionYSeguridadReducer.fetchingListaSettings,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarSetting: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_SETTINGS', showModalEditarSettings: false, settingSeleccionado: null})
        },
        onEditarSetting: ( formulario, settingSeleccionado, listaSettings) => {
            dispatch({ type: "EDITAR_SETTINGS_REQUEST" , formulario: formulario, settingSeleccionado: settingSeleccionado, listaSettings: listaSettings });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarSettings)