import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoRegistroReporte extends React.Component {
    formRef = React.createRef();
    render() {
        const {showModalNuevoRegistroReporte, fetchingNuevoRegistroReporte, limpiarFormulario, busqueda,
            onShowModalRegistroReporte, onCrearRegistroReporte, data
        } = this.props;
        
        if (limpiarFormulario && limpiarFormulario === 'crearRegistroReporte') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onFinish = (formulario) => {
            onCrearRegistroReporte(formulario, busqueda, data, permisosUsuario.especialEvaluaciónDeLaGestión);        
        }

        const handleCancel = () => {
            onShowModalRegistroReporte();
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal
                    visible={showModalNuevoRegistroReporte}
                    title='Nuevo Registro Predeterminado'
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    <Spin spinning={fetchingNuevoRegistroReporte}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row type='flex' justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label='Título del documento'
                                        name='titulo'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={200} placeholder='Ingresa el título del documento' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Nombre'
                                        name='nombre'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={100} placeholder='Ingresa el nombre' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Form.Item
                                        label='Descripción'
                                        name='descripcion'
                                        >
                                        <TextArea autoSize maxLength={450} placeholder='Ingresa la descripción' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Registro Predeterminado</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevoRegistroReporte: state.EvaluacionDeLaGestionReducer.showModalNuevoRegistroReporte,
        fetchingNuevoRegistroReporte: state.EvaluacionDeLaGestionReducer.fetchingNuevoRegistroReporte,
        busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        data: state.EvaluacionDeLaGestionReducer.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalRegistroReporte: () => {
            dispatch({ type: "MODAL_NUEVO_REGISTRO_REPORTE", showModalNuevoRegistroReporte: false});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onCrearRegistroReporte: (formulario, busqueda, data, especial) => {
            dispatch({ type: 'NUEVO_REGISTRO_REPORTE_REQUEST', formulario, busqueda, data, especial})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevoRegistroReporte);