import React, { Component } from 'react';
import { Drawer, Empty, Spin, Button, Row, Col, List, Avatar, Badge, Typography, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { connect } from "react-redux";

import SolicitudDeServicios from '../assets/iconossidebar/solicitudes_de_servicio_noti.svg';
import ServiciosPlanificados from '../assets/iconossidebar/servicio_planificado_noti.svg';
import MecanismosDePago from '../assets/iconossidebar/mecanismos_de_pago_noti.svg';

const {Text} = Typography
class Notificaciones extends Component {
	componentDidMount(){ this.props.onGetNotificaciones(1); this.props.onGetNotificacionesNoLeidas(); }
	state = { page: 1 }
	render() {
        	const { showNotificaciones, onShowNotificaciones, fetchingListaNotificaciones, listaNotificaciones } = this.props;

		const onCloseNotificaciones = () => {
			onShowNotificaciones();
			this.props.onGetNotificaciones(1);
		}
		const cargasMas = () => {
			this.props.onGetNotificaciones(this.state.page + 1);
			this.setState({ page: this.state.page + 1 })
		};

		return (
			<Drawer title="Notificaciones" id="scrollableDiv" width={640} placement="right" onClose={onCloseNotificaciones} visible={showNotificaciones}
				footer={
					<Row justify='space-around'>
						<Col>
							<Button disabled={this.props.cantidadNotificaciones < 10} className="iconSecundary" shape='circle' icon={<PlusOutlined /> } loading={this.props.fetchingListaNotificaciones} onClick={cargasMas}/>
						</Col>
					</Row>
				}
			>
				<Spin spinning={fetchingListaNotificaciones}>
					<Row justify='space-around'>
						<Col span={24}>
							{this.props.cantidadNotificaciones > 0 ? 
								<List
									loading={fetchingListaNotificaciones}
									className='notificacionDiv'
									dataSource={listaNotificaciones}
									renderItem={(item, contador) => (
										<List.Item key={item.idNotificacion} >
											<List.Item.Meta
												avatar={<Tooltip placement="left" title={'Módulo: ' + item.modulo}><Avatar shape="square" src={item.modulo === 'Servicios planificados' ? ServiciosPlanificados : item.modulo === 'Mecanismos de pago' ? MecanismosDePago : SolicitudDeServicios} /></Tooltip>}
												title={item.modulo === 'Servicios planificados' ? item.descripcion.split('_')[0] : item.descripcion + ': ' + item.titulo}
												description={item.descripcionTicket}
											/>
											<div>{!item.notificacionVista ?(<Badge status="processing" />) : false } <Text disabled> {item.duracion.value + ' ' + item.duracion.unit} </Text> </div>
										</List.Item>
									)}
								/>
							:
								<Row justify='center' align='middle' style={{paddingTop:'14em'}}>
									<Empty description='No hay notificaciones que mostrar'/>
								</Row>
							}
						</Col>
						{/* <Col>
							<Button disabled={this.props.cantidadNotificaciones <= 9} className="iconSecundary" shape='circle' icon={<PlusOutlined /> } loading={this.props.fetchingListaNotificaciones} onClick={cargasMas}/>
						</Col> */}
					</Row>
				</Spin>
            </Drawer>
		);
	}
}

const mapStateToProps = state => {
	return { 
        	showNotificaciones: state.ConfiguracionYSeguridadReducer.showNotificaciones,
		fetchingListaNotificaciones: state.ConfiguracionYSeguridadReducer.fetchingListaNotificaciones,

		listaNotificaciones: state.ConfiguracionYSeguridadReducer.listaNotificaciones,
		cantidadNotificaciones: state.ConfiguracionYSeguridadReducer.cantidadNotificaciones,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		onShowNotificaciones: () => {
			dispatch({ type: "SHOW_NOTIFICACIONES" , showNotificaciones: false });
		},
		onGetNotificaciones: (page) => {
			dispatch({ type: "LISTA_NOTIFICACIONES_REQUEST", page });
		},
		onGetNotificacionesNoLeidas: () => {
			dispatch({type: "LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST"});
		}
	};
};
export default connect(mapStateToProps,mapDispatchToProps) (Notificaciones);
