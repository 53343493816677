import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Spin, Input } from 'antd';
import { connect } from "react-redux";
import {  } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalEditarVariable extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          page: 1,
          variableSeleccionada: '',

     }


     render() {
          const { 
               limpiarFormulario, 
               onCloseModalEditarVariable,
               showModalEditarVariableMedicion,
               fetchingEditarVariableMedicion,
               listaUnidadesDeMedicionActivos,
               fetchingListaUnidadesDeMedicionActivos,
               variableMedicionSeleccionado,
               onEditarVariable
          } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);
               onEditarVariable(formulario, variableMedicionSeleccionado, this.props.page, this.props.busqueda, listaUnidadesDeMedicionActivos);
          };

          if (limpiarFormulario && limpiarFormulario === 'editarVariable') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onCloseModalEditarVariable();
               this.setState({
                    variableSeleccionada: ''
               });
               this.formRef.current.resetFields();
          };

          return (

               <Modal
                    visible={showModalEditarVariableMedicion}
                    title='Editar Variable de Medición'
                    onCancel={handleCancel}
                    footer={false}
                    width={'40%'}
               >
                    {variableMedicionSeleccionado ? ( 
                         <Spin spinning={fetchingEditarVariableMedicion}>
                              <Form {...layout}
                                   name='formulario'
                                   initialValues={variableMedicionSeleccionado}
                                   onFinish={onFinish}
                                   
                                   ref={this.formRef}
                              >
                                   <Row type='flex' justify='space-between'>
                                        <Col span={24}>
                                             <Form.Item
                                                  name='nombre'
                                                  label='Nombre de Variable de Medición'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input
                                                       placeholder='Nombre de variable'
                                                       pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:'\s\-,]+$"
                                                       title="Nombre sin carácteres especiales." 
                                                       maxLength={30} 
                                                  />
                                             </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                             <Form.Item
                                                  name='unidadMedida'
                                                  label='Unidad de Medida'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Select
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione una unidad de medida"
                                                       loading={fetchingListaUnidadesDeMedicionActivos}
                                                  >
                                                       {listaUnidadesDeMedicionActivos.map(option => <Option key={option.unidadMedida}>{ option.unidadMedida}</Option>)}
                                                  </Select>    
                                             </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                             <Form.Item
                                                  name='operacion'
                                                  label='Operación'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Select
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione una operación"
                                                  >
                                                       <Option value='suma'>Suma</Option>
                                                       <Option value='resta'>Resta</Option>
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                             <Form.Item
                                                  name='descripcion'
                                                  label='Descripción'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input
                                                       placeholder='Descripción de variable'
                                                       pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:'\s\-,]+$"
                                                       title="Descripción sin carácteres especiales." 
                                                       maxLength={250} 
                                                  />
                                                  
                                             </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                             <Button className="btnPrueba" htmlType="submit" style={{ width: '100%' }}>
                                                  Guardar
                                             </Button>
                                        </Col>

                                   </Row>
                              </Form>
                         </Spin>
                    ): false }
               </Modal>
          );             

     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalEditarVariableMedicion: state.ServiciosPublicosReducer.showModalEditarVariableMedicion,
          fetchingEditarVariableMedicion: state.ServiciosPublicosReducer.fetchingEditarVariableMedicion,
          listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
          fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
          variableMedicionSeleccionado: state.ServiciosPublicosReducer.variableMedicionSeleccionado,
          page: state.ServiciosPublicosReducer.page,
          busqueda: state.ServiciosPublicosReducer.busqueda,

     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onCloseModalEditarVariable: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_VARIABLE_MEDICION', showModalEditarVariableMedicion: false });
          },
          onEditarVariable: (formulario, variableMedicionSeleccionado, page, busqueda, listaUnidadesDeMedicionActivos)  => {
               dispatch({ type: 'EDITAR_VARIABLES_MEDICION_REQUEST', formulario, variableMedicionSeleccionado, page, busqueda, listaUnidadesDeMedicionActivos });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarVariable);