import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Row, Col, Table, Input, Button, Switch, Modal, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEditarVariable from './ModalEditarVariable';
import ModalNuevoVariable from './ModalNuevoVariable';

const confirm = Modal.confirm;
const { Search } = Input;

const CatalogoVariables = () => {
    //constants
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('ASC');
    const [field, setField] = useState('fechaAlta');
    const [param, setParam] = useState('');
    const [busqueda, setBusqueda] = useState({order: 'ASC', field: 'fechaAlta'});
    const dispatch = useDispatch();
    const listaVariables = useSelector((state) => state.ServiciosVariablesReducer.listaVariables);
    const fetchingListaVariables = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaVariables);
    const listaVariablesCantidad = useSelector((state) => state.ServiciosVariablesReducer.listaVariablesCantidad);
    const scroll = {"x": 700};

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if (permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    };

    console.log("listaVariables",listaVariables)

    useEffect(() => {
      dispatch({ type: "OBTENER_VARIABLES_REQUEST", page, busqueda});
    }, [page, busqueda]); 
    
    //functions
    const onBuscarVariable = param => {
        const search = { param: param, campo: field, orden: order};
        setBusqueda(search)
        setPage(1)
        dispatch({ type: "OBTENER_VARIABLES_REQUEST", page:1, search});
    };

    const editarVariable = key => {
        
        for(let i=0; i<key.subvariablesByVariable.length; i++){
            key.subvariablesByVariable[i].unidadMedida = key.subvariablesByVariable[i].unidadMedidaByIdUnidadMedida.unidadMedida;
        }
        console.log('key', key);
        dispatch({ type: "SHOW_MODAL_EDITAR_VARIABLE", showModalEditarVariable: true, variableSeleccionada:key, page});
    };

    const desactivarVariable = key => {
        console.log(key.idVariable);
      
        confirm({
            title: '¿Estás seguro que deseas cambiar el estado de la variable "' + key.variable + '"?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch({ type: "ESTADO_VARIABLES_REQUEST", idVariable:key.idVariable, page});
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleTableChange = (pagination, filtro, orderParam) => {
        setPage(pagination.current)
        if(orderParam){
            let campo = 'fechaAlta';
            let orderValue = 'ASC';

            if(orderParam.order === 'descend'){
                orderValue = 'DESC';
            }
            if (orderParam.columnKey === 'fechaAlta') {
                campo = 'fechaAlta';
            } 

            const search = { param: param, field: campo, order: order};
            dispatch({ type: "OBTENER_VARIABLES_REQUEST", page:pagination.current, busqueda:search});
            setOrder(orderValue)
            setField(campo)
            setBusqueda(search)
        } else {
            dispatch({ type: "OBTENER_VARIABLES_REQUEST", page:pagination.current, busqueda:busqueda})
        }
    };

    const nuevaVariable = () => {
        dispatch({ type: "SHOW_MODAL_CREAR_VARIABLE", showModalNuevaVariable: true});
    };
    
    //columns
    const columnSubvariablesByVariable = [
        { title: 'Nombre', dataIndex: 'nombre',  key: 'nombre'},
        { title: 'Unidad de Medida', dataIndex: 'unidadMedidaByIdUnidadMedida',  key: 'unidadMedidaByIdUnidadMedida',
            render: (text, record) => (
                record.unidadMedidaByIdUnidadMedida.unidadMedida && <span>{record.unidadMedidaByIdUnidadMedida.unidadMedida}</span>
            ),
        },
    ];

    const columns = [{
        title: 'Variable',
        dataIndex: 'variable',
        key: 'variable',
        sorter: true,
        align: 'center',
        width:'15%'
    },
    { title: 'Subvariables', key: 'subvariablesByVariable',  width:'65%',  align: 'center',
            render: (text, record) => (<>
                    <Table style={{padding:'1em'}}
                        size="small" 
                        rowKey="idUsuario" 
                        columns={columnSubvariablesByVariable}
                        dataSource={record.subvariablesByVariable}
                        locale={{ emptyText: 'Sin datos' }}
                        pagination={ false }
                    /> 
            </>),
     },
    {
        title: 'Tiempo',
        dataIndex: 'tiempoColumn',
        key: 'tiempoColumn',
        align: 'center',
        width:'10%'
    },{
        title: 'Fecha de Alta',
        dataIndex: 'fechaAlta',
        key: 'fechaAlta',
        sorter: true,
        width:'35%'
    },{
        title: 'Activo',
        dataIndex: 'activo',
        key: 'activo',
        width: '5%',
        render: (text, record) => (
            <Switch checked={text} disabled={!permisosUsuario.variableActivoInactivo} onChange = {() => desactivarVariable(record)} />
        ),
    },{
        title: 'Editar',
        key: 'editar',
        width:'15%',
        align: 'center',
        render: (record) => (
           <a className='editarIcono' disabled={!permisosUsuario.editarVariable} onClick={ () => editarVariable(record) }> < EditOutlined />
           </a>
        ),          
    }];

    return(
        <>
          <Row type='flex' justify='space-between' gutter={[8,8]}>
                <Col span={22}>
                    <Search className='buscarModulos' placeholder="Buscar variable" onSearch={value => onBuscarVariable(value)} style={{ width: '100%' }} />
                </Col>
                {/* <Col span={8}>
                    <Button onClick={nuevaVariable} disabled={!permisosUsuario.crearVariable} className='nuevoUsuario'>Nueva Variable</Button>
                </Col> */}
                <Col span={1}>
                    <Tooltip title='Nueva Variable' placement="topRight">
                        <Button className="iconTer" shape="circle" onClick={nuevaVariable} disabled={!permisosUsuario.crearVariable} icon={<PlusOutlined />}/>
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <Table
                        size="small"
                        rowKey="idVariable"
                        columns={columns}
                        scroll={scroll}
                        dataSource={listaVariables}
                        loading={fetchingListaVariables}
                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                        onChange={handleTableChange}
                        pagination={{ total: listaVariablesCantidad, simple: true, current: page }}
                    />
                </Col>
            </Row>
            <ModalEditarVariable/>
            <ModalNuevoVariable/>  
        </>
    )
}



export default CatalogoVariables;