import React, { Component, message } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Select, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { SaveOutlined } from '@ant-design/icons';

const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 }, };
const { Option } = Select;

class EdtiarTipoMoneda extends Component {
     formRef = React.createRef();

     render() {       
          const { fetchingEditarTipoMoneda, showModalEditarTipoMoneda, onEditar, onCloseModal, tipoMonedaSeleccionado, limpiarFormulario, } = this.props;
          console.log(tipoMonedaSeleccionado);
          
          const onFinish = formulario => {
               console.log('Success:', formulario);
               onEditar(formulario, tipoMonedaSeleccionado, this.props.page, this.props.busqueda);
          };

          if (limpiarFormulario && limpiarFormulario === 'editarTipoMoneda') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          

          const handleCancel = () => { onCloseModal(); }

          return (
               <Modal
               visible={showModalEditarTipoMoneda}
               title='Editar Tipo de Moneda'
               onCancel={handleCancel}
               footer={false}
               >
                    <Spin spinning={fetchingEditarTipoMoneda}>
                         {tipoMonedaSeleccionado ? (
                         <Form {...layout}
                         name="formulario"
                         initialValues={tipoMonedaSeleccionado}
                         onFinish={onFinish}
                         ref={this.formRef}
                         >
                              <Form.Item
                                   label="Moneda"
                                   name="nombre"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Input maxLength={30} placeholder="Ingrese nombre del tipo de moneda" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                              </Form.Item>

                              <Form.Item
                                   label="Nomenclatura"
                                   name="nomenclatura"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Input maxLength={3} placeholder="Ingrese la nomenclatura del tipo de moneda" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                              </Form.Item>
                         
                              {/* <Row justify='center'>
                                   <Col span={11}>
                                        <Button className="btnFiltrar" htmlType="submit">Editar Tipo de Moneda</Button>
                                   </Col>
                              </Row> */}

                              <Row justify='end' gutter={[8,8]}>
                                   <Col span={1} style={{paddingRight:'2.8em', paddingTop:'2em'}}>
                                        <Tooltip title='Editar Tipo de Moneda' placement="bottom">
                                             <Button className="iconTer" shape="circle" htmlType="submit" icon={<SaveOutlined />}/>
                                        </Tooltip> 
                                   </Col>
                              </Row>
                         </Form>
                         ):false}
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingEditarTipoMoneda: state.CatalogosGeneralesReducer.fetchingEditarTipoMoneda,
          showModalEditarTipoMoneda: state.CatalogosGeneralesReducer.showModalEditarTipoMoneda,
          tipoMonedaSeleccionado: state.CatalogosGeneralesReducer.tipoMonedaSeleccionado,
          
          page: state.CatalogosGeneralesReducer.page,
          busqueda: state.CatalogosGeneralesReducer.busqueda,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_TIPO_MONEDA', showModalEditarTipoMoneda: false})
          },
          onEditar: ( formulario, tipoMonedaSeleccionado, page, busqueda ) => {
               dispatch({ type: "EDITAR_TIPO_MONEDA_REQUEST" , formulario, tipoMonedaSeleccionado, page, busqueda });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EdtiarTipoMoneda);