import React, { Component } from 'react';
import { Row, Col, Table, Input, Button, Card, Tooltip } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalCatalogoMovimientos from './ModalCatalogoMovimientos';
import ContenedorNuevoFolio from './ContenedorNuevoFolio';

const { Search } = Input;
class MovimientoAlmacenContainer extends Component {
    state = {
        page: 1,
        busqueda: {order: 'DESC', field: 'fechaAlta', param: ''},
        order: 'DESC',
        field: 'fechaAlta',
    }
    componentDidMount(){
        this.props.onGetMovimientosGeneral(1, this.state.busqueda); this.props.onGetTiposMonedas();
    }  
    
    render() {
        const {
            onGetMovimientosGeneral,
            onModalOpcionesMovimientos,
            onGetConsultaFolio,
            onModalNuevoFolio,
            listaMovimientosGeneralBusqueda,
            fetchingListaMovimientosGeneralBusqueda,
            listaMovimientosGeneralBusquedaCantidad 
        } = this.props;
           
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onBuscarMovimiento = param => {
            const busqueda = { param: param, field: this.state.field, order: this.state.order}
            this.setState({ busqueda: busqueda});
            onGetMovimientosGeneral(this.state.page, busqueda)
        }

        const handleTableChange = (pagination, filtro,  orden) => {
            console.log('orden', orden)
            this.setState({ page: pagination.current });
                if(orden) {
                    let campo = 'fechaAlta';
                    let order = 'DESC';
                    if (orden.order === 'ascend') {
                        order = 'ASC';
                    }
                    if (orden.columnKey === 'folio') {
                        campo = 'idMovimientoAlmacen';
                    } else if (orden.columnKey === 'estadoFolio') {
                        campo = 'estadoFolio';
                    } else if(orden.columnKey === 'nombreTipoFolio') {
                        campo = 'nombreTipoFolio';
                    }  else if(orden.columnKey === 'fechaAlta') {
                        campo = 'fechaAlta';
                    }
                    if(!orden.order){
                        campo = 'fechaAlta';
                        order = 'DESC';
                    }
    
                    const busqueda = { param: this.state.busqueda.param, field: campo, order: order}
                    onGetMovimientosGeneral(pagination.current, busqueda)
                    this.setState({
                        order: order,
                        field: campo,
                        busqueda: busqueda,
                    })
                } else {
                    console.log('this.state.busqueda');
                    console.log(this.state.busqueda);
                    onGetMovimientosGeneral(pagination.current, this.state.busqueda)
                }
        };


        const showOpciones = (key) => {
            console.log('key', key);
            onModalOpcionesMovimientos(key, this.state.page, this.state.busqueda);
            onGetConsultaFolio(key.folio, 1, this.state.busqueda);
        };

        const onNuevoFolio = () => {
            onModalNuevoFolio(this.state.page, this.state.busqueda);
        }

        const columns = [{
            title: 'Numero de Folio',
            dataIndex: 'folio',
            key: 'folio',
            sorter: true
        },{
            title: 'Estado de Folio',
            dataIndex: 'estadoFolio',
            key: 'estadoFolio',
            sorter: true
        },{
            title: 'Tipo de Folio',
            dataIndex: 'nombreTipoFolio',
            key: 'nombreTipoFolio',
            sorter: true
        }, {
            title: 'Fecha de Alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            sorter: true
        },{
            title: 'Opciones',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' onClick={ () => showOpciones(record) }> < ExclamationCircleOutlined />
               </a>
            ),          
        },];


        return (
            <Card className="cardCatalogos" title={<div className="headerLista">Registro de Movimientos de Almacén</div>}>
                <Row type='flex' justify='space-between'>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Buscar folio /Estado folio /Tipo folio" onSearch={value => onBuscarMovimiento(value)} style={{ width: '100%' }} />
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevoFolio} disabled={!permisosUsuario.crearFolioDeMovimientoDeAlmacén} className='nuevoUsuario'>Nuevo Folio</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Folio' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoFolio} disabled={!permisosUsuario.crearFolioDeMovimientoDeAlmacén} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="folio"
                            columns={columns}
                            dataSource={listaMovimientosGeneralBusqueda}
                            loading={fetchingListaMovimientosGeneralBusqueda}
                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaMovimientosGeneralBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalCatalogoMovimientos/> 
                <ContenedorNuevoFolio/> 
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaMovimientosGeneralBusqueda: state.AlmacenesReducer.fetchingListaMovimientosGeneralBusqueda,
        listaMovimientosGeneralBusqueda: state.AlmacenesReducer.listaMovimientosGeneralBusqueda,
        listaMovimientosGeneralBusquedaCantidad: state.AlmacenesReducer.listaMovimientosGeneralBusquedaCantidad, 
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetMovimientosGeneral: (page, busqueda) => {
            dispatch({ type: "LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST", page, busqueda})
        }, 
        onModalOpcionesMovimientos: (movimientoSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN", showModalOpcionesMovimientosAlmacen: true, movimientoSeleccionado: [movimientoSeleccionado], page, busqueda });
        },
        onGetConsultaFolio: (folio, page, busqueda) => {
            dispatch({ type: "LISTA_DETALLE_CONSULTA_REQUEST", folio, page, busqueda});
        },
        onModalNuevoFolio: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_FOLIO", showModalNuevoFolio: true, page, busqueda});
        },
        onGetTiposMonedas: () => {
            dispatch({ type: 'LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST' });
        },
        
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(MovimientoAlmacenContainer) ;