import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, DatePicker, Select, Tooltip } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import { connect } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarUnidadDeMedicion extends Component {
    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetCategoriasdeMedicion();
    }

    render() {

        const { fetchingEditarUnidadDeMedicion, showModalEditarUnidadDeMedicion, onEditarUnidadDeMedicion, onShowModalEditarUnidadDeMedicion,
            UnidadDeMedicionSeleccionado, page, limpiarFormulario, fetchingListaCategoriasDeMedicionActivos, listaCategoriasDeMedicionActivos } = this.props;

        console.log('UnidadDeMedicionSeleccionado en modal');
        console.log(UnidadDeMedicionSeleccionado);

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarUnidadDeMedicion(formulario, UnidadDeMedicionSeleccionado, page, listaCategoriasDeMedicionActivos);

        };
        if (limpiarFormulario && limpiarFormulario === 'editarUnidadMedicion') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarUnidadDeMedicion();
        }
        
        return (
            <Modal
                visible={showModalEditarUnidadDeMedicion}
                title='Editar Unidad de Medición'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarUnidadDeMedicion}>
                    {UnidadDeMedicionSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={UnidadDeMedicionSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Unidad de medición"
                                name="unidadMedida"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input
                                    pattern="^[ñíóáéú a-zA-Z ]+$"
                                    title="Unidad de medición debe tener letras"
                                    maxLength={80}
                                    placeholder='Unidad Medición' />
                            </Form.Item>

                            <Form.Item
                                label="Abreviación"
                                name="abreviacion"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input
                                maxLength={80}
                                placeholder='Abreviación' />
                            </Form.Item>
                            <Form.Item
                                label="Categoría de medida"
                                name="idCategoriaUnidadMedida"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona la categoría de medida"
                                    loading={fetchingListaCategoriasDeMedicionActivos}
                                >
                                    {listaCategoriasDeMedicionActivos.map(option => <Option key={option.idCategoriaUnidadMedida}>{option.categoriaUnidadMedida}</Option>)}
                                </Select>
                            </Form.Item>
                            <Row justify='space-around'>
                                <Col span={12}>
                                    <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Editar Unidad de Medición</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarUnidadDeMedicion: state.AlmacenesReducer.fetchingEditarUnidadDeMedicion,
        showModalEditarUnidadDeMedicion: state.AlmacenesReducer.showModalEditarUnidadDeMedicion,
        UnidadDeMedicionSeleccionado: state.AlmacenesReducer.UnidadDeMedicionSeleccionado,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaCategoriasDeMedicionActivos: state.AlmacenesReducer.listaCategoriasDeMedicionActivos,
        fetchingListaCategoriasDeMedicionActivos: state.AlmacenesReducer.fetchingListaCategoriasDeMedicionActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarUnidadDeMedicion: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_UNIDAD_MEDICION', showModalEditarUnidadDeMedicion: false, UnidadDeMedicionSeleccionado: null })
        },
        onEditarUnidadDeMedicion: (formulario, UnidadDeMedicionSeleccionado, page, listaCategoriasDeMedicionActivos) => {
            dispatch({ type: 'EDITAR_UNIDAD_MEDICION_REQUEST', formulario: formulario, UnidadDeMedicionSeleccionado: UnidadDeMedicionSeleccionado, page: page, listaCategoriasDeMedicionActivos: listaCategoriasDeMedicionActivos })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onGetCategoriasdeMedicion: () => {
            dispatch({ type: "LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST" });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarUnidadDeMedicion);