import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ArchivoEditarTiempoDeGarantia extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() { 
        const {onEditarNivelSeveridad, 
            limpiarFormulario,
            onLimpiarFormulario,
            formularioNivelSeveridad, 
            page,
            nivelesEditados,
            nivelSeveridadSeleccionado,
            fetchingEditarNivelSeveridad,
            listaNivelesSeveridadActivos
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarNivelSeveridadPDF'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
            this.props.onCambiarVista();
        }

        const onFinish = formularioPDF => {
            console.log('formularioPDF');
            console.log(formularioPDF);
            onEditarNivelSeveridad(formularioNivelSeveridad, page, nivelesEditados, formularioPDF, nivelSeveridadSeleccionado, listaNivelesSeveridadActivos);
        };
        

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };


        return ( 
            <Spin spinning={fetchingEditarNivelSeveridad}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={24}>
                            <Form.Item
                                label="Archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir Convenio PDF
                                    </Button>
                                    ):false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        
                        <Col span={24} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Nivel de Severidad</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
         );
    }
}

const mapStateToProps = state => {
    return {
        nivelesEditados: state.MecanismosDePagosReducer.nivelesEditados,
        formularioNivelSeveridad: state.MecanismosDePagosReducer.formularioNivelSeveridad,
        fetchingEditarNivelSeveridad: state.MecanismosDePagosReducer.fetchingEditarNivelSeveridad,
        nivelSeveridadSeleccionado: state.MecanismosDePagosReducer.nivelSeveridadSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        listaNivelesSeveridadActivos: state.MecanismosDePagosReducer.listaNivelesSeveridadActivos,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onEditarNivelSeveridad: (formularioNivelSeveridad, page, nivelesEditados, formularioPDF, nivelSeveridadSeleccionado, listaNivelesSeveridadActivos, busqueda) => {
            dispatch({ type: 'EDITAR_NIVEL_SEVERIDAD_REQUEST', formularioNivelSeveridad, page, nivelesEditados, formularioPDF, nivelSeveridadSeleccionado, nivelSeveridadSeleccionado, listaNivelesSeveridadActivos, busqueda})
        },
        onCambiarVista: () => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_SEVERIDAD', vistaModalNivelSeveridad: 1, formularioNivelSeveridad: null, nivelesEditados: []})
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ArchivoEditarTiempoDeGarantia);