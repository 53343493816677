import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import EditarTiempoDeGarantia from './EditarTiempoDeGarantia';
import VerificarContraseña from './VerificarContraseña';
import ListaTiempoDeGarantia from './ListaTiempoDeGarantia';
import ArchivoEditarTiempoDeGarantia from './ArchivoEditarTiempoDeGarantia';

class ModalEditarTiempoDeGarantia extends Component {
    state = {
        password: '',
    }
    formRef = React.createRef();
    render() { 
        const {onShowModalEditarTiempoDeGarantia,
            onLimpiarFormulario,
            onCambiarVista,
            vistaModalTiempoDeGarantia,
            showModalEditarTiempoDeGarantia,
            onListaServiciosFiltroLimpiar
        } = this.props;

        const handleCancel = () => {
            onShowModalEditarTiempoDeGarantia()
            onLimpiarFormulario('')
            onListaServiciosFiltroLimpiar();
            onCambiarVista(1);
        }

        function ContenidoEditar() {
            if(vistaModalTiempoDeGarantia === 1){
                return(
                    <EditarTiempoDeGarantia/>
                );
            }
            else if(vistaModalTiempoDeGarantia === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalTiempoDeGarantia === 3){
                return(
                    <ListaTiempoDeGarantia/>
                );
            }
            else if(vistaModalTiempoDeGarantia === 4){
                return(
                    <ArchivoEditarTiempoDeGarantia/>
                );
            }
        };

        return ( 
            <Modal
            visible={showModalEditarTiempoDeGarantia}
            title='Editar Tiempo de garantia'
            onCancel={handleCancel}
            footer={false}
            >
                <ContenidoEditar/>
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditarTiempoDeGarantia: state.MecanismosDePagosReducer.showModalEditarTiempoDeGarantia,
        vistaModalTiempoDeGarantia: state.MecanismosDePagosReducer.vistaModalTiempoDeGarantia,
        formularioGarantia: state.MecanismosDePagosReducer.formularioGarantia,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarTiempoDeGarantia: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_TIEMPODEGARANTIA', showModalEditarTiempoDeGarantia: false, page: null })
        },
        onEditarTiempoDeGarantia: ( formulario, page ) => {
            dispatch({ type: "EDITAR_TIEMPODEGARANTIA_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario });
        },
        onCambiarVista: (vistaModalTiempoDeGarantia) => {
            dispatch({ type: 'CAMBIAR_VISTA_TIEMPODEGARANTIA', vistaModalTiempoDeGarantia: vistaModalTiempoDeGarantia})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarTiempoDeGarantia);