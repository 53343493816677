import React from 'react';
import { message, Form, Row, Col, Button, Input, Table, Card } from 'antd';
import { connect } from "react-redux";
import { BarChartOutlined, FormOutlined, BgColorsOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalCapturaConsumos from './ModalCapturaConsumos';
import ModalPresentacionConsumos from './ModalPresentacionConsumos';
import ModalGraficarConsumos from './ModalGraficarConsumos';
import ModalCapturaAbastecimiento from './ModalCapturaAbastecimiento';

const { Search } = Input;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class RegistroDeLecturaContainer extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          this.props.onGetServiciosPublicos(1, '');
          this.props.onGetUnidadesMedida();
          this.props.onGetVariablesMedicion();
     }

     state = {
          page: 1,
          busqueda: '',
          checked: true,
          servicioPublicoSeleccionadoLocal: [],
     }


     render() {
          const {
               listaServiciosPublicosBusqueda,
               listaServiciosPublicosBusquedaCantidad,
               fetchingListaServiciosPublicosBusqueda,
               onShowModalPresentacion,
               onShowModalCaptura,
               onDepositoByidServicioPublico,
               onDepositoByidServicioPublicoSinProgra,
               onProgramacionByServicio,
               onVariableByProgramacion,
               programacionByServicio,
               onHorariosByServicio,
               onHorarioByServicioAdmin,
          } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);

          };

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const onBuscar = (param) => {
               const busqueda = param;
               this.setState({ busqueda: busqueda, page: 1 });
               
               this.props.onGetServiciosPublicos(1, busqueda);
          };

          const onPresentacion = (key) => {
               console.log('key', key);
               onDepositoByidServicioPublicoSinProgra(key.idServicioPublico);
               this.props.onGetUbicacionesByIdServicioPublico(key.idServicioPublico);
               onShowModalPresentacion(key, this.state.page, this.state.busqueda);
          };

          const onAbastecimiento = (key) => {
               console.log('key', key);
               this.props.onGetAsignacionConsumosByIdServicioPublico(key.idServicioPublico);
               this.props.onGetVariablesMedicionByIdServicioPublico(key.idServicioPublico);
               this.props.onShowModalAbastecimiento(key, this.state.page, this.state.busqueda);
          };

          const columns = [
               {title: 'Servicio Público', dataIndex: 'nombre', key: 'nombre'},
               {
                    title: 'Abastecimiento',
                    dataIndex: 'abastecimiento',
                    key: 'abastecimiento',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!record.abastecimiento} onClick={() => onAbastecimiento(record)}><BgColorsOutlined /></a>
                    ),
               },
               {
                    title: 'Lecturas',
                    key: 'capturar',
                    width: '5%', align: 'center',
                    render: (record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.capturarLecturaDeServicioPublico} onClick={() => onCaptura(record)}><FormOutlined /></a>
                    ),
               },
               {
                    title: 'Presentación', key: 'detalle', align: 'center',
                    render: (record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.presentacionDeConsumosDeServiciosPúblicos}  onClick={() => onPresentacion(record)}><BarChartOutlined /></a>
                    ),
               },
          ];

          const onCaptura = (key) => {

               onShowModalCaptura(key, this.state.page, this.state.busqueda);
               //onDepositoByidServicioPublico(this.state.servicioPublicoSeleccionadoLocal.idServicioPublico);
               onProgramacionByServicio(key.idServicioPublico);
               onHorariosByServicio(key.idServicioPublico);
               onHorarioByServicioAdmin(key.idServicioPublico);
               
               // if(programacionByServicio.length > 0){
               //      console.log('programacionByServicio', programacionByServicio[0].idProgramacionConsumo);
               //      onVariableByProgramacion(programacionByServicio[0].idProgramacionConsumo)
               // }
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
               this.props.onGetServiciosPublicos(pagination.current, this.state.busqueda);
          };

          const rowSelection = {
               onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    if(selectedRowKeys){
                         this.setState({ checked: false, servicioPublicoSeleccionadoLocal: selectedRows[0] });
                    } else {
                         this.setState({ checked: true });
                    }
               },
               
          };

          return (

               <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
               >
                    <Card className='cardCatalogos' title={<div className='headerLista'>Registro de Lecturas</div>}>
                         <Row justify='space-around' style={{ textAlign: 'left' }}>
                              <Col span={24}>
                                   <Search className='buscarModulos' placeholder="Ingresa el nombre del servicio público" onSearch={value => onBuscar(value)} style={{ width: '100%' }}/>
                              </Col> 
                              {/* <Col span={7}>
                                   <Button onClick={onCaptura} disabled={this.state.checked} className='nuevoUsuario'>
                                        Captura de Consumos
                                   </Button>
                              </Col> */}
                              <Col span={24}>
                                   <br/>
                                   <Table
                                        size="small" 
                                        rowKey="idServicioPublico" 
                                        columns={columns}
                                        dataSource={listaServiciosPublicosBusqueda}
                                        loading={fetchingListaServiciosPublicosBusqueda}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={handleTableChange}
                                        pagination={{total: listaServiciosPublicosBusquedaCantidad, simple: true, current: this.state.page}}
                                   />
                              </Col>
                         </Row>
                         <ModalCapturaConsumos />
                         {/* <ModalPresentacionConsumos /> */}
                         <ModalGraficarConsumos />
                         <ModalCapturaAbastecimiento />
                    </Card>
               </Form>
          );
     }
}

const mapStateToProps = state => {
     return {
          listaServiciosPublicosBusqueda: state.ServiciosPublicosReducer.listaServiciosPublicosBusqueda,
          fetchingListaServiciosPublicosBusqueda: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosBusqueda,
          listaServiciosPublicosBusquedaCantidad: state.ServiciosPublicosReducer.listaServiciosPublicosBusquedaCantidad,
          programacionByServicio: state.ServiciosPublicosReducer.programacionByServicio,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onGetServiciosPublicos: (page, busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST", page, busqueda });
          },
          onGetUnidadesMedida: () => {
               dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
          },
          onGetVariablesMedicion: () => {
               dispatch({ type: 'LISTA_VARIABLES_MEDICION_ACTIVOS_REQUEST' });
          },
          onShowModalCaptura: (servicioPublicoSeleccionado, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_CONSUMOS', showModalCapturaConsumos: true, servicioPublicoSeleccionado, page, busqueda });
          },
          onShowModalPresentacion: (servicioPublicoSeleccionado, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_PRESENTACION_CONSUMOS', showModalPresentacionConsumos: true, servicioPublicoSeleccionado, page, busqueda });
          },

          onDepositoByidServicioPublico: (idServicioPublico, idProgramacionHorario) => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_REQUEST', idServicioPublico, idProgramacionHorario});
          },
          onProgramacionByServicio: (idServicioPublico) => {
               dispatch({ type: 'PROGRAMACION_POR_SERVICIO_REQUEST', idServicioPublico });
          },
          onVariableByProgramacion: (idProgramacionConsumo) => {
               dispatch({ type: 'VARIABLES_POR_PROGRAMACION_REQUEST', idProgramacionConsumo });
          },
          onHorariosByServicio: (idServicioPublico) => {
               dispatch({ type: 'HORARIOS_BY_SERVICIOS_REQUEST', idServicioPublico});
          },
          onHorarioByServicioAdmin: (idServicioPublico) => {
               dispatch({ type: 'HORARIOS_BY_SERVICIOS_ADMIN_REQUEST', idServicioPublico});
          },
          onDepositoByidServicioPublicoSinProgra: (idServicioPublico) => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_REQUEST', idServicioPublico});
          },
          onShowModalAbastecimiento: (servicioPublicoSeleccionado, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_ABASTECIMIENTO', showModalCapturaAbastecimiento: true, servicioPublicoSeleccionado, page, busqueda });
          },
          onGetAsignacionConsumosByIdServicioPublico: (idServicioPublico) => {
               dispatch({ type: 'LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_REQUEST', idServicioPublico });
          },
          onGetVariablesMedicionByIdServicioPublico: (idServicioPublico) => {
               dispatch({ type: 'LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_REQUEST', idServicioPublico });
          },
          onGetUbicacionesByIdServicioPublico: (idServicioPublico) => {
               dispatch({ type:'LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_REQUEST', idServicioPublico })
          }
     }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistroDeLecturaContainer);
