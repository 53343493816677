import React from 'react';
import { Form, Card, Spin, Row, Col, Select, Result, Descriptions, Tabs, Tooltip, DatePicker, Alert, Divider, Button, Radio, Typography, Breadcrumb } from 'antd';
import { FileProtectOutlined, FileSearchOutlined, ExceptionOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {Bar, Pie, Doughnut, HorizontalBar} from 'react-chartjs-2';
import moment from 'moment';

import imaSearch from '../../../assets/undraw_metrics_re_6g90.svg';
import ReactVivus from "react-vivus";

const { Option } = Select;
const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, color: 'white'};

class MonitoreoModulosCalidadContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ 
          this.props.onGetGruposServicios(); this.props.onGetListaGerenciasActivas(); this.props.onGetCentroDeCostos(); 
          this.props.onGetServicios(); this.props.onGetInfoProyecto();
          this.props.onGetDataSatisfaccion(); this.props.onGetDataDesempeno(null, null, null, null); this.props.onGetDataPromedioSatisfaccion(null, null, null, null); this.props.onGetDataSatisfaccionServicio(null); this.props.onGetDataEstandaresByTipo();
     }

     state={ once: true, current: 0, idCentroCosto: '', idGrupoSer: '', idClasificacionGerencia: '', idMesCalendario: '', idServicio: '', 
     idGrupoSerQueja: '', idServicioQueja: '', idClasificacionGerenciaQueja: '',
     idGrupoSerDesem: '', idServicioDesem: '', idClasificacionGerenciaDesem: '', mesState: null, anioState: null, title:'Encuestas de Satisfacción', colDer: 19,  colIzq: 5, maximizar: false  }
 
     render() {
          const { fetchingListaGruposServicios, listaGruposServicios, fetchingListaClasificacionesGerenciaActivas, listaClasificacionesGerenciaActivas, listaCentroDeCostosActivos, fetchingListaCentroDeCostosActivos, onGetDataSatisfaccion, dataGraficasByServicio, fetchingListaServiciosActivosF, listaServiciosActivosF, onGetDataSatisfaccionServicio, onGetDataQuejas, dataGraficasQuejas, onGetServiciosByGrupoServicio, listaServiciosByGrupoServicio, dataGraficasEvaluacionDesempeno, onGetDataDesempeno, onGetGerenciasByServicio, listaGerenciasByServicio, onCleanDataSatisfaccionServicio, onGetListaServicioByGerencia, onGetDataQuejasByServicio, onGetDataPromedioSatisfaccion, onGetDataEstandaresByTipo } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ 
               permisosUsuario = JSON.parse(permisosUsuario);
               if(this.state.once){
                    let currentInicial = permisosUsuario.evaluaciónDeDesempeñoEnElMonitoreoDeMódulosDeCalidad ? 0 : permisosUsuario.encuestasDeSatisfacciónEnElMonitoreoDeMódulosDeCalidad ? 1 : 2
                    this.setState({
                         current: currentInicial,
                         once: false
                    })
               }
          }

          let charDataPie = {}; let charDataDoughnut={}; let charDataBar = {};
          let optionsDataPie = {};

          let etiquetas = [];
          let informacion = [];
          let totalEncuestas = 0;
          let etiquetasLarga = [];
               if(this.props.dataEncustasSatisfaccion){
                    for(let i in this.props.dataEncustasSatisfaccion){
                         etiquetas.push(this.props.dataEncustasSatisfaccion[i].etiqueta);
                         informacion.push(this.props.dataEncustasSatisfaccion[i].cantidad);
                         etiquetasLarga.push(this.props.dataEncustasSatisfaccion[i].etiquetaLarga);
                         totalEncuestas += this.props.dataEncustasSatisfaccion[i].cantidad;
                    }
               }

               let dataTable = [];
               for(let i in etiquetas){
                    dataTable.push({ 'servicio': etiquetas[i], 'encuestas': informacion[i] });
               }

               let dataDescriptionOneSatisfaccion = [
                    {label: 'Total de Encuestas Aplicadas', data: totalEncuestas},
               ];

               
          let etiquetasPromedio = []; let informacionPromedio = []; let etiquetasLargasPromedio = [];
          if(this.props.dataGraficaPromedioSatisfaccion){
               for(let i in this.props.dataGraficaPromedioSatisfaccion){
                    etiquetasPromedio.push(this.props.dataGraficaPromedioSatisfaccion[i].etiqueta);
                    if (this.props.dataGraficaPromedioSatisfaccion[i].cantidad > 0 && this.props.dataGraficaPromedioSatisfaccion[i].cantidad < 100 ){ informacionPromedio.push((this.props.dataGraficaPromedioSatisfaccion[i].cantidad).toFixed(2)); } else { informacionPromedio.push((this.props.dataGraficaPromedioSatisfaccion[i].cantidad).toFixed(0)); }
                    etiquetasLargasPromedio.push(this.props.dataGraficaPromedioSatisfaccion[i].etiquetaLarga);
               }
          }

          let etiquetasPieService = [];
          let informacionPieServicio = [];
          let totalPieService = 0;
               if(dataGraficasByServicio){
                    for(let i in dataGraficasByServicio){
                         let etiquetaLocal =  this.props.dataGraficasByServicio[i].etiqueta;
                         if(!etiquetaLocal){ etiquetaLocal = 'Sin evaluar' }
                         etiquetasPieService.push(etiquetaLocal);
                         informacionPieServicio.push(this.props.dataGraficasByServicio[i].cantidad);
                         totalPieService += this.props.dataGraficasByServicio[i].cantidad;
                    }
               }
          
          let etiquetaDesempeno = []; let informacionDesempeno = []; let etiquetaDesempenoLarga = [];
          let graficaResponseDesempeno = []; let cumplenDesempeno = []; let noCumplenDesempeno = [];
          
          let totalEstandares = [];
          if(dataGraficasEvaluacionDesempeno){
               for(let i in dataGraficasEvaluacionDesempeno){
                    etiquetaDesempeno.push(this.props.dataGraficasEvaluacionDesempeno[i].nomenclatura);
                    informacionDesempeno.push(this.props.dataGraficasEvaluacionDesempeno[i].cantidad);
                    etiquetaDesempenoLarga.push(this.props.dataGraficasEvaluacionDesempeno[i].servicio);
                    graficaResponseDesempeno.push(this.props.dataGraficasEvaluacionDesempeno[i].graficaResponseList);

                    let sumaEstandar = 0;
                    for(let j in dataGraficasEvaluacionDesempeno[i].graficaResponseList){
                         if( dataGraficasEvaluacionDesempeno[i].graficaResponseList[j].etiqueta === 'Estándares que cumplen'){
                              cumplenDesempeno.push(dataGraficasEvaluacionDesempeno[i].graficaResponseList[j].cantidad);
                         } else {
                              noCumplenDesempeno.push(dataGraficasEvaluacionDesempeno[i].graficaResponseList[j].cantidad);
                         }
                         sumaEstandar += dataGraficasEvaluacionDesempeno[i].graficaResponseList[j].cantidad;
                    }
                    totalEstandares.push(sumaEstandar);
               }
          }

          let totalSumaEstandares = 0;
          totalEstandares.forEach (function(numero){
               totalSumaEstandares += numero;
          });

          const dataDescriptionDesempeno = [
               {label: 'Total de Estándares por Servicio', data: totalSumaEstandares},
          ];

          let etiquetaDesempenoByTipo = []; let informacionDesempenoByTipo = [];
          if(this.props.dataEstandaresByTipo){
               for(let i in this.props.dataEstandaresByTipo){
                    etiquetaDesempenoByTipo.push(this.props.dataEstandaresByTipo[i].etiqueta);
                    informacionDesempenoByTipo.push(this.props.dataEstandaresByTipo[i].cantidad);
               }
          }

          let totalSumaEstandaresByTipo = 0;
          informacionDesempenoByTipo.forEach (function(numero){
               totalSumaEstandaresByTipo += numero;
          });

          const dataDescriptionDesempenoByTipo = [
               {label: 'Total de Estándares por Tipo', data: totalSumaEstandaresByTipo},
          ];

          const charDataPieDesempeno = {
               labels: etiquetaDesempenoByTipo,
               datasets: [{  data: informacionDesempenoByTipo,  backgroundColor: ['#ffd700',  '#0092df'], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const optionsDataPieDesempeno = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { 
                                   let datasets = ctx.chart.data.datasets;

                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => {
                                             sum += dataset.data[ctx.dataIndex];
                                        });
                                        let percentage = ((value / sum) * 100).toFixed(0);
                                        return percentage + '%';
                                   }
                              }
                         }
                    }, fontSize: 14, fontColor: '#FFF'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem) {
                              
                              return 'Servicio: ' + etiquetasLarga[tooltipItem['index']];
                         },
                         afterLabel: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = (((currentValue * 100) / totalSumaEstandaresByTipo)).toFixed(2);
                              
                              return 'Estándares: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         }
                    },
                    backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false
               },
               hover: { animationDuration: 7 }, 
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    }
               }, 
               animations: { tension: { duration: 1000, easing: 'linear', from: 1, to: 0, loop: true }  }
          }

          const charDataDoughnutDesempeno = {
               labels: etiquetaDesempeno,
               datasets: [{  data: totalEstandares,  backgroundColor: ['#555e7b', '#b7d968', '#b576ad', '#e04644', '#7ccce5', '#b1c200', '#c06c84', '#a7dbd8', '#ecf081', '#3b8183', '#ff9900', '#789048', '#e8caa4','#f07241', '#f07818', '#d4ee5e', '#594f4f', '#8fbe00', '#d68189', '#d9ceb2', '#031634', '#f4ebc3', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#7a6a53'], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const optionsDataDoughnutDesempeno = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { 
                                   let datasets = ctx.chart.data.datasets;

                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => {
                                             sum += dataset.data[ctx.dataIndex];
                                        });
                                        let percentage = ((value / sum) * 100).toFixed(0);
                                        return percentage + '%';
                                   }
                              }
                         }
                    },
                    fontSize: 14,
                    fontColor: '#FFF'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem) {
                              
                              return 'Servicio: ' + etiquetasLarga[tooltipItem['index']];
                         },
                         afterLabel: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = (((currentValue * 100) / totalSumaEstandares)).toFixed(2);
                              
                              return 'Estándares: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               hover: { animationDuration: 7 },
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    }
               },
               animations: { tension: { duration: 1000, easing: 'linear', from: 1, to: 0, loop: true }  },
               
          }

          const charDataDesempeno = {
               labels: etiquetaDesempeno,
               datasets: [
                    {
                         label: 'Estándares que cumplen',
                         data: cumplenDesempeno,
                         backgroundColor: '#b3c52490',
                         borderColor: '#b3c524',  borderWidth: 1
                    },
                    {
                         label: 'Estándares que no cumplen',
                         data: noCumplenDesempeno,
                         backgroundColor: '#c5872490',
                         borderColor: '#c58724',  borderWidth: 1
                    },
               ],
          }

          const optionsDataDesempeno = {
               responsive: true,
               maintainAspectRatio: false,
               pieceLabel: {
                    render: 'value',
                    overlap: true,
                    fontColor: '#000',
                    fontSize: 12,
               },
               scales: {
                    yAxes: [{
                         display: true,
                         ticks: {
                              suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                              suggestedMax: 10, 
                         },
                    }],
                    xAxes: [{  
                         display: true,
                         ticks: {mirror: false},
                         gridLines: { display : true },
                         // scaleLabel: { display: true, labelString: ['Servicios'] } 
                     }]
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']] + ': ' + etiquetaDesempenoLarga[tooltipItem[0]['index']];
                         },
                         // label: function(tooltipItem) {
                              
                         //      return ': ' + etiquetaDesempenoLarga[tooltipItem['index']];
                         // },
                         // afterLabel: function(tooltipItem, data) {
                         //      let dataset = data.datasets[tooltipItem.datasetIndex];
                         //      let currentValue = dataset.data[tooltipItem.index];
                              
                         //           return 'Estándares: ' + currentValue;       
                         // },
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               legend: { display: true, labels: {boxWidth: 15} },
          }

          let dataDescriptionTwoSatisfaccion = [
               {label: 'Total de Encuestas Aplicadas', data: totalPieService},
          ];

          let etiquetasBar = [];
          let informacionBar = [];
          let etiquetasBarLargas = [];
          if(dataGraficasQuejas){
               for(let i in dataGraficasQuejas){
                    etiquetasBar.push(this.props.dataGraficasQuejas[i].etiqueta);
                    informacionBar.push(this.props.dataGraficasQuejas[i].cantidad);
                    etiquetasBarLargas.push(this.props.dataGraficasQuejas[i].etiquetaLarga);
               }
          }


          charDataDoughnut = {
               labels: etiquetas,
               datasets: [{  data: informacion,  backgroundColor: ['#555e7b', '#b7d968', '#b576ad', '#e04644', '#7ccce5', '#b1c200', '#c06c84', '#a7dbd8', '#ecf081', '#3b8183', '#ff9900', '#789048', '#e8caa4','#f07241', '#f07818', '#d4ee5e', '#594f4f', '#8fbe00', '#d68189', '#d9ceb2', '#031634', '#f4ebc3', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#7a6a53'], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const charDataBarSatisfaccion = {
               labels: etiquetasPromedio,
               datasets: [{  data: informacionPromedio,  backgroundColor: ['#555e7b80', '#b7d96880', '#b576ad80', '#e0464480', '#7ccce580', '#b1c20080', '#c06c8480', '#a7dbd880', '#ecf08180', '#3b818380', '#ff990080', '#78904880', '#e8caa480','#f0724180', '#f0781880', '#d4ee5e80', '#594f4f80', '#8fbe0080', '#d6818980', '#d9ceb280', '#03163480', '#f4ebc380', '#3fb8af80', '#54798080', '#83af9b80', '#00a0b080', '#7a6a5380'], borderColor: '#FFF',  borderWidth: 1 }],
          }

          // '#b576ad',  '#c06c84', '#d68189',  '#f04644', '#f07241', '#ff9900', '#f4ebc3', '#ecf081', '#d4ee5e',  '#b7d968', '#b1c200', '#8fbe00', '#789048', '#d9ceb2','#e8caa4', '#7a6a53', '#594f4f', '#031634', '#555e7b', '#3b8183', '#83af9b', '#00a0b0', '#a7dbd8'

          charDataPie = {
               labels: etiquetasPieService,
               datasets: [{  data: informacionPieServicio,  backgroundColor: [ '#a7dbd8', '#00a0b0', '#83af9b', '#3b8183', '#555e7b', '#031634', '#b576ad',  '#c06c84', '#d68189',  '#f04644', '#f07241', '#ff9900', '#f4ebc3', '#ecf081', '#d4ee5e',  '#b7d968', '#b1c200', '#8fbe00', '#789048', '#d9ceb2','#e8caa4', '#7a6a53', '#594f4f',    ], borderColor: '#FFF',  borderWidth: 1 }],
          }

          charDataBar = {
               labels: etiquetasBar,
               datasets: [{ label: 'Quejas y Reclamos',  data: informacionBar,  backgroundColor: ['#555e7b80', '#b7d96880', '#b576ad80', '#f0464480', '#7ccce580', '#b1c20080', '#c06c8480', '#a7dbd880', '#ecf08180', '#3b818380', '#ff990080', '#78904880', '#e8caa480','#f0724180', '#f0781880', '#d4ee5e80', '#594f4f80', '#8fbe0080', '#d6818980', '#d9ceb280', '#03163480', '#f4ebc380', '#3fb8af80', '#54798080', '#83af9b80', '#00a0b080', '#7a6a5380'], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const optionsDataDoughnut = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { 
                                   let datasets = ctx.chart.data.datasets;

                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => {
                                             sum += dataset.data[ctx.dataIndex];
                                        });
                                        let percentage = ((value / sum) * 100).toFixed(0);
                                        return percentage + '%';
                                   }
                              }
                         }
                    },
                    fontSize: 14,
                    fontColor: '#FFF'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem) {
                              
                              return 'Servicio: ' + etiquetasLarga[tooltipItem['index']];
                         },
                         afterLabel: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = (((currentValue * 100) / totalEncuestas)).toFixed(2);
                              
                              return 'Encuestas: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               hover: { animationDuration: 7 },
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    }     
               },
               animations: { tension: { duration: 1000, easing: 'linear', from: 1, to: 0, loop: true }  },
               
          }


          const optionsDataBarSatisfaccion = {
               responsive: true,
               maintainAspectRatio: false,
               pieceLabel: {
                    render: 'value',
                    overlap: true,
                    fontColor: ['#555e7b', '#8fbe00', '#a576ad', '#b04644', '#7ccce5', '#b1c200', '#c06c84', '#a7aab8', '#ecc010', '#3b8183', '#ff9900', '#789048', '#e8aaa4','#f07241', '#f07818', '#d4ee5e', '#594f4f', '#b7d968', '#d68189', '#d9baa2', '#031634', '#f4aaa3', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#7a6a53'],
                    fontSize: 16,
               },
               scales: {
                    yAxes: [{
                         display: true,
                         ticks: {mirror: true, min: 0, stepSize: 1},
                         gridLines: { display : false },
                         barPercentage: 1,
                         scaleLabel: { display: true, labelString: ['Servicios'] } 
                    }],
                    xAxes: [{  
                         display: true,
                         ticks: {
                              suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                              suggestedMax: 100,
                         },
                         scaleLabel: { display: true, labelString: ['Promedio'] } 
                     }]
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem) {
                              return 'Servicio: ' + etiquetasLargasPromedio[tooltipItem['index']];
                         },
                         afterLabel: function(tooltipItem, data) {
                              return 'Promedio: ' +  data['datasets'][0]['data'][tooltipItem['index']];
                         }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 16,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 14,
                    displayColors: false
               },
               legend: { display: false, labels: {boxWidth: 15}  },
          }

          optionsDataPie = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { let datasets = ctx.chart.data.datasets;
                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => {
                                             sum += dataset.data[ctx.dataIndex];
                                        });
                                        let percentage = Math.round((value / sum) * 100) + '%';
                                        return percentage;
                                   }
                              }
                         }
                    },
                    fontSize: 14,
                    fontColor: '#fff'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = ((currentValue * 100) / totalPieService).toFixed(2);
                              
                              return 'Respuestas: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         },
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               hover: { animationDuration: 7 },
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    }
               },
               animations: { tension: { duration: 1000, easing: 'linear', from: 1, to: 0, loop: true }  }, 
          }

          const optionsDataBar = {
               responsive: true,
               maintainAspectRatio: false,
               pieceLabel: {
                    render: 'value',
                    overlap: true,
                    fontColor: ['#555e7b', '#8fbe00', '#b576ad', '#f04644', '#7ccce5', '#b1c200', '#c06c84', '#a7dbd8', '#ecf081', '#3b8183', '#ff9900', '#789048', '#e8caa4','#f07241', '#f07818', '#d4ee5e', '#594f4f', '#b7d968', '#d68189', '#d9ceb2', '#031634', '#f4ebc3', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#7a6a53'],
                    fontSize: 16,
               },
               scales: {
                    xAxes: [{
                         display: true,
                         ticks: {mirror: true},
                         gridLines: { display : false },
                         scaleLabel: { display: true, labelString: ['Servicios'] } 
                    }],
                    yAxes: [{  
                         display: true,
                         ticks: {
                              suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                              suggestedMax: 10,
                         },
                         //scaleLabel: { display: true, labelString: ['No. Quejas y Reclamos'] } 
                     }]
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem) {
                              return 'Servicio: ' + etiquetasBarLargas[tooltipItem['index']];
                         },
                         afterLabel: function(tooltipItem, data) {
                              return 'Quejas y Reclamos: ' +  data['datasets'][0]['data'][tooltipItem['index']];
                         }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               legend: { display: false, labels: {boxWidth: 15} },
          }

          let etiquetasBarQR = []; let etiquetaBarQRLarga = [];
          let informacionBarQR = [];
          let conPlanAbierto = [];
          let conPlanCerrado = [];
          let sinPlan = [];
          let totalQR = [];
          if(this.props.dataGraficasQuejas){
               for(let i in this.props.dataGraficasQuejas){
                    etiquetasBarQR.push(this.props.dataGraficasQuejas[i].nomenclatura);
                    informacionBarQR.push(this.props.dataGraficasQuejas[i].cantidad);
                    etiquetaBarQRLarga.push(this.props.dataGraficasQuejas[i].servicio);
                    let total = 0;
                    for(let j in this.props.dataGraficasQuejas[i].graficaResponseList){         
                         if( this.props.dataGraficasQuejas[i].graficaResponseList[j].etiqueta === 'Quejas con plan correctivo abierto'){
                              conPlanAbierto.push(this.props.dataGraficasQuejas[i].graficaResponseList[j].cantidad);
                         } else if( this.props.dataGraficasQuejas[i].graficaResponseList[j].etiqueta === 'Quejas con plan correctivo cerrado'){
                              conPlanCerrado.push(this.props.dataGraficasQuejas[i].graficaResponseList[j].cantidad);
                         } else {
                              sinPlan.push(this.props.dataGraficasQuejas[i].graficaResponseList[j].cantidad);
                         }
                         total += this.props.dataGraficasQuejas[i].graficaResponseList[j].cantidad;   
                    }
                    totalQR.push(total);
               }
          }

          ////////////////////////////////////////////// QUEJAS Y RECLAMOS - PLANES CORRECTIVOS ////////////////////////////////////////////////

          let dataPlanes = [];
          let sumaPlanAbierto = 0;
          conPlanAbierto.forEach (function(numero){
               sumaPlanAbierto += numero;
          });
          dataPlanes.push(sumaPlanAbierto);

          let sumaPlanCerrado = 0;
          conPlanCerrado.forEach (function(numero){
               sumaPlanCerrado += numero;
          });
          dataPlanes.push(sumaPlanCerrado);

          let sumaSinPlan = 0;
          sinPlan.forEach (function(numero){
               sumaSinPlan += numero;
          });
          dataPlanes.push(sumaSinPlan);

          let totalPlanes = sumaPlanAbierto + sumaPlanCerrado + sumaSinPlan;

          const dataDescriptionPlanesCorrectivos = [
               {label: 'Total de Planes Correctivos', data: totalPlanes},
          ];

          const charDataPiePlanes = {
               labels: [ 'Plan correctivo abierto', 'Plan correctivo cerrado', 'Sin plan correctivo' ],
               datasets: [{  data: dataPlanes,  backgroundColor: [ '#f3da0b', '#8fbe00', '#747474' ], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const optionsDataPiePlanes = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { let datasets = ctx.chart.data.datasets;
                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => {
                                             sum += dataset.data[ctx.dataIndex];
                                        });
                                        let percentage = Math.round((value / sum) * 100) + '%';
                                        return percentage;
                                   }
                              }
                         }
                    }, fontSize: 14, fontColor: '#fff'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = ((currentValue * 100) / totalPlanes).toFixed(2);
                              
                              return 'Cantidad: ' +  currentValue + ' | Porcentaje: ' + percentage + '%';
                         },
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               hover: { animationDuration: 7 },
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    }
               },
               animations: { tension: { duration: 1000, easing: 'linear', from: 1, to: 0, loop: true }  }, 
          }


          let sumaQR = 0;
          totalQR.forEach (function(numero){
               sumaQR += numero;
          });

          const dataDescriptionQR = [
               {label: 'Total de Quejas y Reclamos', data: sumaQR},
          ];

          const charDataDoughnutQR = {
               labels: etiquetasBarQR,
               datasets: [{  data: totalQR,  backgroundColor: ['#555e7b', '#b7d968', '#b576ad', '#e04644', '#7ccce5', '#b1c200', '#c06c84', '#a7dbd8', '#ecf081', '#3b8183', '#ff9900', '#789048', '#e8caa4','#f07241', '#f07818', '#d4ee5e', '#594f4f', '#8fbe00', '#d68189', '#d9ceb2', '#031634', '#f4ebc3', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#7a6a53'], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const optionsDataDoughnutQR = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { 
                                   let datasets = ctx.chart.data.datasets;

                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => {
                                             sum += dataset.data[ctx.dataIndex];
                                        });
                                        let percentage = ((value / sum) * 100).toFixed(0);
                                        return percentage + '%';
                                   }
                              }
                         }
                    }, fontSize: 14,  fontColor: '#FFF'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem) {
                              
                              return 'Servicio: ' + etiquetaBarQRLarga[tooltipItem['index']];
                         },
                         afterLabel: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = (((currentValue * 100) / sumaQR)).toFixed(2);
                              
                              return 'Quejas y Reclamos: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               }, hover: { animationDuration: 7 },
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    }
               },
          }

          const charDataBarQR = {
               labels: etiquetasBarQR,
               datasets: [
                    {
                         label: 'Plan correctivo abierto',
                         data: conPlanAbierto,
                         backgroundColor: '#f3da0b90',
                         borderColor: '#f3da0b',  borderWidth: 1  
                    },
                    {
                        label: 'Plan correctivo cerrado',
                        data: conPlanCerrado,
                        backgroundColor: '#8fbe0090',
                        borderColor: '#8fbe00',  borderWidth: 1
                    },
                    {
                        label: 'Sin plan correctivo',
                        data: sinPlan,
                        backgroundColor: '#74747490',
                        borderColor: '#747474',  borderWidth: 1
                    },
               ],
          }

          const optionsDataBarQR = {
               responsive: true,
               maintainAspectRatio: false,
               pieceLabel: {
                    render: 'value',
                    overlap: true,
                    fontColor: '#000',
                    fontSize: 12,
               },
               scales: {
                    xAxes: [{
                         display: true,
                         ticks: {mirror: false},
                         gridLines: { display : true },
                         // scaleLabel: { display: true, labelString: ['Servicios'] } 
                    }],
                    yAxes: [{  
                         display: true,
                         ticks: { suggestedMin: 0, suggestedMax: 10 },
                         //scaleLabel: { display: true, labelString: ['No. Quejas y Reclamos'] } 
                    }]
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']] + ': ' + etiquetaBarQRLarga[tooltipItem[0]['index']];
                         },
                         // label: function(tooltipItem, data) {
                         //      let dataset = data.datasets[tooltipItem.datasetIndex];
                         //      let currentValue = dataset.data[tooltipItem.index];
                              
                         //           return 'Cantidad: ' + currentValue;       
                         // },
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    bodyFontSize: 12,
                    displayColors: false
               },
               legend: { labels: {boxWidth: 15} },
          }

          const handleClick = e => {
               console.log('click ', e);
               if(e){
                    this.setState({ current: e.key });
                    //this.formRef.current.resetFields();
               }
          };

          const selectGerencia = (value) => {
               if(value){
                    let idClasificacionGerencia = parseInt(value.split('-'[0]));
                    onGetDataSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, idClasificacionGerencia, this.state.mesState, this.state.anioState);
                    onGetDataPromedioSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, idClasificacionGerencia, this.state.idMesCalendario);
                    this.setState({ idClasificacionGerencia });

               } else {
                    onGetDataSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, '',  this.state.mesState, this.state.anioState);
                    onGetDataPromedioSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, '',  this.state.mesState, this.state.anioState);
                    this.setState({ idClasificacionGerencia: '' });
               }
          };

          const onAñoCalendario = (value) => {
               this.props.onGetListaMesesCalendario(value);
          };

          const onMesCalendario = (value) => {
               onGetDataSatisfaccionServicio(value, this.state.idServicio);
               onGetDataSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia, value);
               onGetDataPromedioSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia, value);
               onGetDataDesempeno(value, this.state.idServicioDesem, this.state.idGrupoSerDesem, this.state.idClasificacionGerenciaDesem);
               onGetDataEstandaresByTipo(value, this.state.idServicioDesem, this.state.idGrupoSerDesem, this.state.idClasificacionGerenciaDesem);

               this.setState({ idMesCalendario: value });
          };

          /////////////////////////////////////////////////////// S A T I S F A C C I Ó N ///////////////////////////////////////////////////////

          const grupServicio = (value) => {
               if(value){
                    let idGrupoSer = parseInt(value.split('-'[0]));
                    onGetDataSatisfaccion(idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    onGetDataPromedioSatisfaccion(idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    this.setState({ idGrupoSer });
               } else {
                    onGetDataSatisfaccion('', this.state.idCentroCosto, this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    onGetDataPromedioSatisfaccion('', this.state.idCentroCosto, this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    this.setState({ idGrupoSer: '' });
               }
          };

          const centroCosto = (value) => {
               if(value){
                    let idCentroCosto = parseInt(value.split('-'[0]));
                    onGetDataSatisfaccion(this.state.idGrupoSer, idCentroCosto, this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    onGetDataPromedioSatisfaccion(this.state.idGrupoSer, idCentroCosto, this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    this.setState({ idCentroCosto });
               } else {
                    onGetDataSatisfaccion(this.state.idGrupoSer, '', this.state.idClasificacionGerencia,  this.state.mesState, this.state.anioState);
                    onGetDataPromedioSatisfaccion(this.state.idGrupoSer, '', this.state.idClasificacionGerencia, this.state.mesState, this.state.anioState);
                    this.setState({ idCentroCosto: '' });
               }
          };

          const onServicioSeleccionado = (value) => {
               if(value){
                    let idServicio = parseInt(value.split('-'[0]));
                    onGetDataSatisfaccionServicio( this.state.mesState, this.state.anioState, idServicio);
                    this.setState({ idServicio });
               } else {
                    onGetDataSatisfaccionServicio( this.state.mesState, this.state.anioState);
                    this.setState({ idServicio: '' });
                    onCleanDataSatisfaccionServicio();
               }
          };

          /////////////////////////////////////////////////////// D E S E M P E Ñ O ///////////////////////////////////////////////////////

          const grupServicioDesem = (value) => {
               if(value){
                    let idGrupoSerDesem = parseInt(value.split('-'[0]));
                    onGetDataDesempeno( this.state.mesState, this.state.anioState, this.state.idServicioDesem, idGrupoSerDesem,  this.state.idClasificacionGerenciaDesem);
                    onGetDataEstandaresByTipo( this.state.mesState, this.state.anioState, this.state.idServicioDesem, idGrupoSerDesem,  this.state.idClasificacionGerenciaDesem);
                    this.setState({ idGrupoSerDesem });
                    onGetServiciosByGrupoServicio(idGrupoSerDesem);
                    this.formRef.current.resetFields(['servicioDesempeno']);
               } else {
                    onGetDataDesempeno( this.state.mesState, this.state.anioState, '', '', '');
                    onGetDataEstandaresByTipo( this.state.mesState, this.state.anioState, '', '', '');
                    this.formRef.current.resetFields(['servicioDesempeno']);
                    this.setState({ idGrupoSerDesem: '' , idServicioDesem: '', idClasificacionGerenciaDesem: '' });
               }
          };

          const onServicioSeleccionadoDesem = (value) => {
               if(value){
                    let idServicioDesem = parseInt(value.split('-'[0]));
                    onGetDataDesempeno( this.state.mesState, this.state.anioState, idServicioDesem, this.state.idGrupoSerDesem,  this.state.idClasificacionGerenciaDesem);
                    onGetDataEstandaresByTipo( this.state.mesState, this.state.anioState, idServicioDesem, this.state.idGrupoSerDesem,  this.state.idClasificacionGerenciaDesem);
                    this.setState({ idServicioDesem });
               } else {
                    onGetDataDesempeno( this.state.mesState, this.state.anioState, '', this.state.idGrupoSerDesem,  this.state.idClasificacionGerenciaDesem);
                    onGetDataEstandaresByTipo( this.state.mesState, this.state.anioState, '', this.state.idGrupoSerDesem,  this.state.idClasificacionGerenciaDesem);
                    this.setState({ idServicioDesem: '' });
               }
          };

          const selectGerenciaDesem = (value) => {
               if(value){
                    let idClasificacionGerenciaDesem = parseInt(value.split('-'[0]));
                    onGetDataDesempeno( this.state.mesState, this.state.anioState, this.state.idServicioDesem, this.state.idGrupoSerDesem, idClasificacionGerenciaDesem);
                    onGetDataEstandaresByTipo( this.state.mesState, this.state.anioState, this.state.idServicioDesem, this.state.idGrupoSerDesem, idClasificacionGerenciaDesem);
                    this.setState({ idClasificacionGerenciaDesem });
               } else {
                    onGetDataDesempeno( this.state.mesState, this.state.anioState, this.state.idServicioDesem, this.state.idGrupoSerDesem, '');
                    onGetDataEstandaresByTipo( this.state.mesState, this.state.anioState, this.state.idServicioDesem, this.state.idGrupoSerDesem, '');
                    this.setState({ idClasificacionGerenciaDesem: '' });
               }
          };

          /////////////////////////////////////////////////////// Q U E J A ///////////////////////////////////////////////////////

          const grupServicioQueja = (value) => {
               if(value){
                    let idGrupoSerQueja = parseInt(value.split('-'[0]));
                    onGetDataQuejas(idGrupoSerQueja, '', this.state.idClasificacionGerenciaQueja);
                    this.setState({ idGrupoSerQueja });
                    onGetServiciosByGrupoServicio(idGrupoSerQueja);
                    this.formRef.current.resetFields(['servicioQueja']);
                    onGetDataQuejasByServicio('', '', '');
               } else {
                    onGetDataQuejas('', '', '');
                    this.formRef.current.resetFields(['servicioQueja']);
                    this.setState({ idServicioQueja: '', idGrupoSerQueja: '', idClasificacionGerenciaQueja: ''  });
                    onGetDataQuejasByServicio('', '', '');
               }
          };

          const onServicioSeleccionadoQueja = (value) => {
               if(value){
                    let idServicioQueja = parseInt(value.split('-'[0]));
                    onGetDataQuejas(this.state.idGrupoSerQueja, idServicioQueja, this.state.idClasificacionGerenciaQueja);
                    this.setState({ idServicioQueja });
               } else {
                    onGetDataQuejas('', '', '');
                    this.setState({ idServicioQueja: ''});
               }
          };

          const selectGerenciaQueja = (value) => {
               if(value){
                    let idClasificacionGerenciaQueja = parseInt(value.split('-'[0]));
                    onGetListaServicioByGerencia(idClasificacionGerenciaQueja);
                    onGetDataQuejas(this.state.idGrupoSerQueja, '', idClasificacionGerenciaQueja);
                    this.setState({ idClasificacionGerenciaQueja });
                    onGetDataQuejasByServicio('', '', '');
                    this.formRef.current.resetFields(['servicioQueja']);
               } else {
                    this.formRef.current.resetFields(['servicioQueja']);
                    onGetDataQuejas(this.state.idGrupoSerQueja, '', '');
                    onGetDataQuejasByServicio('', '', '');
                    this.setState({ idClasificacionGerenciaQueja: '' });
               }
          };

          const disabledDate = (date) => {
               if(this.props.listaInfoProyecto.fechaInicio){
                    const mesInicio = (this.props.listaInfoProyecto.fechaInicio._i).split('-')[1];
                    const anioInicio = (this.props.listaInfoProyecto.fechaInicio._i).split('-')[0];

                    const currentYear = new Date();
                    let year = currentYear.getFullYear();
                    let month = String(today.getMonth() + 1).padStart(2, '0'); 
                    year = month == 11 ? year + 1 : year;
                    const fechaInicio = moment(`1/${mesInicio}/${anioInicio}`, 'DD-MM-YYYY');
                    const fechaFin = moment(`1/${month}/${year}`, 'DD-MM-YYYY');
                    if( date && date < fechaInicio) {
                         return date && date < fechaInicio;
                    } else {
                         return date && date > fechaFin;
                    }
               }
          };

          const onChangeMonth = (date, dateString) => {
               if(date){  
                    let mes = parseInt(dateString.split('-')[0]);
                    let anio = parseInt(dateString.split('-')[1]);
                    this.setState({ mesState: mes, anioState: anio });

                    onGetDataSatisfaccionServicio(mes, anio, this.state.idServicio);
                    onGetDataSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia, mes, anio);
                    onGetDataPromedioSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia, mes, anio);
                    onGetDataDesempeno(mes, anio, this.state.idServicioDesem, this.state.idGrupoSerDesem, this.state.idClasificacionGerenciaDesem);
                    onGetDataEstandaresByTipo(mes, anio, this.state.idServicioDesem, this.state.idGrupoSerDesem, this.state.idClasificacionGerenciaDesem);
               } else {
                    onGetDataSatisfaccionServicio(null, null, this.state.idServicio);
                    onGetDataSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia, null, null);
                    onGetDataPromedioSatisfaccion(this.state.idGrupoSer, this.state.idCentroCosto, this.state.idClasificacionGerencia, null, null);
                    onGetDataDesempeno(null, null, this.state.idServicioDesem, this.state.idGrupoSerDesem, this.state.idClasificacionGerenciaDesem);
                    onGetDataEstandaresByTipo(null, null, this.state.idServicioDesem, this.state.idGrupoSerDesem, this.state.idClasificacionGerenciaDesem);

                    this.setState({ mesState: null, anioState: null });
               }
          };

          let today = new Date();;
          let mm = today.getMonth() == 0 ? '12' : String(today.getMonth()).padStart(2, '0'); 
          let yyyy = today.getMonth() == 0 ? today.getFullYear() - 1 : today.getFullYear();
          let actualDate = mm + yyyy;

          const datePickerMonth = {
               date: <DatePicker picker="month"  style={{width: "100%"}} disabledDate={disabledDate} format='MM-YYYY' onChange={onChangeMonth} defaultValue={moment(actualDate, 'MM-YYYY')} allowClear={false}/>,
          };

          const buttonChangeScreen = (current, title) => { this.setState({ current, title }) };

          const onExpandir = () => {
               if(!this.state.maximizar){ this.setState({ colIzq: 0, colDer: 24, maximizar: true }); }
               else{ this.setState({  colIzq: 5, colDer: 19, maximizar: false }); }
          };

          return (<>
               <Row gutter={[0,8]} type="flex">
                    <Col span={this.state.colIzq} style={{backgroundColor: '#737373', borderTopLeftRadius: '1em', borderBottomLeftRadius: '1em'}}>
                         <br/>
                         <p className='textoBlanco' style={{textAlign: 'center', fontSize: 14, color: 'white'}}><strong>Filtro de Búsqueda</strong></p>
                         <br/>
                         <Form name="formulario" ref={this.formRef}  {...layout} >
                              <Row justify='center' gutter={[0,8]}>
                                   <Col span={22}>
                                        <DatePicker picker="month"  style={{width: "100%"}} disabledDate={disabledDate} format='MM-YYYY' onChange={onChangeMonth} defaultValue={moment(actualDate, 'MM-YYYY')} allowClear={false}/>
                                   </Col>

                                   <Col span={22}>
                                        <Button style={{width:'100%'}} disabled={!permisosUsuario.evaluaciónDeDesempeñoEnElMonitoreoDeMódulosDeCalidad} className='btnSecundarioTwo' onClick={e => buttonChangeScreen(0, "Encuestas de Satisfacción")}>
                                        Encuestas de Satisfacción
                                        </Button>
                                   </Col>

                                   <Col span={22}>
                                        <Button style={{width:'100%'}} disabled={!permisosUsuario.encuestasDeSatisfacciónEnElMonitoreoDeMódulosDeCalidad} className='btnSecundarioTwo' onClick={e => buttonChangeScreen(1, "Evaluación de Desempeño")}>
                                             Evaluación de Desempeño
                                        </Button>
                                   </Col>

                                   <Col span={22}>
                                        <Button style={{width:'100%'}} disabled={!permisosUsuario.seguimientoDeQuejasYReclamosEnElMonitoreoDeMódulosDeCalidad} className='btnSecundarioTwo' onClick={e => buttonChangeScreen(2, "Seguimiento de Quejas y Reclamos")}>
                                             Seguimiento de QyR
                                        </Button>
                                   </Col>

                                   {/* { this.state.current === 0 ? (<></>):false } */}
                                   { this.state.current === 0 ? (<>
                                        <Divider style={{fontSize:12, color:'white'}}>Encuestas de Satisfacción</Divider>
                                        <Col span={22}>
                                             <Form.Item name='gerencia'>
                                                  <Select allowClear style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona la gerencia" onChange={selectGerencia} loading={fetchingListaClasificacionesGerenciaActivas} disabled={this.state.idCentroCosto !== '' || this.state.idGrupoSer !== ''}>
                                                       {listaClasificacionesGerenciaActivas.map(option => 
                                                            <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia} >
                                                                 {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                            </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='centroCosto'>
                                                  <Select allowClear showSearch style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el centro de costo" loading={fetchingListaCentroDeCostosActivos} onChange={centroCosto} disabled={this.state.idGrupoSer !== '' || this.state.idClasificacionGerencia !== ''}>
                                                       {listaCentroDeCostosActivos.map(option => 
                                                            <Option key={option.idCentroCosto + ' - ' +  option.centroCosto}>
                                                                 <Tooltip title={option.centroCosto }>
                                                                      { option.idCentroCosto + ' - ' +  option.centroCosto }
                                                                 </Tooltip>
                                                            </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='grupoServicio'>
                                                  <Select 
                                                       allowClear  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} showSearch onChange={grupServicio} disabled={this.state.idCentroCosto !== '' || this.state.idClasificacionGerencia !== ''}
                                                  >
                                                       {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                  </Select>
                                             </Form.Item>
                                             <br/><br/><br/>
                                        </Col>

                                        <Divider style={{fontSize:11, color:'white'}}>Resultado de Encuestas por Servicio</Divider>

                                        <Col span={22}>
                                             <Form.Item name='servicio'>
                                                  <Select allowClear  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un servicio" loading={fetchingListaServiciosActivosF} showSearch onChange={onServicioSeleccionado}
                                                  >
                                                       {listaServiciosActivosF.map(option => 
                                                       <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                            <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                 {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                            </Tooltip>
                                                       </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                   </>):( this.state.current === 1 ? (<>
                                        <Divider style={{fontSize:12, color:'white'}}>Evaluación de Desempeño</Divider>
                                        <Col span={22}>
                                             <Form.Item name='gerenciaDesempeno'>
                                                  <Select allowClear style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona la gerencia" onChange={selectGerenciaDesem} loading={fetchingListaClasificacionesGerenciaActivas} disabled={this.state.idGrupoSerDesem !== '' || this.state.idServicioDesem !== ''}>
                                                       {listaClasificacionesGerenciaActivas.map(option => 
                                                            <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia} >
                                                                 {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                            </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='grupoServicioDesempeno'>
                                                  <Select 
                                                       allowClear  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} showSearch onChange={grupServicioDesem} disabled={this.state.idServicioDesem !== '' || this.state.idClasificacionGerenciaDesem !== ''}
                                                  >
                                                       {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                        
                                        <Col span={22}>
                                             <Form.Item name='servicioDesempeno'>
                                                  <Select allowClear  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un servicio" loading={fetchingListaServiciosActivosF} showSearch onChange={onServicioSeleccionadoDesem} disabled={this.state.idGrupoSerDesem !== '' || this.state.idClasificacionGerenciaDesem !== ''}
                                                  >
                                                       { this.state.idGrupoSerDesem === '' ? (<>
                                                            {listaServiciosActivosF.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip placement="topLeft" title={option.nomenclatura + ': ' + option.nombre}>
                                                                      { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                 </Tooltip>
                                                            </Option>)}
                                                       </>):(<>
                                                            {listaServiciosByGrupoServicio.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip placement="topLeft" title={option.nomenclatura + ': ' + option.nombre}>
                                                                      { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                 </Tooltip>
                                                            </Option>)}
                                                       </>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                   </>):( this.state.current === 2 ? (<>
                                        <Divider style={{fontSize:12, color:'white'}}>Seguimiento de Quejas y Reclamos</Divider>
                                        <Col span={22}>
                                             <Form.Item name='gerenciaQueja'>
                                                  <Select allowClear style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona la gerencia" onChange={selectGerenciaQueja} loading={fetchingListaClasificacionesGerenciaActivas} disabled={this.state.idGrupoSerQueja !== '' || this.state.idServicioQueja !== ''}>
                                                       {listaClasificacionesGerenciaActivas.map(option => 
                                                            <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia} >
                                                                 {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                            </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='grupoServicioQueja'>
                                                  <Select 
                                                       allowClear  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} showSearch onChange={grupServicioQueja} disabled={ this.state.idClasificacionGerenciaQueja !== '' || this.state.idServicioQueja !== '' }
                                                  >
                                                       {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                        
                                        <Col span={22}>
                                             <Form.Item name='servicioQueja'>
                                                  <Select allowClear  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un servicio" loading={ fetchingListaServiciosActivosF } showSearch onChange={onServicioSeleccionadoQueja} disabled={this.state.idGrupoSerQueja !== '' || this.state.idClasificacionGerenciaQueja !== ''}
                                                  >
                                                       {listaServiciosActivosF.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip placement="topLeft" title={option.nomenclatura + ': ' + option.nombre}>
                                                                      { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                 </Tooltip>
                                                            </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                   </>):false ) ) }
                              </Row>
                         </Form>
                    </Col>

                    <Col span={this.state.colDer} style={{backgroundColor: '#F2F2F2', borderTopRightRadius:'1em', borderBottomRightRadius:'1em' }}>
                         <Row gutter={[0,8]} type="flex" align='middle' style={{backgroundColor: '#E4E4E4', borderTopRightRadius:'1em'}}>
                              <Col span={23} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em'}}>
                                   <Row type="flex" align='start'>
                                        <Text style={{textAlign: 'left', fontSize: '2em', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em', color: '#737373', paddingLeft:'.5em'}} strong> RDO </Text> 
                                        
                                        <Text style={{ fontSize: '2em', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em', color: '#737373', paddingLeft:'.5em' }}>Monitoreo de Módulos de Calidad </Text> 
                                        
                                        <Text style={{color:'#1747ca', fontSize: '2em', paddingLeft:'.5em'}}> {this.state.title} </Text>
                                   </Row>
                              </Col>

                              <Col span={1} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom:'1em'}}>
                                   { this.state.maximizar ? (<> <RightCircleOutlined className='iconoMaxMin' onClick={onExpandir} /> </>):(<> <LeftCircleOutlined className='iconoMaxMin' onClick={onExpandir}/> </>) }
                              </Col>
                         </Row>     
                         <Row gutter={[8,8]} type="flex" style={{padding:'.5em'}}>
                              { this.state.current === 0 ? (<>
                                   { this.props.dataEncustasSatisfaccion.length > 0 ? (<>

                                   <Col span={10} style={{backgroundColor: '#F2F2F2', paddingLeft: '1em'}} display='flex'>
                                        <Col xs={24} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={<p style={{textAlign:'left', fontSize:14, color: '#0e2c82'}}>Porcentajes de Encuestas por Servicio</p>} style={{backgroundColor:'#F2F2F2'}}>
                                                  <Row justify='space-around'>
                                                       <Col span={20}>
                                                            <Spin spinning={this.props.fetchingDataEncuestasSatisfaccion}>
                                                                 <Doughnut data={charDataDoughnut} options={optionsDataDoughnut} />
                                                            </Spin>
                                                            <br />
                                                            <Descriptions layout="horizontal" bordered= {true}>
                                                                 {dataDescriptionOneSatisfaccion.map(item => 
                                                                      <Descriptions.Item label={item.label} style={{fontSize:12}}>{item.data}</Descriptions.Item>
                                                                 )}
                                                            </Descriptions>
                                                       </Col>
                                                  </Row>
                                             </Card>
                                        </Col>
                                        <Col xs={24} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={<p style={{textAlign:'left', fontSize:14, color: '#0e2c82'}}>Resultado de Encuestas por Servicio</p>} style={{backgroundColor:'#F2F2F2'}}>
                                                  <Row justify='space-around'>
                                                       <Col span={20}>
                                                            { dataGraficasByServicio ? (<>
                                                                 { dataGraficasByServicio.length > 0 ? (
                                                                      <Col span={24}>
                                                                           <Spin spinning={this.props.fetchingDataGraficasByServicio}>
                                                                                <br />
                                                                                <Pie data={charDataPie} options={optionsDataPie} />
                                                                           </Spin>
                                                                           <br />
                                                                           <Descriptions layout="horizontal" bordered= {true}>
                                                                                {dataDescriptionTwoSatisfaccion.map(item => 
                                                                                     <Descriptions.Item label={item.label} style={{fontSize:12}}>{item.data}</Descriptions.Item>
                                                                                )}
                                                                           </Descriptions>
                                                                      </Col>
                                                                 ):(
                                                                      <Col span={24}>
                                                                           <Spin spinning={this.props.fetchingDataGraficasByServicio}>
                                                                                {/* <Alert
                                                                                     message="Encuestas por Servicio"
                                                                                     description="Es necesario seleccionar un servicio con encuestas para continuar."
                                                                                     type="info"
                                                                                     showIcon
                                                                                     style={{ borderRadius: '2em' }}
                                                                                /> */}
                                                                                <Result
                                                                                     status="info"
                                                                                     title='Encuestas por Servicio'
                                                                                     subTitle="Lo siento, es necesario seleccionar un servicio con encuestas para continuar."
                                                                                />
                                                                           </Spin>
                                                                      </Col>
                                                                 ) }
                                                            </>):false }
                                                       </Col>
                                                  </Row>
                                             </Card>
                                        </Col>
                                   </Col>

                                   <Col span={14} style={{backgroundColor: '#F2F2F2'}} display='flex'>
                                        <Col xs={23} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#F2F2F2'}} title={<p style={{textAlign:'left', color: '#0e2c82', fontSize:14}}>Evaluación de la Satisfacción en el Servicio</p>}>
                                                  <Spin spinning={this.props.fetchingDataEncuestasSatisfaccion}>
                                                       <div style={{ height: '60em' }}>
                                                            <HorizontalBar data={charDataBarSatisfaccion} options={optionsDataBarSatisfaccion}  />
                                                       </div>
                                                  </Spin>
                                             </Card>
                                        </Col>
                                   </Col>
                                   
                                   </>): (<Col span={24} style={{paddingLeft: '1em'}}>
                                        <Spin spinning={this.props.fetchingDataEncuestasSatisfaccion}>
                                             {/* <Result
                                                  status="info"
                                                  title='Información para Encuestas de Satisfacción'
                                                  subTitle="Lo siento, no existe información para mostrar con la combinación seleccionada."
                                             /> */}
                                             <Row justify='center' justify='space-around' align='middle' style={{height:'35em'}}>
                                                  <Col span={8} style={{paddingTop:'8em', paddingBottom:'8em', textAlign:'center'}}>
                                                  {/* <img src={imaSearch} /> */}
                                                       <ReactVivus
                                                            id="imaSearch"
                                                            option={{
                                                                 file: imaSearch,
                                                                 animTimingFunction: "EASE",
                                                                 type: "delayed",
                                                            }}
                                                       />

                                                       <Paragraph><Text strong> Información para Encuestas de Satisfacción </Text></Paragraph>
                                                       <Paragraph><Text type='secondary'> Lo siento, no existe información para mostrar con la combinación seleccionada. </Text></Paragraph>
                                                  </Col>
                                             </Row>
                                        </Spin>
                                   </Col>) } 

                              </>):this.state.current === 1 ? (<>
                                   { this.props.dataGraficasEvaluacionDesempeno.length > 0 ? (<>
                                   <Col span={24} style={{backgroundColor: '#F2F2F2'}} display='flex'>
                                        <Row justify='space-around'>
                                        <Col xs={23} display='flex'> <br/>
                                             <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#F2F2F2'}} title={<p style={{textAlign:'left', color: '#0e2c82'}}> Evaluación de Estándares de Desempeño </p>}>
                                                  <Spin spinning={this.props.fetchingDataGraficasEvaluacionDesempeno}>
                                                       <div style={{ height: '30em' }}>
                                                            <Bar data={charDataDesempeno} options={optionsDataDesempeno} />
                                                       </div>
                                                  </Spin>
                                             </Card>
                                        </Col>
                                        </Row>
                                   </Col>
                                   <Col span={24} style={{backgroundColor: '#F2F2F2'}} display='flex'>
                                        <Row justify='space-around'>
                                        <Col xs={11} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={<p style={{textAlign:'left', fontSize:14, color: '#0e2c82'}}>Estándares por Servicio </p>} style={{backgroundColor:'#F2F2F2'}}>
                                                  <Row justify='space-around'>
                                                       <Col span={23}>
                                                            <Spin spinning={this.props.fetchingDataGraficasEvaluacionDesempeno}>
                                                                 <Doughnut data={charDataDoughnutDesempeno} options={optionsDataDoughnutDesempeno} />
                                                            </Spin>
                                                            <br />
                                                            <Descriptions layout="horizontal" bordered= {true} >
                                                                 {dataDescriptionDesempeno.map(item => 
                                                                      <Descriptions.Item label={item.label} style={{fontSize:12}}>{item.data}</Descriptions.Item>
                                                                 )}
                                                            </Descriptions>
                                                       </Col>
                                                  </Row>
                                             </Card>
                                        </Col>
                                        <Col xs={11} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={<p style={{textAlign:'left', fontSize:14, color: '#0e2c82'}}>Estándares Evaluados Según su Tipo </p>} style={{backgroundColor:'#F2F2F2'}}>
                                                  <Row justify='space-around'>
                                                       <Col span={23}>
                                                            <Spin spinning={this.props.fetchingDataEstandaresByTipo}>
                                                                 <Pie data={charDataPieDesempeno} options={optionsDataPieDesempeno} />
                                                            </Spin>
                                                            <br />
                                                            <Descriptions layout="horizontal" bordered= {true} >
                                                                 {dataDescriptionDesempenoByTipo.map(item => 
                                                                      <Descriptions.Item label={item.label} style={{fontSize:12}}>{item.data}</Descriptions.Item>
                                                                 )}
                                                            </Descriptions>
                                                       </Col>
                                                  </Row>
                                             </Card>
                                        </Col>
                                        </Row>
                                   </Col>
                                   </>): (<Col span={24} style={{paddingLeft: '1em'}}>
                                        <Spin spinning={this.props.fetchingDataGraficasEvaluacionDesempeno}>
                                             {/* <Alert
                                                  message="Información para Evaluación de Desempeño"
                                                  description="No existen información para mostrar con la combinación seleccionada."
                                                  type="warning"
                                                  showIcon
                                                  style={{ borderRadius: '2em' }}
                                             /> */}
                                             {/* <Result
                                                  status="info"
                                                  title='Información para Evaluación de Desempeño'
                                                  subTitle="Lo siento, no existe información para mostrar con la combinación seleccionada."
                                             /> */}
                                             <Row justify='center' justify='space-around' align='middle' style={{height:'35em'}}>
                                                  <Col span={8} style={{paddingTop:'8em', paddingBottom:'8em', textAlign:'center'}}>
                                                  {/* <img src={imaSearch} /> */}
                                                       <ReactVivus
                                                            id="imaSearch"
                                                            option={{
                                                                 file: imaSearch,
                                                                 animTimingFunction: "EASE",
                                                                 type: "delayed",
                                                            }}
                                                       />

                                                       <Paragraph><Text strong> Información para Evaluación de Desempeño </Text></Paragraph>
                                                       <Paragraph><Text type='secondary'> Lo siento, no existe información para mostrar con la combinación seleccionada. </Text></Paragraph>
                                                  </Col>
                                             </Row>
                                        </Spin>
                                   </Col>) }                  
                              </>):this.state.current === 2 ? (<>
                                   { this.props.dataGraficasQuejas.length > 0 ? (<>
                                   <Col span={24} style={{backgroundColor: '#F2F2F2'}} display='flex'>
                                        <Row justify='space-around'>
                                             <Col xs={23} display='flex'> <br/>
                                                  <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#F2F2F2'}} title={<p style={{textAlign:'left', color: '#0e2c82'}}>  Planes Correctivos en el Servicio </p>}>
                                                       <Spin spinning={this.props.fetchingDataGraficasQuejasByServicio}>
                                                            <div style={{ height: '30em' }}>
                                                                 <Bar data={charDataBarQR} options={optionsDataBarQR} />
                                                            </div>
                                                       </Spin>
                                                  </Card>
                                             </Col>
                                        </Row>
                                   </Col>
                                   <Col span={24} style={{backgroundColor: '#F2F2F2'}} display='flex'>
                                        <Row justify='space-around'>
                                             <Col xs={11} display='flex'>
                                                  <Card className="cardCatalogos" bordered={false} title={<p style={{textAlign:'left', fontSize:14, color: '#0e2c82'}}> Porcentajes de Quejas y Reclamos por Servicio </p>} style={{backgroundColor:'#F2F2F2'}}>
                                                       <Row justify='space-around'>
                                                            <Col span={23}>
                                                                 <Spin spinning={this.props.fetchingDataGraficasQuejasByServicio}>
                                                                      <Doughnut data={charDataDoughnutQR} options={optionsDataDoughnutQR} />
                                                                 </Spin>
                                                                 <br />
                                                                 <Descriptions layout="horizontal" bordered= {true} >
                                                                      {dataDescriptionQR.map(item => 
                                                                           <Descriptions.Item label={item.label} style={{fontSize:12}}>{item.data}</Descriptions.Item>
                                                                      )}
                                                                 </Descriptions>
                                                            </Col>
                                                       </Row>
                                                  </Card>
                                             </Col>
                                             <Col xs={11} display='flex'>
                                                  <Card className="cardCatalogos" bordered={false} title={<p style={{textAlign:'left', fontSize:14, color: '#0e2c82'}}> Porcentajes de Planes Correctivos </p>} style={{backgroundColor:'#F2F2F2'}}>
                                                       <Row justify='space-around'>
                                                            <Col span={23}>
                                                                 <Spin spinning={this.props.fetchingDataGraficasQuejasByServicio}>
                                                                      <Pie data={charDataPiePlanes} options={optionsDataPiePlanes} />
                                                                 </Spin>
                                                                 <br />
                                                                 <Descriptions layout="horizontal" bordered= {true} >
                                                                      {dataDescriptionPlanesCorrectivos.map(item => 
                                                                           <Descriptions.Item label={item.label} style={{fontSize:12}}>{item.data}</Descriptions.Item>
                                                                      )}
                                                                 </Descriptions>
                                                            </Col>
                                                       </Row>
                                                  </Card>
                                             </Col>
                                        </Row>
                                   </Col> 
                                   </>): (
                                   <Col xs={24} display='flex'>
                                        <Spin spinning={this.props.fetchingDataGraficasQuejas}>
                                             {/* <Result
                                                  status="info"
                                                  title='Información para Seguimiento de Quejas y Reclamos'
                                                  subTitle="Lo siento, no existe información para mostrar con la combinación seleccionada."
                                             /> */}
                                             <Row justify='center' justify='space-around' align='middle' style={{height:'35em'}}>
                                                  <Col span={8} style={{paddingTop:'8em', paddingBottom:'8em', textAlign:'center'}}>
                                                  {/* <img src={imaSearch} /> */}
                                                       <ReactVivus
                                                            id="imaSearch"
                                                            option={{
                                                                 file: imaSearch,
                                                                 animTimingFunction: "EASE",
                                                                 type: "delayed",
                                                            }}
                                                       />

                                                       <Paragraph><Text strong> Información para Seguimiento de Quejas y Reclamos </Text></Paragraph>
                                                       <Paragraph><Text type='secondary'> Lo siento, no existe información para mostrar con la combinación seleccionada. </Text></Paragraph>
                                                  </Col>
                                             </Row>
                                        </Spin>
                                   </Col>
                                   ) }  
                              </>):false }
                         </Row>
                    </Col>
               </Row>


          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.listaClasificacionesGerenciaActivas,
          fetchingListaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesGerenciaActivas,
          listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
          fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
          dataEncustasSatisfaccion: state.MonitorIntegralReducer.dataEncustasSatisfaccion,
          dataGraficasByServicio: state.MonitorIntegralReducer.dataGraficasByServicio,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

          fetchingDataGraficasByServicio: state.MonitorIntegralReducer.fetchingDataGraficasByServicio,
          fetchingDataEncuestasSatisfaccion: state.MonitorIntegralReducer.fetchingDataEncuestasSatisfaccion,

          fetchingDataGraficasQuejas: state.MonitorIntegralReducer.fetchingDataGraficasQuejas,
          dataGraficasQuejas: state.MonitorIntegralReducer.dataGraficasQuejas,
          listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,
          fetchingListaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.fetchingListaServiciosByGrupoServicio,

          fetchingDataGraficasEvaluacionDesempeno: state.MonitorIntegralReducer.fetchingDataGraficasEvaluacionDesempeno,
          dataGraficasEvaluacionDesempeno: state.MonitorIntegralReducer.dataGraficasEvaluacionDesempeno,

          listaGerenciasByServicio: state.GestionDeCalidadReducer.listaGerenciasByServicio,

          listaServiciosByClasificacionGerencia: state.MonitorIntegralReducer.listaServiciosByClasificacionGerencia,
          fetchingListaServiciosByClasificacionGerencia: state.MonitorIntegralReducer.fetchingListaServiciosByClasificacionGerencia,
          dataGraficasQuejasByServicio: state.MonitorIntegralReducer.dataGraficasQuejasByServicio,
          fetchingDataGraficasQuejasByServicio: state.MonitorIntegralReducer.fetchingDataGraficasQuejasByServicio,

          fetchingDataGraficaPromedioSatisfaccion: state.MonitorIntegralReducer.fetchingDataGraficaPromedioSatisfaccion,
          dataGraficaPromedioSatisfaccion: state.MonitorIntegralReducer.dataGraficaPromedioSatisfaccion,

          dataEstandaresByTipo: state.MonitorIntegralReducer.dataEstandaresByTipo,
          fetchingDataEstandaresByTipo: state.MonitorIntegralReducer.fetchingDataEstandaresByTipo,

          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },
          onGetListaGerenciasActivas: () => {
               dispatch({ type: 'LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST' });
          },
          onGetCentroDeCostos: () => {
               dispatch({ type: "LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST"});
          },
          onGetServicios: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetServiciosLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_SUCCESS", listaServiciosActivosF: [] });
          },
          onGetServiciosByGrupoServicio: (idGrupoSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer });
          },
          onGetDataSatisfaccion: (idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio) => {
               dispatch({ type: 'DATA_GRAFICAS_ENCUESTAS_SATISFACCION_REQUEST', idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio });
          },
          onGetDataSatisfaccionServicio: (idMes, anio, idServicio) => {
               dispatch({ type: 'DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_REQUEST', idMes, anio, idServicio });
          },
          onCleanDataSatisfaccionServicio: () => {
               dispatch({ type: 'DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_SUCCESS', dataGraficasByServicio: [] });
          },
          onGetDataQuejas: (idGrupoSer, idServicio, idClasificacionGerencia) => {
               dispatch({ type: 'DATA_GRAFICAS_QUEJAS_REQUEST', idGrupoSer, idServicio, idClasificacionGerencia });
          },
          onGetDataQuejasByServicio: (idGrupoSer, idServicio, idClasificacionGerencia) => {
               dispatch({ type: 'DATA_GRAFICAS_QUEJAS_SERVICIO_REQUEST', idGrupoSer, idServicio, idClasificacionGerencia });
          },
          onGetDataDesempeno: (idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia) => {
               dispatch({ type: 'DATA_GRAFICAS_EVALUACION_DESEMPENO_REQUEST', idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia });
          },
          onGetGerenciasByServicio: (idServicio) => {
               dispatch({ type: 'LISTA_GERENCIAS_BY_SERVICIO_REQUEST', idServicio })
          },
          onGetListaServicioByGerencia: (idClasificacionGerencia) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_REQUEST', idClasificacionGerencia });
          },
          onGetDataPromedioSatisfaccion: (idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio) => {
               dispatch({ type: 'DATA_GRAFICA_PROMEDIO_SATISFACCION_REQUEST', idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio });
          },
          onGetDataEstandaresByTipo: (idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia) => {
               dispatch({ type: 'DATA_ESTANDARES_BY_TIPO_REQUEST', idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia});
          },
          onGetInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(MonitoreoModulosCalidadContainer);