import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal,DatePicker, InputNumber, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaCategoriaFallaDeCalidad extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevaCategoriaFallaDeCalidad, showModalNuevaCategoriaFallaDeCalidad, onShowModalNuevaCategoriaFallaDeCalidad,
             onNuevaCategoriaFallaDeCalidad, page, limpiarFormulario, listaInfoProyecto } = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            onNuevaCategoriaFallaDeCalidad(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario==='crearCategoriaFalla') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;

        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevaCategoriaFallaDeCalidad();
            this.formRef.current.resetFields();
        }
        return (
            <Modal
                visible={showModalNuevaCategoriaFallaDeCalidad}
                title='Nueva Categoria de Fallas de Calidad'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevaCategoriaFallaDeCalidad}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Categoría de fallas de calidad"
                            name="fallaCalidad"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            pattern="^^[ñíóáéú a-zA-Z0-9 ]+$"
                            title="Categoría de fallas debe tener letras." 
                            maxLength={80} placeholder='Categoría de fallas de calidad' />
                        </Form.Item>
                        <Form.Item
                            label="Descripción"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            maxLength={200} placeholder='Descripción' />
                        </Form.Item>
                        <Form.Item
                            label="Valor porcentaje"
                            name="valorPorcentaje"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <InputNumber min={1} max={100} placeholder='Valor porcentaje'
                            title="Número debe ser entero" pattern="^[0-9]+$" />
                        </Form.Item>
                        <Form.Item
                            label="Fechas vigencias valor porcentaje"
                            name="rangoVigencia"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                            <RangePicker
                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                disabledDate={disabledRange}
                                defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}  
                                disabled={[false, true]}                          />
                        </Form.Item>
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Categoría de Fallas de Calidad</Button>
                        </Col>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaCategoriaFallaDeCalidad: state.MecanismosDePagosReducer.fetchingNuevaCategoriaFallaDeCalidad,
        showModalNuevaCategoriaFallaDeCalidad: state.MecanismosDePagosReducer.showModalNuevaCategoriaFallaDeCalidad,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaCategoriaFallaDeCalidad: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_CATEGORIA_FALLA_CALIDAD', showModalNuevaCategoriaFallaDeCalidad: false });
        },
        onNuevaCategoriaFallaDeCalidad: (formulario, page) => {
            dispatch( { type: 'NUEVA_CATEGORIA_FALLA_CALIDAD_REQUEST', formulario: formulario, page:page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaCategoriaFallaDeCalidad);