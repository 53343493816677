import React, { Component } from 'react';
import { Form, Button, Modal, Col, Spin, Row, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalEditarUbicacion extends Component {
    formRef = React.createRef();
    render() { 
        const {
            onEditarUbicacion, onShowModalEditarUbicacion, showModalEditarUbicaciones, fetchingEditarUbicaciones,
            ubicacionSeleccionado, fetchingListaAreasFuncionalesActivos, listaAreasFuncionalesActivos,
            fetchingListaUnidadesFuncioActPorArea, listaUnidadesFuncioActPorArea,
            fetchingListaInteriorActivosPorUnidad, listaInteriorActivosPorUnidad,
            fetchingListaSubInteriorActivosPorInterior, listaSubInteriorActivosPorInterior, 
            onListaUnidadesFuncionalesPorArea, onListaInterioresFuncionalesPorUnidad, onListaSubInterioresFuncionalesPorInterior,
            onLimpiarInterioresFuncionales, onLimpiarSubInterioresFuncionales, limpiarFormulario,
            onLimpiarUnidadesFuncionales,
        } = this.props;

        console.log('ubicacionSeleccionado');
        console.log(ubicacionSeleccionado);
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarUbicacion(formulario, ubicacionSeleccionado, this.props.page );
        };

        if (limpiarFormulario && limpiarFormulario === 'editarUbicacion') {
            onLimpiarUnidadesFuncionales();
            onLimpiarInterioresFuncionales();
            onLimpiarSubInterioresFuncionales();
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarUbicacion();
            onLimpiarUnidadesFuncionales();
            onLimpiarInterioresFuncionales();
            onLimpiarSubInterioresFuncionales();
            this.formRef.current.resetFields();
        }
        const onSelect = (value, tipo) => {
            if(tipo === 'idAreaFuncional'){
                onListaUnidadesFuncionalesPorArea(value);
                onLimpiarInterioresFuncionales();
                onLimpiarSubInterioresFuncionales();
                this.formRef.current.setFieldsValue({'unidadFuncional': null, 'interiorFuncional': null, 'nombreSubInteriorFuncional': null});
            }
            else if(tipo === 'idUnidadFuncional'){
                onListaInterioresFuncionalesPorUnidad(value);
                onLimpiarSubInterioresFuncionales();
                this.formRef.current.setFieldsValue({'interiorFuncional': null, 'nombreSubInteriorFuncional': null});
            }
            else if(tipo === 'idInteriorFuncional'){
                onListaSubInterioresFuncionalesPorInterior(value);
                onLimpiarSubInterioresFuncionales();
                this.formRef.current.setFieldsValue({'nombreSubInteriorFuncional': null});
            }
        }

        return ( 
            <Modal
            visible={showModalEditarUbicaciones}
            title='Editar Ubicacion'
            onCancel={handleCancel}
            footer={false}
            >
                {ubicacionSeleccionado?(
                <Spin spinning={fetchingEditarUbicaciones}>
                    <Form {...layout}
                    name="formulario"
                    initialValues={ubicacionSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'></Row>
                            <Col span={24}>
                                <Form.Item
                                    label="Area funcional"
                                    name="areaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el area funcional"
                                        loading={fetchingListaAreasFuncionalesActivos}
                                        onChange={(value) => onSelect(value, 'idAreaFuncional')}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{ option.areaFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                            <Col span={24}>
                                <Form.Item
                                    label="Unidad funcional"
                                    name="unidadFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la unidad funcional"
                                        loading={fetchingListaUnidadesFuncioActPorArea}
                                        onChange={(value) => onSelect(value, 'idUnidadFuncional')}
                                    >
                                        {listaUnidadesFuncioActPorArea.map(option => <Option key={option.idUnidadFuncional}>{ option.unidadFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                            <Col span={24}>
                                <Form.Item
                                    label="Interior funcional"
                                    name="interiorFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona interior funcional"
                                        loading={fetchingListaInteriorActivosPorUnidad}
                                        onChange={(value) => onSelect(value, 'idInteriorFuncional')}
                                    >
                                        {listaInteriorActivosPorUnidad.map(option => <Option key={option.idInteriorFuncional}>{ option.interiorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                            <Col span={24}>
                                <Form.Item
                                    label="SubInterior funcional"
                                    name="nombreSubInteriorFuncional"
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona subinterior funcional"
                                        loading={fetchingListaSubInteriorActivosPorInterior}
                                    >
                                        {listaSubInteriorActivosPorInterior.map(option => <Option key={option.idSubInteriorFuncional}>{ option.subInteriorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Ubicacion</Button>
                            </Col>
                        <Row/>
                    </Form>
                </Spin>
                ):false}
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditarUbicaciones: state.CatalogosGeneralesReducer.showModalEditarUbicaciones,
        fetchingEditarUbicaciones: state.CatalogosGeneralesReducer.fetchingEditarUbicaciones,
        ubicacionSeleccionado: state.CatalogosGeneralesReducer.ubicacionSeleccionado,
        fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        fetchingListaUnidadesFuncioActPorArea: state.CatalogosGeneralesReducer.fetchingListaUnidadesFuncioActPorArea, 
        listaUnidadesFuncioActPorArea: state.CatalogosGeneralesReducer.listaUnidadesFuncioActPorArea,
        fetchingListaInteriorActivosPorUnidad: state.CatalogosGeneralesReducer.fetchingListaInteriorActivosPorUnidad, 
        listaInteriorActivosPorUnidad: state.CatalogosGeneralesReducer.listaInteriorActivosPorUnidad,
        fetchingListaSubInteriorActivosPorInterior: state.CatalogosGeneralesReducer.fetchingListaSubInteriorActivosPorInterior, 
        listaSubInteriorActivosPorInterior: state.CatalogosGeneralesReducer.listaSubInteriorActivosPorInterior,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarUbicacion: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_UBICACION', showModalEditarUbicaciones: false, ubicacionSeleccionado: null, page: null})
        },
        onEditarUbicacion: ( formulario, ubicacionSeleccionado, page, listaAreasFuncionalesActivos, listaUnidadesFuncionalesActivos, listaInteriorActivos, listaSubInteriorActivos ) => {
            dispatch({ type: "EDITAR_UBICACIONES_REQUEST" , formulario: formulario, ubicacionSeleccionado: ubicacionSeleccionado, page: page, listaAreasFuncionalesActivos: listaAreasFuncionalesActivos, listaUnidadesFuncionalesActivos: listaUnidadesFuncionalesActivos, listaInteriorActivos: listaInteriorActivos, listaSubInteriorActivos: listaSubInteriorActivos });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onListaUnidadesFuncionalesPorArea: (idAreaFuncional) => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST', idAreaFuncional });
        },
        onListaInterioresFuncionalesPorUnidad: (idUnidadFuncional) => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST', idUnidadFuncional });
        },
        onListaSubInterioresFuncionalesPorInterior: (idInteriorFuncional) => {
            dispatch({ type: 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST', idInteriorFuncional });
        },
        onLimpiarInterioresFuncionales: () => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_POR_UNIDAD_SUCCESS', listaInteriorActivosPorUnidad: [] });
        },
        onLimpiarSubInterioresFuncionales: () => {
            dispatch({ type: 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_SUCCESS', listaSubInteriorActivosPorInterior: [] });
        },
        onLimpiarUnidadesFuncionales: () => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_POR_AREA_SUCCESS', listaUnidadesFuncioActPorArea: [] });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarUbicacion);