import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Spin, Tooltip } from 'antd';
import { EditOutlined, ExclamationCircleOutlined, LockOutlined, UnlockOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoAreaFuncional from './ModalNuevoAreaFuncional';
import ModalEditarAreaFuncional from './ModalEditarAreaFuncional';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeAreasFuncionales extends Component {
    componentDidMount(){
        this.props.onRequestAreasFuncionales(1, '');
    }
    state={
        page: 1,
        busqueda: '',
    }

    render() { 
        const {
            fetchingListaAreasFuncionalesBusqueda,
            listaAreasFuncionalesBusqueda,
            onRequestAreasFuncionales,
            onModalEditarAreaFuncional,
            onEstadoAreaFuncional, fetchingAreasBloquear,
            onModalNuevoAreaFuncional,
            listaAreasFuncionalesBusquedaCantidad, onBloquearAreas,
        } = this.props;
        console.log('listaAreasFuncionalesBusqueda');
        console.log(listaAreasFuncionalesBusqueda);
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };
        const onBuscarAreasFuncionales = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onRequestAreasFuncionales(1, busqueda);
        }

        const columns = [{
                title: 'Clave área',
                dataIndex: 'claveArea',
                key: 'claveArea',
            }, {
                title: 'Área funcional',
                dataIndex: 'areaFuncional',
                key: 'areaFuncional',
            }, {
                title: 'Código área',
                dataIndex: 'codigoArea',
                key: 'codigoArea',
            }, {
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                align: 'right'
            }, {
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
            }, {
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={record.convenio ? !permisosUsuario.areaFuncionalActivoInactivo : true} onChange={() => handleDesactivar(record)} />
                ),
            }, {
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={record.visible ? (record.convenio ? !permisosUsuario.editarAreaFuncional : !permisosUsuario.editarPorConvenio) : true} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];


        const onEditar = (key) => {
            console.log(key);
            key.page = this.state.page;
            onModalEditarAreaFuncional(key, this.state.page, key.coordenadas, this.state.busqueda, key.convenio);
        };

        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la Área Funcional "'+ key.codigoArea +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoAreaFuncional(key.idAreaFuncional, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } 
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onRequestAreasFuncionales(pagination.current, this.state.busqueda);
        };

        const onNuevaAreaFuncional = (bloqueado) => {
            onModalNuevoAreaFuncional(this.state.page, this.state.busqueda, bloqueado);
        };
        
        const bloquearAreas = (bloqueado) => {
            if(bloqueado){
                confirm({
                    title: '¿Estás seguro que deseas bloquear las áreas funcionales?',
                    content: 'Ésta acción no podrá revertirse.',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onBloquearAreas(bloqueado);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
            else{
                confirm({
                    title: '¿Estás seguro que deseas desbloquear las áreas funcionales?',
                    content: 'Si se agregan o editan las áreas funcionales, serán "Por Convenio".',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onBloquearAreas(bloqueado);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
        }

        return (  
            <Spin spinning={fetchingListaAreasFuncionalesBusqueda || fetchingAreasBloquear}>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={21}>
                        <Search className='buscarModulos' placeholder="Buscar área funcional" onSearch={value => onBuscarAreasFuncionales(value)} style={{ width: '100%' }}/> 
                    </Col>
                    {/* <Col span={6}>
                        <Button onClick={() => onNuevaAreaFuncional(listaAreasFuncionalesBusqueda[0] ? listaAreasFuncionalesBusqueda[0].convenio : true)} disabled={listaAreasFuncionalesBusqueda[0] ? (listaAreasFuncionalesBusqueda[0].visible ? (listaAreasFuncionalesBusqueda[0].convenio ? !permisosUsuario.crearAreaFuncional : !permisosUsuario.crearPorConvenio) : true) : !permisosUsuario.crearAreaFuncional} className='nuevoUsuario'>Nueva Área Funcional</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Área Funcional' placement="topRight">
                            <Button className="iconTer" shape="circle"  onClick={() => onNuevaAreaFuncional(listaAreasFuncionalesBusqueda[0] ? listaAreasFuncionalesBusqueda[0].convenio : true)} disabled={listaAreasFuncionalesBusqueda[0] ? (listaAreasFuncionalesBusqueda[0].visible ? (listaAreasFuncionalesBusqueda[0].convenio ? !permisosUsuario.crearAreaFuncional : !permisosUsuario.crearPorConvenio) : true) : !permisosUsuario.crearAreaFuncional} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip placement="topRight" title={listaAreasFuncionalesBusqueda[0] ? (listaAreasFuncionalesBusqueda[0].visible ? 'Bloquear áreas funcionales' : 'Desbloquear áreas funcionales') : 'Sin áreas funcionales'}>
                            <Button onClick={()=>bloquearAreas(listaAreasFuncionalesBusqueda[0].visible)} disabled={listaAreasFuncionalesBusqueda[0] ? (listaAreasFuncionalesBusqueda[0].visible ? !permisosUsuario.bloquearAreasFuncionales : !permisosUsuario.desbloquearAreasFuncionales) : !permisosUsuario.bloquearAreasFuncionales} type="primary" danger shape='circle'>{listaAreasFuncionalesBusqueda[0] ? (listaAreasFuncionalesBusqueda[0].visible ? <LockOutlined /> : <UnlockOutlined />) : <ExclamationCircleOutlined />}</Button>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="areaFuncional" 
                            columns={columns}
                            dataSource={listaAreasFuncionalesBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaAreasFuncionalesBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoAreaFuncional/> 
                <ModalEditarAreaFuncional/> 
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaAreasFuncionalesBusqueda: state.CatalogosGeneralesReducer.listaAreasFuncionalesBusqueda,
        fetchingListaAreasFuncionalesBusqueda: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesBusqueda,
        listaAreasFuncionalesBusquedaCantidad: state.CatalogosGeneralesReducer.listaAreasFuncionalesBusquedaCantidad,
        fetchingAreasBloquear: state.CatalogosGeneralesReducer.fetchingAreasBloquear,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        onRequestAreasFuncionales: (page, busqueda) => {
            dispatch({ type: "AREASFUNCIONALES_BUSQUEDA_REQUEST", page, busqueda });
        },
        onModalNuevoAreaFuncional: (page, busqueda, bloqueado) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_AREASFUNCIONALES", showModalNuevoAreasFuncionales: true, page, cerrarModal: true, busqueda, bloqueado });
        },
        onModalEditarAreaFuncional: (AreaFuncionalSeleccionado, page, editedGeometry, busqueda, bloqueado) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_AREASFUNCIONALES", showModalEditarAreaFuncional: true, AreaFuncionalSeleccionado, page, editedGeometry, busqueda, bloqueado })
        },
        onBloquearAreas: (bloqueado) => {
            dispatch({ type: "AREAS_BLOQUEAR_REQUEST", bloqueado })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeAreasFuncionales);