import React from 'react';
import { Form, Spin, Row, Modal, Col, Input, Result, Table, Tooltip, Button, Switch, Select } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalAlta from './ModalNuevoObjetivo';
import ModalEditar from './ModalEditarObjetivo';

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

class CatalogoDeObjetivosContainer extends React.Component {
     formRef = React.createRef();

     state={  page: 1, busqueda: {gerencia: '', centro: ''} }

     componentDidMount () { this.props.onGetListaObjetivosBusqueda(1, {gerencia: '', centro: ''} ); this.props.onGetCentroDeCostos(); this.props.onGetListaGerenciasActivas(); }
 
     render() {
          const { showModalAlta, showModalEditar, onGetListaObjetivosBusqueda, listaObjetivosBusqueda, listaObjetivosBusquedaCantidad, onChangeStatus, listaCentroDeCostosActivos, fetchingListaCentroDeCostosActivos, listaClasificacionesGerenciaActivas, fetchingListaClasificacionesGerenciaActivas, onUpdateObject } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onShowModalAlta = () => { 
               showModalAlta(this.state.page, this.state.busqueda);
          };

          const scroll = {"x": 2000 | true, "y": 1000};

          const columnas = [
               { title: 'Gerencia', dataIndex: 'gerencia', key: 'gerencia', align: 'center', },
               { title: 'Centro de costos', dataIndex: 'centroCosto', key: 'centroCosto', align: 'center', },
               { title: 'Tema', dataIndex: 'tema', key: 'tema', align: 'center', },
               { title: 'Nombre del objetivo', dataIndex: 'objetivo', key: 'objetivo', align: 'center', },
               { title: 'Estándar relacionado', dataIndex: 'estandar', key: 'estandar', align: 'center', width: '45%'},
               { title: 'Activo', dataIndex: 'activo', key: 'activo',  width: '10%', align: 'center', width: '5%',
                    render: (text, record) => (
                        <Switch checked={text} disabled={!permisosUsuario.objetivoActivoinactivo} onChange = {() => handleDesactivar(record)} />
                    ),
                },
               { title: 'Editar', key:'editar', align: 'center', width: '5%',
               render: (record) => (
                    <EditOutlined disabled={!permisosUsuario.editarObjetivo} className='editarIcono' onClick={() => onEditar(record)} />
               )},
          ];

          const handleDesactivar = (key) => {
               let page = this.state.page;
               let busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del objetivo: "' + key.objetivo + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onChangeStatus(key.idObjetivo, page, busqueda);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          };

          const onEditar = (record) => {
               console.log('objetivo', record);
               let serviciosMap = [];
               record.temaEditar = record.idTema + ' - ' + record.tema;
               record.centroCostoEditar = record.idCentroCosto + ' - ' + record.centroCosto;

               record.servicioEditar = [];
               for(let i in record.objetivosEstandaresByIdObjetivo){
                    record.servicioEditar.push(record.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio.idServicio + ' - ' + record.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio.nomenclatura + ': ' + record.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio.nombre);

                    let concatEstandar = 'estandar-' + parseInt(record.objetivosEstandaresByIdObjetivo[i].idServicio);
                    record[concatEstandar] = record.objetivosEstandaresByIdObjetivo[i].estandarByIdEstandar.idEstandarDesempeno + ' - ' + record.objetivosEstandaresByIdObjetivo[i].estandarByIdEstandar.referencia + ': ' +  record.objetivosEstandaresByIdObjetivo[i].estandarByIdEstandar.descripcion;

                    if(record.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio){
                         serviciosMap.push(record.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio);
                    }
               }
               onUpdateObject(serviciosMap);
               showModalEditar(record, this.state.page, this.state.busqueda)
          };

          const onBuscarGerencia = (value) => {
               if(value){
                    let busqueda = { gerencia: value, centro: this.state.busqueda.centro }
                    onGetListaObjetivosBusqueda(this.state.page, busqueda);
                    this.setState({ busqueda: busqueda });
               } else {
                    let busqueda = { gerencia: '', centro: this.state.busqueda.centro }
                    onGetListaObjetivosBusqueda(this.state.page, busqueda);
                    this.setState({ busqueda: busqueda });
               }
          };

          const onBuscarCentro = (value) => {
               if(value){
                    let busqueda = { gerencia: this.state.busqueda.gerencia, centro: value }
                    onGetListaObjetivosBusqueda(this.state.page, busqueda);
                    this.setState({ busqueda: busqueda });
               } else {
                    let busqueda = { gerencia: this.state.busqueda.gerencia, centro: '' }
                    onGetListaObjetivosBusqueda(this.state.page, busqueda);
                    this.setState({ busqueda: busqueda });
               }
          };

          const handleTableChange = (pagination)=>{
               this.setState({ page: pagination.current })
          };

          return (<>
               { permisosUsuario ? (<>
                    <Spin spinning={false}>
                    <Form
                         name="formulario"
                         ref={this.formRef}
                    >
                         <Row justify='space-around' gutter={[8,8]}>
                              <Col span={11}>
                                    <Select allowClear style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona la gerencia" onChange={value => onBuscarGerencia(value)} loading={fetchingListaClasificacionesGerenciaActivas}>
                                        {listaClasificacionesGerenciaActivas.map(option => 
                                             <Option key={option.nombreGerencia} > {option.nombreGerencia} </Option>
                                        )}
                                   </Select>
                              </Col>

                              <Col span={11}>
                                   <Select allowClear style={{ width: '100%' }} placeholder="Selecciona el centro de costo" loading={fetchingListaCentroDeCostosActivos} onChange={value => onBuscarCentro(value)} showSearch>
                                        {listaCentroDeCostosActivos.map(option => 
                                        <Option key={option.centroCosto}>
                                             <Tooltip title={option.centroCosto}>
                                                  {option.centroCosto}
                                             </Tooltip>
                                        </Option>
                                        )}
                                   </Select>
                              </Col>

                              {/* <Col span={8}>
                                   <Tooltip title='Ver detalle'>
                                        <Button className="nuevoUsuario" disabled={!permisosUsuario.crearObjetivo} onClick={onShowModalAlta}>
                                             Nuevo Objetivo
                                        </Button>
                                   </Tooltip>
                              </Col> */}

                              <Col span={1}>
                                   <Tooltip title='Nuevo Objetivo' placement="topRight">
                                        <Button className="iconTer" shape="circle" disabled={!permisosUsuario.crearObjetivo} onClick={onShowModalAlta} icon={<PlusOutlined />}/>
                                   </Tooltip>
                              </Col>

                              <Col span={24}>
                                   <Table
                                        size="small"
                                        rowKey="idObjetivo"
                                        columns={columnas}
                                        dataSource={listaObjetivosBusqueda}
                                        loading={this.props.fetchingListaObjetivosBusqueda}
                                        locale={{ emptyText: 'Sin datos' }}
                                        scroll={scroll}
                                        onChange={handleTableChange}
                                        pagination={{ total: listaObjetivosBusquedaCantidad, simple: true, current: this.state.page }}
                                   />
                              </Col>

                         </Row>
                         <ModalAlta />
                         <ModalEditar />
                    </Form>
               </Spin>
               </>): (<>
                    <Row justify='center'>
                         <Result
                              status="403"
                              title="Acceso denegado"
                              subTitle="Lo siento, no tiene permiso autorizado para acceder."
                         />
                    </Row>
               </>) }
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          listaObjetivosBusqueda: state.EvaluacionDeLaGestionReducer.listaObjetivosBusqueda,
          listaObjetivosBusquedaCantidad: state.EvaluacionDeLaGestionReducer.listaObjetivosBusquedaCantidad,
          fetchingListaObjetivosBusqueda: state.EvaluacionDeLaGestionReducer.fetchingListaObjetivosBusqueda,
          listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
          fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,

          listaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.listaClasificacionesGerenciaActivas,
          fetchingListaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesGerenciaActivas,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          showModalAlta: (page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_OBJETIVO', showModalNuevoObjetivo: true, page, busqueda });
          },
          showModalEditar: (objetivoSeleccionado, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_OBJETIVO', showModalEditarObjetivo: true, objetivoSeleccionado, page, busqueda});
          },
          onGetListaObjetivosBusqueda: (page, busqueda) => {
               dispatch({ type: 'LISTA_OBJETIVOS_BUSQUEDA_REQUEST', page, busqueda });
          },
          onGetCentroDeCostos: () => {
               dispatch({ type: "LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST"});
          },
          onChangeStatus: (idObjetivo, page, busqueda) => {
               dispatch({ type: 'ESTADO_OBJETIVO_REQUEST', idObjetivo, page, busqueda });
          },
          onGetListaGerenciasActivas: () => {
               dispatch({ type: 'LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST' });
          },

          onUpdateObject: (serviciosMapping) => {
               dispatch({ type: 'UPDATE_OBJETCT_SELECTED', serviciosMapping });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeObjetivosContainer);