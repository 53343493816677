import React, { Component } from 'react';
import {  Modal, Row, Col, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
const confirm = Modal.confirm;
class ModalArchivoExpediente extends Component {
    render() {
        const { showArchivosExpediente, onShowModalArchivoExpediente, fetchingArchivosExpediente,
            expedienteSeleccionado, archivosExpediente, onBorrarArchivo,
        } = this.props;

        const handleCancel = () => {
            onShowModalArchivoExpediente()
        }
        const borrarArchivo = (archivo) => {
            console.log('archivo');
            console.log(archivo);
            let pathArchivo = expedienteSeleccionado.rutaCarpeta + '/' + archivo;
            confirm({
                title: '¿Estás seguro que deseas eliminar el archivo?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onBorrarArchivo(pathArchivo, expedienteSeleccionado.nombreCarpeta);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
          
        return (
            <Modal
            visible={showArchivosExpediente}
            title='Archivos Expediente'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                {expedienteSeleccionado ? (
                <Row justify='space-around'>
                    <Col span={24}>
                        {expedienteSeleccionado.expedientePersonal ? (
                            <List
                                header={
                                    <div className='headerLista'>Archivos en expediente</div>
                                }
                                className="listaCatalogos"
                                bordered
                                loading={fetchingArchivosExpediente}
                                dataSource={archivosExpediente}
                                renderItem={item => (
                               <List.Item className='listaHover' className='listaHover'>
                                    <a className='archivosExpediente' target='_blank' href={expedienteSeleccionado.rutaCarpeta  + '/' + item}>{expedienteSeleccionado.rutaCarpeta + '/' + item}</a>
                                </List.Item>
                                )}
                            /> 
                        ) : (
                            <List
                                header={
                                    <div className='headerLista'>Archivos en expediente</div>
                                }
                                className="listaCatalogos"
                                bordered
                                loading={fetchingArchivosExpediente}
                                dataSource={archivosExpediente}
                                renderItem={item => (
                               <List.Item className='listaHover' className='listaHover'
                                    actions={[<a onClick={(e) => { e.stopPropagation(); borrarArchivo(item); } } className="abrirCatalogo editarIcono"><DeleteOutlined /></a>]}
                                >
                                    <a className='archivosExpediente' target='_blank' href={expedienteSeleccionado.rutaCarpeta  + '/' + item}>{expedienteSeleccionado.rutaCarpeta + '/' + item}</a>
                                </List.Item>
                                )}
                            /> 
                        )}
                        
                    </Col>
                </Row>
                ):false}
                
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        showArchivosExpediente: state.InformacionOperativaReducer.showArchivosExpediente,
        expedienteSeleccionado: state.InformacionOperativaReducer.expedienteSeleccionado,
        archivosExpediente: state.InformacionOperativaReducer.archivosExpediente,
        fetchingArchivosExpediente: state.InformacionOperativaReducer.fetchingArchivosExpediente,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalArchivoExpediente: () => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_EXPEDIENTE', showArchivosExpediente: false, expedienteSeleccionado: null, page: null, archivosExpediente: [] })
        },
        onBorrarArchivo: (pathArchivo, nombreCarpeta) => {
            dispatch({ type: 'ELIMIAR_ARCHIVO_EXPEDIENTE_REQUEST', pathArchivo: pathArchivo, nombreCarpeta: nombreCarpeta })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalArchivoExpediente);