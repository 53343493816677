import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalleCalculosFacturacion extends React.Component {
    state = {
        page: 1,
     }
    render() {
        const {showModalDetalleCalculosFacturacion, listaDetalleCalculosFacturacion, fetchingListaDetalleCalculosFacturacion, servicioPublicoSeleccionadoCCDFacturacion, listaDetalleCalculosFacturacionCantidad,
            onShowModalDetalle, onGetDetalleCalculosFacturacion, onLimpiarDetalle} = this.props;

        const columns = [{
            title: 'Servicio público',
            dataIndex: 'servicioPublico',
            key: 'servicioPublico',
        }, {
            title: 'Folio',
            dataIndex: 'folio',
            key: 'folio',
            width: '12%',
        },{
            title: 'Factura',
            dataIndex: 'factura',
            key: 'factura',
            align: 'center',
        },{
            title: 'Mes',
            dataIndex: 'mesNombre',
            key: 'mesNombre',
            align: 'center',
        }, {
            title: 'Consumo cliente',
            dataIndex: 'consumoPyrs',
            key: 'consumoPyrs',
            align: 'right',
        }, {
            title: 'Consumo desarrollador',
            dataIndex: 'consumoDesarrollador',
            key: 'consumoDesarrollador',
            align: 'right',
        }, {
            title: 'Precio unitario',
            dataIndex: 'precioUnitario',
            key: 'precioUnitario',
            align: 'right',
        }, {
            title: 'Cobro por consumo cliente',
            dataIndex: 'cobroConsumoPyrs',
            key: 'cobroConsumoPyrs',
            align: 'right',
        },{
            title: 'Cobro por consumo desarrollador',
            dataIndex: 'cobroConsumoDesarrollador',
            key: 'cobroConsumoDesarrollador',
            align: 'right',
        }];

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetDetalleCalculosFacturacion(servicioPublicoSeleccionadoCCDFacturacion, pagination.current);
        }

        const handleCancel = () => {
            onShowModalDetalle();
            onLimpiarDetalle();
        }
        return (
            <div>
                {servicioPublicoSeleccionadoCCDFacturacion ? (
                    <Modal
                        visible={showModalDetalleCalculosFacturacion}
                        title={`Cálculos de Consumo Diferenciado por Facturación`}
                        onCancel={handleCancel}
                        footer={false}
                        width='70%'
                    >
                        <Row justify='space-between' >
                            <Col span={24}>
                                <br />
                                <Table
                                    size="small"
                                    rowKey="idConsumoFacturacion"
                                    columns={columns}
                                    dataSource={listaDetalleCalculosFacturacion}
                                    loading={fetchingListaDetalleCalculosFacturacion}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: listaDetalleCalculosFacturacionCantidad, simple: true, current: this.state.page }}
                                />
                            </Col>
                        </Row>
                        

                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleCalculosFacturacion: state.ServiciosPublicosReducer.showModalDetalleCalculosFacturacion,
        servicioPublicoSeleccionadoCCDFacturacion: state.ServiciosPublicosReducer.servicioPublicoSeleccionadoCCDFacturacion,
        listaDetalleCalculosFacturacion: state.ServiciosPublicosReducer.listaDetalleCalculosFacturacion,
        fetchingListaDetalleCalculosFacturacion: state.ServiciosPublicosReducer.fetchingListaDetalleCalculosFacturacion,
        listaDetalleCalculosFacturacionCantidad: state.ServiciosPublicosReducer.listaDetalleCalculosFacturacionCantidad,
    }
}



const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle : () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_CCD_FACTURACION', showModalDetalleCalculos: false, servicioPublicoSeleccionadoCCDFacturacion: null })
        },
        onGetDetalleCalculosFacturacion: (servicioPublicoSeleccionadoCCDFacturacion, page) => {
            dispatch({ type: 'LISTA_DETALLE_CCD_FACTURACION_REQUEST', servicioPublicoSeleccionadoCCDFacturacion: servicioPublicoSeleccionadoCCDFacturacion, page:page})
       },
        onLimpiarDetalle: () => {
            dispatch({ type: 'LISTA_DETALLE_CCD_FACTURACION_SUCCESS', listaDetalleCalculosFacturacion: [], listaDetalleCalculosFacturacionCantidad: null })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleCalculosFacturacion);
