import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevaReferenciaDeEstandares from './ModalNuevaReferenciaDeEstandares';
import ModalEditarReferenciaDeEstandares from './ModalEditarReferenciaDeEstandares';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoReferenciasDeEstandares extends React.Component {
     state = {
          page: 1,
          busqueda: {order: 'ASC', field: 'idReferenciaDesempeno'},
          order: 'ASC',
          field: 'idReferenciaDesempeno',
          param: '',
     }

     componentDidMount() {
          this.props.onGetReferenciasDeEstandares(1, this.state.busqueda);
     }

     render() {
          const { 
               fetchingListaReferenciasDeEstandaresBusqueda, 
               listaReferenciasDeEstandaresBusqueda, 
               onGetReferenciasDeEstandares,
               onModalNuevaReferenciaEstandar, 
               onModalEditarReferenciaEstandar, 
               onEstadoReferenciasDeEstandares, 
               listaReferenciasDeEstandaresBusquedaCantidad,
               onActualizarEstandar 
          } = this.props;
                
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [
               {title: 'Referencia', dataIndex: 'referencia', key: 'referencia', sorter: true},
               {title: 'Subnivel Referencia', dataIndex: 'subnivelTabla', key: 'subnivelTabla'}, 
               {title: 'Clase Referencia', dataIndex: 'claseReferencia', key: 'claseReferencia', sorter: true},
               {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'}, 
               {title: 'Activo', dataIndex: 'activo', key: 'activo',
               render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.estándaresDeDesempeñoActivoinactivo} onChange = {() => handleDesactivar(record)} />
               ),}, 
               {title: 'Editar', key: 'editar',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarEstándaresDeDesempeño}  onClick={() => onEditar(record)}><EditOutlined /></a>
               ),},
          ];

          const onBuscarReferenciasDeEstandares = (param) => {
               const busqueda = { param: param, order: this.state.order, field: this.state.field }
               this.setState({ busqueda: busqueda, page: 1 });
               console.log(busqueda);
               onGetReferenciasDeEstandares(1, busqueda);
          } 

          const handleDesactivar = (key) => {
               console.log(key.idReferenciaDesempeno);
               let page = this.state.page;
               let busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado de la referencia de estándares de desempeño"' + key.referencia + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoReferenciasDeEstandares(key.idReferenciaDesempeno, page, busqueda);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const onEditar = (key) => {
               onActualizarEstandar(key.mostrarEspecifica);
               onModalEditarReferenciaEstandar(key, this.state.page, this.state.busqueda);
          }

          const onNuevoEstandarDesempenoEspecifico = () => {
               onModalNuevaReferenciaEstandar(this.state.page);
          }

          const handleTableChange = (pagination, filtro, orden) => {
               this.setState({ page: pagination.current,});
               if(orden){
                    let campo = 'idReferenciaDesempeno';
                    let order = 'ASC';
                
                    if(orden.order === 'descend'){
                        order = 'DESC';
                    }
    
                    if(orden.columnKey === 'referencia'){
                        campo = 'referencia';
                    }else if(orden.columnKey === 'claseReferencia'){
                        campo = 'claseReferencia';
                    }
                    
                    const busqueda = { param: this.state.param, order: order, field: campo }
                    onGetReferenciasDeEstandares(pagination.current, busqueda);
                    this.setState({
                        order: order,
                        field: campo,
                        busqueda: busqueda,
                    });                   
                }
                else{
                    onGetReferenciasDeEstandares(pagination.current, this.state.busqueda);
                }
          };

          return (
               <div>
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={22}>
                         <Search className='buscarModulos' placeholder='Ingresar referencia, clase de referencia o descripción' onSearch={value => onBuscarReferenciasDeEstandares(value)} />
                         </Col>
                         {/* <Col span={8}>
                         <Button onClick={onNuevoEstandarDesempenoEspecifico} disabled={!permisosUsuario.crearReferenciaDeEstándaresDeDesempeño} className='nuevoUsuario'>Nueva Referencia</Button>
                         </Col> */}
                         <Col span={1}>
                              <Tooltip title='Nueva Referencia' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNuevoEstandarDesempenoEspecifico} disabled={!permisosUsuario.crearReferenciaDeEstándaresDeDesempeño} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>
                         <Col span={24}>
                              <Table
                                   size="small"
                                   rowKey="referencia"
                                   columns={columns}
                                   dataSource={listaReferenciasDeEstandaresBusqueda}
                                   loading={fetchingListaReferenciasDeEstandaresBusqueda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: listaReferenciasDeEstandaresBusquedaCantidad, simple: true, current: this.state.page }}
                              />
                         </Col>
                    </Row>
                    <ModalNuevaReferenciaDeEstandares/>
                    <ModalEditarReferenciaDeEstandares/>
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          listaReferenciasDeEstandaresBusqueda: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresBusqueda,
          fetchingListaReferenciasDeEstandaresBusqueda: state.MecanismosDePagosReducer.fetchingListaReferenciasDeEstandaresBusqueda,
          listaReferenciasDeEstandaresBusquedaCantidad: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresBusquedaCantidad,
     }
};

const mapDispatchToProps = dispatch => {
     return {
          onGetReferenciasDeEstandares: (page, busqueda) => {
               dispatch({ type: "REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
          },
          onEstadoReferenciasDeEstandares: (idReferenciaDesempeno, page, busqueda) => {
               dispatch({ type: "ESTADO_REFERENCIA_ESTANDAR_REQUEST", idReferenciaDesempeno: idReferenciaDesempeno, page: page, busqueda: busqueda });
          },
          onModalNuevaReferenciaEstandar: (page) => {
               dispatch({ type: "SHOW_MODAL_NUEVA_REFERENCIA_ESTANDAR", showModalNuevaReferenciaEstandar: true, page: page })
          },
          onModalEditarReferenciaEstandar: (referenciaEstandarSeleccionada, page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_REFERENCIA_ESTANDAR", showModalEditarReferenciaEstandar: true, referenciaEstandarSeleccionada: referenciaEstandarSeleccionada, page: page, busqueda: busqueda});
          },
          onActualizarEstandar: (mostrarEspecifica) => {
               dispatch({ type: "REFERENCIAS_ESTANDARES_BUSQUEDA_FAILURE", mostrarEspecifica: mostrarEspecifica });
           },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoReferenciasDeEstandares);