import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Checkbox, Table} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

const { Step } = Steps;

const ReporteDinamico = props => {

    const {listaReporteDinamicoBusqueda, formularioRD,listaServiciosActivosF,onGetReporteDinamico,onLimpiarFormulario
    } = props;

    const [state, setState] = useState({page: 1, currentStep:0});
    const [order, setOrder] = useState({field:'sse.Folio',order:'ASC'});
    const [checkAll, setCheckAll] = useState (false)
    const [checkArray, setCheckArray] = useState([]);
    const [checkbox, setCheckbox] = useState ({
        nombreServicio: false, folioSolicitudServicio: false, descripcionFalla: false,
        areaFuncional: false, unidadFuncional: false, interiorFuncional: false, 
        usuarioSolicitoName: false, usuarioRegistro: false, fechaAlta: false,
        fechaCierre: false, fechaTerminacion: false, estado: false, actividadesRealizadas: false
    });
    
    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if(permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    useEffect(()=> {
        const arrayCheckbox = checkboxArray();
        setCheckArray(arrayCheckbox);
    },[checkbox]);

    
    const checkboxArray = () => {
        let fieldArray = []
        for (var key in checkbox) {
            if(Object.prototype.hasOwnProperty.call(checkbox, key)) {
               if(checkbox[key] === true){
                   fieldArray.push(key);
               }
            }
        }
        return fieldArray;
    }

    const handleSteps = value => {
        if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

        setOrder({field:'sse.Folio',order:'ASC'});
        setState({page:1, currentStep: state.currentStep -1});
        onLimpiarFormulario(formularioRD);
    }


    const selectHandle = (event,field) => {
        const checked = event.target.checked
        setCheckAll(false);

        if (checked && field === 'interiorFuncional') return setCheckbox({...checkbox, areaFuncional: true, unidadFuncional: true, [field]:checked});
        if (checked && field === 'unidadFuncional') return setCheckbox({...checkbox, areaFuncional: true,[field]:checked});
        if (!checked && field === 'areaFuncional') return setCheckbox({...checkbox,[field]:checked, unidadFuncional: false, interiorFuncional: false});
        if (!checked && field === 'unidadFuncional') return setCheckbox({...checkbox, [field]: checked, interiorFuncional: false});
        
        if(checked) return setCheckbox({...checkbox,[field]:event.target.checked })
        setCheckbox({...checkbox,[field]:event.target.checked});
    }

    const selectAll = event => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            return setCheckbox({
                nombreServicio: true, folioSolicitudServicio: true, descripcionFalla: true,areaFuncional: true, unidadFuncional: true, interiorFuncional: true, 
                usuarioSolicitoName: true, usuarioRegistro: true, fechaAlta: true,fechaCierre: true, fechaTerminacion: true, estado: true, actividadesRealizadas: true, queja: true});
        }
        setCheckbox({nombreServicio: false, folioSolicitudServicio: false, descripcionFalla: false, areaFuncional: false, unidadFuncional: false, interiorFuncional: false,
            usuarioSolicitoName: false, usuarioRegistro: false, fechaAlta: false, fechaCierre: false, fechaTerminacion: false, estado: false, actividadesRealizadas: false, queja: false,})
    }

    const handleTableChange = (pagination, filter, orden) => {
        setState({...state, page: pagination.current});
        console.log('orden en RD', orden)
        
        const orderFields = {nombreServicio:'ser.Nombre',folioSolicitudServicio:'sse.Folio',descripcionFalla:'fe.Nombre',areaFuncional:'afe.AreaFuncional',
            unidadFuncional:'ufe.UnidadFuncional',interiorFuncional: 'ife.InteriorFuncional',usuarioSolicitoName:'us.Nombre',usuarioRegistro:' usu.Nombre',
            fechaAlta: 'FechaAlta',fechaCierre:'sse.FechaCierre,',fechaTerminacion:'sse.FechaTerminacion',estado:'sse.Estado',actividadesRealizadas:'sse.ActividadesRealizadas', queja: 'sse.Queja',
        }

        if(orden) {
            const field = orderFields[orden.columnKey];
            const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
            const sorter = {field: field, order: orderField}
            setOrder({field: field, order: orderField});
            return onGetReporteDinamico(formularioRD,pagination.current, listaServiciosActivosF, sorter );
        }

        onGetReporteDinamico(formularioRD,pagination.current, listaServiciosActivosF, order);
    }

    const checkFields = [{field: 'nombreServicio', title: 'Servicio'}, {field:'folioSolicitudServicio', title:'Número de folio de Solicitud de servicio'},
    {field:'descripcionFalla', title: 'Descripción de la Falla'}, {field: 'areaFuncional', title: 'Nombre del área funcional'},
    {field: 'unidadFuncional', title:'Nombre de unidad funcional'}, {field:'interiorFuncional', title: 'Nombre de interior funcional'},
    {field:'usuarioSolicitoName', title:'Usuario que solicitó el servicio'}, {field:'usuarioRegistro', title:'Usuario CCO'},
    {field: 'fechaAlta', title: 'Fecha alta'}, {field:'fechaCierre', title: 'Fecha de cierre'}, {field:'fechaTerminacion', title:'Fecha de realizado'},
    {field: 'estado', title: 'Estado'}, {field: 'actividadesRealizadas', title: 'Actividades que se realizaron'}, {field: 'queja', title: 'Clasificación'}]

    const SelectSection = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Alert message="Selecciona las columnas a incluir en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
                </Row>
                <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px' }}>
                    <Col span={11}>
                        <Checkbox defaultChecked={checkAll}  onChange={selectAll} key='todos'><strong>Todas</strong></Checkbox>
                    </Col>
                </Row>
                <Row style={{ width: '100%', textAlign: 'left' }}>
                    {checkFields.map( item => {
                        return (
                            <Col key={item.field} span={11}>
                                <Checkbox defaultChecked={checkbox[item.field]} onChange={(event)=>selectHandle(event,item.field)} key={item.field}>{item.title}</Checkbox>
                            </Col>
                        )
                    })}
                </Row>
                <Row justify='end'>
                    <Col span={6}>
                        <Button className="btnFiltrar" disabled={!checkArray.length>0} onClick={()=> handleSteps('siguiente')} style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                    </Col>
                </Row>
            </>
        )
    }
    
    const dataProps = { checkbox, checkArray, state, order}
    const handleProps = {handleTableChange , handleSteps}
    return (
        <>
        <Steps
            type='navigation'
            size='small'
            current={state.currentStep}
            style={{marginBottom:'20px', paddingTop:'0px'}}
        >
            <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
            <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
            { listaReporteDinamicoBusqueda ? (
                <Step status={listaReporteDinamicoBusqueda.length>0? 'finish':'process'} title='Exportar Reporte' />
            ): false }
        </Steps>
        {state.currentStep === 0 ? <SelectSection/> : false}
        {state.currentStep === 1 ? <FilterSection handleSteps={handleSteps} checkboxData={checkbox} /> : false}
        {state.currentStep === 2 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
        </>
    )
}

const mapStateToProps = state => {
    return {
        listaReporteDinamicoBusqueda: state.SolicitudDeServiciosReducer.listaReporteDinamicoBusqueda,
        formularioRD: state.SolicitudDeServiciosReducer.formularioRD,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetReporteDinamico: (formulario, page, listaServiciosActivosF, order) => {
            dispatch({ type: 'LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST', formulario, page, listaServiciosActivosF, order});
        },
        onLimpiarFormulario: (formularioRD) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO_RD', formularioRD: formularioRD, listaReporteDinamicoBusqueda: [], listaReporteDinamicoBusquedaCantidad: null})
        }
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDinamico);