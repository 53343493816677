import React, { Component } from 'react';
import { Row, Col, Modal, Select, Divider, Button, Spin, Tooltip, Typography, Table, Input, Steps, message } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ClearOutlined, SaveOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const { Step } = Steps;

class ModalEditarSolicitud extends Component {
    formRef = React.createRef();
    componentDidMount() {
        this.props.onGetServiciosAct();
    }
    state = {
        page: 1,
        selectedRowKeys: [],
        selectedRows: [],
        currentStep: 0,
        fileList: [],
        filteredData: [],
        busqueda: '',
        order: 1,
        myInterval: null,
        fetchingInterval: false,
        servicio: undefined,
        subFamilia: undefined,
        campo: undefined,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listaSeleccionados !== this.props.listaSeleccionados) {
            this.setState({ filteredData: this.props.listaSeleccionados });
        }
    }

    render() {
        const {
            fetchingListaServiciosActivosF,
            listaServiciosActivosF,

            showModalEditarRequisicion,
            onCloseModal,
            fetchingRegistrarRequisicionMateriales,

            selectedRowKeys,
            listaSeleccionados,
            requisicionSeleccionada,

            onEditarRequisicion,
            listaProductosBusqueda,
            fetchingListaProductosBusqueda,
            listaProductosBusquedaCantidad,
            onGetInventario,
            onActualizarTabla,

            fetchingListaSubfamiliasServicio,
            listaSubfamiliasServicio,

            limpiarFormulario, listaPartidasByServicio, fetchingListaPartidasByServicio,
        } = this.props; 

        if (limpiarFormulario && limpiarFormulario === 'editarRequisicion') {
            this.setState({
                page: 1,
                selectedRowKeys: [],
                selectedRows: [],
                currentStep: 0,
                fileList: [],
                filteredData: [],
                busqueda: '',
                myInterval: null,
                fetchingInterval: false,
                servicio: undefined,
                subFamilia: undefined,
                campo: undefined,
            });
            onActualizarTabla([], [], null, true);
            this.props.onLimpiarFormulario();
        }

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) {
            userInfo = JSON.parse(userInfo);
        }

        const serviciosByIdUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

        const handleCancel = () => {
            onCloseModal();
            this.setState({
                page: 1,
                selectedRowKeys: [],
                selectedRows: [],
                currentStep: 0,
                fileList: [],
                filteredData: [],
                busqueda: '',
                myInterval: null,
                fetchingInterval: false,
                servicio: undefined,
                subFamilia: undefined,
                campo: undefined,
            });
            onActualizarTabla([], [], null, true);
            this.props.onGetListaFoliosRequisicionMateriales(this.props.page, this.props.busqueda, this.props.orden, userInfo[0].idUsuario, false);
        };

        const onBuscarProducto = (busqueda, name) => {
            this.setState({
                [name]: busqueda,
                page: 1,
            });
            if (name === 'campo') {
                onGetInventario(1, requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre, requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idPartida, busqueda, this.state.subFamilia, listaSeleccionados)
            }
            else if (name === 'subFamilia') {
                onGetInventario(1, requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre, requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idPartida, this.state.campo, busqueda, listaSeleccionados)
            }
        }

        const onChangeTabla = (record, selected, selectedRows) => {
            let listaSelected = listaSeleccionados;
            const listaFinal = [];
            if (!listaSelected) {
                listaSelected = [];
            }
            if (selected.length) {
                for (let i in selected) {
                    if (selected[i]) {
                        selected[i].agregado = false;
                    }
                }
                for (let i in selected) {
                    if (listaSelected.length) {
                        for (let x in listaSelected) {
                            if (selected[i]) {
                                if (!selected[i].agregado) {
                                    if (listaSelected[x].rowKey === record[i]) {
                                        listaFinal.push(listaSelected[x]);
                                        selected[i].agregado = true;
                                    }
                                    else {
                                        listaFinal.push(selected[i]);
                                        selected[i].agregado = true;
                                    }
                                }
                            }
                            else {
                                if (listaSelected[x].idProducto === record[i]) {
                                    listaFinal.push(listaSelected[x]);
                                }
                            }
                        }
                    }
                    else {
                        listaFinal.push(selected[i]);
                    }
                }
            }

            let hasSelectedLocal = true

            if (record.length !== 0) {
                hasSelectedLocal = false;
            }

            this.setState({ selectedRowKeys: record, selectedRows: selected });
            onActualizarTabla(listaFinal, record, null, hasSelectedLocal);
        };

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });

            onGetInventario(pagination.current, requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre, requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idPartida, this.state.campo, this.state.subFamilia, listaSeleccionados);
        };

        const onEditar = key => {
            console.log('key', key);
            this.props.onShowModalModificar(key);
            onCloseModal(requisicionSeleccionada, this.props.page, this.props.busqueda, this.props.orden);
        };
        const onEliminar = key => {            
            let listaSelected = listaSeleccionados;
            onActualizarTabla([], [], null, false);
            for(let i in listaSeleccionados){
                if(listaSeleccionados[i].idProducto === key.idProducto){
                    listaSelected.splice(i, 1);
                }
            }
            let recordLocal = [];
            for(let i in listaSelected){
                recordLocal.push(listaSelected[i].idProducto);
            }

            let hasSelectedLocal = true
            if (recordLocal.length !== 0) {
                hasSelectedLocal = false;
            }
            this.setState({fetchingInterval: true, myInterval: setInterval(function () {onActualizarTabla(listaSelected, recordLocal, 'reiniciarIntervalEditar', hasSelectedLocal);}, 1) }) 
            //this.setState({ selectedRowKeys: recordLocal, filteredData: listaSelected });
        }
        if(this.props.pageMovimientos === 'reiniciarIntervalEditar'){
            console.log('entró en el if de pageMovimientos :V')
            clearInterval(this.state.myInterval); 
            onActualizarTabla(listaSeleccionados, selectedRowKeys, null, this.props.hasSelected);
            this.setState({ fetchingInterval: false })
        }

        const onLimpiar = () => {
            this.setState({ selectedRowKeys: [], filteredData: [], currentStep: 0 });
            onActualizarTabla([], [], null, false);
        }

        const columns = [
            { title: 'Código de almacén', dataIndex: 'codigoAdicional', key: 'codigoAdicional', align: 'center', },
            { title: 'Nombre del producto', dataIndex: 'nombre', key: 'nombre', align: 'center', },
            { title: 'Clave del producto', dataIndex: 'clave', key: 'clave', align: 'center', },
            { title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center', },
            { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'center', },
        ];

        const columnsSelected = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        }, {
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        }, {
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        }, {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        }, {
            title: 'Cantidad   ',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'right',
        }, {
            title: ' Modificar ',
            key: 'editar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },{ 
            title: 'Eliminar', key: 'eliminar', align: 'center',
            render: (text, record) => (
                <a className='eliminarIcono' onClick={() => onEliminar(record)}><DeleteOutlined /></a>
            ),
        }
        ];

        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const onFinalizar = () => {
            for(let i in listaSeleccionados){
                if(!listaSeleccionados[i].cantidad){
                    message.warning('Favor de agregar cantidad al PIH "' + listaSeleccionados[i].nombre + ' - ' + listaSeleccionados[i].codigoAdicional + '"')
                    return;
                }
            }
            onEditarRequisicion(requisicionSeleccionada, listaSeleccionados, this.props.page, this.props.busqueda, this.props.orden)
        }

        const onSiguiente = () => {
            this.setState({ currentStep: 1 })
        }
        
        return (
            <div>
                <Modal
                    visible={showModalEditarRequisicion}
                    title='Editar Solicitud de Materiales'
                    onCancel={handleCancel}
                    footer={false}
                    width={'80%'}
                >
                    <Spin spinning={fetchingRegistrarRequisicionMateriales || this.props.fetchingEditarRequisicion}>
                        <Row justify='space-between' gutter={[8, 8]}>
                            <Col span={22}>
                                <Divider orientation='left'> <Text strong> Editar Productos </Text></Divider>
                            </Col>
                            {this.state.currentStep === 1 ?(
                            <Col span={1} style={{ paddingTop: '1em' }}>
                                <Tooltip title='Anterior'>
                                    <Button className="iconTer" shape="circle" onClick={() => { this.setState({ currentStep: 0 })}} icon={<ArrowLeftOutlined />} disabled={this.state.currentStep !== 0 ? false : true} />
                                </Tooltip>
                            </Col>
                            ):false}
                            {this.state.currentStep === 0 ?(
                            <Col span={1} style={{ paddingTop: '1em' }}>
                                <Tooltip title='Agregar productos'>
                                    <Button className="iconTer" shape="circle" onClick={onSiguiente} icon={<PlusOutlined />} />
                                </Tooltip>
                            </Col>
                            ):false}
                            {this.state.currentStep === 0 ?(
                            <Col span={1} style={{ paddingTop: '1em' }}>
                                <Tooltip title='Finalizar'>
                                    <Button className="iconTer" shape="circle" onClick={onFinalizar} icon={<SaveOutlined />} disabled={this.state.selectedRowKeys.length > 0 ? false : this.props.hasSelected} />
                                </Tooltip>
                            </Col>
                            ):false}
                            <Col span={24} style={{ padding: '1em' }}>
                                <Steps
                                    type='navigation'
                                    size='small'
                                    current={this.state.currentStep}
                                >
                                    <Step status={this.state.currentStep > 0 ? 'finish' : 'process'} title='Detalle de Productos' />
                                    <Step status={this.state.currentStep > 1 ? 'finish' : 'process'} title='Seleccionar Productos' />
                                </Steps>
                            </Col>
                            <Col span={24}>
                            {this.state.currentStep === 1 ? (
                                <Row justify='space-between'gutter={[8, 8]}>
                                    <Col span={listaPartidasByServicio.length ? 6 : 8}>
                                        {permisosUsuario.especialAlmacén ? (
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosActivosF}
                                            showSearch
                                            defaultValue={requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idServicio + ' - ' + requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre}
                                            onChange={(value) => onBuscarProducto(value, "servicio")}
                                            disabled={true}
                                        >
                                            {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                        ):(
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            showSearch
                                            defaultValue={requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idServicio + ' - ' + requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre}
                                            onChange={(value) => onBuscarProducto(value, "servicio")}
                                            disabled={true}
                                        >
                                            {serviciosByIdUsuario.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                        )}
                                    </Col>
                                    {listaPartidasByServicio.length ? (
                                    <Col span={6}>
                                        <Select 
                                            style={{width: '100%'}}
                                            placeholder="Selecciona una partida"
                                            defaultValue={requisicionSeleccionada.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.partidaByIdPartida.nombre}
                                            loading={fetchingListaPartidasByServicio}
                                            showSearch
                                            disabled={true}
                                        >
                                            {listaPartidasByServicio.map(option => <Option
                                                key={option.idPartida}>{option.nombre}</Option>)}
                                        </Select>
                                    </Col>
                                    ):(
                                    <Col span={8}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la subfamilia"
                                            loading={fetchingListaSubfamiliasServicio}
                                            onChange={(value) => onBuscarProducto(value, "subFamilia")}
                                            value={this.state.subFamilia}
                                        >
                                            {listaSubfamiliasServicio.map(option => <Option key={option.subFamilia}>{option.subFamilia}</Option>)}
                                        </Select>
                                    </Col>
                                    )}
                                    {listaPartidasByServicio.length ? (
                                    <Col span={6}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la subfamilia por partida"
                                            loading={this.props.fetchingListaSubfamiliasByIdPartida}
                                            onChange={(value) => onBuscarProducto(value, "subFamilia")}
                                            value={this.state.subFamilia}
                                        >
                                            {this.props.listaSubfamiliasByIdPartida.map(option => <Option key={option.subFamilia}>{option.subFamilia}</Option>)}
                                        </Select>
                                    </Col>
                                    ):false}
                                    
                                    <Col span={listaPartidasByServicio.length ? 6 : 8}>
                                        <Search allowClear className='buscarModulos' placeholder='Ingresa nombre/clave/código de almacén del producto' onSearch={(value) => onBuscarProducto(value, "campo")} />
                                    </Col>
                                    <Col span={24}>
                                        <br/>
                                        <Table
                                            size="small"
                                            rowKey="rowKey"
                                            columns={columns}
                                            dataSource={listaProductosBusqueda}
                                            loading={fetchingListaProductosBusqueda}
                                            locale={{ emptyText: 'Sin datos de productos' }}
                                            onChange={handleTableChange}
                                            pagination={{ total: listaProductosBusquedaCantidad, simple: true, current: this.state.page }}
                                            showSorterTooltip={true}
                                            rowSelection={rowSelection}
                                            scroll={{"x": 1100, "y": 1000}}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                this.state.currentStep === 0 &&
                                <Row justify='end'>
                                    <Col span={24}>
                                        <Table
                                            size="small"
                                            rowKey="idProducto"
                                            columns={columnsSelected}
                                            dataSource={this.state.filteredData}
                                            locale={{ emptyText: 'Sin datos' }}
                                            pagination={false}
                                            scroll={{"x": 1000 | true, "y": 1000}}
                                            loading={this.state.fetchingInterval}
                                        />
                                    </Col>
                                    <Col span={1}>
                                        <br/>
                                        <Tooltip title='Limpiar lista de productos' placement="topRight">
                                            <Button disabled={!this.state.filteredData.length} className="iconSecundary" shape='circle' icon={<ClearOutlined /> } onClick={onLimpiar}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            )}
                            </Col>
                        </Row>
                    </Spin>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

        showModalEditarRequisicion: state.AlmacenesReducer.showModalEditarRequisicion,
        fetchingRegistrarRequisicionMateriales: state.AlmacenesReducer.fetchingRegistrarRequisicionMateriales,

        listaProductosBusqueda: state.AlmacenesReducer.listaProductosBusqueda,
        fetchingListaProductosBusqueda: state.AlmacenesReducer.fetchingListaProductosBusqueda,
        listaProductosBusquedaCantidad: state.AlmacenesReducer.listaProductosBusquedaCantidad,

        formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,

        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
        fetchingEditarRequisicion: state.AlmacenesReducer.fetchingEditarRequisicion,
        selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,
        hasSelected: state.AlmacenesReducer.hasSelected,
        requisicionSeleccionada: state.AlmacenesReducer.requisicionSeleccionada,

        fetchingListaSubfamiliasServicio: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasServicio,
        listaSubfamiliasServicio: state.CatalogosGeneralesReducer.listaSubfamiliasServicio,
        pageMovimientos: state.AlmacenesReducer.pageMovimientos,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        orden: state.AlmacenesReducer.orden,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        listaPartidasByServicio: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio,

        listaSubfamiliasByIdPartida: state.CatalogosGeneralesReducer.listaSubfamiliasByIdPartida,
        fetchingListaSubfamiliasByIdPartida: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasByIdPartida,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onCloseModal: (requisicionSeleccionada, page, busqueda, orden) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_REQUISICION", showModalEditarRequisicion: false, requisicionSeleccionada, page, busqueda, orden })
        },
        onGetServiciosAct: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
        onGetInventario: (page, servicio, partida, campo, subFamilia, productosByIdRequisicion) => {
            dispatch({ type: "LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST", page, servicio, partida, campo, subFamilia, productosByIdRequisicion })
        },
        onEditarRequisicion: (requisicionSeleccionada, listaSeleccionados, page, busqueda, orden) => {
            dispatch({ type: "EDITAR_REQUISICION_REQUEST", requisicionSeleccionada, listaSeleccionados, page, busqueda, orden});
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_REQUISICION_MATERIALES', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected });
        },
        onShowModalModificar: (registroProductoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_REQUISICION", showModalModificarRequisicionMateriales: true, registroProductoSeleccionado})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onSubfamiliasPorServicio: (idServicio) => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST', idServicio });
        },
        onGetListaFoliosRequisicionMateriales: (page, busqueda, orden, idUsuario, responsable) => {
            dispatch({ type: "LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST", page, busqueda, orden, idUsuario, responsable });
       },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarSolicitud);