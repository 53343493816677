import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';

import CatalogoDeRoles from './CatalogoDeRoles/CatalogoDeRoles';
import CatalogoDeModulosSistema from './CatalogoDeModulos/CatalogoDeModulosSistema';

import imaSearch from '../../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";


import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import TiposBitacoraContainer from './TiposDeBitacoras/TiposBitacoraContainer';
import CatalogoBitacorasContainer from './CatalogoDeBitacoras/CatalogoBitacorasContainer';
import CatalogoModulosBitacoras from './ModulosBitacoras/ModulosBitacorasContainer';
import CatalogoRangosValidacion  from './CatalogoDeRangosValidacion/RangosDeValidacionContainer';
import CatalogoDeResponsables from './CatalogoDeResponsables/ResponsablesContainer';

function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}
class DashboardContainer extends React.Component {
    state={
        catalogo: 'Selecciona un Catálogo',
        colLista: 8,
        colTabla: 15,
        maximizar: false
    }
    render() {
        const { onChangeCatalogo, catalogoSeleccionado } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const listaCatalogos = [];
        if(permisosUsuario.buscarRol){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Roles',
            })
        }
        if(permisosUsuario.buscarModulo){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Módulos del Sistema',
            })
        }
        if(permisosUsuario.buscarTipoDeBitácora){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Tipos de Bitácora',
            })
        }
        if(permisosUsuario.verBitácoraMódulos){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Bitácoras por Módulo',
            })
        }
        // if(permisosUsuario.verBitácoraMódulos){
        //     listaCatalogos.push({
        //         'catalogo': 'Catálogo de Rangos de Validación',
        //     })
        // }
        if(permisosUsuario.verBitácoraMódulos){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Responsables',
            })
        }
        function ShowInfo(props) {
            if (isEmpty(catalogoSeleccionado)) {
                return (
                    <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>
                );
            }
            
	        return (
                <div>
                    <CatalogoApp/>
                </div>
            );
        }

        function CatalogoApp() {
            if(catalogoSeleccionado === 'Catálogo de Roles'){
                return(
                    <div>
                        <CatalogoDeRoles/>
                    </div>
                );
            }
            else if(catalogoSeleccionado === 'Catálogo de Módulos del Sistema'){
                return(
                    <div>
                        <CatalogoDeModulosSistema/>
                    </div>
                );
            }
            else if(catalogoSeleccionado === 'Catálogo de Tipos de Bitácora'){
                return(
                    <div>
                        <TiposBitacoraContainer/>
                    </div>
                );
            }
            else if(catalogoSeleccionado === 'Catálogo de Bitácoras por Módulo'){
                return(
                    <div>
                        <CatalogoModulosBitacoras/>
                    </div>
                );
            }
            // else if(catalogoSeleccionado === 'Catálogo de Rangos de Validación'){
            //     return(
            //         <div>
            //             <CatalogoRangosValidacion/>
            //         </div>
            //     );
            // }
            else if(catalogoSeleccionado === 'Catálogo de Responsables'){
                return(
                    <div>
                        <CatalogoDeResponsables/>
                    </div>
                );
            }
			return <div>  </div>;
        };
        
        const changeCatalogo = (catalogo) => {
            this.setState({
                catalogo: catalogo.catalogo,
            })
            onChangeCatalogo(catalogo.catalogo);
        }

        const onExpandir = () => {
            if(!this.state.maximizar){
                this.setState({
                    colLista: 0,
                    colTabla: 24,
                    maximizar: true
                });
            }
            else{
                this.setState({
                    colLista: 8,
                    colTabla: 15,
                    maximizar: false,
                });
            }
        }

        return (
            <div className="imgCorp">
                <Row justify='space-around'>
                    <Col span={this.state.colLista}>
                        <List
                            header={
                                <div className='headerLista'>Catálogos</div>
                            }
                            className="listaCatalogos"
                            bordered
                            dataSource={listaCatalogos}
                            renderItem={item => (
                            <List.Item className='listaHover'
                                actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                            >
                                <div>{item.catalogo}</div>
                            </List.Item>
                            )}
                        />                    
                    </Col>
                    <Col span={this.state.colTabla}>
                        <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                            <ShowInfo info={catalogoSeleccionado} />
                        </Card> 
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        catalogoSeleccionado: state.ConfiguracionYSeguridadReducer.catalogoSeleccionado,
    };
};
const mapDispatchToProps = dispatch => {
	return {
	  	onRequestLogin: (loginInfo) => {
	    	dispatch({ type: "LOGIN_API_CALL_REQUEST", loginInfo:loginInfo });
        },
        onChangeCatalogo: (catalogoSeleccionado) => {
            dispatch({ type: "CAMBIAR_CATALOGO_SELECCIONADO" , catalogoSeleccionado: catalogoSeleccionado});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);