import React, { Component, Alert } from 'react';
import {Row, Col, Modal, List} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalDetalleActivo extends Component {
    formRef = React.createRef();

    render() {
        const { onShowModalDetalleActivo, showModalDetalleActivo, ActualizacionActivoSeleccionado} = this.props;
//
        let data=[]
        if (ActualizacionActivoSeleccionado){
            const {codigoInterno,codigoEquipo,sku,serieNum,estado,fechaInstalacion,fechaAlta,vidaUtil,vidaUtilSugerida,unidadMedida, diasParaVencer, precioTabla, tipoMoneda} = ActualizacionActivoSeleccionado;
            
            data = [
                {title:"Código interno", data:codigoInterno},
                {title:"Código de equipo", data:codigoEquipo},
                {title:"SKU", data:sku},
                {title:"Número de serie", data:serieNum},
                {title:"Estado",data:estado},
                {title:"Fecha de instalación",data:fechaInstalacion},
                {title:"Fecha de alta",data:fechaAlta},
                {title:"Precio",data:precioTabla},
                {title:"Tipo Moneda",data:tipoMoneda},
                {title:"Vida útil",data:`${vidaUtil} ${unidadMedida}`},
                {title:"Vida útil sugerida",data:vidaUtilSugerida},
                {title: "Vida útil restante", data: `${diasParaVencer.toFixed()} días`}
            ];
        }

        const handleCancel = () => {
            onShowModalDetalleActivo();
        }
        
        return (
            <Modal
                visible={showModalDetalleActivo}
                title='Detalle del Activo'
                onCancel={handleCancel}
                footer={false}
            >
                {ActualizacionActivoSeleccionado ? (
                    <Row justify='space-around'>
                        { ActualizacionActivoSeleccionado.ultimaFechaTipoCambio ? (
                            <Col span={24}>
                                <Alert
                                    message="Tipo de Cambio"
                                    description={"Último registro de tipo de cambio: " +  ActualizacionActivoSeleccionado.ultimaFechaTipoCambio}
                                    type="info"
                                    closable
                                />
                            </Col>
                        ) : false }

                        <Col span={24}>
                            <List
                            className="listaCatalogos"
                            bordered
                            dataSource={data}
                            renderItem={item => (
                               <List.Item className='listaHover' className='listaHover'>
                                        <Col span={11}>
                                            <strong>{item.title}</strong>  
                                        </Col>
                                        <Col span={11}>
                                            {item.data} 
                                        </Col>
                                </List.Item>
                            )}
                            />
                        </Col>
                    </Row>
                ) : false}
                
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleActivo: state.AlmacenesReducer.showModalDetalleActivo,
        ActualizacionActivoSeleccionado: state.AlmacenesReducer.ActualizacionActivoSeleccionado,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleActivo: () => {
            dispatch({ type: 'SHOW_MODAL_ACTUALIZACION_ACTIVO_DETALLE', showModalNuevoEstatusActivo: false });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleActivo);