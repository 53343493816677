import React from 'react';
import { Row, Col, Input, Select, Button, Form, message, Card, Tooltip, Typography } from 'antd';
import { connect } from "react-redux";
import { FieldNumberOutlined, ArrowRightOutlined } from '@ant-design/icons';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalSalidaDeBienes from './ModalSalidaDeBienes';

import imaSearch from '../../../../assets/undraw_heavy_box_agqi.svg';
import ReactVivus from "react-vivus";

const { Option } = Select;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, };
const {Text} = Typography;

class SalidaDeBienesContainer extends React.Component {
    formRef = React.createRef();
    
     componentDidMount(){
          this.props.onListaMotivosActivos();
          this.props.generarFolio();
          this.props.onGetServicios();
          this.props.onGetEstatusActivo();
     }
     state={
          page: 1,
          statusIconServicio: 'warning',
          statusIconMotivo: 'warning',
          hasSelectedServicio: false,
          hasSelectedMotivo: false,
          hasSelected: true,         
     }


     render() {
          const { 
               listaServiciosFiltro,
               fetchingListaServiciosFiltro,
               onListaServiciosFiltro,
               onListaServiciosFiltroLimpiar,
               fetchingListaMotivosSalidaActivos,
               listaMotivosSalidaActivos,
               onModalSolicitudSalida,
               generarFolio,
               fetchingFolioSalidaDeBienes,
               tipoMovimiento,
               onObtenerActivosPorServicio,
               listaServiciosActivosF,
               datosFormulario,
               motivoSalida,
               servicioSalida,
               onCambiarVista,
               limpiarFormulario,
               onGetActivosEnOperacion,
               onListaMotivosActivosLimpiar,
               limpiarGenerarFolio,
               showModalSalidaDeBienes
          } = this.props;

          const buscarServicios = value => {
               if(value.length>=3){
                    if(value){
                         onListaServiciosFiltro(value)
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                    onListaServiciosFiltroLimpiar();
               } 
          }

          const onSolicitud = () => {
               const idServicio = parseInt(this.state.idServicioLocal);
               console.log('this.state.page', this.state.page)

               onObtenerActivosPorServicio(idServicio, this.state.page, '');
               onModalSolicitudSalida(this.state.page);
               onCambiarVista(1);
               onGetActivosEnOperacion(idServicio);

               this.setState({ hasSelected: true });

               this.formRef.current.resetFields();
               onListaServiciosFiltroLimpiar();

               
          }

          const servicioSeleccion = (value) => {
               console.log('value servicioSeccion: ', value);
               let idServicioLocal = parseInt(value.split(' - ')[0]);
               let statusIconServicio = 'success';
               let hasSelectedServicio = true;
               let servicioLocal = '';

               for(let i = 0; i < listaServiciosActivosF.length; i++){
                    if(!idServicioLocal){
                         statusIconServicio = 'error';
                         hasSelectedServicio = false;
                    } else if(listaServiciosActivosF[i].idServicio === idServicioLocal) {
                         servicioLocal = listaServiciosActivosF[i].nombre;
                         console.log('servcioLocal: ', servicioLocal);
                    }
               }

               this.setState({
                    statusIconServicio,
                    hasSelectedServicio,
                    idServicioLocal,
                    servicioLocal
               });
          }

          if (limpiarFormulario && limpiarFormulario === 'crearSolicitudDeSalida') {
               this.setState({ hasSelected: true });
               this.props.onLimpiarFormulario();
               this.formRef.current.resetFields();
          }
          
          const motivoSeleccion = (value) => {
               console.log('value: ', value)
               let idMotivoSalida = parseInt(value.split(' - ')[0]);
               let statusIconMotivo = 'validating';
               let hasSelectedMotivo = true;
               let tipoMovimientoTexto = '';
               let movimientoSalidaLocal = '';
               
               if(!idMotivoSalida){
                    statusIconMotivo = 'error';
                    hasSelectedMotivo = false;
                    tipoMovimientoTexto= '';
                    generarFolio(tipoMovimientoTexto);
               } else {
                    statusIconMotivo = 'success';
                    hasSelectedMotivo = true;

                    for(let i = 0; i < listaMotivosSalidaActivos.length; i++){
                         if(listaMotivosSalidaActivos[i].idMotivoSalida === idMotivoSalida){
                              tipoMovimientoTexto = listaMotivosSalidaActivos[i].tipoSalida;
                              movimientoSalidaLocal = listaMotivosSalidaActivos[i].tipoSalida + ',  ' + listaMotivosSalidaActivos[i].descripcion;
                         }
                    }

                    generarFolio(tipoMovimientoTexto);
               }

               this.setState({
                    statusIconMotivo,
                    hasSelectedMotivo,
                    movimientoSalidaLocal,
                    idMotivoSalida
               });      
          }
          
          datosFormulario(this.state.servicioLocal, this.state.idServicioLocal, this.state.movimientoSalidaLocal, this.state.tipoMovimientoTexto, this.state.idMotivoSalida);
          
          if(this.state.hasSelectedMotivo === true && this.state.hasSelectedServicio === true){
               this.state.hasSelected = false;
          } else {
               this.state.hasSelected = true;
          }

          if(this.state.hasSelectedMotivo === true){
               this.state.statusIconMotivo = 'success';
          }
          if(this.state.hasSelectedServicio === true){
               this.state.statusIconServicio = 'success';
          }


          if(tipoMovimiento === null){
               this.state.hasSelected = true;
               this.state.statusIconServicio = 'warning';
               this.state.statusIconMotivo = 'warning';
          }

          console.log('listaMotivosSalidaActivos', listaMotivosSalidaActivos)

          return(
               <Card className="cardCatalogos" title={<div className="headerLista">Solicitud de Salida de Activos</div>}>
                    <Form
                         {...layout}
                         name="formulario"
                         ref={this.formRef}
                    >
                         <Row justify='space-around' gutter={[8,8]}>
                              <Col span={5}>
                                   <Form.Item
                                   // name='folioSalida'
                                   label='Folio de Salida'
                                   >
                                        <Input
                                             disabled={true}
                                             value={tipoMovimiento}
                                             addonBefore={<FieldNumberOutlined />}
                                        />
                                   </Form.Item>
                              </Col>
                              <Col span={10}>
                                   <Form.Item
                                   name="idServicio"
                                   label="Servicio"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   
                                   >
                                        {/* <Select
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Escribe el servicio a buscar "
                                             loading={fetchingListaServiciosFiltro}
                                             showSearch={true}
                                             filterOption={false}
                                             onSearch={buscarServicios}
                                             notFoundContent={null}
                                             onChange={servicioSeleccion}
                                        >
                                             {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                        </Select> */}
                                        <Select
                                             
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona el servicio"
                                             loading={this.props.fetchingListaServiciosActivosF}
                                             showSearch
                                             onChange={servicioSeleccion}
                                        >
                                             {this.props.listaServiciosActivosF.map(option => 
                                                  <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                       <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                            <Text style={{color:'whitesmoke'}}>{option.idServicio + ' - '}</Text>{option.nomenclatura + ': ' + option.nombre}
                                                       </Tooltip>
                                                  </Option>
                                             )}
                                        </Select>
                                   </Form.Item>                   
                              </Col>
                              <Col span={8}>
                                   <Form.Item
                                        label="Motivos de Salida"
                                        name="idMotivoSalida"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select 
                                             // allowClear
                                             showSearch
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona el motivo de salida"
                                             loading={fetchingListaMotivosSalidaActivos}
                                             onChange={motivoSeleccion}
                                        >
                                             {/* {listaMotivosSalidaActivos.map(option => <Option key={option.idMotivoSalida}>{ option.tipoSalida + ', ' + option.descripcion }</Option>)} */}
                                             {this.props.listaMotivosSalidaActivos.map(option => 
                                                  <Option key={option.idMotivoSalida + ' - ' + option.tipoSalida + ': ' + option.descripcion}>
                                                       <Text style={{color:'whitesmoke'}}>{option.idMotivoSalida + ' - '}</Text>{option.tipoSalida + ': ' + option.descripcion}
                                                  </Option>
                                             )}
                                        </Select>
                                   </Form.Item>
                              </Col>
                              
                              <Col span={1} style={{paddingTop:'3em'}}>
                                   <Tooltip title='Verificar'>
                                        <Button shape="circle" icon={<ArrowRightOutlined />} className="iconTer" onClick={onSolicitud} disabled={this.state.hasSelected} />
                                   </Tooltip>
                              </Col>              
                         </Row>

                         <Row justify='center'>
                              <Col span={8} style={{paddingTop:'3em', paddingBottom:'3em'}}>
                              {/* <img src={imaSearch} /> */}
                                   <ReactVivus
                                        id="imaSearch"
                                        option={{
                                             file: imaSearch,
                                             animTimingFunction: "EASE",
                                             type: "delayed",
                                        }}
                                   />
                              </Col>
                         </Row>
                         {/* <Row justify='center'>
                              <Col span={8}>
                                   <Button className="btnFiltrar" style={{width: '100%'}} onClick={onSolicitud} disabled={this.state.hasSelected}> 
                                        Verificar 
                                   </Button>
                              </Col>
                         </Row> */}

                         <ModalSalidaDeBienes/>
                    </Form>
               </Card>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaMotivosSalidaActivos: state.AlmacenesReducer.fetchingListaMotivosSalidaActivos,
          listaMotivosSalidaActivos: state.AlmacenesReducer.listaMotivosSalidaActivos,
          fetchingFolioSalidaDeBienes: state.AlmacenesReducer.fetchingFolioSalidaDeBienes,
          tipoMovimiento: state.AlmacenesReducer.tipoMovimiento,
          motivoSalida: state.AlmacenesReducer.motivoSalida,
          servicioSalida: state.AlmacenesReducer.servicioSalida,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalSalidaDeBienes: state.AlmacenesReducer.showModalSalidaDeBienes
     };
};
const mapDispatchToProps = dispatch => {
	return {
          // onNuevaSalidaDeBienes: ( formulario, page ) => {
          //      dispatch({ type: "NUEVA_SOLICITUD_SALIDA_BIENES_REQUEST" , formulario: formulario, page: page });
          // },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onModalSolicitudSalida: (datosSolicitudSeleccionados, page) => {
               dispatch({ type: "SHOW_MODAL_SALIDA_BIENES", showModalSalidaDeBienes: true, datosSolicitudSeleccionados: datosSolicitudSeleccionados, page: page });
          },
          onListaMotivosActivos: () => {
               dispatch({ type: 'MOTIVOS_DE_SALIDA_ACTIVOS_REQUEST' });
          },
          onListaMotivosActivosLimpiar: () => {
               dispatch({ type: 'MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS', listaMotivosSalidaActivos: [] });
          },
          generarFolio: (tipoMovimiento) => {
               dispatch({ type: 'GENERAR_FOLIO_SALIDA_REQUEST', tipoMovimiento: tipoMovimiento });
          },
          limpiarGenerarFolio: () => {
               dispatch({ type: 'GENERAR_FOLIO_SALIDA_SUCCESS', tipoMovimiento: null });
          },
          onObtenerActivosPorServicio: (idServicio, page, busqueda) => {
               dispatch({ type: "LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST", idServicio: idServicio, page: page, busqueda: busqueda });
          },
          datosFormulario: (servicioSalida, idServicioSalida, motivoSalida, motivoSalidaTex, idMotivoSalidaLocal) => {
               dispatch({ type: 'GENERAR_FOLIO_SALIDA_FAILURE', servicioSalida: servicioSalida, idServicioSalida: idServicioSalida, motivoSalida: motivoSalida, motivoSalidaTex: motivoSalidaTex, idMotivoSalidaLocal: idMotivoSalidaLocal});
          },
          onGetServicios: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onCambiarVista: (vistaModalSalidaDeBienes) => {
               dispatch({ type: 'CAMBIAR_VISTA_SALIDA_BIENES', vistaModalSalidaDeBienes: vistaModalSalidaDeBienes, formularioSalidaDeBienes: null})
          },
          onGetEstatusActivo: () => {
               dispatch({ type: 'LISTA_ESTATUS_ACTIVOS_REQUEST' });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onGetActivosEnOperacion: (idServicio) => {
               dispatch({ type: 'LISTA_ACTIVOS_OPERACION_SERVICIO_REQUEST', idServicio })
          }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SalidaDeBienesContainer);