import React, { Component } from 'react';
import {Card, Row, Col, Select, DatePicker, Divider, Table, Radio, Input, Spin, Form, Typography} from 'antd';
import {FileSearchOutlined , FilePdfOutlined, SelectOutlined, CalendarOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import moment from 'moment';
import ModalDetallePrograma from './ModalDetallePrograma';
import ModalGenerarOrden from './ModalGenerarOrden';
import ModalEditarOrden from './ModalEditarOrden';
import ModalVerProgramacion from './ModalVerProgramacion';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const {Text} = Typography;

class OrdenRutinaMantenimientoContainer extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetServiciosFiltroRutina();
        this.props.onGetAreas()
    }
    state = {
        busqueda: '',
        page: 1,
        idServicio: '',
        idPartida: '',
        programa: '',
        fecha: null,
        btnTablaSeleccionada: 'Filtrado',
        btnDisabled: true,
        disabledSelectFiltro: false,
        idAreaFuncional: '',
        idUnidadFuncional: '',
        busquedaOrdenes: {param: ''},
        pageOrdenes: 1,
        partida: undefined
    }
    render() {
        const { fetchingListaServiciosRutinaFiltro, listaServiciosRutinaFiltro, listaOrdenesBusquedaRequestCantidad,
                listaOrdenesBusquedaRequest, onGetProgramaciones, fetchingListaOrdenesBusquedaRequest, onShowModalDetallePrograma,
                fetchingDescargarPDForden, listaOrdenesRMP, fetchingListaOrdenesRMP, listaOrdenesRMPCantidad , onGetOrdenes, onShowModalEditar,
                listaProgramacionOrden, onLimpiarGridFiltro , onShowModalVerProgramacion, onGetProgramacionOrden,onGetUnidades,
                listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas,fetchingListaUnidadesFuncionalesActivas,listaUnidadesFuncionalesActivas,
                
            } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario) 
        }
           

        // modal detalle rutinas
        const showDetalle = key => {
            console.log('key', key)
            let arregloInsumos = [];
            if (key.insumosByIdRutina) {
                arregloInsumos = key.insumosByIdRutina.map(insumo => insumo);
                for(let i in arregloInsumos){
                    if(arregloInsumos[i].productoByIdProducto){
                        arregloInsumos[i].descripcion = arregloInsumos[i].productoByIdProducto.descripcion;
                        if(arregloInsumos[i].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                            arregloInsumos[i].unidadMedida = arregloInsumos[i].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida
                        }
                    }
                }
            }
            onShowModalDetallePrograma(key, arregloInsumos, this.state.page);
        };


        const onChangeSelect = (value, field) => {
            console.log(value, field)
            let busqueda;
            if(field === "fecha"){
                busqueda = {fecha: value, idServicio: this.state.idServicio,programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: this.state.idPartida}
            } 
            // else if(field === "idServicio"){
            //     if(value){
            //         busqueda = {fecha: this.state.fecha, idServicio: value,programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: this.state.idPartida}
            //         this.setState({partida: undefined})
            //         this.props.onGetPartidasByIdServicio(value);
            //     } else {
            //         this.setState({partida: undefined});
            //         this.props.onCleanPartidasByIdServicio();
            //     } 
            // } 
            else if(field === "programa"){
                busqueda = {fecha: this.state.fecha, idServicio: this.state.idServicio,programa: value, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: this.state.idPartida}
            } else if(field === "idAreaFuncional") {
                if(value){
                    onGetUnidades(value.split('-')[0]);
                }
                busqueda = {fecha: this.state.fecha, idServicio: this.state.idServicio,programa: this.state.programa, idAreaFuncional: value , idUnidadFuncional: this.state.idUnidadFuncional, idPartida: this.state.idPartida}
            } 
            // else if(field === "idPartida") {
            //     busqueda = {fecha: this.state.fecha, idServicio: this.state.idServicio,programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: value}
            // }
            else {
                busqueda = {fecha: this.state.fecha, idServicio: this.state.idServicio,programa: this.state.programa,idAreaFuncional: this.state.idAreaFuncional , idUnidadFuncional: value, idPartida: this.state.idPartida}
            }
             
            this.setState({ [field]: value }, () => onGetProgramaciones(busqueda, this.state.page));
            this.setState({ busqueda: busqueda});
        };

        const onServicioSelect = (value, field) => {
            let busqueda;
            if(value){
                busqueda = {fecha: this.state.fecha, idServicio: value,programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: ''}
                this.setState({partida: undefined, idServicio: value, idPartida: ''})
                this.props.onGetPartidasByIdServicio(value);
                onGetProgramaciones(busqueda, this.state.page)
                this.formRef.current.resetFields(['idPartida']);
            } else {
                busqueda = {fecha: this.state.fecha, idServicio: '',programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: ''}
                this.setState({partida: undefined, idServicio: '', idPartida: ''});
                this.props.onCleanPartidasByIdServicio();
                onGetProgramaciones(busqueda, this.state.page);
                this.formRef.current.resetFields(['idPartida']);
            }
            this.setState({busqueda});
        };

        const onPartidaSelect = (value, field) => {
            let busqueda;
            if(value){
                busqueda = {fecha: this.state.fecha, idServicio: this.state.idServicio,programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: value}
                this.setState({idPartida: value})
                onGetProgramaciones(busqueda, this.state.page)
            } else {
                busqueda = {fecha: this.state.fecha, idServicio: this.state.idServicio,programa: this.state.programa, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idPartida: ''}
                this.setState({partida: undefined, idPartida: ''})
                onGetProgramaciones(busqueda, this.state.page);
                this.formRef.current.resetFields(['idPartida']);
            }
            this.setState({busqueda});
        };

    
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current
            })
            onGetProgramaciones(this.state.busqueda, pagination.current);
        };

        const handleTableChangeOrdenes = (pagination) => {
            this.setState({
                pageOrdenes: pagination.current
            });
            onGetOrdenes(this.state.busquedaOrdenes, pagination.current);
        };

        const editarOrden = (key) => {
            console.log('key', key);
            onShowModalEditar(key, this.state.page, this.state.busqueda); 
        };

        const generarPDF = (key) => {
            console.log('key', key);
            const folio = key.folioOrden;
            if(key.programa === "Mantenimiento"){
                this.props.onGenerarMantenimientoPDF(folio);
            } else if(key.programa === "Rutina"){
                this.props.onGenerarRutinaPDF(folio);
            } else {
                this.props.onGenerarPruebaPDF(folio);
            }
           
        };
        // grid ordenes caledanrio
        const verProgramacion = (key) => {
            console.log('key', key);
            let folio = key.folioOrden;
            onGetProgramacionOrden(folio, this.state.page);
            if(listaProgramacionOrden){
                onShowModalVerProgramacion(key, listaProgramacionOrden);
            }
        };


        const columns = [{
            title: 'Categoria',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center'
        },{
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
            align: 'center'
        },{
            title: 'Partida',
            dataIndex: 'partida',
            key: 'partida',
            align: 'center',
            render: (text, record) => (
                text === '-' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
            ),
        },{
            title: 'Programa',
            dataIndex: 'programa',
            key: 'programa',
            align: 'center'
        },{
            title: 'Nombre',
            dataIndex :'rutina',
            key :'rutina',
            align: 'center'
        },{
            title: 'Tipo',
            dataIndex: 'tipoRutina',
            key: 'tipoRutina',
            align: 'center'
        },{
            title: 'Clave RM',
            dataIndex: 'claveRutina',
            key: 'claveRutina',
            align: 'center'
        },{
            title: 'Ver Detalle',
            key: 'detalle',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.verDetalleDeOrdenRutinamantenimiento} onClick={ () => showDetalle(record) }> <FileSearchOutlined />
               </a>
            ),          
        },{
            title: 'Generar Orden',
            key: 'editar',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.generarOrdenDeRutinamantenimiento} onClick={ () => editarOrden(record) }> < SelectOutlined />
               </a>
            ),          
        }];

        const columnsOrdenes = [{
            title: 'Folio Orden',
            dataIndex: 'folioOrden',
            key: 'folioOrden',
            align: 'center'
        },{
            title: 'Estado de Orden',
            dataIndex: 'estadoOrden',
            key: 'estadoOrden',
            align: 'center'
        },{
            title: 'Nombre Rutina',
            dataIndex: 'rutina',
            key: 'rutina',
            align: 'center'
        },{
            title: 'Programa',
            dataIndex: 'programa',
            key: 'programa',
            align: 'center'
        },{
            title: 'Fecha Alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            align: 'center'
        },{
            title: 'Generar PDF',
            key: 'generar',
            align: 'center',
            render: (record) => (
                <a className='editarIcono' disabled={!permisosUsuario.generarPdfOrdenDeRmp} onClick={ () => generarPDF(record) }><FilePdfOutlined />
                </a>
            )         
        },{
            title: 'Ver Programación',
            key: 'programacion',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.verProgramaciónOrdenDeRmp} onClick={ () => verProgramacion(record) }><CalendarOutlined />
               </a>
            )         
        }];
        // radio button grid
        const onChangeTablaSeleccionada = e => {
            let valor = e.target.value;
            if(valor === "Filtrado"){
                this.setState({  btnTablaSeleccionada: valor, disabledSelectFiltro: false })
            } else {
                console.log('this.state.busquedaOrdenes :>> ', this.state.busquedaOrdenes);
                const busquedaOrdenes = { param: '' };
                onGetOrdenes(busquedaOrdenes, this.state.pageOrdenes);
                this.setState({ btnTablaSeleccionada: valor, disabledSelectFiltro: true })
                onLimpiarGridFiltro();
             }
        };

        const onBuscarFolio = (param) => {
            const busquedaOrdenes = { param: param };
            this.setState({ busquedaOrdenes: param })
            onGetOrdenes(busquedaOrdenes, this.state.pageOrdenes);
        };

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            //let fechaEjecucion = tareaSeleccionadaVC.fechaEjecucion;
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY");
            const fechaInicio = moment();
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {                
                return fecha && fecha > fechaVigencia;
            }
           //return fecha && moment(fecha.format('YYYY')).isBefore(moment().format('YYYY'))
        }

        
        return ( 
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Generar Orden de Rutina y Mantenimiento</div>}>
                    <Spin spinning={fetchingDescargarPDForden}>
                        {this.state.btnTablaSeleccionada === 'Filtrado' ? ( 
                        <>
                        <Form name="formulario" ref={this.formRef}>
                            <Row justify='space-between' gutter={[8,8]}>
                                <Col span={9}>
                                    <Form.Item  name="idServicio">
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            disabled={this.state.disabledSelectFiltro}
                                            placeholder="Seleccione el servicio"
                                            loading={fetchingListaServiciosRutinaFiltro}
                                            onChange= {value => onServicioSelect(value, 'idServicio')}
                                        >
                                            {listaServiciosRutinaFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item  name="idPartida">
                                        <Select
                                            allowClear
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una partidas"
                                            loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                            disabled={!this.props.listaPartidasByServicio4Fallas[0]}
                                            onChange={value => onPartidaSelect(value, 'idPartida')}
                                            value={this.state.partida}
                                        >
                                            {this.props.listaPartidasByServicio4Fallas.map(option =>
                                                <Option key={option.idPartida}>
                                                    {option.nomenclatura  + ': ' + option.nombre}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <RangePicker 
                                    format='DD-MM-YYYY'
                                    disabledDate={disabledRange}
                                    disabled={this.state.disabledSelectFiltro}
                                    onChange= {value => onChangeSelect(value, 'fecha')}
                                    style={{ width: '100%' }} />
                                </Col>
                            </Row>
                        </Form>
                            <Row justify='space-between'  gutter={[8,8]} style={{ marginBottom: '20px' }}>
                                <Col span={8}>
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el área funcional"
                                        loading={fetchingListaAreasFuncionalesActivas}
                                        disabled={this.state.disabledSelectFiltro}
                                        //onChange={onUnidadesFuncionales}
                                        onChange= {value => onChangeSelect(value, 'idAreaFuncional')}
                                    >
                                        {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional + "-" + option.areaFuncional}>{  option.areaFuncional }</Option>)}
                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el unidad funcional"
                                        loading={fetchingListaUnidadesFuncionalesActivas}
                                        disabled={this.state.disabledSelectFiltro}
                                        onChange= {value => onChangeSelect(value, 'idUnidadFuncional')}
                                    >
                                        {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional + "-" + option.unidadFuncional}>{  option.unidadFuncional }</Option>)}
                                    </Select>
                                </Col>

                                <Col span={8}>
                                    <Select allowClear onChange= {value => onChangeSelect(value, 'programa')} disabled={this.state.disabledSelectFiltro}
                                        style={{ width: '100%' }} placeholder="Selecciona un programa">
                                        <Option value='Rutina'>Rutina</Option>
                                        <Option value='Mantenimiento'>Mantenimiento</Option>
                                        <Option value='Prueba'>Prueba</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </>
                        ): (
                        <>
                            <Col span={24}>
                                <Search className='buscarModulos' placeholder="Buscar Orden" onSearch={value => onBuscarFolio(value)} style={{ width: '100%' }} />
                            </Col> 
                        </>
                        )}
                        <Divider />
                        <Row>
                            <Col span={4}>
                                <Radio.Group defaultValue="Filtrado" onChange={onChangeTablaSeleccionada} style={{ marginTop: 16 }}>
                                    <Radio value="Filtrado"> Filtro</Radio>
                                    <Radio value="Ordenes"> Ordenes</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        {this.state.btnTablaSeleccionada === "Filtrado" ? (
                        <Row >
                            <Col span={24}>
                                <br />
                                <Table
                                    size="small"
                                    rowKey="idRutina"
                                    columns={columns}
                                    dataSource={listaOrdenesBusquedaRequest}
                                    loading={fetchingListaOrdenesBusquedaRequest}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: listaOrdenesBusquedaRequestCantidad, simple: true, current: this.state.page }}
                                />
                            </Col>
                        </Row>
                        ): (
                        <>
                            <br/>
                            <Row type="flex" justify="space-between">
                                <Col span={24}>
                                    <br />
                                    <Table
                                        size="small"
                                        rowKey="folioOrden"
                                        columns={columnsOrdenes}
                                        dataSource={listaOrdenesRMP}
                                        loading={fetchingListaOrdenesRMP}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={handleTableChangeOrdenes}
                                        pagination={{ total: listaOrdenesRMPCantidad, simple: true, current: this.state.pageOrdenes }}
                                    />
                                </Col>
                            </Row>
                        </>
                        )}
                    </Spin>
                </Card>
                <ModalDetallePrograma/>
                <ModalGenerarOrden/>
                <ModalEditarOrden/>
                <ModalVerProgramacion/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        fetchingListaServiciosRutinaFiltro: state.ServiciosPlanificadosReducer.fetchingListaServiciosRutinaFiltro,
        listaServiciosRutinaFiltro: state.ServiciosPlanificadosReducer.listaServiciosRutinaFiltro,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        listaOrdenesBusquedaRequest: state.ServiciosPlanificadosReducer.listaOrdenesBusquedaRequest,
        listaOrdenesBusquedaRequestCantidad: state.ServiciosPlanificadosReducer.listaOrdenesBusquedaRequestCantidad,
        fetchingListaOrdenesBusquedaRequest: state.ServiciosPlanificadosReducer.fetchingListaOrdenesBusquedaRequest,
        listaOrdenesRMP: state.ServiciosPlanificadosReducer.listaOrdenesRMP,
        fetchingListaOrdenesRMP: state.ServiciosPlanificadosReducer.fetchingListaOrdenesRMP,
        listaOrdenesRMPCantidad: state.ServiciosPlanificadosReducer.listaOrdenesRMPCantidad,
        listaProgramacionOrden: state.ServiciosPlanificadosReducer.listaProgramacionOrden,

        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
        fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
        fetchingDescargarPDForden: state.ServiciosPlanificadosReducer.fetchingDescargarPDForden,

        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetProgramaciones: (busqueda, page) => {
            dispatch({ type: "LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST", busqueda, page})
        },
        onGetServiciosFiltroRutina: () => {
            dispatch({ type: "LISTA_SERVICIOS_RUTINAS_ACTIVOS_REQUEST" });
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onShowModalDetallePrograma: (programaSeleccionadaDetalle, programaSeleccionada, page) => {
            dispatch({ type: "SHOW_MODAL_DETALLE_PROGRAMA", showModalDetallePrograma: true,programaSeleccionadaDetalle, programaSeleccionada, page})
        },
        onShowModalGenerarOrden: (programaSeleccionadoParaOrden, programaGenerar, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_GENERAR_ORDEN", showModalGenerarOrden: true, programaSeleccionadoParaOrden, programaGenerar,page, busqueda});
        },
        onGetOrdenes: (busquedaOrdenes, pageOrdenes) => {
            dispatch({ type: "LISTA_ORDENES_RMP_ACTIVOS_REQUEST", busquedaOrdenes, pageOrdenes})
        },
        onShowModalEditar: (tareaSeleccionada, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ORDEN", showModalEditarOrden: true, tareaSeleccionada, page, busqueda})
        },
        onGetProgramacionOrden: (folio, page) => {
            dispatch({ type: "OBTENER_PROGRAMACION_ORDEN_REQUEST", folio, page})
        },
        onGenerarMantenimientoPDF: (folio) => {
            dispatch({ type: "DESCARGAR_PDF_MANTENIMIENTO_REQUEST", folio });
        },
        onGenerarPruebaPDF: (folio) => {
            dispatch({ type: "DESCARGAR_PDF_PRUEBA_REQUEST", folio });
        },
        onGenerarRutinaPDF: (folio) => {
            dispatch({ type: "DESCARGAR_PDF_RUTINA_REQUEST", folio });
        },
        onLimpiarGridFiltro: () => {
            dispatch({ type: "LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_SUCCESS", listaOrdenesBusquedaRequest: []});
        },
        onShowModalVerProgramacion: (verProgramacionSeleccionadoOrden, listaProgramacionOrden) => {
            dispatch({ type: "SHOW_MODAL_VER_PROGRAMACION_ORDEN", showModalVerProgramacionOrden: true, verProgramacionSeleccionadoOrden, listaProgramacionOrden })
        },
        onGetUnidades: (idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional});
        },
        onGetAreas: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST" });
        },

        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrdenRutinaMantenimientoContainer);