import React from 'react';
import { Form, Row, Col, Switch, Modal, Table, Input, Button, Tooltip, Select, message } from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEditarEstandarDeDesempeño from './ModalEditarEstandarDeDesempeño';
import ModalNuevoEstandarDeDesempeño from './ModalNuevoEstandarDeDesempeño';

const { Search } = Input;
const { Option } = Select;
const confirm = Modal.confirm;

class CatalogoEstandaresDeDesempeño extends React.Component {
     state={
          page: 1,
          busqueda: {referenciaEstandar: '', estandar: '', idsServicios: '', seccion: '', order: 'ASC', field: 'referencia'},
          referenciaEstandar: '',
          estandar: '',
          idsServicios: '',
          seccion: '',
          param: '',
          order: 'ASC',
          field: 'referencia',
          formulario: null,
     }

     componentDidMount(){
          this.props.onObtenerEstandaresDesempeño(1, this.state.busqueda);
          this.props.onObtenerReferenciasActivas();
          this.props.onObtenerFallaCalidad();
          this.props.onObtenerUnidadMedida();
          this.props.onObtenerMetodosSupervision();
          this.props.onRequestInfoProyecto();
          this.props.onObtenerSeccionServ();
          this.props.onObtenerServicios();
          const formulario = {
               referenciaBusqueda: '',
               descripcionBusqueda: '',
               estandarBusqueda: '',
               servicioSeccionBusqueda: ''
          }
          this.setState({
               formulario: formulario,
           })
     }

     render() {
          const {
               fetchingListaEstandaresDeDesempeñoBusqueda,
               listaEstandaresDeDesempeñoBusqueda,
               listaEstandaresDeDesempeñoBusquedaCantidad,
               
               onObtenerEstandaresDesempeño,
               onModalNuevoEstandarDesempeño,
               onModalEditarEstandarDesempeño,
               onEstadoEstandarDesempeño,

               listaReferenciasDeEstandaresActivos,
               onActualizarEstandar,
               onActualizarReferencia,
               onActualizarTiempoRespuesta, fetchingListaServiciosFiltro,
               listaServiciosFiltro, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, fetchingListaEstandaresDesempenoEspecificosActivos,
               listaEstandaresDesempenoEspecificosActivos, fetchingListaEstandaresFiltro,
               listaEstandaresFiltroActivos, onListaEstandarFiltro, onListaEstandarFiltroLimpiar

          } = this.props;
          
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
          permisosUsuario = JSON.parse(permisosUsuario);
          }
          const onBuscar = (formulario) => {
               const busqueda = { referenciaEstandar: formulario.referenciaBusqueda, estandar: formulario.estandarBusqueda, idsServicios: formulario.servicioBusqueda, seccion: formulario.seccionBusqueda, order: this.state.order, field: this.state.field }
               this.setState({ busqueda: busqueda, page: 1 });

               onObtenerEstandaresDesempeño(1, busqueda);
          }

          const columns = [
               {title: 'Estándar', dataIndex: 'referencia', key: 'referencia', sorter: true, width: '5%'},
               {title: 'Referencia', dataIndex: 'referenciaSub', key: 'referenciaSub', sorter: true, width: '5%'},
               {title: 'Clase', dataIndex: 'claseReferenciaReferencia', key: 'claseReferenciaReferencia', sorter: true, width: '5%'},
               {title: 'Descripción de referencia', dataIndex: 'descripcionReferencia', key: 'descripcionReferencia',  sorter: true, width: '10%'},
               {title: 'Sección', dataIndex: 'descripcionSecciones', key: 'descripcionSecciones', width: '10%', sorter: true},
               {title: 'Servicio', dataIndex: 'nombreServicio', key: 'nombreServicio', width: '25%', sorter: true},
               {title: 'Estándar de desempeño', dataIndex: 'descripcion', key: 'descripcion', width: '20%'},
               {title: 'Categoría', dataIndex: 'fallaCalidadCompleta', key: 'fallaCalidadCompleta', width: '7%'},
               {title: 'Tolerancia', dataIndex: 'toleranciaTabla', key: 'toleranciaTabla', align: 'right', width: '3%'},
               {title: 'Tiempo respuesta', dataIndex: 'tiempoRespuestaCompleto', key: 'tiempoRespuesta', width: '5%'},
               // {title: 'Unidad de Medida', dataIndex: 'unidadMedida', key: 'unidadMedida', width: '5%'},
               {title: 'Método supervisión', dataIndex: 'descripcionC', key: 'descripcionC', width: '7%'},
               // {title: 'Clave Método Supervisión', dataIndex: 'claveMetodoSupervision', key: 'claveMetodoSupervision', width: '5%'},
               {title: 'Fecha alta', dataIndex: 'fechaAlta', key: 'fechaAlta', width: '5%'},
               {title: 'Activo', dataIndex: 'activo', key: 'activo', width: '3%', align: 'center',
               render: (text, record) => (
                   <Switch checked={text} disabled={!permisosUsuario.estándarDeDesempeñoActivoinactivo} onChange={() => handleDesactivar(record)}/>
               ),},
               { title: 'Editar', key: 'editar', width: '3%', align: 'center',
               render: (text, record) => (
                   <a className='editarIcono' disabled={!permisosUsuario.editarEstandarDeDesempeño} onClick={() => onEditar(record)}><EditOutlined /></a>
               ),}
          ];

          const onEditar = (key) => {
               console.log('key');console.log(key);
               onActualizarEstandar(key.mostrarEspecifica);
               onActualizarReferencia(key.referenciaSub);
               onActualizarTiempoRespuesta(key.banderaTiempo);
               onModalEditarEstandarDesempeño(key, this.state.page, this.state.busqueda);
          }
          const handleDesactivar = (key) => {
               console.log(key);
               let page = this.state.page;
               let busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del estandar de desempeño?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoEstandarDesempeño(key.idEstandarDesempeno, page, busqueda)
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const handleTableChange = (pagination, filtro, orden) => {
               this.setState({ page: pagination.current,});
               if(orden){
                    let campo = 'referencia';
                    let order = 'ASC';
                
                    if(orden.order === 'descend'){
                        order = 'DESC';
                    }
    
                    if(orden.columnKey === 'referenciaSub'){
                        campo = 'rde.referencia';
                    }else if(orden.columnKey === 'claseReferenciaReferencia'){
                        campo = 'rde.claseReferencia';
                    }else if(orden.columnKey === 'referencia'){
                         campo = 'referencia';
                    }else if(orden.columnKey === 'descripcionReferencia'){
                         campo = 'rde.descripcion';
                    }else if(orden.columnKey === 'descripcionSecciones'){
                         campo = 'sse.descripcion';
                    }else if(orden.columnKey === 'nombreServicio'){
                         campo = 'ser.nombre';
                    }
                    
                    let busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: this.state.estandar, idsServicios: this.state.servicio, seccion: this.state.seccion, order: this.state.order, field: this.state.field }

                    onObtenerEstandaresDesempeño(pagination.current, busqueda);
                    this.setState({
                        order: order,
                        field: campo,
                        busqueda: busqueda,
                    });                   
                }
                else{
                    onObtenerEstandaresDesempeño(pagination.current, this.state.busqueda);
                }
          }

          
          const onNuevoEstandar = () => {
               console.log('busqueda: ', this.state.busqueda);
               onModalNuevoEstandarDesempeño(this.state.page, this.state.busqueda);
          }

          const scroll = {"x": 3200 | true, "y": 1000};

          const buscarServicios = (value) => {
               if (value.length >= 3) {
                    if (value) {
                         onListaServiciosFiltro(value);
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                   onListaServiciosFiltroLimpiar();
               }
          };

          const filtrarServicios = (value) => {
               let busqueda = {}
               if(value){
                    let serviciosLocal = [];

                    for(let i in value){
                         serviciosLocal.push(value[i].split(' - ')[0]);
                    }

                    busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: this.state.estandar, idsServicios: serviciosLocal.toString(), seccion: this.state.seccion, order: this.state.order, field: this.state.field }

                    this.setState({ busqueda: busqueda, page: 1, servicio: serviciosLocal.toString() });

                    onObtenerEstandaresDesempeño(1, busqueda);
               } else {
                    busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: this.state.estandar, idsServicios: '', seccion: this.state.seccion, order: this.state.order, field: this.state.field }

                    this.setState({ busqueda: busqueda, page: 1, servicio: '' });
                    onObtenerEstandaresDesempeño(1, busqueda);
               }
          };

          const seccionBusqueda = (value) => {
               let busqueda = {}
               if(value){
                    let seccionLocal = value;
                    busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: this.state.estandar, idsServicios: this.state.idsServicios, seccion: seccionLocal, order: this.state.order, field: this.state.field }

                    this.setState({ busqueda: busqueda, page: 1, seccion: seccionLocal });

                    onObtenerEstandaresDesempeño(1, busqueda);
               } else {
                    busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: this.state.estandar, idsServicios: this.state.idsServicios, seccion: '', order: this.state.order, field: this.state.field }

                    this.setState({ busqueda: busqueda, page: 1, seccion: '' });
                    onObtenerEstandaresDesempeño(1, busqueda);
               }
          };

          const buscarEstandar = (value) => {
               if (value.length >= 3) {
                   console.log(value);
                   onListaEstandarFiltro(value)
               } else {
                   onListaEstandarFiltroLimpiar();
               }
          };

          const filtroEstandar = (value) => {
               let busqueda = {}
               if(value){
                    let estandarLocal = value.split('-')[1];
                    busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: estandarLocal, idsServicios: this.state.idsServicios, seccion: this.state.seccion, order: this.state.order, field: this.state.field }

                    this.setState({ busqueda: busqueda, page: 1, estandar: estandarLocal });

                    onObtenerEstandaresDesempeño(1, busqueda);
               } else {
                    busqueda = { referenciaEstandar: this.state.referenciaEstandar, estandar: '', idsServicios: this.state.idsServicios, seccion: this.state.seccion, order: this.state.order, field: this.state.field }

                    this.setState({ busqueda: busqueda, page: 1, estandar: '' });
                    onObtenerEstandaresDesempeño(1, busqueda);
               }
          };

          return (
          <Form
          name="formulario"
          ref={this.formRef}
          onFinish={onBuscar}
           >
               <Row justify='space-between' gutter={[8,8]}>
                    {/* <Col span={15}>
                         <Search 
                         className='buscarModulos' 
                         placeholder="Ingresa la referencia, clase de referencia, descripción, referencia específica, servicio o sección" 
                         onSearch={value => onBuscar(value)} 
                         style={{ width: '100%' }}/>
                    </Col> */}
                    <Col span={24}>
                         <Row justify='space-around' gutter={8,8}>
                              <Col span={5}>
                                   <Form.Item
                                   name="referenciaBusqueda"
                                   >
                                        <Input className='inputFiltro' placeholder="Referencia/Clase a buscar"/>
                                   </Form.Item>
                              </Col>
                              
                              <Col span={6}>
                                   <Tooltip title='Ingresa descripción del estándar de desempeño a buscar'>
                                        <Form.Item
                                             name="estandarBusqueda"
                                        >
                                             <Select
                                                  mode='tag'
                                                  allowClear
                                                  style={{ width: '100%', textAlign: 'left' }}
                                                  placeholder="Ingresa el estándar de desempeño a buscar"
                                                  loading={fetchingListaEstandaresFiltro}
                                                  showSearch
                                                  filterOption={false}
                                                  onSearch={buscarEstandar}
                                                  notFoundContent={null}
                                                  onChange={filtroEstandar}
                                             >
                                                  {listaEstandaresFiltroActivos.map(option => <Option key={option.idEstandarDesempeno + '-' + option.descripcion}>
                                                  <Tooltip placement="topRight" title={option.descripcion}>
                                                       {option.descripcion}
                                                  </Tooltip>
                                                       </Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Tooltip>
                              </Col>

                              <Col span={5}>
                                   <Tooltip title='Ingresa servicio a buscar'>
                                        <Form.Item
                                            name="servicioBusqueda"
                                        >
                                            <Select
                                                mode='multiple'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa el servicio a buscar"
                                                loading={fetchingListaServiciosFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={buscarServicios}
                                                onChange={filtrarServicios}
                                                notFoundContent={null}
                                            >
                                                  {listaServiciosFiltro.map(option => 
                                                  <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                       <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre} placement="topRight">
                                                            {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                       </Tooltip>
                                                  </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Tooltip>
                              </Col>

                              <Col span={5}>
                                   <Tooltip title='Ingresa sección a buscar'>
                                        <Form.Item
                                             name="seccionBusqueda"
                                        >
                                             <Select
                                                mode='tag'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa la sección a buscar"
                                                loading={fetchingListaEstandaresDesempenoEspecificosActivos}
                                                showSearch
                                                notFoundContent={null}
                                                onChange={seccionBusqueda}
                                            >
                                                  {listaEstandaresDesempenoEspecificosActivos.map(option => 
                                                  <Option key={option.descripcion}>
                                                       <Tooltip title={option.descripcion } placement="right">
                                                            { option.descripcion }
                                                       </Tooltip>
                                                  </Option>)}
                                            </Select>
                                        </Form.Item>
                                   </Tooltip>
                              </Col>
                              <Col span={1}>
                                   <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                              </Col>
                              <Col span={1}>
                                   <Tooltip title='Nuevo Estándar de Desempeño' placement="topRight">
                                        <Button className="iconTer" shape="circle" onClick={onNuevoEstandar} disabled={!permisosUsuario.crearEstándarDeDesempeño} icon={<PlusOutlined />}/>
                                   </Tooltip>
                              </Col>
                         </Row>
                         
                    </Col>
                    {/* <Col offset={16} span={8}>
                         <Button onClick={onNuevoEstandar} disabled={!permisosUsuario.crearEstándarDeDesempeño} className='nuevoUsuario'>Nuevo Estándar de Desempeño</Button>
                    </Col> */}
                    <Col span={24}>
                         <Table 
                              size="small" 
                              rowKey="referencia" 
                              columns={columns}
                              dataSource={listaEstandaresDeDesempeñoBusqueda}
                              loading={fetchingListaEstandaresDeDesempeñoBusqueda}
                              locale={{ emptyText: 'Sin datos' }}
                              onChange={handleTableChange}
                              pagination={{ total: listaEstandaresDeDesempeñoBusquedaCantidad, simple: true, current: this.state.page}}
                              scroll= {scroll}
                         />         
                    </Col>
               </Row>
               <ModalNuevoEstandarDeDesempeño/>
               <ModalEditarEstandarDeDesempeño/>
          </Form>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListaEstandaresDeDesempeñoBusqueda: state.MecanismosDePagosReducer.fetchingListaEstandaresDeDesempeñoBusqueda,
          listaEstandaresDeDesempeñoBusqueda: state.MecanismosDePagosReducer.listaEstandaresDeDesempeñoBusqueda,
          listaEstandaresDeDesempeñoBusquedaCantidad: state.MecanismosDePagosReducer.listaEstandaresDeDesempeñoBusquedaCantidad,

          listaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresActivos,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.fetchingListaEstandaresDesempenoEspecificosActivos,
          listaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosActivos,
          fetchingListaEstandaresFiltro: state.MecanismosDePagosReducer.fetchingListaEstandaresFiltro,
          listaEstandaresFiltroActivos: state.MecanismosDePagosReducer.listaEstandaresFiltroActivos,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onObtenerEstandaresDesempeño: (page, busqueda) => {
               dispatch({ type: "ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
          },
          onEstadoEstandarDesempeño: (idEstandarDesempeno, page, busqueda) => {
               dispatch({ type: "ESTADO_ESTANDAR_DESEMPEÑO_REQUEST", idEstandarDesempeno: idEstandarDesempeno, page: page, busqueda: busqueda });
          },
          onModalNuevoEstandarDesempeño: (page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_NUEVO_ESTANDAR_DESEMPEÑO", showModalNuevoEstandaresDeDesempeño: true, page: page, busqueda: busqueda});
          },
          onModalEditarEstandarDesempeño: (EstandaresDeDesempeñoSeleccionado, page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_ESTANDAR_DESEMPEÑO", showModalEditarEstandaresDeDesempeño: true, EstandaresDeDesempeñoSeleccionado: EstandaresDeDesempeñoSeleccionado, page: page, busqueda: busqueda });
          },
          onObtenerReferenciasActivas: () => {
               dispatch({ type: "LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST" });
          },
          onObtenerFallaCalidad: () => {
               dispatch({ type: "LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST" });
          },
          onObtenerUnidadMedida: () => {
               dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida: 1 });
          },
          onObtenerMetodosSupervision: () => {
               dispatch({ type: "LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST" });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onObtenerSeccionServ: () => {
               dispatch({ type: 'LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST' });
          },
          onObtenerServicios: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
          onActualizarEstandar: (mostrarEspecifica) => {
               dispatch({ type: "ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE", mostrarEspecifica: mostrarEspecifica });
          },
          onActualizarReferencia: (refSeleccionado) => {
               dispatch({ type: "ESTADO_ESTANDAR_DESEMPEÑO_FAILURE", refSeleccionado: refSeleccionado });
          },
          onActualizarTiempoRespuesta: (banderaTiempo) => {
               dispatch({ type: "LISTA_ESTANDARES_DESEMPEÑO_FAILURE", banderaTiempo: banderaTiempo})
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onListaEstandarFiltro: (param) => {
               dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_REQUEST", param})
          },
          onListaEstandarFiltroLimpiar: () => {
               dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_SUCCESS", listaEstandaresFiltroActivos: []})
          },
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoEstandaresDeDesempeño);