import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, Row, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevaPartida extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevaPartida, showModalNuevaPartida, onNuevaPartida, onShowModalNuevaPartida,
            limpiarFormulario, onLimpiarFormulario, fetchingListaServiciosActivosF, listaServiciosActivosF, 
            onListaContratosPorServicio, listaContratosPorServicio, fetchingListaContratosPorServicio, onListaContratosPorServicioLimpiar
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevaPartida(formulario, this.props.page, this.props.busqueda, listaContratosPorServicio);
        };
        
        if(limpiarFormulario && limpiarFormulario === 'crearPartida'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onListaContratosPorServicioLimpiar();
        }

        const handleCancel = () => {
            onShowModalNuevaPartida();
            this.formRef.current.resetFields();
            onListaContratosPorServicioLimpiar();
        }
        
        const onServicioSeleccionado = (value) => {
            this.formRef.current.setFieldsValue({'idContrato': undefined});
            onListaContratosPorServicio(value.split(' - ')[0]);
        }
          
        return (
            <Modal
            visible={showModalNuevaPartida}
            title='Nueva Partida'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevaPartida}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={250} pattern="^([a-zA-ZáéíóúñÑÁÉÍÓÚ .,()-_])+$" title="El nombre debe ser letras, puntos, comas, paréntesis o guiones." placeholder="Ingresa el nombre" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                               <Form.Item
                                    label='Servicio'
                                    name='idServicio'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Ingresa el servicio a buscar"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch
                                        filterOption={false}
                                        onChange={onServicioSeleccionado}
                                        notFoundContent={null}
                                    >
                                        {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}  
                                    </Select>
                                </Form.Item> 
                            </Col>
                            {listaContratosPorServicio ? (
                            <Col span={24}>
                               <Form.Item
                                    label='Contrato'
                                    name='idContrato'
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Selecciona el contrato deseado"
                                        loading={fetchingListaContratosPorServicio}
                                        showSearch
                                        notFoundContent={null}
                                    >
                                        {listaContratosPorServicio.map(option => <Option key={option.clave + ' - ' + option.descripcion}>{option.clave + ' - ' + option.descripcion}</Option>)}  
                                    </Select>
                                </Form.Item> 
                            </Col>
                            ):false}
                            <Col span={24}>
                               <Form.Item
                                    label='Nomenclatura'
                                    name='nomenclatura'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={15} pattern="^([A-Z/])+$" title="La nomenclatura debe ser mayúsculas." placeholder="Ingresa la nomenclatura" />
                                </Form.Item> 
                            </Col>
                            <Col span={24}>
                               <Form.Item
                                    label='Observaciones'
                                    name='observaciones'
                                >
                                    <Input maxLength={250} placeholder="Ingresa la nomenclatura"/>
                                </Form.Item> 
                            </Col>
                            <Col span={12}>
                                <Button style={{width: '100%'}} className="btnFiltrar" htmlType="submit">Crear Partida</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevaPartida: state.InformacionOperativaReducer.fetchingNuevaPartida,
        showModalNuevaPartida: state.InformacionOperativaReducer.showModalNuevaPartida,
        page: state.InformacionOperativaReducer.page,
        busqueda: state.InformacionOperativaReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        listaContratosPorServicio: state.InformacionOperativaReducer.listaContratosPorServicio,
        fetchingListaContratosPorServicio: state.InformacionOperativaReducer.fetchingListaContratosPorServicio,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevaPartida: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_PARTIDA', showModalNuevaPartida: false, page: null, busqueda: null })
        },
        onNuevaPartida: ( formulario, page, busqueda, listaContratosPorServicio ) => {
            dispatch({ type: "NUEVA_PARTIDA_REQUEST" , formulario, page, busqueda, listaContratosPorServicio });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaContratosPorServicio: (idServicio) => {
            dispatch({ type: "LISTA_CONTRATOS_POR_SERVICIO_REQUEST", idServicio })
        },
        onListaContratosPorServicioLimpiar: () => {
            dispatch({ type: "LISTA_CONTRATOS_POR_SERVICIO_SUCCESS", listaContratosPorServicio: null })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaPartida);