import React from 'react';
import { Card, Col, Row, Button, Form, Tooltip, Select, Modal, Spin, Result, Table, Collapse, Badge, Radio, Divider, Typography, Input, Empty } from 'antd';
import { ExpandAltOutlined, FileExcelOutlined, FileSearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { Pie, HorizontalBar, Bar } from 'react-chartjs-2';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, color: 'white' };
const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography;

class RDODeductivas extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ 
          this.props.onMesesProcesadosAnioActual(); this.props.onDeductivaInterna(null); this.props.onDeductivaExterna(null); this.props.onDetalleDeductiva('Servicio', null); this.props.onDeductivasByServicio(null); this.props.onDeductivaAnterior(null);

          if(this.props.listaInfoProyecto.idProyecto){
               this.props.onGetListaAñosCalendario(this.props.listaInfoProyecto);
          } else{  this.props.onRequestInfoProyecto() }

          if(this.props.listaAñosCalendario[0]){
               this.props.onMesesProcesadosAnioActual(this.props.listaAñosCalendario[0]);
          }
     };

     state = { visible: false, tipoGrafica: 'Servicio', idMesCalendario: null, mesTxt: '', xScroll: 1400, visibleDrawer: false, tipePie: '' }

     handleOk = () => { this.setState({ visible: false}) };  
     handleCancel = () => { this.setState({ visible: false }) };

     render() {
          const { infoRDO_Internas, infoRDO_Externas, infoRDO_DeductivaByServicio, infoRDO_DeductivasAnteriores, onDescargar } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }
          let charDataPie = {
               labels: ['Generadas', 'En proceso', 'Fuera de tiempo', 'Cerradas'],
               datasets: [{  data: [94, 64, 14, 11],  backgroundColor: infoRDO_DeductivasAnteriores.colors, borderColor: '#FFF',  borderWidth: 1 }],
          };

          const onDetalle = () => { this.setState({ visible: true }) };

          const onMesSelected = (value) => {
               let idMesCalendario =  parseInt(value.split('-')[0]);
               this.setState({idMesCalendario, mesTxt: value.split('-')[1] });
               this.props.onDeductivaInterna(idMesCalendario); 
               this.props.onDeductivaExterna(idMesCalendario);
               this.props.onDetalleDeductiva(this.state.tipoGrafica, idMesCalendario);
               this.props.onDeductivasByServicio(idMesCalendario);
               this.props.onDeductivaAnterior(idMesCalendario);
          };

          const tableInternas = (
               <Collapse expandIconPosition='right' className='collapseDetalleRDO'>
                    <Panel header="Datos" key="1" showArrow={true}>
                         <Table
                              rowKey="identificador"
                              columns={[ 
                                   { title: 'Nomenclatura',  key: 'etiqueta', render: (record) => (
                                        <Badge color={record.color} text={record.etiqueta}/>
                                   ),  },
                                   { title: 'Servicio', dataIndex: 'etiquetaLarga', key: 'etiquetaLarga' },
                                   { title: 'Deducción', dataIndex: 'deduccion', key: 'deduccion', align: 'right' },
                              ]}
                              dataSource={ this.props.listaRDODeductivasInternas }
                              loading={ this.props.fetchingListaRDODeductivasInternas }
                              locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                              size='small'
                              pagination={false}
                         />
                    </Panel>
               </Collapse>
          );

          const tableExternas = (
               <Collapse expandIconPosition='right' className='collapseDetalleRDO'>
                    <Panel header="Datos" key="1" showArrow={true}>
                         <Table
                              rowKey="identificador"
                              columns={[ 
                                   { title: 'Nomenclatura',  key: 'etiqueta', render: (record) => (
                                        <Badge color={record.color} text={record.etiqueta}/>
                                   ),  },
                                   { title: 'Servicio', dataIndex: 'etiquetaLarga', key: 'etiquetaLarga' },
                                   { title: 'Deducción', dataIndex: 'deduccion', key: 'deduccion', align: 'right' },
                              ]}
                              dataSource={ this.props.listaRDODeductivasExternas }
                              loading={ this.props.fetchingListaRDODeductivasExternas }
                              locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                              size='small'
                              pagination={false}
                         />
                    </Panel>
               </Collapse>
          );

          const tableAnteriores = (
               <Collapse expandIconPosition='right' className='collapseDetalleRDO'>
                    <Panel header="Datos" key="1" showArrow={true}>
                         <Table
                              rowKey="identificador"
                              columns={[ 
                                   { title: 'Nomenclatura',  key: 'etiqueta', render: (record) => (
                                        <Badge color={record.color} text={record.etiqueta}/>
                                   ),  },
                                   { title: 'Servicio', dataIndex: 'etiquetaLarga', key: 'etiquetaLarga' },
                                   { title: 'Tickets', dataIndex: 'cantidad', key: 'cantidad', align: 'right' },
                              ]}
                              dataSource={ this.props.deductivasAnterioresRDO }
                              loading={ this.props.fetchingDeductivasAnterioresRDO }
                              locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                              size='small'
                              pagination={false}
                         />
                    </Panel>
               </Collapse>
          );

          const chartDeductivaAnterior = (
               <Spin spinning={this.props.fetchingDeductivasAnterioresRDO}>
                    { infoRDO_DeductivasAnteriores.total > 0 ? (<>
                         <Pie 
                              data={{
                                   labels: infoRDO_DeductivasAnteriores.etiquetas,
                                   datasets: [{  data: infoRDO_DeductivasAnteriores.informacion,  backgroundColor: infoRDO_DeductivasAnteriores.colors, borderColor: '#fff',  borderWidth: 1.5 }],
                              }}
                              options={{
                                   legend: { display: true, position: "right", labels: {boxWidth: 15},
                                        onHover: function (event, legendItem) {
                                             let chart = this.chart;
                                             let index = legendItem.index;
                                             let segment = chart.getDatasetMeta(0).data[index];
                                             chart.tooltip._active = [segment]
                                             chart.tooltip.update()
                                             chart.draw()
                                        },
                                        onLeave: function (event, legendItem) {
                                             let chart = this.chart;
                                             let index = legendItem.index;
                                             chart.tooltip._active = []
                                             chart.tooltip.update()
                                             chart.draw()
                                        }
                                   },
                                   hover: { animationDuration: 7 },
                                   pieceLabel: { fontSize: 12, fontColor: '#FFF' },
                                   tooltips: {
                                        callbacks: {
                                             title: function(tooltipItem, data) {
                                                  return data['labels'][tooltipItem[0]['index']];
                                             },
                                             label: function(tooltipItem) {
                                                  return infoRDO_DeductivasAnteriores.eLargas[tooltipItem['index']];
                                             },
                                             afterLabel: function(tooltipItem, data) {
                                                  let dataset = data.datasets[tooltipItem.datasetIndex];
                                                  let currentValue = dataset.data[tooltipItem.index];
                                                  let percentage = (((currentValue * 100) / infoRDO_DeductivasAnteriores.total)).toFixed(2);
                                                  
                                                  return 'Tickets: ' +  infoRDO_DeductivasAnteriores.informacion[tooltipItem['index']] + ' | ' + percentage + '%';
                                             }
                                        },
                                        //backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false,
                                        backgroundColor: '#FFF',
                                        titleFontColor: '#0e2c82',
                                        bodyFontColor: '#1747ca',
                                        mode: 'nearest',
                                        "enabled": true,
                                        displayColors: false,
                                        caretSize: 0,
                                        titleFontSize: 12,
                                        bodyFontSize: 11,
                                        bodySpacing: 0,
                                        titleSpacing: 0,
                                        xPadding: 1,
                                        yPadding: 1,
                                        cornerRadius: 2,
                                        titleMarginBottom: 2,
                                   },
                              }}
                         />
                    </>): (
                         <Empty
                              // status="info"
                              description="No hay servicios con tickets anteriores para el mes."
                         />
                    )}
               </Spin>
          );
          
          const chartDeduccionInterna = (
               <Spin spinning={this.props.fetchingListaRDODeductivasInternas}>
                    { this.props.infoRDO_Internas.total > 0 ? (<>
                         <Pie 
                              data={{
                                   labels: this.props.infoRDO_Internas.etiquetas,
                                   datasets: [{  data: this.props.infoRDO_Internas.informacion,  backgroundColor: infoRDO_Internas.colors, borderColor: '#fff',  borderWidth: 1.5 }],
                              }}
                              options={{
                                   legend: { display: true, position: "right", labels: {boxWidth: 15},
                                        onHover: function (event, legendItem) {
                                             let chart = this.chart;
                                             let index = legendItem.index;
                                             let segment = chart.getDatasetMeta(0).data[index];
                                             chart.tooltip._active = [segment]
                                             chart.tooltip.update()
                                             chart.draw()
                                        },
                                        onLeave: function (event, legendItem) {
                                             let chart = this.chart;
                                             let index = legendItem.index;
                                             chart.tooltip._active = []
                                             chart.tooltip.update()
                                             chart.draw()
                                        }
                                   },
                                   hover: { animationDuration: 7 },
                                   pieceLabel: { fontSize: 12, fontColor: '#FFF' },
                                   tooltips: {
                                        callbacks: {
                                             title: function(tooltipItem, data) {
                                                  return data['labels'][tooltipItem[0]['index']];
                                             },
                                             label: function(tooltipItem) {
                                                  return infoRDO_Internas.eLargas[tooltipItem['index']];
                                             },
                                             afterLabel: function(tooltipItem, data) {
                                                  let dataset = data.datasets[tooltipItem.datasetIndex];
                                                  let currentValue = dataset.data[tooltipItem.index];
                                                  let percentage = (((currentValue * 100) / infoRDO_Internas.total)).toFixed(2);
                                                  
                                                  return 'Deducción: ' +  infoRDO_Internas.informacionFormt[tooltipItem['index']] + ' | ' + percentage + '%';
                                             }
                                        },
                                        //backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false
                                        backgroundColor: '#FFF',
                                        titleFontColor: '#0e2c82',
                                        bodyFontColor: '#1747ca',
                                        mode: 'nearest',
                                        "enabled": true,
                                        displayColors: false,
                                        caretSize: 0,
                                        titleFontSize: 12,
                                        bodyFontSize: 11,
                                        bodySpacing: 0,
                                        titleSpacing: 0,
                                        xPadding: 1,
                                        yPadding: 1,
                                        cornerRadius: 2,
                                        titleMarginBottom: 2,
                                   },
                              }}
                         />
                    </>): (
                         <Empty
                              //status="info"
                              description="No hay servicios con tickets por deducción interna para el mes."
                         />
                    )}
               </Spin>
          );

          const chartDeduccionExterna = (
               <Spin spinning={this.props.fetchingListaRDODeductivasExternas}>
                    { this.props.infoRDO_Externas.total > 0 ? (
                         <Pie 
                              data={{
                                   labels: this.props.infoRDO_Externas.etiquetas,
                                   datasets: [{  data: this.props.infoRDO_Externas.informacion,  backgroundColor: this.props.infoRDO_Externas.colors, borderColor: '#fff',  borderWidth: 1.5 }],
                              }}
                              options={{
                                   legend: { display: true, position: "right", labels: {boxWidth: 15},
                                        onHover: function (event, legendItem) {
                                             let chart = this.chart;
                                             let index = legendItem.index;
                                             let segment = chart.getDatasetMeta(0).data[index];
                                             chart.tooltip._active = [segment]
                                             chart.tooltip.update()
                                             chart.draw()
                                        },
                                        onLeave: function (event, legendItem) {
                                             let chart = this.chart;
                                             let index = legendItem.index;
                                             chart.tooltip._active = []
                                             chart.tooltip.update()
                                             chart.draw()
                                        }
                                   },
                                   hover: { animationDuration: 7 },
                                   pieceLabel: { fontSize: 12, fontColor: '#FFF' },
                                   tooltips: {
                                        callbacks: {
                                             title: function(tooltipItem, data) {
                                                  return data['labels'][tooltipItem[0]['index']];
                                             },
                                             label: function(tooltipItem) {
                                                  return infoRDO_Externas.eLargas[tooltipItem['index']];
                                             },
                                             afterLabel: function(tooltipItem, data) {
                                                  let dataset = data.datasets[tooltipItem.datasetIndex];
                                                  let currentValue = dataset.data[tooltipItem.index];
                                                  let percentage = (((currentValue * 100) / infoRDO_Externas.total)).toFixed(2);
                                                  
                                                  return 'Deducción: ' +  infoRDO_Externas.informacionFormt[tooltipItem['index']] + ' | ' + percentage + '%';
                                             }
                                        },
                                        //backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false
                                        backgroundColor: '#FFF',
                                        titleFontColor: '#0e2c82',
                                        bodyFontColor: '#1747ca',
                                        mode: 'nearest',
                                        "enabled": true,
                                        displayColors: false,
                                        caretSize: 0,
                                        titleFontSize: 12,
                                        bodyFontSize: 11,
                                        bodySpacing: 0,
                                        titleSpacing: 0,
                                        xPadding: 1,
                                        yPadding: 1,
                                        cornerRadius: 2,
                                        titleMarginBottom: 2,
                                   },
                              }}
                         />
                    ): (
                         <Empty
                              //status="info"
                              description="No hay servicios con tickets por deducción externa para el mes."
                         />
                    )}
               </Spin>
          );

          const maxChart = (value) => {
               if(value === 'externas'){
                    Modal.info({
                         width: '75%',
                         title: 'Porcentaje de Deducción por Servicio (Externas)',
                         content: (
                              <Row type="flex" justify='center' align='middle' gutter={[0,8]}>
                                   <Col span={24}> <br/> {chartDeduccionExterna} </Col>
                                   <Col span={24}> <br/> {tableExternas} </Col>
                              </Row>
                         ),
                    });
               } else if (value === 'internas'){
                    Modal.info({
                         width: '75%',
                         title: 'Porcentaje de Deducción por Servicio (Internas)',
                         content: (
                              <Row type="flex" justify='center' align='middle' gutter={[0,8]}>
                                   <Col span={24}> <br/> {chartDeduccionInterna} </Col>
                                   <Col span={24}> <br/> {tableInternas} </Col>
                              </Row>
                         ),
                    });
               } else {
                    Modal.info({
                         width: '75%',
                         title: 'Servicios con Tickets de Meses Anteriores',
                         content: ( <>
                              <Row type="flex" justify='center' align='middle' gutter={[0,8]}>
                                   <Col span={24}> <br/> {chartDeductivaAnterior} </Col>
                                   <Col span={24}> <br/> {tableAnteriores} </Col>
                              </Row>
                         </> ),
                    });
               }
          };

          const charDataBarQR = {
               labels: infoRDO_DeductivaByServicio.etiquetas,
               datasets: [
                    { label: 'Monto Total Original', data: infoRDO_DeductivaByServicio.informacionOriginal,  backgroundColor: '#ff8c6295',  borderColor: '#ff8c62',  borderWidth: 1 },
                    { label: 'Monto Total Conciliado', data: infoRDO_DeductivaByServicio.informacionConciliada, backgroundColor: '#0e2c8295', borderColor: '#1747ca',  borderWidth: 1 },
               ],
          }

          const optionsDataBarQR = {
               responsive: true,
               maintainAspectRatio: false,
               // pieceLabel: {
               //      render: 'value',
               //      overlap: true,
               //      fontColor: '#000',
               //      fontSize: 12,
               // },

               scales: {
                    xAxes: [{
                         display: true,
                         ticks: {mirror: false},
                         gridLines: { display : true },
                         position: 'left',
                         minRotation : 90
                         // scaleLabel: { display: true, labelString: ['Servicios'] }
                    }],
                    yAxes: [{  
                         display: true,
                         //scaleLabel: { display: true, labelString: ['No. Quejas y Reclamos'] } 
                         ticks: {
                              beginAtZero:true,
                              userCallback: function(value, index, values) {
                                  value = value.toString();
                                  value = value.split(/(?=(?:...)*$)/);
                                  value = value.join(',');
                                  return value;
                              },
                              minRotation: 0
                         },
                         position: 'bottom',
                         type: "linear",
                    }]
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) {
                              return data['labels'][tooltipItem[0]['index']] + ': ' + infoRDO_DeductivaByServicio.eLargas[tooltipItem[0]['index']];
                         },
                         label: function(tooltipItem, data) { 
                              return 'Monto: ' + tooltipItem.yLabel.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
                         },
                    },
                    backgroundColor: '#FFF',
                    titleFontColor: '#0e2c82',
                    bodyFontColor: '#1747ca',
                    position: 'nearest',
                    mode: 'nearest',
                    "enabled": true,
                    displayColors: false,
                    caretSize: 0,
                    titleFontSize: 12,
                    bodyFontSize: 11,
                    bodySpacing: 0,
                    titleSpacing: 0,
                    xPadding: 1,
                    yPadding: 1,
                    cornerRadius: 2,
                    titleMarginBottom: 2,
               },
               legend: { display: true, position: "top", labels: {boxWidth: 15} },
               plugins: {
                    datalabels: {
                      anchor: 'end',
                      align: 'end',
                      rotation: -45
                    }
               }
          }

          const onChangeTipoDetalle = (value) => { 
               let xScroll = 1400;
               if (value.target.value === 'GrupoSer') { xScroll = 800; }
               this.setState({ tipoGrafica: value.target.value, xScroll }); 
               this.props.onDetalleDeductiva(value.target.value, this.state.idMesCalendario);
          };

          const descargarDetalle = () => { onDescargar(this.state.tipoGrafica, this.state.idMesCalendario); };

          const showDrawer = () => {
               this.setState({ visibleDrawer: true });
          };
           
          const   onClose = () => {
               this.setState({
                    visibleDrawer: false,
               });
          };
           
          const onAñoCalendario = (value) => {
               //ALGO PARA EL COMMIT
               this.formRef.current.setFieldsValue({'mesCalendario': undefined});
               this.props.onMesesProcesadosAnioActual(value);
          };

          return (
               <Row gutter={[8,8]} type="flex">
                    <Col span={24} style={{backgroundColor: '#F2F2F2', borderBottomLeftRadius: '1em', borderBottomRightRadius:'1em'}}>
                         <Row gutter={[0,8]} type="flex" align='middle'>
                              <Col span={5} style={{backgroundColor: '#737373', paddingBottom: '1em', paddingTop: '1em', borderTopLeftRadius: '1em'}}>
                                   {/* { this.props.listaMesesProcesados[0] ? ( */}
                                   { true ? (
                                   <Form name="formulario" ref={this.formRef} {...layout} initialValues={ this.props.listaMesesProcesados[0] }>
                                        <Row justify='space-around' align='middle'>
                                             {this.props.listaAñosCalendario[0] ? (
                                                  <Col span={7}> <br />
                                                       <Form.Item name="anio" >
                                                            <Select
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Año"
                                                                 onChange={onAñoCalendario}
                                                                 loading={this.props.fetchingListaAñosCalendario}
                                                            >
                                                                 {this.props.listaAñosCalendario.map(option => <Option key={option}>{option}</Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             ):false}

                                             <Col span={11} ><br />
                                                  <Form.Item name="mesCalendario" >
                                                       <Select  placeholder='Mes' loading={this.props.fetchingListaMesesProcesados} onChange={(e) => onMesSelected(e)} >
                                                            {this.props.listaMesesProcesados.map(option => <Option key={option.idMesCalendario + '-' + option.mesCalendario}>{option.mesCalendario}</Option>)}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>

                                             <Col span={3}>
                                                  <Tooltip title='Más Detalle'>
                                                       <Button disabled={!permisosUsuario.verDetalleDeMonitorDeDeductivasPorMes ? true : !this.props.listaMesesProcesados[0]} className="iconTer" shape='circle' icon={<FileSearchOutlined /> }  onClick={onDetalle}/>
                                                  </Tooltip>
                                             </Col>
                                        </Row>
                                   </Form>
                                   ):(<> 
                                        <Row justify='space-around' align='middle' gutter={[0,0]} style={{ padding:'1em' }}>
                                             <Col span={24} >
                                                  <Input disabled placeholder='No hay meses procesados'/>
                                             </Col>
                                        </Row>
                                   </>) }
                              </Col>
                              
                              <Col span={19} style={{backgroundColor: '#E4E4E4', paddingBottom: '2em', paddingTop: '2em', paddingLeft: '2em', borderTopRightRadius:'1em'}}>
                                   <Row justify='space-around' align='middle'>
                                        <Col span={24}>
                                             <Row justify='start'>
                                                  {/* <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em'}} strong>RDO </Text>  */}
                                                  
                                                  <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', paddingLeft: '0.5em'}}>Monitor de Deductivas</Text>
                                             </Row>
                                        </Col>
                                   </Row>
                              </Col>
                              
                              <Col span={24} style={{backgroundColor: '#dddddd10', borderBottomRightRadius: '1em', borderBottomLeftRadius:'1em', borderColor: '#E4E4E4'}} display='flex'>
                                   <Row gutter={[0,16]} type="flex" align='middle' justify='center' style={{padding:'6px'}}>
                                        <Col xs={24}>
                                             <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#F2F2F2'}} title={<p style={{textAlign:'left', color: '#0e2c82'}}>Monto Deductivas por Servicio</p>}>
                                                  <Spin spinning={this.props.fetchingDeductivaByServicio}>
                                                       <div style={{ height: '25em' }}>
                                                                 <Bar data={charDataBarQR} options={optionsDataBarQR} />
                                                       </div>
                                                  </Spin>
                                             </Card>
                                        </Col>
                                   </Row>

                                   <Row gutter={[16,16]} justify='space-around' style={{padding:'6px'}}>
                                        <Col xs={8} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={ <Tooltip title='Servicios con Tickets de Meses Anteriores'><Text style={{textAlign:'left', fontSize:12, color: '#0e2c82'}}>Servicios con Tickets de Meses Anteriores</Text></Tooltip> } style={{backgroundColor:'#F2F2F2'}}>
                                                  { chartDeductivaAnterior }
                                                  { this.props.infoRDO_DeductivasAnteriores.total > 0 ? (
                                                  <Row justify='end'>
                                                       <Divider />
                                                       <Tooltip title='Maximizar'> <div className='iconButton' ><ExpandAltOutlined onClick={e => maxChart('anteriores')} /> </div> </Tooltip>
                                                  </Row> ):false }
                                             </Card>
                                        </Col>
                                        <Col xs={8} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={<Tooltip title='Porcentaje Deducción por Servicio (Internas)'><Text style={{textAlign:'left', fontSize:12, color: '#0e2c82'}}>Porcentaje Deducción por Servicio (Internas)</Text></Tooltip>} style={{backgroundColor:'#F2F2F2'}}>
                                                  { chartDeduccionInterna }
                                                  { this.props.infoRDO_Internas.total > 0 ? ( 
                                                  <Row justify='end'>
                                                       <Divider />
                                                       <Tooltip title='Maximizar'> <div className='iconButton' ><ExpandAltOutlined onClick={e => maxChart('internas')} /> </div> </Tooltip>
                                                  </Row> ):false}
                                             </Card>
                                        </Col>

                                        <Col xs={8} display='flex'>
                                             <Card className="cardCatalogos" bordered={false} title={<Tooltip title='Porcentaje Deducción por Servicio (Externas)'><Text style={{textAlign:'left', color: '#0e2c82', fontSize:12}}>Porcentaje Deducción por Servicio (Externas)</Text></Tooltip>} style={{backgroundColor:'#F2F2F2'}}>
                                                  { chartDeduccionExterna }
                                                  { this.props.infoRDO_Externas.total > 0 ? (
                                                  <Row justify='end'>
                                                       <Divider />
                                                       <Tooltip title='Maximizar'> <div className='iconButton' ><ExpandAltOutlined onClick={e => maxChart('externas')} /> </div> </Tooltip>
                                                  </Row> ): false }
                                             </Card>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                         
                    </Col>

                    <Col span={24}>
                    { this.props.listaMesesProcesados[0] ? (
                         <Modal title="Comparativo de Deductivas" visible={this.state.visible} footer={false} onCancel={this.handleCancel}  width = {'90%'} >
                              <Table
                                   title={ () => (
                                        <>
                                             <Row justify='center' type="flex" align='middle'>
                                                  <h2>Comparativo de Deductivas de <b>{ this.state.mesTxt !== '' ? (this.state.mesTxt) : (this.props.listaMesesProcesados[0].mesCalendario) }</b></h2>
                                             </Row>
                                             <Row justify='space-between' gutter={[8,8]} type="flex" align='middle' style={{paddingRight:'1em', paddingLeft:'1em'}}>
                                                  <Col span={6}>
                                                       <Radio.Group defaultValue="Servicio" buttonStyle="solid" onChange={(e) => onChangeTipoDetalle(e)}>
                                                            <Radio value="Servicio">Servicio</Radio>
                                                            <Radio value="GrupoSer">Grupo de Servicios</Radio>
                                                       </Radio.Group>
                                                  </Col>
                                                  <Col span={1}>
                                                       <Tooltip title='Exportar a Excel'>
                                                            <Button disabled={!permisosUsuario.exportarExcelDetalleDeMonitorDeDeductivasPorMes} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingDetalleDeductivaRDOExcel} onClick={descargarDetalle}/>
                                                       </Tooltip>
                                                  </Col>
                                             </Row>
                                        </>)
                                   }
                                   rowKey="id"
                                   size='small'
                                   columns={this.props.detalleDeductivaRDO.columns}
                                   dataSource={this.props.detalleDeductivaRDO.data}
                                   loading={this.props.fetchingDetalleDeductivaRDO}
                                   locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                   pagination={false}
                                   scroll={{ x: this.state.xScroll, y: 600 }}
                              />
                         </Modal>
                    ):false}
                    </Col>
               </Row>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaMesesProcesados: state.MecanismosDePagosReducer.fetchingListaMesesProcesados,
          listaMesesProcesados: state.MecanismosDePagosReducer.listaMesesProcesados,
          fetchingListaRDODeductivasInternas: state.MecanismosDePagosReducer.fetchingListaRDODeductivasInternas,
          listaRDODeductivasInternas: state.MecanismosDePagosReducer.listaRDODeductivasInternas,
          infoRDO_Internas: state.MecanismosDePagosReducer.infoRDO_Internas,
          fetchingListaRDODeductivasExternas: state.MecanismosDePagosReducer.fetchingListaRDODeductivasExternas,
          infoRDO_Externas: state.MecanismosDePagosReducer.infoRDO_Externas,
          detalleDeductivaRDO: state.MecanismosDePagosReducer.detalleDeductivaRDO,
          fetchingDetalleDeductivaRDO: state.MecanismosDePagosReducer.fetchingDetalleDeductivaRDO,
          listaRDODeductivasExternas: state.MecanismosDePagosReducer.listaRDODeductivasExternas,
          fetchingDetalleDeductivaRDOExcel: state.MecanismosDePagosReducer.fetchingDetalleDeductivaRDOExcel,
          infoRDO_DeductivaByServicio: state.MecanismosDePagosReducer.infoRDO_DeductivaByServicio,
          fetchingDeductivaByServicio: state.MecanismosDePagosReducer.fetchingDeductivaByServicio,

          infoRDO_DeductivasAnteriores: state.MecanismosDePagosReducer.infoRDO_DeductivasAnteriores,
          fetchingDeductivasAnterioresRDO: state.MecanismosDePagosReducer.fetchingDeductivasAnterioresRDO,
          deductivasAnterioresRDO: state.MecanismosDePagosReducer.deductivasAnterioresRDO,

          fetchingListaAñosCalendario: state.MecanismosDePagosReducer.fetchingListaAñosCalendario,
          listaAñosCalendario: state.MecanismosDePagosReducer.listaAñosCalendario,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onMesesProcesadosAnioActual: (anio) => {
               dispatch({ type: 'LISTA_MESES_PROCESADOS_REQUEST', anio });
          },
          onDeductivaInterna: (idMesCalendario) => {
               dispatch({ type: 'RDO_DEDUCTIVAS_INTERNAS_REQUEST', idMesCalendario });
          },
          onDeductivaExterna: (idMesCalendario) => {
               dispatch({ type: 'RDO_DEDUCTIVAS_EXTERNAS_REQUEST', idMesCalendario });
          },
          onDeductivaAnterior: (idMesCalendario) => {
               dispatch({ type: 'DEDUCTIVAS_ANTERIORES_RDO_REQUEST', idMesCalendario });
          },
          onDetalleDeductiva: (tipoGrafica, idMesCalendario) => {
               dispatch({ type: 'DETALLE_DEDUCTIVA_RDO_REQUEST', tipoGrafica, idMesCalendario });
          },
          onDescargar: (tipoGrafica, idMesCalendario) => {
               dispatch({ type: 'DETALLE_DEDUCTIVA_RDO_EXCEL_REQUEST', tipoGrafica, idMesCalendario });
          },
          onDeductivasByServicio: (idMesCalendario) => {
               dispatch({ type: 'DEDUCTIVA_POR_SERVICIO_REQUEST', idMesCalendario });
          },
          onGetListaAñosCalendario: (listaInfoProyecto) => {
               dispatch({ type: 'LISTA_AÑO_CALENDARIO_REQUEST', listaInfoProyecto });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST', añoMesCalendario: true });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RDODeductivas);