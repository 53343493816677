import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Input, Spin, Alert } from 'antd';
import { connect } from "react-redux";
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class NoFolio extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {

     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               onCloseModalNoFolio,
               showModalNoFolio,
               onShowModalIngresarFolio,
          } = this.props;

          const onRegresar = () => {
               setTimeout(onShowModalIngresarFolio(), 2000);
               onCloseModalNoFolio();
          };

          const onFinish = formulario => {              
               console.log('Success:', formulario);
               onRegresar();
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               this.formRef.current.resetFields();
               onCloseModalNoFolio();
          };

          const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

          const loading = (
               <Spin tip="Validando Folio..." indicator={antIcon} style={{ color: '#ff8c62' }}>
   
               </Spin>
          )

          return (
               <Modal
                    visible={showModalNoFolio}
                    title={''}
                    onCancel={handleCancel}
                    footer={false}
                    width={'30%'}
               >

                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                    >

                         <Row justify='space-around'>
                              <Col span={24}>
                                   <div style={{textAlign:"center"}}>
                                   <CloseCircleOutlined spin={false} style={{fontSize: 80, color:"#fc4040"}}/>
                                   </div>
                              </Col>
                              
                              <Col span={24}>
                                   <br/>
                                   <h3 style={{ textAlign:"center" }}>
                                   <strong style={{ color:"#fc4040", }}>No existen productos para conteo</strong> con las especificaciones señaladas, no se ha generado ningun folio.
                                   </h3>
                              </Col>

                              {/* <Col span={24}>
                                   <Button className="btnPrueba" style={{ width: '100%' }} htmlType='submit'>
                                        Regresar
                                   </Button>
                              </Col> */}
                         </Row>

                    </Form>

               </Modal>

          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalNoFolio: state.AlmacenesReducer.showModalNoFolio,
          showModalIngresarFolio: state.AlmacenesReducer.showModalIngresarFolio,
          tipoInventario: state.AlmacenesReducer.tipoInventario,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onCloseModalNoFolio: () => {
               dispatch({ type: 'SHOW_MODAL_NO_FOLIO', showModalNoFolio: false });
          },
          onShowModalIngresarFolio: () => {
               dispatch({ type: 'SHOW_MODAL_INGRESAR_FOLIO', showModalIngresarFolio: true });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoFolio);
