import React, { Component } from 'react';
import { Form, Button, Modal, Row, Col, InputNumber, Input, Divider, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;
const { TextArea } = Input;
class ListaTiempoDeGarantia extends Component {
     formRef = React.createRef();
     state = {
          fallaSeleccionado: []
     }

     render() { 
          const { 
               onCambiarVista, 
               formularioDisponibilidad, 
               FallasDeDisponibilidadSeleccionado,
               listaCategoriasDisponibilidad,
               listaCatDisponibilidad
          } = this.props

          const onFinish = formulario => {
               let listaDisponibilidad = []
               let categoriaEditados = []
               let noIguales = true
               for (var key in formulario) {
                   if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                       let idCampo = key.split('-');
                       if (idCampo.length === 2) {
                           let idFallaDisponibilidad = idCampo[1];
                           let campo = idCampo[0];
                           let valor = formulario[key];
       
                           let categoriaFormulario = {[campo]: parseInt(valor), 'idFallaDisponibilidad': parseInt(idFallaDisponibilidad), 'agregar': true};
                           listaDisponibilidad.push(categoriaFormulario);
                           
                       }
                   }
               }
   
               for(let i=0; i<listaCategoriasDisponibilidad.length; i++){
                    for(let x=0; x<listaDisponibilidad.length; x++){
                         if(listaCategoriasDisponibilidad[i].porcentajeDeduccion === listaDisponibilidad[x].porcentajeDeduccion && listaCategoriasDisponibilidad[i].idFallaDisponibilidad === listaDisponibilidad[x].idFallaDisponibilidad){
                              if(FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad === listaDisponibilidad[x].idFallaDisponibilidad){
                                   listaDisponibilidad[x].agregar = true;
                              }
                              else{
                                   listaDisponibilidad[x].agregar = false;
                              }
                         }
                    }
               }
               formularioDisponibilidad.porcentajeDeduccion = parseInt(formularioDisponibilidad.porcentajeDeduccion);

               console.log('listaDisponibilidad del for', listaDisponibilidad);
               console.log('lista listaCategoriasDisponibilidad', listaCategoriasDisponibilidad);
               console.log('formularioDisponibilidad', formularioDisponibilidad);
               console.log('FallasDeDisponibilidadSeleccionado', FallasDeDisponibilidadSeleccionado)
              
               for (let i = 0; i < listaCategoriasDisponibilidad.length; i++) {
                    for (let x = 0; x < listaDisponibilidad.length; x++) {
               
                         if (listaCategoriasDisponibilidad[i].porcentajeDeduccion !== listaDisponibilidad[x].porcentajeDeduccion && listaDisponibilidad[x].agregar && listaCategoriasDisponibilidad[i].idFallaDisponibilidad === listaDisponibilidad[x].idFallaDisponibilidad) {
                              console.log('primer if')
     
                              if (!categoriaEditados[0]) {
                                   console.log('segundo if')
                                   
                                        if (FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad === listaDisponibilidad[x].idFallaDisponibilidad ) {
                                        console.log('tercer if')

                                             if (formularioDisponibilidad.porcentajeDeduccion === listaDisponibilidad[x].porcentajeDeduccion) {
                                                  console.log('cuarto if')

                                                  categoriaEditados.push(listaDisponibilidad[x]);
                                                  listaDisponibilidad[x].agregar = false;
                                                  noIguales = false;
                                             }
                                        } else {
                                             console.log('else tercer if')
                                             categoriaEditados.push(listaDisponibilidad[x]);
                                        }
                                   
                                   
                              }
                              console.log('categoriaEditados:', categoriaEditados)
                              for (let y = 0; y < categoriaEditados.length; y++) {
                                   console.log('categoriaEditados[y]:', categoriaEditados[y])
                                   if (categoriaEditados[y].idFallaDisponibilidad === listaDisponibilidad[x].idFallaDisponibilidad) {
                                        listaDisponibilidad[x].agregar = false;
                                   }
                                   if (listaDisponibilidad[x].agregar === true) {
                                        console.log('entro else if ', listaDisponibilidad[x].agregar)
                                        if (FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad === listaDisponibilidad[x].idFallaDisponibilidad) {
                                        if (formularioDisponibilidad.porcentajeDeduccion === listaDisponibilidad[x].porcentajeDeduccion) {
                                             categoriaEditados.push(listaDisponibilidad[x]);
                                             listaDisponibilidad[x].agregar = false;
                                             noIguales = false;
                                        }
                                        }
                                        else {
                                        categoriaEditados.push(listaDisponibilidad[x]);
                                        listaDisponibilidad[x].agregar = false;
                                        }
                                   }  
                              }
                           
                         }   
                    }
               } 

               
               if(noIguales){
                   message.warning('El porcentaje de la categoría de falla de disponibilidad ' + FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad + ', debe ser el mismo que ingresó al editar (' + formularioDisponibilidad.porcentajeDeduccion + ').')
                   this.formRef.current.resetFields(['porcentajeDeduccion-' + FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad]);
               } else {
                   confirm({
                       title: 'Estas son las categorías de falla de disponibilidad que fueron modificadas. ¿Estás seguro de modificarlas?',
                       content: 
                       <div>
                           {categoriaEditados.map(categoriaEditado =>
                              <Row justify='space-around' key={categoriaEditado.idFallaDisponibilidad}>
                                   <Divider>{'Categoría de Falla de Disponibilidad ' + categoriaEditado.idFallaDisponibilidad}
                                   </Divider>
                                   <Col span={12}>
                                        <p><strong>Categoría: </strong>{categoriaEditado.fallaDisponibilidad}</p>
                                   </Col>
                                   <Col span={10}>
                                        <p><strong>Porcentaje de Deducción: </strong>{categoriaEditado.porcentajeDeduccion}%</p>
                                   </Col>
                              </Row>
                           )}
                       </div>,
                       okText: 'Sí',
                       okType: 'danger',
                       cancelText: 'No',
                       width: '50%',
                       onOk() {
                           onCambiarVista(4, formularioDisponibilidad, categoriaEditados);
                       },
                       onCancel() {
                           onCambiarVista(1, null);
                       },
                   });
               }   
          }; 

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const changeDecimales = (value) => {
               let valueDecimal = parseInt(value);
               let campo = 'porcentajeDeduccion';
               if(isNaN(valueDecimal)){
                    valueDecimal = 0;
               }
               this.formRef.current.setFieldsValue({[campo]: valueDecimal});
          };

          console.log('listaCategoriasDisponibilidad', listaCategoriasDisponibilidad)
          console.log('listaCatDisponibilidad', listaCatDisponibilidad)

          return ( 
               <Form {...layout}
               name="formulario"
               onFinish={onFinish}
               
               ref={this.formRef}
               initialValues={listaCatDisponibilidad}
               >
                    <div>
                         <h4>Favor de ingresar nuevamente los porcentajes de deducción</h4>
                         {listaCategoriasDisponibilidad.map(fallaDisponibilidad => 
                              <Row justify='space-around' key={fallaDisponibilidad.idFallaDisponibilidad}>
                                   <Divider>{'Categoría de Falla de Disponibilidad ' + fallaDisponibilidad.idFallaDisponibilidad}</Divider>

                                   <Col span={11}>
                                        <Form.Item
                                             label="Categoría de Falla"
                                             name="fallaDisponibilidad"
                                        >
                                             <Input disabled={true} placeholder={fallaDisponibilidad.fallaDisponibilidad } ></Input>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item
                                             label="Porcentaje de Deducción"
                                             name={'porcentajeDeduccion-' + fallaDisponibilidad.idFallaDisponibilidad}
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                             
                                        >
                                             <InputNumber 
                                             min={1.00} 
                                             max={100.00}
                                             step={1.00} 
                                             formatter={value => `${value}%`}
                                             //parser={value => value.replace('%', '')}
                                             placeholder="Ingresa el porcentaje de deducción"
                                             onChange={value => changeDecimales(value, fallaDisponibilidad.idFallaDisponibilidad)}
                                             title="Número debe ser entero" 
                                             pattern='^[0-9]*(\.[0-9]{0,2})?%' />
                                        </Form.Item>
                                   </Col>
                                   
                                   <Col span={24}>
                                        <Form.Item
                                             label="Descripción"
                                             name='descripcion'
                                        >
                                             <TextArea disabled={true} placeholder={fallaDisponibilidad.descripcion } autoSize/>
                                        </Form.Item>
                                   </Col>
                              </Row>
                         )}
                         
                         <Col span={24} className='marginBoton'>
                              <br/>
                              <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                         </Col>
                    </div>
               </Form>
          );
     }
}

const mapStateToProps = state => {
     return {
          formularioDisponibilidad: state.MecanismosDePagosReducer.formularioDisponibilidad,
          listaCategoriasDisponibilidad: state.MecanismosDePagosReducer.listaCategoriasDisponibilidad,
          FallasDeDisponibilidadSeleccionado: state.MecanismosDePagosReducer.FallasDeDisponibilidadSeleccionado,
          fallaDisponibilidadEditados: state.MecanismosDePagosReducer.fallaDisponibilidadEditados,
          listaCatDisponibilidad: state.MecanismosDePagosReducer.listaCatDisponibilidad
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onCambiarVista: (vistaModalFallaDisponiblidad, formularioDisponibilidad, fallaDisponibilidadEditados) => {
               dispatch({ type: 'CAMBIAR_VISTA_FALLA_DISPONIBILIDAD', vistaModalFallaDisponiblidad, formularioDisponibilidad, fallaDisponibilidadEditados})
          },
     }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ListaTiempoDeGarantia);