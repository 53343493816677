import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ArchivoSalidaDeBienes extends Component {
     formRef = React.createRef();
     state = {
          filePDF: false,
          botonPDF: false,
          botonEditar: false,
     }
     render() {
          const { 
               onNuevaSalidaDeBienes, 
               fetchingNuevaSalidaDeBienes,              
               formularioSalidaDeBienes, 
               limpiarFormulario, 
               onLimpiarFormulario,
               salidaActivoSeleccionado,
               motivoSalida,
               idServicioSalida,
               idMotivoSalidaLocal,
               onLimpiarFormularioInicial,
               //onChangeCatalogo
               limpiarGenerarFolio,
               onActualizarTabla,
               onObtenerActivosPorServicio
          } = this.props;

          if(limpiarFormulario && limpiarFormulario === 'crearSolicitudDeSalida'){
               console.log('entró a limpiar');
               this.formRef.current.resetFields();
               this.setState({
                    filePDF: false,
                    botonPDF: false,
               });
               onLimpiarFormulario();
               onLimpiarFormularioInicial();
          }

          const onFinish = formularioPDF => {
               console.log('formularioPDF', formularioPDF);
               let tipoMovimientoTex = motivoSalida.split(', ')[0];
               onNuevaSalidaDeBienes(formularioSalidaDeBienes, salidaActivoSeleccionado, formularioPDF, idServicioSalida, idMotivoSalidaLocal);
               //onChangeCatalogo('Solicitud de Salida');
               limpiarGenerarFolio();
               onActualizarTabla(1);
               //onObtenerActivosPorServicio();
          };

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const onChangePDF = (info) => {
               if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
               }
               else{
                    this.setState({
                         botonPDF: true,
                         botonEditar: true,
                    })
               }
               var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
               if (info.file.status === 'done') {
                    if(!reGex.test(info.file.name)){
                         message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                         this.formRef.current.resetFields(['pdf']);
                    }
                    else{
                         message.success(`${info.file.name} se cargó de forma correcta`);
                         this.setState({
                         filePDF: true
                         });
                    }
                    this.setState({
                         botonPDF: false,
                         botonEditar: false,
                    });
               } else if (info.file.status === 'error') {
                    if(!reGex.test(info.file.name)){
                         message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                         this.formRef.current.resetFields(['pdf']);
                    }
                    else{
                         message.error(`${info.file.name} se cargó de forma incorrecta`);
                         this.setState({
                         filePDF: true
                         });
                    }
                    this.setState({
                         botonPDF: false,
                         botonEditar: false,
                    });
               }
          }
          const onRemovePDF = () => {
               this.setState({
                    filePDF: false,
                    botonPDF: false,
               });
          }
          const dummyRequest = ({ file, onSuccess }) => {
               setTimeout(() => {
                    onSuccess("ok");
               }, 0);
          };
          return (
               <Spin spinning={fetchingNuevaSalidaDeBienes}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                         <Row justify='space-around'>
                         <Col span={12}>
                              <Form.Item
                                   label="Adjunta el archivo PDF de respaldo para salida o baja del bien"
                                   name="pdf"
                                   rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                              >
                                   <Upload 
                                   customRequest={dummyRequest} 
                                   onChange={onChangePDF} 
                                   onRemove={onRemovePDF} 
                                   accept='.pdf' 
                                   multiple={false}
                                   >
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                             <UploadOutlined /> Click para subir archivo PDF
                                        </Button>
                                        ):false}
                                   </Upload>
                              </Form.Item>
                         </Col>
                         <Col span={11} className='marginBoton'>
                              <br/>
                              <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit" disabled={!this.state.filePDF}>Finalizar</Button>
                         </Col>
                         </Row>
                    </Form>
               </Spin>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevaSalidaDeBienes: state.AlmacenesReducer.fetchingNuevaSalidaDeBienes,
          formularioSalidaDeBienes: state.AlmacenesReducer.formularioSalidaDeBienes,
          page: state.AlmacenesReducer.page,
          salidaActivoSeleccionado: state.AlmacenesReducer.salidaActivoSeleccionado,
          motivoSalida: state.AlmacenesReducer.motivoSalida,
          idServicioSalida: state.AlmacenesReducer.idServicioSalida,
          idMotivoSalidaLocal: state.AlmacenesReducer.idMotivoSalidaLocal,

          listaMotivosSalidaActivos: state.AlmacenesReducer.listaMotivosSalidaActivos,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onNuevaSalidaDeBienes: ( formulario, salidaActivoSeleccionado, formularioPDF, idServicioSalida, idMotivoSalidaLocal ) => {
               dispatch({ type: "NUEVA_SOLICITUD_SALIDA_BIENES_REQUEST" , formulario: formulario,salidaActivoSeleccionado: salidaActivoSeleccionado, formularioPDF: formularioPDF, idServicioSalida, idMotivoSalidaLocal: idMotivoSalidaLocal});
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
          },
          onLimpiarFormularioInicial: () => {
               dispatch({ type: 'GENERAR_FOLIO_SALIDA_FAILURE', servicioSalida: [], idServicioSalida: null, motivoSalida: [], motivoSalidaTex: [], idMotivoSalidaLocal: null})
          },
          // onChangeCatalogo: (catalogoAlmacenes) => {
          //      dispatch({ type: "CAMBIAR_CATALOGO_ALMACENES" , catalogoAlmacenes});
          // },
          limpiarGenerarFolio: () => {
               dispatch({ type: 'GENERAR_FOLIO_SALIDA_SUCCESS', tipoMovimiento: null });
          },
          onActualizarTabla: ( page) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_SALIDA',  page})
          },
          onObtenerActivosPorServicio: () => {
               dispatch({ type: "LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST", idServicio: null, page: 1 });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivoSalidaDeBienes);