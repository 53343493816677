import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Radio } from 'antd';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoMotivoDeSalida extends Component {
    formRef = React.createRef();
    state = {
        tipoSalida: 'Definitiva'
    }
    render() { 
        const {showModalNuevoMotivoSalida,
            fetchingNuevoMotivoSalida,
            onNuevoMotivo,
            onLimpiarFormulario,
            limpiarFormulario,
            onShowModalNuevoMotivo,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoMotivo(formulario, this.props.page);
        };
        

        
        if (limpiarFormulario && limpiarFormulario === 'crearMotivo') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalNuevoMotivo();
            this.formRef.current.resetFields();
        }

        const cambiarMotivo = tipoSalida => {
            console.log(tipoSalida.target.value)
            this.setState({
                tipoSalida: tipoSalida.target.value
            });
        }

        return ( 
            <Modal
            visible={showModalNuevoMotivoSalida}
            title='Nuevo Motivo de Salida'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoMotivoSalida}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Col span={24}>
                        <Form.Item
                            label="Descripcion motivo de salida"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={80} placeholder="Ingrese descripcion" />
                        </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Tipo de salida"
                                name="tipoSalida"
                                rules={[{ required: true, message: 'Favor de elegir tipo de salida', }]}
                            >
                                <Radio.Group onChange={cambiarMotivo} >
                                    <Radio value='Definitiva'>Definitiva</Radio>
                                    <Radio value='Temporal'>Temporal</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Row justify='space-around'>
                            <Col span={12}>
                                <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Motivo de Salida</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
         );
    }
}
const mapStateToProps = state => {
	return {
        fetchingNuevoMotivoSalida: state.AlmacenesReducer.fetchingNuevoMotivoSalida,
        showModalNuevoMotivoSalida: state.AlmacenesReducer.showModalNuevoMotivoSalida,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoMotivo: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_MOTIVOS_DE_SALIDA',  showModalNuevoMotivoSalida: false})
        },
        onNuevoMotivo: ( formulario, page ) => {
            dispatch({ type: "NUEVO_MOTIVOS_DE_SALIDA_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoMotivoDeSalida);