import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, DatePicker, Row , message, Upload, InputNumber} from 'antd';
import {  UploadOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalContraseña from '../../SolicitudDeServicios/SeguimientoSolicitudes/ModalContraseña';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoPARv extends Component {
    formRef = React.createRef();

    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        formulario: null,
    }

    render() {

        const { showModalCrearPARv, onCerrarModal, fetchingCrearPARv, page, busqueda,
                onLimpiarFormulario, limpiarFormulario, onCrearPARv, listaPARv,
                onShowModalContraseña, isEspecial, listaInfoProyecto
        } = this.props;

        if(isEspecial){
            onCrearPARv(this.state.formulario,  page, busqueda);
            onShowModalContraseña(false, false);
        }


        const onFinish = formulario => {
            console.log('formulario:', formulario);
            if(!isEspecial){
                this.setState({ formulario })
                onShowModalContraseña(true, false);
            } 
        };

        if (limpiarFormulario && limpiarFormulario==='crearPARv') {
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }

        if (limpiarFormulario && limpiarFormulario==='crearINPC0') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onCerrarModal();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            this.formRef.current.resetFields();
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }


        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };


        const disabledDate = (fecha) => {

            
            
            const fechaInicioOperacion = moment(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos, 'DD-MM-YYYY');
            const fechaFinVigencia = moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY');
            if(listaPARv[0]){
                console.log('entro al if')
                if (fecha && fecha < moment(listaPARv[0].fechaInicioVigencia, 'DD-MM-YYYY')) {
                    return fecha && fecha < moment(listaPARv[0].fechaInicioVigencia, 'DD-MM-YYYY') && moment(listaPARv[0].fechaInicioVigencia, 'DD-MM-YYYY') 
                } else {
                    return fecha && fecha > fechaFinVigencia
                }
            } else {
                if (fecha && fecha < fechaInicioOperacion) {
                    return fecha && fecha <= fechaInicioOperacion 
                } else {
                    return fecha && fecha > fechaFinVigencia
                }
            }

        };

        let fechaVigenciaDefault = new Date(listaInfoProyecto.fechaInicioOperacion)
        fechaVigenciaDefault.setFullYear(fechaVigenciaDefault.getFullYear() + listaInfoProyecto.vigencia);
        

        return (
            <Modal
                visible={showModalCrearPARv}
                title='Nuevo PARv'
                onCancel={handleCancel}
                footer={false}
                width='40%'
            >
                <Spin spinning={fetchingCrearPARv}>

                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Valor Pago Anual de Referencia Variable"
                                    name="parV"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            placeholder="Ingresa el pago de referencia variable"
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                        />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Numero de Oficio"
                                    name="numeroOficio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input
                                        pattern="^^[ÁÉÍÓÚÑñíóáéú a-zA-Z 0-9]+$"
                                        title="Nombre debe tener letras y numeros."
                                        maxLength={50} placeholder='Ingrese Numero de Oficio' />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Fecha Inicio de Vigencia"
                                    name="fechaInicioVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY'
                                        placeholder={["Seleccione Fecha"]}
                                        disabledDate={disabledDate}
                                        showToday={false}
                                    />
                                </Form.Item>
                            </Col>

                           {listaPARv[0] ? (
                            <Col span={24}>
                                <Form.Item
                                    label="Fecha Fin de Vigencia"
                                >
                                    <DatePicker
                                        disabled
                                        format='DD-MM-YYYY'
                                        style={{ width: '100%' }}
                                        defaultValue={ moment(fechaVigenciaDefault, 'DD-MM-YYYY')}   
                                    />
                                </Form.Item>
                            </Col>
                            ): false}

                            <Col span={24}>
                                <Form.Item
                                    label="Archivo PDF"
                                    name="pdf"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir evidencia PDF
                                        </Button>
                                    ):false}
                                    </Upload>
                                </Form.Item>
                           </Col>


                            <Col span={24}>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear PARv</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                <ModalContraseña/>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalCrearPARv: state.MecanismosDePagosReducer.showModalCrearPARv,
        fetchingCrearPARv: state.MecanismosDePagosReducer.fetchingCrearPARv,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario, 
        listaPARv: state.MecanismosDePagosReducer.listaPARv,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,

        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_CREAR_PARv", showModalCrearPARv: false })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onCrearPARv: (formulario, page, busqueda) => {
            dispatch({ type: "CREAR_PARv_REQUEST", formulario, page, busqueda})
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        } 
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoPARv);