import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarClaveMetodoSupervision extends Component {
    formRef = React.createRef();
    

    render() {

        const { fetchingEditarClavesMetodosSupervision, showModalEditarClaveMetodosSupervision, onEditarClaveMetodoSupervision, onShowModalEditarClaveMetodoSupervision,
            ClaveMetodosSupervisionSeleccionado, page, limpiarFormulario} = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarClaveMetodoSupervision(formulario, ClaveMetodosSupervisionSeleccionado, page);

        };
        if (limpiarFormulario && limpiarFormulario==='editarClave') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarClaveMetodoSupervision();
        }

        const claveMetodosSupervision = ["Interno", "Externo"];

        return (
            <Modal
                visible={showModalEditarClaveMetodosSupervision}
                title='Editar Método de Supervisión'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarClavesMetodosSupervision}>
                    {ClaveMetodosSupervisionSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={ClaveMetodosSupervisionSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Clase"
                                name="claveMetodoSupervision"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione clase método de supervisión"
                                >
                                    {claveMetodosSupervision.map(option => <Option key={option} value={option}>{option}</Option>)}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Descripción corta"
                                name="descripcionC"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <TextArea
                                    pattern="^[ñíóáéú a-zA-Z ]+$"
                                    title="Descripción sin carácteres especiales"
                                    maxLength={100}
                                    placeholder='Descripción corta'
                                    autoSize />
                            </Form.Item>

                            <Form.Item
                                label="Descripción larga"
                                name="descripcionL"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <TextArea
                                    pattern="^[ñíóáéú a-zA-Z ]+$"
                                    title="Descripción sin carácteres especiales"
                                    maxLength={200}
                                    placeholder='Descripción larga'
                                    autoSize />
                            </Form.Item>
                            <Col span={12}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Método de Supervisión</Button>
                            </Col>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarClavesMetodosSupervision: state.MecanismosDePagosReducer.fetchingEditarClavesMetodosSupervision,
        showModalEditarClaveMetodosSupervision: state.MecanismosDePagosReducer.showModalEditarClaveMetodosSupervision,
        ClaveMetodosSupervisionSeleccionado: state.MecanismosDePagosReducer.ClaveMetodosSupervisionSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarClaveMetodoSupervision: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_CLAVE_METODO_SUPERVISION', showModalEditarClaveMetodosSupervision: false });
        },
        onEditarClaveMetodoSupervision: (formulario, ClaveMetodosSupervisionSeleccionado, page) => {
            dispatch({ type: 'EDITAR_CLAVE_METODO_SUPERVISION_REQUEST', formulario: formulario, ClaveMetodosSupervisionSeleccionado:ClaveMetodosSupervisionSeleccionado, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarClaveMetodoSupervision);