import * as CatalogosGeneralesActions from '../actions/CatalogosGeneralesActions';

const initialState = {
     page: null,
     busqueda: null,
     orden: null,
     vistaDashboard: sessionStorage.getItem('vistaDashboard'),
     catalogoGeneral: null,

     //Reducers de Seguridad
     fetchingListaPermisos: false,
     listaPermisos: [],

     fetchingListaPermisosBusqueda: false,
     listaPermisosBusqueda: [],

     fetchingListaLogs: false,
     listaLogs: [],

     // Reducers Grupo de Servicios
     fetchingListaGruposServicios: false,
     listaGruposServicios: [],

     fetchingListaGruposServiciosBusqueda: false,
     listaGruposServiciosBusqueda: [],
     listaGruposServiciosBusquedaCantidad: null,

     showModalNuevoGrupoServicio: false,
     showModalEditarGrupoServicio: false,
     grupoServicioSeleccionado: null,
     equipamientoTec: false,

     fetchingNuevoGrupoServicio: false,
     fetchingEditarGrupoServicio: false,

     //Reducers de Servicios
     fetchingListaServicios: false,
     listaServicios: [],

     fetchingListaServiciosByCentroCosto: false,
     listaServiciosByCentroCosto: [],

     fetchingListaServiciosByGrupoServicio: false,
     listaServiciosByGrupoServicio: [],

     fetchingListaServiciosByGruposServicios: false,
     listaServiciosByGruposServicios: [],

     fetchingListaServiciosByProveedor: false,
     listaServiciosByProveedor: null,

     fetchingListaServiciosActivosF: false,
     listaServiciosActivosF: [],

     fetchingListaServiciosBusqueda: false,
     listaServiciosBusqueda: [],
     listaServiciosBusquedaCantidad: null,

     showModalNuevoServicio: false,
     showModalEditarServicio: false,
     servicioSeleccionado: null,

     fetchingNuevoServicio: false,
     fetchingEditarServicio: false,

     fetchingServiciosPorProveedor: false,
     serviciosPorProveedor: [],

     showArchivosServicio: false,

     listaServiciosByIdsGerencias: [],
     fetchingListaServiciosByIdsGerencias: false,

     fetchingListaServiciosByIdsGrupos: false,
     listaServiciosByIdsGrupos: [],

     // Reducers Familias de Activos
     fetchingListaFamiliasDeActivosBusqueda: false,
     listaFamiliasDeActivosBusqueda: [],
     listaFamiliasDeActivosBusquedaCantidad: null,

     showModalNuevoFamiliasDeActivos: false,
     showModalEditarFamiliasDeActivos: false,
     familiasDeActivosSeleccionado: null,

     fetchingNuevoFamiliasDeActivos: false,
     fetchingEditarFamiliasDeActivos: false,

     fetchingListaFamiliasDeActivos: false,
     listaFamiliasDeActivos: [],

     fetchingListaFamiliasActivasServ: false,
     listaFamiliasActivasServ: [],


     // Reducers Subfamilias
     fetchingListaSubfamilias: false,
     listaSubfamilias: [],

     fetchingListaSubfamiliasServicio: false,
     listaSubfamiliasServicio: [],

     fetchingListaSubfamiliasRutinas: false,
     listaSubfamiliasRutinas: [],

     fetchingListaSubfamiliasBusqueda: false,
     listaSubfamiliasBusqueda: [],
     listaSubfamiliasBusquedaCantidad: null,

     showModalNuevoSubfamilias: false,
     showModalEditarSubfamilias: false,
     SubfamiliasSeleccionado: null,

     fetchingNuevoSubfamilias: false,
     fetchingEditarSubfamilias: false,

     fetchingListaSubfamiliasProductos: false,
     listaSubfamiliasProductosActivos: [],

     fetchingListaSubfamiliasByIdPartida: false,
     listaSubfamiliasByIdPartida: [],

     //Reducers Centro de Costos
     fetchingListaCentroDeCostosBusqueda: false,
     listaCentroDeCostosBusqueda: [],
     listaCentroDeCostosBusquedaCantidad: null,
     
     fetchingListaCentroDeCostosActivos: false,
     listaCentroDeCostosActivos: [],

     fetchingListaCentroDeCostosByGerencia: false,
     listaCentroDeCostosByGerencia: [],

     showModalNuevoCentroDeCostos: false,
     showModalEditarCentroDeCostos: false,
     CentroDeCostosSeleccionado: null,

     fetchingNuevoCentroDeCostos: false,
     fetchingEditarCentroDeCostos: false,

     //areas funcionales 
     fetchingListaAreasFuncionales: false,
     listaAreasFuncionales: [],

     fetchingListaAreasFuncionalesActivos: false,
     listaAreasFuncionalesActivos: [],

     fetchingListaAreasFuncionalesBusqueda: false,
     listaAreasFuncionalesBusqueda: [],
     listaAreasFuncionalesBusquedaCantidad: null,

     showModalNuevoAreasFuncionales: false,
     showModalEditarAreaFuncional: false,
     AreaFuncionalSeleccionado: null,
     showModalMapaAreaFuncional: false,
     cambiarVistaModalArea: 1,
     formularioAreas: null,
     tipo: null,
     editedGeometry: [],
     cerrarModal: false,

     fetchingNuevoAreaFuncional: false,
     fetchingEditarAreaFuncional: false,

     EstadoAreaFuncional: false,

     fetchingListaAreasFuncionalesActivas: false,
     listaAreasFuncionalesActivas: [],

     fetchingAreasBloquear: false,

     // Unidades Funcionales
     fetchingListaUnidadesFuncionalesActivos: false,
     listaUnidadesFuncionalesActivos: [],

     fetchingListaUnidadesFuncioActCompleta: false,
     listaUnidadesFuncioActCompleta: [],

     fetchingListaUnidadesFuncioActPorArea: false,
     listaUnidadesFuncioActPorArea: [],

     fetchingListaUnidadesFuncionalesBusqueda: false,
     listaUnidadesFuncionalesBusqueda: [],
     listaUnidadesFuncionalesBusquedaCantidad: null,

     listaUnidadesFuncionales: [],
     fetchingListaUnidadesFuncionales: false,

     showModalNuevoUnidadesFuncionales: false,
     showModalEditarUnidadesFuncionales: false,
     cambiarVistaModalUnidad: 1,
     formularioUnidad: null,
     UnidadesFuncionalesSeleccionado: null,

     fetchingNuevoUnidadesFuncionales: false,
     fetchingEditarUnidadesFuncionales: false,

     showModalMapaUnidadFuncional: false,

     // Interiores
     fetchingListaInterior: false,
     listaInterior: [],

     fetchingListaInteriorActivos: false,
     listaInteriorActivos: [],

     fetchingListaInteriorActivosCompleta: false,
     listaInteriorActivosCompleta:[],

     fetchingListaInteriorActivosPorUnidad: false,
     listaInteriorActivosPorUnidad: [],

     fetchingListaInteriorBusqueda: false,
     listaInteriorBusqueda: [],
     listaInteriorBusquedaCantidad: null,

     showModalNuevoInterior: false,
     showModalEditarInterior: false,
     interiorSeleccionado: null,

     fetchingNuevoInterior: false,
     fetchingEditarInterior: false,

     EstadoInterior: false,
     showModalMapaInterior: false,

     // SubInteriores
     fetchingListaSubInterior: false,
     listaSubInterior: [],

     fetchingListaSubInteriorActivos: false,
     listaSubInteriorActivos: [],

     fetchingListaSubInteriorActivosPorInterior: false,
     listaSubInteriorActivosPorInterior: [],

     fetchingListaSubInteriorBusqueda: false,
     listaSubInteriorBusqueda: [],
     listaSubInteriorBusquedaCantidad: null,

     showModalNuevoSubInterior: false,
     showModalEditarSubInterior: false,
     subInteriorSeleccionado: null,
     
     fetchingNuevoSubInterior: false,
     fetchingEditarSubInterior: false,
     
     showModalMapaSubInterior: false,
     EstadoSubInterior: false,
     
     //Reducers Categorías de Fallas
     fetchingListaCategoriasDeFallasBusqueda: false,
     listaCategoriasDeFallasBusqueda: [],
     listaCategoriasDeFallasBusquedaCantidad: null,
     
     fetchingListaCategoriasDeFallasActivos: false,
     listaCategoriasDeFallasActivos: [],

     showModalNuevaCategoriaDeFalla: false,
     showModalEditarCategoriaDeFalla: false,
     CategoriaDeFallaSeleccionado: null,

     fetchingNuevaCategoriaDeFalla: false,
     fetchingEditarCategoriaDeFalla: false,

     fetchingExportarExcelTiemposRehabilitacion: false,

     // Reducers Ubicaciones
     fetchingListaUbicacionesTodasBusqueda: false,
     listaUbicacionesTodasBusqueda: [],

     fetchingListaUbicaciones: false,
     listaUbicaciones: [],

     fetchingListaUbicacionesActivos: false,
     listaUbicacionesActivos: [],

     fetchingListaUbicacionesBusqueda: false,
     listaUbicacionesBusqueda: [],
     listaUbicacionesBusquedaCantidad: null,

     showModalNuevoUbicaciones: false,
     showModalEditarUbicaciones: false,
     ubicacionSeleccionado: null,

     fetchingNuevoUbicaciones: false,
     fetchingEditarUbicaciones: false,

     fetchingListaUbicacionesFiltro: false,
     listaUbicacionesFiltro: [],

     fetchingExportarUbicaciones: false,

     //Ponderaciones
     fetchingListaPonderacionesActivas: false,
     listaPonderacionesActivas: [],
     listaPonderacionesAreas: {},

     fetchingListaPonderacionesBusqueda: false,
     listaPonderacionesBusqueda: [],
     listaPonderacionesBusquedaCantidad: null,

     showModalNuevoPonderacion: false,
     showModalEditarPonderacion: false,
     ponderacionSeleccionado: null,

     fetchingNuevoPonderacion: false,
     fetchingEditarPonderacion: false,
     vistaModalPonderacion: 1,
     formulario: null,
     ponderacionEditados: null,

     serviciosByIdUsuarioGrupos: [],
     serviciosByIdUsuarioGerencia: [],

     //Tipo de Moneda
     listTipoMoneda: [],
     listTipoMonedaCount: null,
     fetchingListTipoMoneda: false,

     showModalNuevoTipoMoneda: false,
     fetchingNuevoTipoMoneda: false,

     showModalEditarTipoMoneda: false,
     tipoMonedaSeleccionado: null,
     fetchingEditarTipoMoneda: false,

     listaTipoMonedasActivas: [],
     fetchingListaTipoMonedasActivas: false,
     fetchingExportarExcelTipoMoneda: false,

     //Tipo de Cambio
     listaTipoCambio: [],
     listaTipoCambioCantidad: null,
     fetchingListaTipoCambio: false,
     fetchingNuevoTipoCambio: false,

     showModalNuevoTipoCambio: false,
     tipoCambioSeleccionado: null,
     showModalEditarTipoCambio: false,
     fetchingEditarTipoCambio: false,
     fetchingExportarExcelTipoCambio: false,
}

export default (state = initialState, action) => {
     switch (action.type) {
          case CatalogosGeneralesActions.CAMBIAR_VISTA_DASHBOARD:
               return { ...state, vistaDashboard: action.vistaDashboard };
          case CatalogosGeneralesActions.CAMBIAR_CATALOGO_GENERAL:
               return { ...state, catalogoGeneral: action.catalogoGeneral };

          //REDUCERS DE SERVICIOS
          case CatalogosGeneralesActions.LISTA_SERVICIOS_REQUEST:
               return { ...state, fetchingListaServicios: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_SUCCESS:
               return { ...state, fetchingListaServicios: false, listaServicios: action.listaServicios };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_FAILURE:
               return { ...state, fetchingListaServicios: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST:
               return { ...state, fetchingListaServiciosByGrupoServicio: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_SUCCESS:
               return { ...state, fetchingListaServiciosByGrupoServicio: false, listaServiciosByGrupoServicio: action.listaServiciosByGrupoServicio };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_FAILURE:
               return { ...state, fetchingListaServiciosByGrupoServicio: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_REQUEST:
               return { ...state, fetchingListaServiciosByGruposServicios: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_SUCCESS:
               return { ...state, fetchingListaServiciosByGruposServicios: false, listaServiciosByGruposServicios: action.listaServiciosByGruposServicios };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_FAILURE:
               return { ...state, fetchingListaServiciosByGruposServicios: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_REQUEST:
               return { ...state, fetchingListaServiciosByProveedor: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS:
               return { ...state, fetchingListaServiciosByProveedor: false, listaServiciosByProveedor: action.listaServiciosByProveedor };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_FAILURE:
               return { ...state, fetchingListaServiciosByProveedor: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_REQUEST:
               return { ...state, fetchingListaServiciosActivosF: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_SUCCESS:
               return { ...state, fetchingListaServiciosActivosF: false, listaServiciosActivosF: action.listaServiciosActivosF };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_FAILURE:
               return { ...state, fetchingListaServiciosActivosF: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST:
               return { ...state, fetchingListaServiciosByIdsGerencias: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_SUCCESS:
               return { ...state, fetchingListaServiciosByIdsGerencias: false, listaServiciosByIdsGerencias: action.listaServiciosByIdsGerencias, serviciosByIdUsuarioGerencia: action.serviciosByIdUsuarioGerencia };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_FAILURE:
               return { ...state, fetchingListaServiciosByIdsGerencias: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST:
               return { ...state, fetchingListaServiciosByIdsGrupos: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_SUCCESS:
               return { ...state, fetchingListaServiciosByIdsGrupos: false, listaServiciosByIdsGrupos: action.listaServiciosByIdsGrupos, serviciosByIdUsuarioGrupos: action.serviciosByIdUsuarioGrupos };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_FAILURE:
               return { ...state, fetchingListaServiciosByIdsGrupos: false };    

          // FAMILIAS DE ACTIVOS
          case CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaFamiliasDeActivos: true };
          case CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaFamiliasDeActivos: false, listaFamiliasDeActivos: action.listaFamiliasDeActivos };
          case CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaFamiliasDeActivos: false };

          case CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaFamiliasDeActivosBusqueda: true };
          case CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaFamiliasDeActivosBusqueda: false, listaFamiliasDeActivosBusqueda: action.listaFamiliasDeActivosBusqueda, listaFamiliasDeActivosBusquedaCantidad: action.listaFamiliasDeActivosBusquedaCantidad };
          case CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaFamiliasDeActivosBusqueda: false };

          case CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST:
               return { ...state, fetchingListaFamiliasActivasServ: true };
          case CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_SUCCESS:
               return { ...state, fetchingListaFamiliasActivasServ: false, listaFamiliasActivasServ: action.listaFamiliasActivasServ };
          case CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_FAILURE:
               return { ...state, fetchingListaFamiliasActivasServ: false };

          case CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaFamiliasDeActivosBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaFamiliasDeActivosBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaFamiliasDeActivosBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_FAMILIAS_ACTIVOS:
               return { ...state, showModalNuevoFamiliasDeActivos: action.showModalNuevoFamiliasDeActivos, page: action.page };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_FAMILIAS_ACTIVOS:
               return { ...state, showModalEditarFamiliasDeActivos: action.showModalEditarFamiliasDeActivos, familiasDeActivosSeleccionado: action.familiasDeActivosSeleccionado, page: action.page };

          case CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_REQUEST:
               return { ...state, fetchingNuevoFamiliasDeActivos: true };
          case CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingNuevoFamiliasDeActivos: false };
          case CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_FAILURE:
               return { ...state, fetchingNuevoFamiliasDeActivos: false };

          case CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_REQUEST:
               return { ...state, fetchingEditarFamiliasDeActivos: true };
          case CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingEditarFamiliasDeActivos: false };
          case CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_FAILURE:
               return { ...state, fetchingEditarFamiliasDeActivos: false };

          // SUBFAMILIAS
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST:
               return { ...state, fetchingListaSubfamiliasProductos: true };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_SUCCESS:
               return { ...state, fetchingListaSubfamiliasProductos: false, listaSubfamiliasProductosActivos: action.listaSubfamiliasProductosActivos };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_FAILURE:
               return { ...state, fetchingListaSubfamiliasProductos: false };

          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_REQUEST:
               return { ...state, fetchingListaSubfamilias: true };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_SUCCESS:
               return { ...state, fetchingListaSubfamilias: false, listaSubfamilias: action.listaSubfamilias };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_FAILURE:
               return { ...state, fetchingListaSubfamilias: false };

          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST:
               return { ...state, fetchingListaSubfamiliasServicio: true };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_SUCCESS:
               return { ...state, fetchingListaSubfamiliasServicio: false, listaSubfamiliasServicio: action.listaSubfamiliasServicio };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_FAILURE:
               return { ...state, fetchingListaSubfamiliasServicio: false };

          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_REQUEST:
               return { ...state, fetchingListaSubfamiliasRutinas: true };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_SUCCESS:
               return { ...state, fetchingListaSubfamiliasRutinas: false, listaSubfamiliasRutinas: action.listaSubfamiliasRutinas };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_FAILURE:
               return { ...state, fetchingListaSubfamiliasRutinas: false };

          case CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaSubfamiliasBusqueda: true };
          case CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaSubfamiliasBusqueda: false, listaSubfamiliasBusqueda: action.listaSubfamiliasBusqueda, listaSubfamiliasBusquedaCantidad: action.listaSubfamiliasBusquedaCantidad };
          case CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaSubfamiliasBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_REQUEST:
               return { ...state, fetchingListaSubfamiliasBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_SUCCESS:
               return { ...state, fetchingListaSubfamiliasBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_FAILURE:
               return { ...state, fetchingListaSubfamiliasBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_SUBFAMILIAS:
               return { ...state, showModalNuevoSubfamilias: action.showModalNuevoSubfamilias, page: action.page, listaFamiliasActivasServ: [] };
        
          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_SUBFAMILIAS:
               return { ...state, showModalEditarSubfamilias: action.showModalEditarSubfamilias, SubfamiliasSeleccionado: action.SubfamiliasSeleccionado, page: action.page, listaFamiliasActivasServ: [] };

          case CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_REQUEST:
               return { ...state, fetchingNuevoSubfamilias: true };
          case CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_SUCCESS:
               return { ...state, fetchingNuevoSubfamilias: false };
          case CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_FAILURE:
               return { ...state, fetchingNuevoSubfamilias: false };

          case CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_REQUEST:
               return { ...state, fetchingEditarSubfamilias: true };
          case CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_SUCCESS:
               return { ...state, fetchingEditarSubfamilias: false };
          case CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_FAILURE:
               return { ...state, fetchingEditarSubfamilias: false };

          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_REQUEST:
               return { ...state, fetchingListaSubfamiliasByIdPartida: true };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_SUCCESS:
               return { ...state, fetchingListaSubfamiliasByIdPartida: false, listaSubfamiliasByIdPartida: action.listaSubfamiliasByIdPartida };
          case CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_FAILURE:
               return { ...state, fetchingListaSubfamiliasByIdPartida: false };

          
          // REDUCERS DE SEGURIDAD
          case CatalogosGeneralesActions.GET_PERMISOS_REQUEST:
               return { ...state, fetchingListaPermisos: true };
          case CatalogosGeneralesActions.GET_PERMISOS_SUCCESS:
               return { ...state, fetchingListaPermisos: false, listaPermisos: action.listaPermisos };
          case CatalogosGeneralesActions.GET_PERMISOS_FAILURE:
               return { ...state, fetchingListaPermisos: false };

          case CatalogosGeneralesActions.GET_PERMISOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaPermisosBusqueda: true };
          case CatalogosGeneralesActions.GET_PERMISOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaPermisosBusqueda: false, listaPermisosBusqueda: action.listaPermisosBusqueda };
          case CatalogosGeneralesActions.GET_PERMISOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaPermisosBusqueda: false };

          //CÁTALOG CENTRO DE COSTOS
          //Lista Centros de Costos Activos
          case CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaCentroDeCostosActivos: true };
          case CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaCentroDeCostosActivos: false, listaCentroDeCostosActivos: action.listaCentroDeCostosActivos };
          case CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaCentroDeCostosActivos: false };

          case CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST:
               return { ...state, fetchingListaCentroDeCostosByGerencia: true };
          case CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS:
               return { ...state, fetchingListaCentroDeCostosByGerencia: false, listaCentroDeCostosByGerencia: action.listaCentroDeCostosByGerencia };
          case CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_FAILURE:
               return { ...state, fetchingListaCentroDeCostosByGerencia: false };

          case CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaCentroDeCostosBusqueda: true };
          case CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaCentroDeCostosBusqueda: false, listaCentroDeCostosBusqueda: action.listaCentroDeCostosBusqueda, listaCentroDeCostosBusquedaCantidad: action.listaCentroDeCostosBusquedaCantidad };
          case CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaCentroDeCostosBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_REQUEST:
               return { ...state, fetchingListaCentroDeCostosBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_SUCCESS:
               return { ...state, fetchingListaCentroDeCostosBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_FAILURE:
               return { ...state, fetchingListaCentroDeCostosBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_CENTRO_COSTOS:
               return { ...state, showModalNuevoCentroDeCostos: action.showModalNuevoCentroDeCostos, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_CENTRO_COSTOS:
               return { ...state, showModalEditarCentroDeCostos: action.showModalEditarCentroDeCostos, CentroDeCostosSeleccionado: action.CentroDeCostosSeleccionado, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_REQUEST:
               return { ...state, fetchingNuevoCentroDeCostos: true };
          case CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_SUCCESS:
               return { ...state, fetchingNuevoCentroDeCostos: false };
          case CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_FAILURE:
               return { ...state, fetchingNuevoCentroDeCostos: false };

          case CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_REQUEST:
               return { ...state, fetchingEditarCentroDeCostos: true };
          case CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_SUCCESS:
               return { ...state, fetchingEditarCentroDeCostos: false };
          case CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_FAILURE:
               return { ...state, fetchingEditarCentroDeCostos: false };

          // GRUPO DE SERVICIOS
          case CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_REQUEST:
               return { ...state, fetchingListaGruposServicios: true };
          case CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_SUCCESS:
               return { ...state, fetchingListaGruposServicios: false, listaGruposServicios: action.listaGruposServicios };
          case CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_FAILURE:
               return { ...state, fetchingListaGruposServicios: false };

          //REDUCERS DE GRUPOS DE SERVICIO
          case CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaGruposServiciosBusqueda: true };
          case CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaGruposServiciosBusqueda: false, listaGruposServiciosBusqueda: action.listaGruposServiciosBusqueda, listaGruposServiciosBusquedaCantidad: action.listaGruposServiciosBusquedaCantidad };
          case CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaGruposServiciosBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_REQUEST:
               return { ...state, fetchingListaGruposServiciosBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_SUCCESS:
               return { ...state, fetchingListaGruposServiciosBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_FAILURE:
               return { ...state, fetchingListaGruposServiciosBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_GRUPO_SERVICIO:
               return { ...state, showModalNuevoGrupoServicio: action.showModalNuevoGrupoServicio, page: action.page };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_GRUPO_SERVICIO:
               return { ...state, showModalEditarGrupoServicio: action.showModalEditarGrupoServicio, grupoServicioSeleccionado: action.grupoServicioSeleccionado, page: action.page, equipamientoTec: action.equipamientoTec };

          case CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_REQUEST:
               return { ...state, fetchingNuevoGrupoServicio: true };
          case CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_SUCCESS:
               return { ...state, fetchingNuevoGrupoServicio: false };
          case CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_FAILURE:
               return { ...state, fetchingNuevoGrupoServicio: false };

          case CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_REQUEST:
               return { ...state, fetchingEditarGrupoServicio: true };
          case CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_SUCCESS:
               return { ...state, fetchingEditarGrupoServicio: false, equipamientoTec: action.equipamientoTec };
          case CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_FAILURE:
               return { ...state, fetchingEditarGrupoServicio: false };

          //REDUCERS DE SERVICIOS
          case CatalogosGeneralesActions.LISTA_SERVICIOS_REQUEST:
               return { ...state, fetchingListaServicios: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_SUCCESS:
               return { ...state, fetchingListaServicios: false, listaServicios: action.listaServicios };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_FAILURE:
               return { ...state, fetchingListaServicios: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST:
               return { ...state, fetchingListaServiciosByCentroCosto: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS:
               return { ...state, fetchingListaServiciosByCentroCosto: false, listaServiciosByCentroCosto: action.listaServiciosByCentroCosto };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_FAILURE:
               return { ...state, fetchingListaServiciosByCentroCosto: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaServiciosBusqueda: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaServiciosBusqueda: false, listaServiciosBusqueda: action.listaServiciosBusqueda, listaServiciosBusquedaCantidad: action.listaServiciosBusquedaCantidad };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaServiciosBusqueda: false };

          case CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_REQUEST:
               return { ...state, fetchingListaServiciosBusqueda: true };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_SUCCESS:
               return { ...state, fetchingListaServiciosBusqueda: false, listaServiciosBusqueda: action.listaServiciosBusqueda, listaServiciosBusquedaCantidad: action.listaServiciosBusquedaCantidad };
          case CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_FAILURE:
               return { ...state, fetchingListaServiciosBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_SERVICIO_REQUEST:
               return { ...state, fetchingListaServiciosBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_SERVICIO_SUCCESS:
               return { ...state, fetchingListaServiciosBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_SERVICIO_FAILURE:
               return { ...state, fetchingListaServiciosBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_SERVICIO:
               return { ...state, showModalNuevoServicio: action.showModalNuevoServicio, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_SERVICIO:
               return { ...state, showModalEditarServicio: action.showModalEditarServicio, servicioSeleccionado: action.servicioSeleccionado, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.NUEVO_SERVICIO_REQUEST:
               return { ...state, fetchingNuevoServicio: true };
          case CatalogosGeneralesActions.NUEVO_SERVICIO_SUCCESS:
               return { ...state, fetchingNuevoServicio: false };
          case CatalogosGeneralesActions.NUEVO_SERVICIO_FAILURE:
               return { ...state, fetchingNuevoServicio: false };

          case CatalogosGeneralesActions.EDITAR_SERVICIO_REQUEST:
               return { ...state, fetchingEditarServicio: true };
          case CatalogosGeneralesActions.EDITAR_SERVICIO_SUCCESS:
               return { ...state, fetchingEditarServicio: false };
          case CatalogosGeneralesActions.EDITAR_SERVICIO_FAILURE:
               return { ...state, fetchingEditarServicio: false };

          case CatalogosGeneralesActions.SHOW_MODAL_ARCHIVOS_SERVICIO:
               return { ...state, showArchivosServicio: action.showArchivosServicio, servicioSeleccionado: action.servicioSeleccionado, page: action.page };

          // ******** ÁREAS FUNCIONALES *****//
          case CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_REQUEST:
               return { ...state, fetchingListaAreasFuncionales: true };
          case CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_SUCCESS:
               return { ...state, fetchingListaAreasFuncionales: false, listaAreasFuncionales: action.ListaAreasFuncionales };
          case CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_FAILURE:
               return { ...state, fetchingListaAreasFuncionales: false };

          case CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST:
               return { ...state, fetchingListaAreasFuncionalesActivos: true };
          case CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaAreasFuncionalesActivos: false, listaAreasFuncionalesActivos: action.listaAreasFuncionalesActivos };
          case CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_FAILURE:
               return { ...state, fetchingListaAreasFuncionalesActivos: false };     

          case CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaAreasFuncionalesBusqueda: true };
          case CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaAreasFuncionalesBusqueda: false, listaAreasFuncionalesBusqueda: action.listaAreasFuncionalesBusqueda, listaAreasFuncionalesBusquedaCantidad: action.listaAreasFuncionalesBusquedaCantidad };
          case CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaAreasFuncionalesBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_REQUEST:
               return { ...state, EstadoAreaFuncional: true };
          case CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_SUCCESS:
               return { ...state, EstadoAreaFuncional: false };
          case CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_FAILURE:
               return { ...state, EstadoAreaFuncional: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_AREASFUNCIONALES:
               return { ...state, showModalNuevoAreasFuncionales: action.showModalNuevoAreasFuncionales, page: action.page, editedGeometry: [], cerrarModal: action.cerrarModal, busqueda: action.busqueda, bloqueado: action.bloqueado };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_AREASFUNCIONALES:
               return { ...state, showModalEditarAreaFuncional: action.showModalEditarAreaFuncional, AreaFuncionalSeleccionado: action.AreaFuncionalSeleccionado, page: action.page, editedGeometry: action.editedGeometry, busqueda: action.busqueda, bloqueado: action.bloqueado };

          case CatalogosGeneralesActions.SHOW_MODAL_MAPA_AREA_FUNCIONAL:
               return { ...state, showModalMapaAreaFuncional: action.showModalMapaAreaFuncional, editedGeometry: action.editedGeometry};

          case CatalogosGeneralesActions.CAMBIAR_VISTA_AREAS:
               return { ...state, cambiarVistaModalArea: action.cambiarVistaModalArea, formularioAreas: action.formularioAreas, editedGeometry: action.editedGeometry };

          case CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_REQUEST:
               return { ...state, fetchingNuevoAreaFuncional: true };
          case CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_SUCCESS:
               return { ...state, fetchingNuevoAreaFuncional: false };
          case CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_FAILURE:
               return { ...state, fetchingNuevoAreaFuncional: false };

          case CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_REQUEST:
               return { ...state, fetchingEditarAreaFuncional: true };
          case CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_SUCCESS:
               return { ...state, fetchingEditarAreaFuncional: false };
          case CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_FAILURE:
               return { ...state, fetchingEditarAreaFuncional: false };

          case CatalogosGeneralesActions.LISTA_AREAS_FUNCIONALES_ACTIVAS_REQUEST:
               return { ...state, fetchingListaAreasFuncionalesActivas: true };
          case CatalogosGeneralesActions.LISTA_AREAS_FUNCIOANLES_ACTIVAS_SUCCESS:
               return { ...state, fetchingListaAreasFuncionalesActivas: false, listaAreasFuncionalesActivas: action.listaAreasFuncionalesActivas };
          case CatalogosGeneralesActions.LISTA_AREAS_FUNCIONALES_ACTIVAS_FAILURE:
               return { ...state, fetchingListaAreasFuncionalesActivas: false };

          case CatalogosGeneralesActions.AREAS_BLOQUEAR_REQUEST:
               return { ...state, fetchingAreasBloquear: true };
          case CatalogosGeneralesActions.AREAS_BLOQUEAR_SUCCESS:
               return { ...state, fetchingAreasBloquear: false };
          case CatalogosGeneralesActions.AREAS_BLOQUEAR_FAILURE:
               return { ...state, fetchingAreasBloquear: false };

          // UNIDADES FUNCIONALES
          case CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_REQUEST:
               return { ...state, fetchingListaUnidadesFuncionales: true };
          case CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncionales: false, listaUnidadesFuncionales: action.listaUnidadesFuncionales };
          case CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_FAILURE:
               return { ...state, fetchingListaUnidadesFuncionales: false };

          case CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_REQUEST:
               return { ...state, fetchingListaUnidadesFuncionalesActivos: true };
          case CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncionalesActivos: false, listaUnidadesFuncionalesActivos: action.listaUnidadesFuncionalesActivos };
          case CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_FAILURE:
               return { ...state, fetchingListaUnidadesFuncionalesActivos: false };

          case CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_REQUEST:
               return { ...state, fetchingListaUnidadesFuncioActCompleta: true };
          case CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncioActCompleta: false, listaUnidadesFuncioActCompleta: action.listaUnidadesFuncioActCompleta };
          case CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_FAILURE:
               return { ...state, fetchingListaUnidadesFuncioActCompleta: false };
               
          case CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST:
               return { ...state, fetchingListaUnidadesFuncioActPorArea: true };
          case CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncioActPorArea: false, listaUnidadesFuncioActPorArea: action.listaUnidadesFuncioActPorArea };
          case CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_FAILURE:
               return { ...state, fetchingListaUnidadesFuncioActPorArea: false };

          case CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaUnidadesFuncionalesBusqueda: true };
          case CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncionalesBusqueda: false, listaUnidadesFuncionalesBusqueda: action.listaUnidadesFuncionalesBusqueda, listaUnidadesFuncionalesBusquedaCantidad: action.listaUnidadesFuncionalesBusquedaCantidad };
          case CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaUnidadesFuncionalesBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_REQUEST:
               return { ...state, fetchingListaUnidadesFuncionalesBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncionalesBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_FAILURE:
               return { ...state, fetchingListaUnidadesFuncionalesBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_UNIDADESFUNCIONALES:
               return { ...state, showModalNuevoUnidadesFuncionales: action.showModalNuevoUnidadesFuncionales, page: action.page, busqueda: action.busqueda, bloqueado: action.bloqueado };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_UNIDADESFUNCIONALES:
               return { ...state, showModalEditarUnidadesFuncionales: action.showModalEditarUnidadesFuncionales, UnidadesFuncionalesSeleccionado: action.UnidadesFuncionalesSeleccionado, page: action.page, busqueda: action.busqueda, bloqueado: action.bloqueado };

          case CatalogosGeneralesActions.SHOW_MODAL_MAPA_UNIDAD_FUNCIONAL:
               return { ...state, showModalMapaUnidadFuncional: action.showModalMapaUnidadFuncional};

          case CatalogosGeneralesActions.CAMBIAR_VISTA_UNIDAD:
               return { ...state, cambiarVistaModalUnidad: action.cambiarVistaModalUnidad, formularioUnidad: action.formularioUnidad, editedGeometry: action.editedGeometry };

          case CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_REQUEST:
               return { ...state, fetchingNuevoUnidadesFuncionales: true };
          case CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_SUCCESS:
               return { ...state, fetchingNuevoUnidadesFuncionales: false };
          case CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_FAILURE:
               return { ...state, fetchingNuevoUnidadesFuncionales: false };

          case CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_REQUEST:
               return { ...state, fetchingEditarUnidadesFuncionales: true };
          case CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_SUCCESS:
               return { ...state, fetchingEditarUnidadesFuncionales: false };
          case CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_FAILURE:
               return { ...state, fetchingEditarUnidadesFuncionales: false };

          // ******** INTERIOR  *****//
          case CatalogosGeneralesActions.LISTA_INTERIOR_REQUEST:
               return { ...state, fetchingListaInterior: true };
          case CatalogosGeneralesActions.LISTA_INTERIOR_SUCCESS:
               return { ...state, fetchingListaInterior: false , listaInterior: action.listaInterior};
          case CatalogosGeneralesActions.LISTA_INTERIOR_FAILURE:
               return {...state, fetchingListaInterior: false};

          case CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_REQUEST:
               return { ...state, fetchingListaInteriorActivos: true };
          case CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaInteriorActivos: false , listaInteriorActivos: action.listaInteriorActivos};
          case CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_FAILURE:
               return {...state, fetchingListaInteriorActivos: false};

          case CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_REQUEST:
               return { ...state, fetchingListaInteriorActivosCompleta: true };
          case CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_SUCCESS:
               return { ...state, fetchingListaInteriorActivosCompleta: false, listaInteriorActivosCompleta: action.listaInteriorActivosCompleta };
          case CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_FAILURE:
               return { ...state, fetchingListaInteriorActivosCompleta: false };

          case CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST:
               return { ...state, fetchingListaInteriorActivosPorUnidad: true };
          case CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_SUCCESS:
               return { ...state, fetchingListaInteriorActivosPorUnidad: false, listaInteriorActivosPorUnidad: action.listaInteriorActivosPorUnidad };
          case CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_FAILURE:
               return { ...state, fetchingListaInteriorActivosPorUnidad: false };
               
          case CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaInteriorBusqueda: true };
          case CatalogosGeneralesActions.INTERIOR_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaInteriorBusqueda: false, listaInteriorBusqueda: action.listaInteriorBusqueda, listaInteriorBusquedaCantidad: action.listaInteriorBusquedaCantidad };
          case CatalogosGeneralesActions.INTERIOR_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaInteriorBusqueda: false };     

          case CatalogosGeneralesActions.ESTADO_INTERIOR_REQUEST:
               return { ...state, EstadoInterior: true };
          case CatalogosGeneralesActions.ESTADO_INTERIOR_SUCCESS:
               return { ...state, EstadoInterior: false };
          case CatalogosGeneralesActions.ESTADO_INTERIOR_FAILURE:
               return { ...state, EstadoInterior: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_INTERIOR:
               return { ...state, showModalNuevoInterior: action.showModalNuevoInterior, page: action.page };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_INTERIOR:
               return { ...state, showModalEditarInterior: action.showModalEditarInterior, interiorSeleccionado: action.interiorSeleccionado , page: action.page};

          case CatalogosGeneralesActions.SHOW_MODAL_MAPA_INTERIOR:
               return { ...state, showModalMapaInterior: action.showModalMapaInterior};
          
          case CatalogosGeneralesActions.NUEVO_INTERIOR_REQUEST:
               return { ...state, fetchingNuevoInterior: true };
          case CatalogosGeneralesActions.NUEVO_INTERIOR_SUCCESS:
               return { ...state, fetchingNuevoInterior: false };
          case CatalogosGeneralesActions.NUEVO_INTERIOR_FAILURE:
               return { ...state, fetchingNuevoInterior: false };

          case CatalogosGeneralesActions.EDITAR_INTERIOR_REQUEST:
               return { ...state, fetchingEditarInterior: true };
          case CatalogosGeneralesActions.EDITAR_INTERIOR_SUCCESS:
               return { ...state, fetchingEditarInterior: false };
          case CatalogosGeneralesActions.EDITAR_INTERIOR_FAILURE:
               return { ...state, fetchingEditarInterior: false };


          // ******** SUB INTERIOR  *****//
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_REQUEST:
               return { ...state, fetchingListaSubInterior: true };
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_SUCCESS:
               return { ...state, fetchingListaSubInterior: false , listaSubInterior: action.listaSubInterior};
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_FAILURE:
               return {...state, fetchingListaSubInterior: false};

          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_REQUEST:
               return { ...state, fetchingListaSubInteriorActivos: true };
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaSubInteriorActivos: false , listaSubInteriorActivos: action.listaSubInteriorActivos};
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_FAILURE:
               return {...state, fetchingListaSubInteriorActivos: false};

          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST:
               return { ...state, fetchingListaSubInteriorActivosPorInterior: true };
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_SUCCESS:
               return { ...state, fetchingListaSubInteriorActivosPorInterior: false , listaSubInteriorActivosPorInterior: action.listaSubInteriorActivosPorInterior};
          case CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_FAILURE:
               return {...state, fetchingListaSubInteriorActivosPorInterior: false};
               
           case CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaSubInteriorBusqueda: true };
          case CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaSubInteriorBusqueda: false, listaSubInteriorBusqueda: action.listaSubInteriorBusqueda, listaSubInteriorBusquedaCantidad: action.listaSubInteriorBusquedaCantidad };
          case CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaSubInteriorBusqueda: false };     

          case CatalogosGeneralesActions.ESTADO_SUBINTERIOR_REQUEST:
               return { ...state, EstadoSubInterior: true };
          case CatalogosGeneralesActions.ESTADO_SUBINTERIOR_SUCCESS:
               return { ...state, EstadoSubInterior: false };
          case CatalogosGeneralesActions.ESTADO_SUBINTERIOR_FAILURE:
               return { ...state, EstadoSubInterior: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_SUBINTERIOR:
               return { ...state, showModalNuevoSubInterior: action.showModalNuevoSubInterior, page: action.page, busqueda: action.busqueda };
          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_SUBINTERIOR:
               return { ...state, showModalEditarSubInterior: action.showModalEditarSubInterior, subInteriorSeleccionado: action.subInteriorSeleccionado , page: action.page, busqueda: action.busqueda};
          case CatalogosGeneralesActions.SHOW_MODAL_MAPA_SUBINTERIOR:
               return { ...state, showModalMapaSubInterior: action.showModalMapaSubInterior};

          case CatalogosGeneralesActions.NUEVO_SUBINTERIOR_REQUEST:
               return { ...state, fetchingNuevoSubInterior: true };
          case CatalogosGeneralesActions.NUEVO_SUBINTERIOR_SUCCESS:
               return { ...state, fetchingNuevoSubInterior: false };
          case CatalogosGeneralesActions.NUEVO_SUBINTERIOR_FAILURE:
               return { ...state, fetchingNuevoSubInterior: false };

          case CatalogosGeneralesActions.EDITAR_SUBINTERIOR_REQUEST:
               return { ...state, fetchingEditarSubInterior: true };
          case CatalogosGeneralesActions.EDITAR_SUBINTERIOR_SUCCESS:
               return { ...state, fetchingEditarSubInterior: false };
          case CatalogosGeneralesActions.EDITAR_SUBINTERIOR_FAILURE:
               return { ...state, fetchingEditarSubInterior: false };
               
               
          //CATÁLOGO DE FALLAS
          case CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaCategoriasDeFallasActivos: true };
          case CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaCategoriasDeFallasActivos: false, listaCategoriasDeFallasActivos: action.listaCategoriasDeFallasActivos };
          case CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaCategoriasDeFallasActivos: false };

          case CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaCategoriasDeFallasBusqueda: true };
          case CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaCategoriasDeFallasBusqueda: false, listaCategoriasDeFallasBusqueda: action.listaCategoriasDeFallasBusqueda, listaCategoriasDeFallasBusquedaCantidad: action.listaCategoriasDeFallasBusquedaCantidad };
          case CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaCategoriasDeFallasBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_REQUEST:
               return { ...state, fetchingListaCategoriasDeFallasBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_SUCCESS:
               return { ...state, fetchingListaCategoriasDeFallasBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_FAILURE:
               return { ...state, fetchingListaCategoriasDeFallasBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVA_CATEGORIA_FALLA:
               return { ...state, showModalNuevaCategoriaDeFalla: action.showModalNuevaCategoriaDeFalla, page: action.page };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_CATEGORIA_FALLA:
               return { ...state, showModalEditarCategoriaDeFalla: action.showModalEditarCategoriaDeFalla, CategoriaDeFallaSeleccionado: action.CategoriaDeFallaSeleccionado, page: action.page };

          case CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_REQUEST:
               return { ...state, fetchingNuevaCategoriaDeFalla: true };
          case CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_SUCCESS:
               return { ...state, fetchingNuevaCategoriaDeFalla: false };
          case CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_FAILURE:
               return { ...state, fetchingNuevaCategoriaDeFalla: false };

          case CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_REQUEST:
               return { ...state, fetchingEditarCategoriaDeFalla: true };
          case CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_SUCCESS:
               return { ...state, fetchingEditarCategoriaDeFalla: false };
          case CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_FAILURE:
               return { ...state, fetchingEditarCategoriaDeFalla: false };

          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_REQUEST:
               return { ...state, fetchingExportarExcelTiemposRehabilitacion: true };
          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_SUCCESS:
               return { ...state, fetchingExportarExcelTiemposRehabilitacion: false };
          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_FAILURE:
               return { ...state, fetchingExportarExcelTiemposRehabilitacion: false };    


          // Ubicaciones 
          case CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaUbicacionesTodasBusqueda: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaUbicacionesTodasBusqueda: false, listaUbicacionesTodasBusqueda: action.listaUbicacionesTodasBusqueda };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaUbicacionesTodasBusqueda: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_REQUEST:
               return { ...state, fetchingListaUbicaciones: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_SUCCESS:
               return { ...state, fetchingListaUbicaciones: false, listaUbicaciones: action.listaUbicaciones };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_FAILURE:
               return { ...state, fetchingListaUbicaciones: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_REQUEST:
               return { ...state, fetchingListaUbicacionesActivos: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaUbicacionesActivos: false, listaUbicacionesActivos: action.listaUbicacionesActivos };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_FAILURE:
               return { ...state, fetchingListaUbicacionesActivos: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_REQUEST:
               return { ...state, fetchingListaUbicacionesFiltro: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_SUCCESS:
               return { ...state, fetchingListaUbicacionesFiltro: false, listaUbicacionesFiltro: action.listaUbicacionesFiltro };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_FAILURE:
               return { ...state, fetchingListaUbicacionesFiltro: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaUbicacionesBusqueda: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaUbicacionesBusqueda: false, listaUbicacionesBusqueda: action.listaUbicacionesBusqueda, listaUbicacionesBusquedaCantidad: action.listaUbicacionesBusquedaCantidad };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaUbicacionesBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_UBICACIONES_REQUEST:
               return { ...state, fetchingListaUbicacionesBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_UBICACIONES_SUCCESS:
               return { ...state, fetchingListaUbicacionesBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_UBICACIONES_FAILURE:
               return { ...state, fetchingListaUbicacionesBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_UBICACION:
               return { ...state, showModalNuevoUbicaciones: action.showModalNuevoUbicaciones, page: action.page };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_UBICACION:
               return { ...state, showModalEditarUbicaciones: action.showModalEditarUbicaciones, ubicacionSeleccionado: action.ubicacionSeleccionado, page: action.page };

          case CatalogosGeneralesActions.NUEVO_UBICACIONES_REQUEST:
               return { ...state, fetchingNuevoUbicaciones: true };
          case CatalogosGeneralesActions.NUEVO_UBICACIONES_SUCCESS:
               return { ...state, fetchingNuevoUbicaciones: false };
          case CatalogosGeneralesActions.NUEVO_UBICACIONES_FAILURE:
               return { ...state, fetchingNuevoUbicaciones: false };

          case CatalogosGeneralesActions.EDITAR_UBICACIONES_REQUEST:
               return { ...state, fetchingEditarUbicaciones: true };
          case CatalogosGeneralesActions.EDITAR_UBICACIONES_SUCCESS:
               return { ...state, fetchingEditarUbicaciones: false };
          case CatalogosGeneralesActions.EDITAR_UBICACIONES_FAILURE:
               return { ...state, fetchingEditarUbicaciones: false };
// Ubicaciones 
          case CatalogosGeneralesActions.LISTA_UBICACIONES_REQUEST:
               return { ...state, fetchingListaUbicaciones: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_SUCCESS:
               return { ...state, fetchingListaUbicaciones: false, listaUbicaciones: action.listaUbicaciones };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_FAILURE:
               return { ...state, fetchingListaUbicaciones: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_REQUEST:
               return { ...state, fetchingListaUbicacionesActivos: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaUbicacionesActivos: false, listaUbicacionesActivos: action.listaUbicacionesActivos };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_FAILURE:
               return { ...state, fetchingListaUbicacionesActivos: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_REQUEST:
               return { ...state, fetchingListaUbicacionesFiltro: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_SUCCESS:
               return { ...state, fetchingListaUbicacionesFiltro: false, listaUbicacionesFiltro: action.listaUbicacionesFiltro };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_FAILURE:
               return { ...state, fetchingListaUbicacionesFiltro: false };

          case CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaUbicacionesBusqueda: true };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaUbicacionesBusqueda: false, listaUbicacionesBusqueda: action.listaUbicacionesBusqueda, listaUbicacionesBusquedaCantidad: action.listaUbicacionesBusquedaCantidad };
          case CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaUbicacionesBusqueda: false };

          case CatalogosGeneralesActions.ESTADO_UBICACIONES_REQUEST:
               return { ...state, fetchingListaUbicacionesBusqueda: true };
          case CatalogosGeneralesActions.ESTADO_UBICACIONES_SUCCESS:
               return { ...state, fetchingListaUbicacionesBusqueda: false };
          case CatalogosGeneralesActions.ESTADO_UBICACIONES_FAILURE:
               return { ...state, fetchingListaUbicacionesBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_UBICACION:
               return { ...state, showModalNuevoUbicaciones: action.showModalNuevoUbicaciones, page: action.page };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_UBICACION:
               return { ...state, showModalEditarUbicaciones: action.showModalEditarUbicaciones, ubicacionSeleccionado: action.ubicacionSeleccionado, page: action.page };

          case CatalogosGeneralesActions.NUEVO_UBICACIONES_REQUEST:
               return { ...state, fetchingNuevoUbicaciones: true };
          case CatalogosGeneralesActions.NUEVO_UBICACIONES_SUCCESS:
               return { ...state, fetchingNuevoUbicaciones: false };
          case CatalogosGeneralesActions.NUEVO_UBICACIONES_FAILURE:
               return { ...state, fetchingNuevoUbicaciones: false };

          case CatalogosGeneralesActions.EDITAR_UBICACIONES_REQUEST:
               return { ...state, fetchingEditarUbicaciones: true };
          case CatalogosGeneralesActions.EDITAR_UBICACIONES_SUCCESS:
               return { ...state, fetchingEditarUbicaciones: false };
          case CatalogosGeneralesActions.EDITAR_UBICACIONES_FAILURE:
               return { ...state, fetchingEditarUbicaciones: false };

          case CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_REQUEST:
               return { ...state, fetchingExportarUbicaciones: true };
          case CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_SUCCESS:
               return { ...state, fetchingExportarUbicaciones: false };
          case CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_FAILURE:
               return { ...state, fetchingExportarUbicaciones: false };

          case CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_REQUEST:
               return { ...state, fetchingExportarUbicaciones: true };
          case CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_SUCCESS:
               return { ...state, fetchingExportarUbicaciones: false };
          case CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_FAILURE:
               return { ...state, fetchingExportarUbicaciones: false };

          // PONDERACIONES 
          case CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_REQUEST:
               return { ...state, fetchingListaPonderacionesActivas: true };
          case CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_SUCCESS:
               return { ...state, fetchingListaPonderacionesActivas: false, listaPonderacionesActivas: action.listaPonderacionesActivas, listaPonderacionesAreas: action.listaPonderacionesAreas };
          case CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_FAILURE:
               return { ...state, fetchingListaPonderacionesActivas: false };

          case CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaPonderacionesBusqueda: true };
          case CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaPonderacionesBusqueda: false, listaPonderacionesBusqueda: action.listaPonderacionesBusqueda, listaPonderacionesBusquedaCantidad: action.listaPonderacionesBusquedaCantidad };
          case CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaPonderacionesBusqueda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_PONDERACION:
               return { ...state, showModalNuevoPonderacion: action.showModalNuevoPonderacion, page: action.page, busqueda: action.busqueda, bloqueado: action.bloqueado };
          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_PONDERACION:
               return { ...state, showModalEditarPonderacion: action.showModalEditarPonderacion, ponderacionSeleccionado: action.ponderacionSeleccionado, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.NUEVO_PONDERACION_REQUEST:
               return { ...state, fetchingNuevoPonderacion: true };
          case CatalogosGeneralesActions.NUEVO_PONDERACION_SUCCESS:
               return { ...state, fetchingNuevoPonderacion: false };
          case CatalogosGeneralesActions.NUEVO_PONDERACION_FAILURE:
               return { ...state, fetchingNuevoPonderacion: false };

          case CatalogosGeneralesActions.EDITAR_PONDERACION_REQUEST:
               return { ...state, fetchingEditarPonderacion: true };
          case CatalogosGeneralesActions.EDITAR_PONDERACION_SUCCESS:
               return { ...state, fetchingEditarPonderacion: false };
          case CatalogosGeneralesActions.EDITAR_PONDERACION_FAILURE:
               return { ...state, fetchingEditarPonderacion: false };

          case CatalogosGeneralesActions.CAMBIAR_VISTA_PONDERACION:
               return { ...state, vistaModalPonderacion: action.vistaModalPonderacion, formulario: action.formulario, ponderacionEditados: action.ponderacionEditados };

          //Tipo de Moneda
          case CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_REQUEST:
               return { ...state, fetchingListTipoMoneda: true };
          case CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_SUCCESS:
               return { ...state, fetchingListTipoMoneda: false, listTipoMoneda: action.listTipoMoneda, listTipoMonedaCount: action.listTipoMonedaCount };
          case CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_FAILURE:
               return { ...state, fetchingListTipoMoneda: false };

          case CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_REQUEST:
               return { ...state, fetchingListTipoMoneda: true };
          case CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_SUCCESS:
               return { ...state, fetchingListTipoMoneda: false };
          case CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_FAILURE:
               return { ...state, fetchingListTipoMoneda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_TIPO_MONEDA:
               return { ...state, showModalNuevoTipoMoneda: action.showModalNuevoTipoMoneda, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_REQUEST:
               return { ...state, fetchingNuevoTipoMoneda: true };
          case CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_SUCCESS:
               return { ...state, fetchingNuevoTipoMoneda: false };
          case CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_FAILURE:
               return { ...state, fetchingNuevoTipoMoneda: false };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_TIPO_MONEDA:
               return { ...state, showModalEditarTipoMoneda: action.showModalEditarTipoMoneda, tipoMonedaSeleccionado: action.tipoMonedaSeleccionado, page: action.page, busqueda: action.busqueda };

          case CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_REQUEST:
               return { ...state, fetchingEditarTipoMoneda: true };
          case CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_SUCCESS:
               return { ...state, fetchingEditarTipoMoneda: false };
          case CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_FAILURE:
               return { ...state, fetchingEditarTipoMoneda: false };

          case CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaTipoMonedasActivas: true };
          case CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaTipoMonedasActivas: false, listaTipoMonedasActivas: action.listaTipoMonedasActivas };
          case CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaTipoMonedasActivas: false };

          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_REQUEST:
               return { ...state, fetchingExportarExcelTipoMoneda: true };
          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_SUCCESS:
               return { ...state, fetchingExportarExcelTipoMoneda: false };
          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_FAILURE:
               return { ...state, fetchingExportarExcelTipoMoneda: false };

          //TIPO DE CAMBIO
          case CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_REQUEST:
               return { ...state, fetchingListaTipoCambio: true };
          case CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_SUCCESS:
               return { ...state, fetchingListaTipoCambio: false, listaTipoCambio: action.listaTipoCambio, listaTipoCambioCantidad: action.listaTipoCambioCantidad };
          case CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_FAILURE:
               return { ...state, fetchingListaTipoCambio: false };

          case CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_REQUEST:
               return { ...state, fetchingNuevoTipoCambio: true };
          case CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_SUCCESS:
               return { ...state, fetchingNuevoTipoCambio: false };
          case CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_FAILURE:
               return { ...state, fetchingNuevoTipoCambio: false };

          case CatalogosGeneralesActions.SHOW_MODAL_NUEVO_TIPO_CAMBIO:
               return { ...state, showModalNuevoTipoCambio: action.showModalNuevoTipoCambio, page: action.page, busqueda: action.busqueda, orden: action.orden };

          case CatalogosGeneralesActions.SHOW_MODAL_EDITAR_TIPO_CAMBIO:
               return { ...state, showModalEditarTipoCambio: action.showModalEditarTipoCambio, tipoCambioSeleccionado: action.tipoCambioSeleccionado, page: action.page, busqueda: action.busqueda, orden: action.orden };

          case CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_REQUEST:
               return { ...state, fetchingEditarTipoCambio: true };
          case CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_SUCCESS:
               return { ...state, fetchingEditarTipoCambio: false };
          case CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_FAILURE:
               return { ...state, fetchingEditarTipoCambio: false };

          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_REQUEST:
               return { ...state, fetchingExportarExcelTipoCambio: true };
          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_SUCCESS:
               return { ...state, fetchingExportarExcelTipoCambio: false };
          case CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_FAILURE:
               return { ...state, fetchingExportarExcelTipoCambio: false };

          default:
               return state;
     }
};