import React, { Component } from 'react';
import {Input, Button, Col, Select ,Row, Table, message, Tooltip, Divider} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { Search } = Input;

class ListaPorTipoDeFolio extends Component {
    formRef = React.createRef();
    state = {
        page: 1,
        idSubFamilia: undefined,
        order: 'ASC',
        field: 'nombre',
        busqueda: '',
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        showTable: false,
        idUbicacion: null,
        idOrigen: null,
        idDestino: null,
    }
    componentDidMount(){
        this.props.onGetSubfamilias();
        this.props.onGetAlmacenes();
    }
    render() { 
        
        const {listaProductosFiltradoRequest,fetchingListaProductosFiltradoRequest,listaProductosFiltradoRequestCantidad,
            fetchingListaSubfamiliasProductos,listaSubfamiliasProductosActivos, listaDetalle, listaSeleccionados,
            fetchingListaAlmacenesActivos, listaAlmacenesActivos, selectedRowKeys, hasSelected,
            onActualizarTabla, fetchingListaSubfamilias, listaSubfamilias, tipoActivos, listaInventarioTipoActivos,
            fetchingListaInventarioTipoActivos, listaInventarioTipoActivosCantidad, limpiarFormulario
        } = this.props;

        if (limpiarFormulario && limpiarFormulario === 'agregarProductosFolio') {
            console.log('Entró a limpiar fomulario agregarProductos')
            this.setState({ 
                page: 1,
                idSubFamilia: undefined,
                order: 'ASC',
                field: 'nombre',
                busqueda: '',
                selectedRowKeys: [], // Check here to configure the default column
                selectedRows: [],
                showTable: false,
                idUbicacion: null,
                idOrigen: null,
                idDestino: null,
            });
            onActualizarTabla(null, [], 1, true);
            this.props.onLimpiarFormulario();
        }

        const folioOrigen = listaDetalle[0].folioOrigen;   
        const folio = listaDetalle[0].folio;   
           
        const columns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center'
        },{
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center'
        }, {
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center'
        },{
            title: 'Número de serie',  dataIndex: 'serieNum', key: 'serieNum', align: 'center', render: (text, record) => (
                record.serieNum ? text : 'No aplica'
            ), 
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center'
        },{
            title: 'Presentación',
            dataIndex: 'presentacion',
            key: 'presentacion',
            align: 'center',
        },{
            title: 'Costo unitario',
            dataIndex: 'precioTabla',
            key: 'precioTabla',
            align: 'center',
        },{
            title: 'Ubicacion Producto Origen',
            dataIndex: 'ubicacionO',
            key: 'ubicacionO',
            align: 'center',
        },{
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center'
        },{
            title: 'Lote',
            dataIndex: 'lote',
            key: 'lote',
            align: 'center'
        }];

        const onBuscarProducto = (busqueda, name) => {
            this.setState({
                [name]: busqueda,
                page: 1,
            });
            for(let i in listaDetalle){
                if(listaDetalle[i].tiposFoliosByIdTipoFolio.folioOrigen === "true"){
                    if(name === 'busqueda'){
                        this.props.onGetRequisicionFiltrado(1, folioOrigen, busqueda, this.state.idSubFamilia, folio);
                    } else {
                        this.props.onGetRequisicionFiltrado(1, folioOrigen, this.state.busqueda, busqueda, folio);
                    } 
                } else { 
                    if(listaDetalle[i].tiposFoliosByIdTipoFolio.operacion === "Suma"){
                        console.log('entro en suma');
                        if(name === 'busqueda'){
                            console.log('entro a busqueda')
                            console.log("this.state.idSubFamilia", this.state.idSubFamilia)
                            this.props.onGetProductosFiltrado(1, busqueda, this.state.field, this.state.order, this.state.idSubFamilia, folio)
                        } else {
                            this.props.onGetProductosFiltrado(1, this.state.busqueda, this.state.field, this.state.order, busqueda, folio)
                        }
                    } else {
                        if(tipoActivos){
                            if(name === 'busqueda'){
                                this.props.onGetInventarioTipoActivos(1, this.props.almacenOrigen[0].idAlmacen, busqueda, this.state.idSubFamilia)
                            } else {
                                this.props.onGetInventarioTipoActivos(1, this.props.almacenOrigen[0].idAlmacen, this.state.busqueda, busqueda)
                            }
                        }
                        else{
                            if(name === 'busqueda'){
                                console.log('this.state.idUbicacion en busqueda', this.state.idUbicacion)
                                this.props.onGetInventarioFiltrado(1, busqueda, this.state.idSubFamilia, '', this.state.idUbicacion, folio)
                            } else {
                                this.props.onGetInventarioFiltrado(1, this.state.busqueda, busqueda, '', this.state.idUbicacion, folio)
                            }
                        }
                    }
                }
            }
        }
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });

            for(let i in listaDetalle){
                if (listaDetalle[i].tiposFoliosByIdTipoFolio.folioOrigen === "true") {
                   this.props.onGetRequisicionFiltrado(pagination.current, folioOrigen, this.state.busqueda, this.state.idSubFamilia, folio);
                } else {
                    if (listaDetalle[i].tiposFoliosByIdTipoFolio.operacion === "Suma") {
                        console.log('entro en suma ',this.state.page, this.state.busqueda, "", "", this.state.idSubFamilia, folio )
                        //this.props.onGetProductosFiltrado(this.state.page, this.state.busqueda, this.state.field, this.state.order, this.state.idSubFamilia, folio)
                        this.props.onGetProductosFiltrado(pagination.current, this.state.busqueda,this.state.field, this.state.order, this.state.idSubFamilia, folio)
                    } else {
                        if(tipoActivos){
                            this.props.onGetInventarioTipoActivos(pagination.current, this.props.almacenOrigen[0].idAlmacen, this.state.busqueda, this.state.idSubFamilia)
                        }
                        this.props.onGetInventarioFiltrado(pagination.current, this.state.busqueda, this.state.idSubFamilia, '', this.state.idUbicacion, folio, this.props.almacenOrigen[0].idAlmacen)
                    }
                }
            }
            onActualizarTabla( this.props.listaSeleccionados, selectedRowKeys, pagination.current, hasSelected );
        }  

        const onChangeTabla = (record, selected, selectedRows) => {
            console.log('record: ', record);
            console.log('selected: ', selected);
            let listaSelected = listaSeleccionados;
            const listaFinal = [];
            if(!listaSelected){
                listaSelected = [];
            }
            
            if(selected.length){
                for(let i in selected){
                    if(selected[i]){
                        selected[i].agregado = false;
                    }
                }
                for(let i in selected){
                    if(listaSelected.length){
                        console.log('If de lista no vacía')
                        for(let x in listaSelected){
                            if(selected[i]){
                                console.log('!selected[i].agregado', !selected[i].agregado)
                                if(!selected[i].agregado){
                                    console.log('listaSelected[x].rowKey, record[i]', listaSelected[x].rowKey, record[i])
                                    if(listaSelected[x].rowKey === record[i]){
                                        console.log('Entró al if de record')
                                        listaFinal.push(listaSelected[x]);
                                        selected[i].agregado = true;
                                    }
                                    else{
                                        console.log('Entró al else de record')
                                        listaFinal.push(selected[i]);
                                        selected[i].agregado = true;
                                    }
                                }
                            }
                            else{
                                if(listaSelected[x].rowKey === record[i]){
                                    console.log('Entró al else de undefined selected')
                                    listaFinal.push(listaSelected[x]);
                                }
                            }
                        }
                    }
                    else{
                        console.log('Else de primera vez')
                        listaFinal.push(selected[i]);
                    }
                }
            }
            console.log('listaFinal');
            console.log(listaFinal);
            
            let hasSelectedLocal = true
            
            if(record.length !== 0){
                hasSelectedLocal = false;
            }
            
            this.setState({ selectedRowKeys: record, selectedRows: selected });
            onActualizarTabla( listaFinal, record, this.state.page, hasSelectedLocal );   
        };
       
      
        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

       const vistaAnterior = () => {
           this.props.onCambiarVista(1);
           this.props.onBorrarListas();
           this.props.onBorrarListasRequisicion();// quitarla
           this.props.onBorrarUbicaciones();
           this.props.onBorrarUbicacionesDestino();
           this.props.onBorrarUbicacionesOrigen();
           onActualizarTabla(null, [], 1, true);
       }

       const vistaSiguiente = () => {
            if(listaDetalle[0].tiposFoliosByIdTipoFolio.operacion === "Ambas"){
                for (let i = 0; i < listaSeleccionados.length; i++) {
                    listaSeleccionados[i].idUbicacionProductoO = this.state.idOrigen;
                    listaSeleccionados[i].idUbicacionProductoD = this.state.idDestino;
                }
                this.props.onCambiarVista(3, listaSeleccionados, {busqueda: this.state.busqueda, idSubFamilia: this.state.idSubFamilia});
                this.props.onBorrarUbicacionesDestino();
               
            } else if(listaDetalle[0].tiposFoliosByIdTipoFolio.operacion === "Suma"){
                if(listaDetalle[0].idAlmacenDestino){
                    this.props.onGetUbicacionDestino(1, listaDetalle[0].idAlmacenDestino.split('-')[0]);  
                    this.props.onCambiarVista(3, listaSeleccionados, {busqueda: this.state.busqueda, idSubFamilia: this.state.idSubFamilia});
                } else {
                    message.warning('Favor de seleccionar un almacén destino')
                }
            } else if(listaDetalle[0].tiposFoliosByIdTipoFolio.operacion === "Resta"){
                for (let i = 0; i < listaSeleccionados.length; i++) {
                    listaSeleccionados[i].idUbicacionProductoO = this.state.idOrigen;
                }
                this.props.onCambiarVista(3, listaSeleccionados, {busqueda: this.state.busqueda, idSubFamilia: this.state.idSubFamilia}, tipoActivos);
                this.props.onBorrarUbicacionesOrigen(); 
            } 
        }

       const onAlmacenDestino = value => {
            console.log('value', value);
            for (const key in listaDetalle) {
                listaDetalle[key].idAlmacenDestino = value;
            }
            this.props.onGetConsultaListaDetalle(listaDetalle)
        };
   
        return ( 
            <Row type='flex' justify='space-between'>
                {tipoActivos ? (
                <Col span={11}>
                    <Select allowClear
                        style={{ width: '100%' }}
                        placeholder="Selecciona la subfamilia"
                        loading={fetchingListaSubfamilias}
                        onChange={(value) => onBuscarProducto(value, "idSubFamilia")}
                    >
                        {listaSubfamilias.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                    </Select>
                </Col>
                ): (
                    <Col span={11}>
                    <Select allowClear
                        style={{ width: '100%' }}
                        placeholder="Selecciona la subfamilia"
                        loading={fetchingListaSubfamiliasProductos}
                        onChange={(value) => onBuscarProducto(value, "idSubFamilia")}
                    >
                        {listaSubfamiliasProductosActivos.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                    </Select>
                </Col>
                )}
                <Col span={11}>
                    <Search className='buscarModulos' placeholder='Ingresa nombre/clave/SKU/código de almacén' onSearch={(value) => onBuscarProducto(value, "busqueda")} />
                </Col>

                {listaAlmacenesActivos && listaDetalle[0].tiposFoliosByIdTipoFolio.operacion === "Suma" ? (
                <Col span={11}>
                    <br />
                    <Select allowClear
                        style={{ width: '100%' }}
                        placeholder="Selecciona almacen destino"
                        loading={fetchingListaAlmacenesActivos}
                        onChange={onAlmacenDestino}
                    >
                        {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen + "-" + option.caracteristica}>{ option.nombreC }</Option>)}
                    </Select>
                </Col>
                ): false}

                <Col span={20}>
                    <Divider orientation="left">Agregar Productos</Divider>
                </Col>

                <Col span={1} style={{ paddingTop:'1em' }}>
                    <Tooltip title='Paso Anterior'>
                        <Button className="iconTer" shape="circle" onClick={vistaAnterior} icon={<ArrowLeftOutlined />}/>
                    </Tooltip>
                </Col>

                <Col span={1} style={{ paddingTop:'1em' }}>
                    <Tooltip title='Siguiente Paso'>
                        <Button className="iconTer" shape="circle" onClick={vistaSiguiente} icon={<ArrowRightOutlined />} disabled={hasSelected}/>
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <br />
                    <Table
                        size="small"
                        rowKey="rowKey"
                        columns={columns}
                        dataSource={tipoActivos ? listaInventarioTipoActivos : listaProductosFiltradoRequest}
                        loading={tipoActivos ? fetchingListaInventarioTipoActivos : fetchingListaProductosFiltradoRequest}
                        locale={{ emptyText: 'Sin datos de productos' }}
                        onChange={handleTableChange}
                        pagination={{ total: tipoActivos ? listaInventarioTipoActivosCantidad : listaProductosFiltradoRequestCantidad, simple: true, current: this.state.page }}
                        showSorterTooltip={true}
                        rowSelection={rowSelection}
                        scroll={{"x": 1000 | true, "y": 1000}}
                    />
                </Col>      
                {/* <Col span={10}>
                    <Button onClick={vistaAnterior} className='nuevoUsuario'>Anterior Paso</Button>
                </Col>
                <Col span={10}>
                    <Button onClick={vistaSiguiente} className='nuevoUsuario' disabled={hasSelected}>Siguiente Paso</Button>
                </Col> */}
            </Row>
        );
    }
}
 
const mapStateToProps = state => {
	return {
        listaProductosFiltradoRequest: state.AlmacenesReducer.listaProductosFiltradoRequest,
        fetchingListaProductosFiltradoRequest: state.AlmacenesReducer.fetchingListaProductosFiltradoRequest,
        listaProductosFiltradoRequestCantidad: state.AlmacenesReducer.listaProductosFiltradoRequestCantidad,
        fetchingListaSubfamiliasProductos: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasProductos,
        listaSubfamiliasProductosActivos: state.CatalogosGeneralesReducer.listaSubfamiliasProductosActivos,
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaUbicaciones: state.AlmacenesReducer.listaUbicaciones,
        fetchingObtenerUbicacion: state.AlmacenesReducer.fetchingObtenerUbicacion,
        fetchingObtenerUbicacionDestino: state.AlmacenesReducer.fetchingObtenerUbicacionDestino,
        listaUbicacionesDestino: state.AlmacenesReducer.listaUbicacionesDestino,
        hasSelected: state.AlmacenesReducer.hasSelected,
        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
        selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,
        pageMovimientos: state.AlmacenesReducer.pageMovimientos,

        fetchingListaSubfamilias: state.CatalogosGeneralesReducer.fetchingListaSubfamilias,
        listaSubfamilias: state.CatalogosGeneralesReducer.listaSubfamilias,
        tipoActivos: state.AlmacenesReducer.tipoActivos,
        fetchingListaInventarioTipoActivos: state.AlmacenesReducer.fetchingListaInventarioTipoActivos,
        listaInventarioTipoActivos: state.AlmacenesReducer.listaInventarioTipoActivos,
        listaInventarioTipoActivosCantidad: state.AlmacenesReducer.listaInventarioTipoActivosCantidad,
        almacenOrigen: state.AlmacenesReducer.almacenOrigen,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST' });
        },
        onCambiarVista: (vistaModalEditarFolio, listaSeleccionadosEditar, busqueda, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_EDITARFOLIO", vistaModalEditarFolio: vistaModalEditarFolio, listaSeleccionadosEditar, busqueda, tipoActivos});
        },
        onGetProductosFiltrado: (page, busqueda, field, order ,idSubFamilia, folio) => {
            dispatch({ type: "LISTA_PRODUCTOS_FILTRADO_REQUEST", page: page, busqueda: busqueda, field: field, order: order, idSubFamilia:idSubFamilia, folio: folio });
        },
        onGetInventarioFiltrado: (page, busqueda, idSubFamilia, idFamilia, idUbicacion, folio, idAlmacenOrigen) => {
            dispatch({ type: "LISTA_INVENTARIO_FILTRADO_REQUEST", page: page, busqueda: busqueda, idFamilia: idFamilia, idSubFamilia: idSubFamilia, idUbicacion: idUbicacion, folio: folio, idAlmacenOrigen})
        },
        onGetRequisicionFiltrado: (page, folioOrigen, busqueda, idSubFamilia, idFamilia, folio) => {
            dispatch({ type: "LISTA_REQUISICION_FILTRADO_REQUEST", page: page, folioOrigen: folioOrigen, busqueda: busqueda, idFamilia: idFamilia, idSubFamilia: idSubFamilia, folio});
        },
        onBorrarUbicaciones: () => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_SUCCESS", listaUbicaciones: null})
        },
        onBorrarUbicacionesDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null})
        },
        onBorrarUbicacionesOrigen: () => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_SUCCESS", listaUbicaciones: null})
        },
        onBorrarListas: () => {
            dispatch({ type: "LISTA_INVENTARIO_SUCCESS", listaProductosBusqueda: null});
        },
        onGetAlmacenes: () => {
            dispatch({ type: "ALMACENES_ACTIVOS_BUSQUEDA_REQUEST"});
        },
        onBorrarListasRequisicion: () => {
            dispatch({ type: "LISTA_REQUISICION_FILTRADO_SUCCESS", listaProductosFiltradoRequest: null});
        }, 
        onGetUbicacionDestino: (page, idAlmacen) => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onGetConsultaListaDetalle: (listaDetalle) => {
            dispatch({ type: "LISTA_DETALLE_CONSULTA_SUCCESS", listaDetalle})
        },
        onGetUbicacionOrigen: (page, idAlmacen) => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected})
        },
        onGetInventarioTipoActivos: (page, idAlmacenOrigen, busqueda, idSubFamilia) => {
            dispatch({ type: 'LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST', page, idAlmacenOrigen, busqueda, idSubFamilia })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ListaPorTipoDeFolio);