import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Table, InputNumber } from 'antd';
import { connect } from "react-redux";
import {  } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class CapturaInventario extends React.Component {
    formRef = React.createRef();

    componentDidMount() {
          
     }

     state = {
          page: 1,
          buttonGuardar: true,
     }


    render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               showModalCapturaInventario,
               onShowModalCapturaInventario,
               listaProductosByFolio,
               listaProductosByFolioCantidad,
               fetchingListaProductosByFolio,
               onGuardarCaptura,
               numeroConteo,
               onEnviarCaptura,
               //onLimpiarTabla
          } = this.props;

          const onFinish = formulario => {               
               console.log('Success:', formulario);

          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onShowModalCapturaInventario();
               //onLimpiarTabla();
          };

          const columns = [
               { title: 'SKU', dataIndex: 'sku', key: 'sku', width:'8%' },
               { title: 'Clave de Producto', dataIndex: 'clave', key: 'clave', width:'10%' },
               { title: 'Nobre de Producto', dataIndex: 'nombre', key: 'nombre', width:'15%' },
               { title: 'Unidad de Medida', dataIndex: 'unidadMedida', key: 'unidadMedida', width:'10%' },
               { title: 'Ubicación', dataIndex: 'ubicacion', key: 'ubicacion', width:'22%' },
               { title: 'Cantidad Contada', dataIndex: '', key: '', width:'10%',
               render: (record) => (
                    <InputNumber value={record.conteo} min={0} onChange = {(value) => handleCapturar(value, record)} /> 
               )},
          ];

          const handleCapturar = (value, record) => {
               console.log('record', record)
               let listaProductosByFolioLocal = listaProductosByFolio;
               onGuardarCaptura(null, listaProductosByFolioCantidad);

               for(let i = 0; i < listaProductosByFolioLocal.data.length; i++){
                    if(listaProductosByFolioLocal.data[i].idCapturaInventario === record.idCapturaInventario){
                         listaProductosByFolioLocal.data[i].conteo = value;

                         if(numeroConteo == 1){ 
                              listaProductosByFolioLocal.data[i].conteo1 = value
                         }
                         else if(numeroConteo == 2){ 
                              listaProductosByFolioLocal.data[i].conteo2 = value }
                         else if(numeroConteo == 3){ listaProductosByFolioLocal.data[i].conteo3 = value }
                    }
               }

               onGuardarCaptura(listaProductosByFolioLocal, listaProductosByFolioCantidad);
          }

          const onCapturar = () => {
               for(let i = 0; i < listaProductosByFolio.data.length; i++){
                    if(numeroConteo == 1){ 
                         if(listaProductosByFolio.data[i].conteo1 === -1)
                              {listaProductosByFolio.data[i].conteo1 = 0;}
                    }
                    else if(numeroConteo == 2){ 
                         if(listaProductosByFolio.data[i].conteo2 === -1)
                              {listaProductosByFolio.data[i].conteo2 = 0;}
                    }
                    else if(numeroConteo == 3){ 
                         if(listaProductosByFolio.data[i].conteo3 === -1)
                              {listaProductosByFolio.data[i].conteo3 = 0;}
                    }
               }
               onEnviarCaptura(listaProductosByFolio.data, numeroConteo);
               
               this.formRef.current.resetFields();
               onLimpiarFormulario();
          };

          return (
               <Modal
                    visible={showModalCapturaInventario}
                    title={'Captura de Toma de Inventarios Físicos'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'80%'}
               >

                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                    >
                         <Row justify='space-around' style={{ textAlign: 'left' }}>   
                              <Col span={24}>
                                   <Table
                                        size="small" 
                                        rowKey="idCapturaInventario" 
                                        columns={columns}
                                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}

                                        dataSource={listaProductosByFolio.data}
                                        loading={fetchingListaProductosByFolio}
                                        // pagination={{ total: listaProductosByFolio.cantidad, simple: true, current: this.state.page }}
                                        pagination={{ pageSize: listaProductosByFolioCantidad }} 
                                        scroll={{ y: 450 }}
                                   />
                              </Col> 

                              <Button className={'btnPrueba'} onClick={onCapturar} style={{ width: '33%' }} >
                                   Guardar
                              </Button>                                             
                         </Row>
                    </Form>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalCapturaInventario: state.AlmacenesReducer.showModalCapturaInventario,
          listaProductosByFolio: state.AlmacenesReducer.listaProductosByFolio,
          fetchingListaProductosByFolio: state.AlmacenesReducer.fetchingListaProductosByFolio,
          listaProductosByFolioCantidad: state.AlmacenesReducer.listaProductosByFolioCantidad,
          numeroConteo: state.AlmacenesReducer.numeroConteo,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onShowModalCapturaInventario: () => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_INVENTARIO', showModalCapturaInventario: false });
          },
          onGuardarCaptura: (listaProductosByFolio, listaProductosByFolioCantidad) => {
               dispatch({ type: 'LISTA_PRODUCTOS_BY_FOLIO_SUCCESS', listaProductosByFolio, listaProductosByFolioCantidad });
          },
          onEnviarCaptura: (listaProductosByFolio, numConteo) => {
               dispatch({ type: 'CAPTURAR_CONTEO_REQUEST', listaProductosByFolio, numConteo });
          },
          // onLimpiarTabla: () => {
          //      dispatch({ type: 'CAPTURAR_CONTEO_SUCCESS', listaProductosByFolio: []});
          // }

     };
};

export default connect(mapStateToProps, mapDispatchToProps)(CapturaInventario);
