import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarRol extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingEditarDominio, showModalEditarDominio, onEditarDominio, onShowModalEditarDominio,
            dominioSeleccionado, limpiarFormulario, onLimpiarFormulario,
        } = this.props;
        console.log('dominioSeleccionado en modal');
        console.log(dominioSeleccionado);
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarDominio(formulario, dominioSeleccionado, this.props.page);
        }; 
        if(limpiarFormulario && limpiarFormulario === 'editarDominio'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }
        
        

        const handleCancel = () => {
            onShowModalEditarDominio()
        }
        return (
            <Modal
            visible={showModalEditarDominio}
            title='Editar Dominio'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarDominio}>
                    {dominioSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={dominioSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-between'>
                            <Col span={14}>
                                <Form.Item
                                    label="Nombre del dominio"
                                    name="dominio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input addonBefore='@' maxLength={30} pattern="(.[a-z0-9]{1,10})+$" title="El valor de dominio debe ser letras minúsculas o números" placeholder="Ingresa el dominio" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                               <Form.Item
                                    label='Termino del dominio'
                                    name='terminacion'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input title="El valor de terminación debe ser letras minúsculas" 
                                        pattern="^\.(.[comx]{1,2})+$" 
                                        placeholder='.com/.mx/.com.mx' 
                                        maxLength={15}/>
                                </Form.Item> 
                            </Col>
                        </Row>
                        
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Editar Dominio</Button>
                        </Col>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarDominio: state.InformacionOperativaReducer.fetchingEditarDominio,
        showModalEditarDominio: state.InformacionOperativaReducer.showModalEditarDominio,
        dominioSeleccionado: state.InformacionOperativaReducer.dominioSeleccionado,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarDominio: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_DOMINIO', showModalEditarDominio: false, dominioSeleccionado: null})
        },
        onEditarDominio: ( formulario, dominioSeleccionado, page ) => {
            dispatch({ type: "EDITAR_DOMINIO_REQUEST" , formulario: formulario, dominioSeleccionado: dominioSeleccionado, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRol);