import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Table, Input, Button, Select, Card, message, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, EyeOutlined, PlusOutlined, FileExcelFilled, DownloadOutlined } from '@ant-design/icons';
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevoDocumentoBase from './ModalNuevoDocumentoBase';
import ModalEditarDocumentoBase from './ModalEditarDocumentoBase';
import ModalVerDocumento from './ModalVerDocumento';

const { Option, OptGroup } = Select;

class BaseDeConocimiento extends React.Component {
    formRef = React.createRef();
    state = {
        page: 1,
        order: 'ASC',
        field: 'tipoConocimiento',
        formulario:{
            descripcion: null,
            servicios: [],
        },
        serviciosTodos: false
    }

    componentDidMount() {
        this.props.onGetServiciosActivos();
        this.props.onGetDocumentosBase(this.state.page, this.state.formulario, this.state.field, this.state.order, this.props.listaServiciosActivosF);
    }
    componentWillUnmount(){
        this.props.onListaServiciosFiltroLimpiar();
    }

    render() {
        const {listaDocumentosBaseBusqueda, fetchingListaDocumentosBaseBusqueda, listaDocumentosBaseBusquedaCantidad, fetchingListaServiciosFiltro, listaServiciosFiltro,
            onGetDocumentosBase, onModalNuevoDocumentoBase, onModalEditarDocumentoBase, onListaServiciosFiltro, onListaServiciosFiltroLimpiar,
            onExportarExcel, listaServiciosActivosF
        } = this.props;
        const {page, field} = this.state;
            
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Tipo',
            dataIndex: 'tipoConocimiento',
            key: 'tipoConocimiento',
            sorter: true,
        },{
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
        }, {
            title: 'Nombre del documento',
            dataIndex: 'descripcion',
            key: 'descripcion',
        }, {
            title: 'Versión',
            dataIndex: 'version',
            key: 'version',
        },{
            title: 'Servicio',
            dataIndex: 'servicio',
            key: 'servicio',
        }, {
            title: 'Consultar',
            key: 'consultar',
            align: 'center',
            render: (text, record) => (
                    <a className='editarIcono' onClick={() => onMostrarPDF(record)}><EyeOutlined /></a>
            ),
        },{
            title: 'Descargar',
            key: 'descargar',
            align: 'center',
            render: (text, record) => (
                    <a className='editarIcono' target='_blank' href={record.rutaArchivo}><DownloadOutlined /></a>
            ),
        },{
            title: 'Editar',
            key: 'editar',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarDocumentoBase}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onMostrarPDF = (key) => {
            this.props.onShowModalVerDocumento(key);
       }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const onFinish = (formulario) =>{
            console.log(formulario);
            onGetDocumentosBase(page, formulario, field, this.state.order, listaServiciosActivosF);
            this.setState({
                formulario,
            })
        };

        

        const onEditar = (key) => {
            onModalEditarDocumentoBase(key, page, this.state.formulario);

        };

        const onNuevoDocumentoBase = () => {
            onModalNuevoDocumentoBase(page, this.state.formulario);
        };

        const handleTableChange = (pagination, filtro, orden) => {
            this.setState({
                page: pagination.current,
            });
            if (orden.columnKey) {
                let order = 'ASC';

                if (orden.order === 'descend') {
                    order = 'DESC';
                }

                this.setState ({
                    order: order, 
                });
                console.log(pagination.current,this.state.formulario, field, order)
                onGetDocumentosBase(pagination.current, this.state.formulario, field, order, listaServiciosActivosF);
            } else {
                console.log(pagination.current, this.state.formulario, field, this.state.order)
                onGetDocumentosBase(pagination.current, this.state.formulario, field, this.state.order, listaServiciosActivosF);
            }
        }

        const exportarExcel = () => {
            onExportarExcel(page, this.state.formulario, field, this.state.order, listaServiciosActivosF);
        }

        const tipoDocumento = ['Políticas Transversales', 'Manuales de Operación', 'Manuales de Procedimientos', 'Información Técnica', 'Planes', 'Contrato'];

        const onChangeServicios = value => {
            this.setState({serviciosTodos: false});
            const todos = value.some(item => item === 'todos');
            if(todos) {
                this.setState({serviciosTodos: true});
                this.formRef.current.setFieldsValue({'servicios':['todos']})
            }
        }

        return (
            <div>
            <Card className="cardCatalogos" title={<div className="headerLista">Base de Conocimiento</div>}>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Form
                            name="formulario"
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                    <Col span={7}>
                                        <Form.Item
                                        name="tipoConocimiento">
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Seleccione el tipo de documento"
                                                //onChange={onTipoDocto}
                                            >
                                                {tipoDocumento.map(item => <Option value={item} key={item}>{item}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                        name="descripcion"
                                        >
                                            <Input className='inputFiltro' placeholder='Ingresa el nombre del documento'/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="servicios"
                                        >
                                            <Select
                                                mode='multiple'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa el servicio a buscar"
                                                loading={fetchingListaServiciosFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={buscarServicios}
                                                onChange={onChangeServicios}
                                                notFoundContent={null}
                                            >
                                                <OptGroup label="Todos los servicios">
                                                    <Option value="todos">Todos</Option>
                                                </OptGroup>
                                                <OptGroup label="Servicios">
                                                    {listaServiciosFiltro.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + '-' + option.nomenclatura + '_' + option.nombre}>{option.idServicio + '-' + option.nomenclatura + '_' + option.nombre}</Option>)}
                                                </OptGroup>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                    </Col>
                                </Row>
                        </Form>
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={onNuevoDocumentoBase} disabled={!permisosUsuario.crearDocumentoBase} className='nuevoUsuario'>Nuevo Documento Base</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Documento Base' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoDocumentoBase} disabled={!permisosUsuario.crearDocumentoBase} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button onClick={exportarExcel} loading={this.props.fetchingExportarExcel} className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } />
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="nomenclatura"
                            columns={columns}
                            dataSource={listaDocumentosBaseBusqueda}
                            loading={fetchingListaDocumentosBaseBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaDocumentosBaseBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                {/* <Row justify='space-around'>
                    <Col span={12}>
                        <Button loading={this.props.fetchingExportarExcel} className='btnCargaArchivos' onClick={exportarExcel}>Exportar a Excel</Button>
                    </Col>
                </Row> */}
                <ModalNuevoDocumentoBase/>
                <ModalEditarDocumentoBase/>
                <ModalVerDocumento />
            </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaDocumentosBaseBusqueda: state.BaseDeConocimientoReducer.listaDocumentosBaseBusqueda,
        fetchingListaDocumentosBaseBusqueda: state.BaseDeConocimientoReducer.fetchingListaDocumentosBaseBusqueda,
        listaDocumentosBaseBusquedaCantidad: state.BaseDeConocimientoReducer.listaDocumentosBaseBusquedaCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        fetchingExportarExcel: state.BaseDeConocimientoReducer.fetchingExportarExcel,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetDocumentosBase: (page, formulario, field, order, listaServiciosActivosF) => {
            dispatch({ type: "LISTA_BASE_CONOCIMIENTO_REQUEST", page, formulario, field, order, listaServiciosActivosF });
        },
        onExportarExcel: (page, formulario, field, orden, listaServiciosActivosF) => {
            dispatch({ type: "EXPORTAR_EXCEL_REQUEST", page, formulario, field, orden, listaServiciosActivosF });
        },
        onModalNuevoDocumentoBase: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_DOCUMENTO_BASE", showModalNuevoDocumentoBase: true, page, busqueda })
        },
        onModalEditarDocumentoBase: (DocumentoBaseSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_DOCUMENTO_BASE", showModalEditarDocumentoBase: true, DocumentoBaseSeleccionado, page, busqueda });
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
        onShowModalVerDocumento: (documentoSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DOCUMENTO', showModalVerDocumento: true, documentoSeleccionado })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseDeConocimiento);