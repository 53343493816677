import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Form, message, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import ModalNuevaPartida from './ModalNuevaPartida';
import ModalEditarPartida from './ModalEditarPartida';
import { loginCall } from "../../../services/LoginServices";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const confirm = Modal.confirm;
class PartidasContainer extends React.Component {
    componentDidMount(){
        this.props.onGetPartidas(1, '');
        this.props.onGetServicios();
    }
    state={
        page: 1,
        busqueda: {
            param: null,
            servicio: null,
        },
        password:'',
    }
    render() {
        const { fetchingListaPartidasBusqueda, listaPartidasBusqueda, onEstadoPartida, 
            onGetPartidas, onModalEditarPartida, onModalNuevaPartida, listaPartidasBusquedaCantidad, 
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }
        const onFinish = busqueda => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetPartidas(1, busqueda);
        }

        const columns = [{
                title: 'Nombre de partida',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '15%',
            },{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                width: '15%',
            },{
                title: 'Nomenclatura',
                dataIndex: 'nomenclatura',
                key: 'nomenclatura',
                width: '10%',
            },{
                title: 'Observaciones',
                dataIndex: 'observaciones',
                key: 'observaciones',
                width: '20%',
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAltaGrid',
                key: 'fechaAltaGrid',
                width: '15%',
            },{
                title: 'Fecha actualización',
                dataIndex: 'fechaActualizacionGrid',
                key: 'fechaActualizacionGrid',
                width: '15%',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.partidaActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '5%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarPartida} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            this.props.onListaContratosPorServicio(key.servicioForm.split(' - ')[0]);
            onModalEditarPartida(key, this.state.page, this.state.busqueda);
        };

        const onPassword = (value) => {
            this.setState({
                password: value.target.value,
            });
        };
        const onCambiarEstado = (idPartida) => {
            console.log(idPartida);
            
            const userName = userInfo[0].username;
            const values = {
                'userName': userName,
                'password': this.state.password,  
            }
            const page = this.state.page;
            const busqueda = this.state.busqueda;
            loginCall(values).then(response => {
                console.log('response');
                console.log(response);
                
                if(response.status === 200){
                    onEstadoPartida(idPartida, page, busqueda)
                }
                else{
                    let errorMensaje = '';
                    if(response.error_message === 'Bad credentials'){
                        errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                    }
                    else{
                        errorMensaje = response.error_message;
                    }
                    message.error(errorMensaje);
                    return;
                }
                this.setState({
                    password: '',
                });
            }).catch(error => {
                message.error('Hubo un error, favor de intentar después');
                return;
            })
        }
        const handleDesactivar = (key) => {
            console.log(key);
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la partida "'+ key.nombre +'"?',
                content: <Input.Password prefix={<LockOutlined/>} placeholder="Ingrese contraseña" onChange={onPassword} />,
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onCambiarEstado(key.idPartida);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetPartidas(pagination.current, this.state.busqueda);
		}
        const onNuevaPartida = () => {
            onModalNuevaPartida(this.state.page, this.state.busqueda);
        }
        const scroll = { "x": 1500 | true, "y": 1000 };
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                    <Form
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                <Col span={10}>
                                    <Form.Item
                                    name="param"
                                    >
                                        <Input disabled={this.state.sinFiltros} className='inputFiltro' placeholder="Ingresa partida a filtrar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="servicio"
                                    >
                                        <Input disabled={this.state.sinFiltros} className='inputFiltro' placeholder="Ingresa servicio a filtrar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button disabled={this.state.sinFiltros} shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                </Col>
                                <Col span={1}>
                                    <Tooltip title='Nuevo Expediente' placement="topRight">
                                        <Button className="iconTer" shape="circle" onClick={onNuevaPartida} disabled={!permisosUsuario.crearPartida} icon={<PlusOutlined />}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevaPartida} disabled={!permisosUsuario.crearPartida} className='nuevoUsuario'>Nueva Partida</Button>
                    </Col> */}
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idPartida" 
                            columns={columns}
                            dataSource={listaPartidasBusqueda}
                            loading={fetchingListaPartidasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaPartidasBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                        />         
                    </Col>
                    <ModalNuevaPartida/>
                    <ModalEditarPartida/>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaPartidasBusqueda: state.InformacionOperativaReducer.listaPartidasBusqueda,
        fetchingListaPartidasBusqueda: state.InformacionOperativaReducer.fetchingListaPartidasBusqueda,
        listaPartidasBusquedaCantidad: state.InformacionOperativaReducer.listaPartidasBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetPartidas: (page, busqueda) => {
	    	dispatch({ type: "LISTA_PARTIDAS_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoPartida: (idPartida, page, busqueda) => {
	    	dispatch({ type: "CAMBIAR_ESTADO_PARTIDA_REQUEST", idPartida, page, busqueda });
        },
        onModalNuevaPartida: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_PARTIDA", showModalNuevaPartida: true, page, busqueda });
        },
        onModalEditarPartida: (partidaSeleccionada, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PARTIDA", showModalEditarPartida: true, partidaSeleccionada, page, busqueda });
        },
        onListaContratosPorServicio: (idServicio) => {
            dispatch({ type: "LISTA_CONTRATOS_POR_SERVICIO_REQUEST", idServicio })
        },
        onGetServicios: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
       },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PartidasContainer);