import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Checkbox, Card} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

import RegistrosReportesSPContainer from './RegistrosReportesSPContainer';

const { Step } = Steps;

const ReporteDinamicoSP = props => {

     const {listaReporteDinamicoSP, formularioReporte,listaServiciosActivosF,onGetReporteDinamico,onLimpiarFormulario, onGetGrupoServicioByIdUsuario, onGetReporteDinamicoAgrupado } = props;

     const [state, setState] = useState({page: 1, currentStep:0});
     const [order, setOrder] = useState({field:'osp.IdOrdenServicioPlanificado', order:'DESC'});
     
     const [checkAll, setCheckAll] = useState (false)
     const [checkArray, setCheckArray] = useState([]);
     const [checkbox, setCheckbox] = useState ({
          folioOrdenC: false, gerenciaC: false, grupoServiciosC: false,
          servicioC: false, partidaC: false, tipoProgramaC: false, nombreRutinaC: false, 
          claveRutinaC: false, ubicacionC: false, estatusOrdenC: false,
          nombreActivoC: false, numeroSerieC: false, codigoInternoC: false, 
          codigoEquipoC: false, codigoActivoC: false, estadoC: false,
     });
    
     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if(permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     useEffect(()=> {
          const arrayCheckbox = checkboxArray();
          setCheckArray(arrayCheckbox);
     },[checkbox]);

    
     const checkboxArray = () => {
          let fieldArray = []
          for (var key in checkbox) {
               if(Object.prototype.hasOwnProperty.call(checkbox, key)) {
                    if(checkbox[key] === true){
                    fieldArray.push(key);
                    }
               }
          }
          return fieldArray;
     }

     const handleSteps = value => {
          console.log('Entra al handle')

          if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

          setOrder({field:'osp.IdOrdenServicioPlanificado', order:'DESC'});
          setState({page:1, currentStep: state.currentStep -1});
          onLimpiarFormulario(formularioReporte);
     }


     const selectHandle = (event,field) => {
          const checked = event.target.checked
          console.log("field",field, checked)
          setCheckAll(false);

          if (checked && field === 'grupoServiciosC') return setCheckbox({...checkbox, servicioC: true, [field]:checked});
          if (checked && field === 'gerenciaC') return setCheckbox({...checkbox, servicioC: true, [field]:checked});
          if (checked && field === 'partidaC') return setCheckbox({...checkbox, servicioC: true, [field]:checked});
          
          if(checked) return setCheckbox({...checkbox, [field]:event.target.checked })
          setCheckbox({...checkbox, [field]:event.target.checked});
     }

     const selectAll = event => {
          setCheckAll(event.target.checked);
          if (event.target.checked) {
               return setCheckbox({
                    folioOrdenC: true, gerenciaC: true, grupoServiciosC: true, servicioC: true, partidaC: true, tipoProgramaC: true, 
                    nombreRutinaC: true, claveRutinaC: true, ubicacionC: true, estatusOrdenC: true, nombreActivoC: true, 
                    numeroSerieC: true, codigoInternoC: true, codigoEquipoC: true, codigoActivoC:true,  estadoC:true
               });
          }
          setCheckbox({
               folioOrdenC: false, gerenciaC: false, grupoServiciosC: false, servicioC: false, partidaC: false, tipoProgramaC: false, 
               nombreRutinaC: false, claveRutinaC: false, ubicacionC: false, estatusOrdenC: false, nombreActivoC: false, 
               numeroSerieC: false, codigoInternoC: false, codigoEquipoC: false, codigoActivoC: false, estadoC:false
          })
     }

     const handleTableChange = (pagination, filter, orden, agrupado) => {
          setState({...state, page: pagination.current});
          console.log(pagination, filter, orden, agrupado)
          if(agrupado === true){
               console.log('Entró al if de agrupado')
               return onGetReporteDinamicoAgrupado(formularioReporte, pagination.current, undefined, checkbox, order)
          }
          if(orden) {
               let sorter = {field: 'osp.IdOrdenServicioPlanificado', order: 'DESC'}
               if(orden.order){
                    const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                    sorter = {field: orden.field, order: orderField, ordenReiniciar: orden.order}
               }
               setOrder(sorter);
               console.log('sorter', sorter, formularioReporte)
               return onGetReporteDinamico(formularioReporte, pagination.current, sorter, checkbox );
          }

          onGetReporteDinamico(formularioReporte, pagination.current, order, checkbox );
     }

     const handleOrden = (field, order, ordenReiniciar) => {
          let agrupado = undefined;
          console.log(`field, orden`, field, order)
          const sorter = {field, order}
          setOrder({field, order, ordenReiniciar});
          onGetReporteDinamicoAgrupado(formularioReporte, state.page, agrupado, checkbox, sorter)
      }

     const checkFields = [
          {field: 'folioOrdenC', title: 'Folio'}, 
          {field: 'gerenciaC', title:'Gerencia'},
          {field: 'grupoServiciosC', title: 'Grupo de Servicios'}, 
          {field: 'servicioC', title: 'Servicio'},
          {field: 'partidaC', title: 'Partida'},
          {field: 'tipoProgramaC', title:'Tipo de Progama (Rutina, Mantenimiento o Prueba)'}, 
          {field: 'nombreRutinaC', title: 'Nombre de Rutina'},
          {field: 'claveRutinaC', title:'Clave'},
          {field: 'ubicacionC', title:'Ubicación'}, 
          {field: 'estatusOrdenC', title: 'Estatus'}, 
          {field: 'nombreActivoC', title: 'Nombre del Activo'}, 
          {field: 'numeroSerieC', title:'Número de Serie'},
          {field: 'codigoInternoC', title: 'Código Interno'}, 
          {field: 'codigoEquipoC', title: 'Código de Equipo'}, 
          {field: 'codigoActivoC', title: 'Código de Activo'}, 
          {field: 'estadoC', title: 'Estado'}, 
     ];

     const SelectSection = () => {
          return (
               <>
                    <Row style={{padding:'1em'}}>
                         <Col span={24}>
                         <Alert message="Selecciona las columnas a incluir en el reporte." type="info" style={{ marginBottom: '16px' }} />
                         </Col>
                    </Row>
                    <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px', padding:'1em'}}>
                         <Col span={11}>
                              <Checkbox defaultChecked={checkAll}  onChange={selectAll} key='todos'><strong>Todas</strong></Checkbox>
                         </Col>
                    </Row>
                    <Row style={{ width: '100%', textAlign: 'left', padding:'1em'}}>
                         {checkFields.map( item => {
                         return (
                              <Col key={item.field} span={8}>
                                   <Checkbox defaultChecked={checkbox[item.field]} onChange={(event)=>selectHandle(event,item.field)} key={item.field}>{item.title}</Checkbox>
                              </Col>
                         )
                         })}
                    </Row>
                    <Row justify='end' style={{padding:'1em'}}>
                         <Col span={6}>
                              <Button className="btnFiltrar" disabled={!checkArray.length>0} onClick={()=> handleSteps('siguiente')} style={{ width: '100%', marginTop: '20px' }}>
                                   Siguiente
                              </Button>
                         </Col>
                    </Row>
               </>
          )
     }
    
     const dataProps = { checkbox, checkArray, state, order }
     const handleProps = {handleTableChange , handleSteps, handleOrden}
     return (
          <Card className="cardCatalogos" title={<div className='headerLista'>Reporte Dinámico</div>}>
               <Steps
                    type='navigation'
                    size='small'
                    current={state.currentStep}
                    style={{marginBottom:'20px', paddingTop:'0px'}}
               >
                    <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
                    <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
                    <Step status={listaReporteDinamicoSP.length>0? 'finish':'process'} title='Exportar Reporte' />
               </Steps>
               {state.currentStep === 0 ? <SelectSection/> : false}
               {state.currentStep === 1 ? <FilterSection handleSteps={handleSteps} checkboxData={checkbox} order={order} /> : false}
               {state.currentStep === 2 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
               {state.currentStep === 0 ? <RegistrosReportesSPContainer/> : false}

          </Card>
     );
}

const mapStateToProps = state => {
     return {
          listaReporteDinamicoSP: state.ServiciosPlanificadosReducer.listaReporteDinamicoSP,
          formularioReporte: state.ServiciosPlanificadosReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onGetReporteDinamico: (formulario, page, order, checkboxData) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_SP_REQUEST', formulario, page, order, checkboxData});
          },
          onLimpiarFormulario: (formularioReporte) => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_RD', formularioReporte, listaReporteDinamicoSP: [], listaReporteDinamicoSPCantidad: null})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado, checkboxData, order) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, page, agrupado, checkboxData, order});
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDinamicoSP);