export const CAMBIAR_CATALOGO_PROVEEDOR = 'CAMBIAR_CATALOGO_PROVEEDOR';

//ACTIONS DOMINIOS
export const LISTA_DOMINIOS_BUSQUEDA_REQUEST = 'LISTA_DOMINIOS_BUSQUEDA_REQUEST';
export const LISTA_DOMINIOS_BUSQUEDA_SUCCESS = 'LISTA_DOMINIOS_BUSQUEDA_SUCCESS';
export const LISTA_DOMINIOS_BUSQUEDA_FAILURE = 'LISTA_DOMINIOS_BUSQUEDA_FAILURE';

export const ESTADO_DOMINIO_REQUEST = 'ESTADO_DOMINIO_REQUEST';
export const ESTADO_DOMINIO_SUCCESS = 'ESTADO_DOMINIO_SUCCESS';
export const ESTADO_DOMINIO_FAILURE = 'ESTADO_DOMINIO_FAILURE';

export const PROVEEDOR_POR_DOMINIO_REQUEST = 'PROVEEDOR_POR_DOMINIO_REQUEST';
export const PROVEEDOR_POR_DOMINIO_SUCCESS = 'PROVEEDOR_POR_DOMINIO_SUCCESS';
export const PROVEEDOR_POR_DOMINIO_FAILURE = 'PROVEEDOR_POR_DOMINIO_FAILURE';

export const SHOW_MODAL_NUEVO_DOMINIO = 'SHOW_MODAL_NUEVO_DOMINIO';
export const SHOW_MODAL_EDITAR_DOMINIO = 'SHOW_MODAL_EDITAR_DOMINIO';

export const NUEVO_DOMINIO_REQUEST = 'NUEVO_DOMINIO_REQUEST';
export const NUEVO_DOMINIO_SUCCESS = 'NUEVO_DOMINIO_SUCCESS';
export const NUEVO_DOMINIO_FAILURE = 'NUEVO_DOMINIO_FAILURE';

export const EDITAR_DOMINIO_REQUEST = 'EDITAR_DOMINIO_REQUEST';
export const EDITAR_DOMINIO_SUCCESS = 'EDITAR_DOMINIO_SUCCESS';
export const EDITAR_DOMINIO_FAILURE = 'EDITAR_DOMINIO_FAILURE';

//ACTIONS EXPEDIENTES
export const LISTA_EXPEDIENTES_REQUEST = 'LISTA_EXPEDIENTES_REQUEST';
export const LISTA_EXPEDIENTES_SUCCESS = 'LISTA_EXPEDIENTES_SUCCESS';
export const LISTA_EXPEDIENTES_FAILURE = 'LISTA_EXPEDIENTES_FAILURE';

export const LISTA_EXPEDIENTES_BUSQUEDA_REQUEST = 'LISTA_EXPEDIENTES_BUSQUEDA_REQUEST';
export const LISTA_EXPEDIENTES_BUSQUEDA_SUCCESS = 'LISTA_EXPEDIENTES_BUSQUEDA_SUCCESS';
export const LISTA_EXPEDIENTES_BUSQUEDA_FAILURE = 'LISTA_EXPEDIENTES_BUSQUEDA_FAILURE';

export const LISTA_EXPEDIENTES_SIN_INFO_REQUEST = 'LISTA_EXPEDIENTES_SIN_INFO_REQUEST';
export const LISTA_EXPEDIENTES_SIN_INFO_SUCCESS = 'LISTA_EXPEDIENTES_SIN_INFO_SUCCESS';
export const LISTA_EXPEDIENTES_SIN_INFO_FAILURE = 'LISTA_EXPEDIENTES_SIN_INFO_FAILURE';

export const ESTADO_EXPEDIENTE_REQUEST = 'ESTADO_EXPEDIENTE_REQUEST';
export const ESTADO_EXPEDIENTE_SUCCESS = 'ESTADO_EXPEDIENTE_SUCCESS';
export const ESTADO_EXPEDIENTE_FAILURE = 'ESTADO_EXPEDIENTE_FAILURE';

export const SHOW_MODAL_NUEVO_EXPEDIENTE = 'SHOW_MODAL_NUEVO_EXPEDIENTE';
export const SHOW_MODAL_EDITAR_EXPEDIENTE = 'SHOW_MODAL_EDITAR_EXPEDIENTE';

export const ARCHIVOS_EXPEDIENTE_REQUEST = 'ARCHIVOS_EXPEDIENTE_REQUEST';
export const ARCHIVOS_EXPEDIENTE_SUCCESS = 'ARCHIVOS_EXPEDIENTE_SUCCESS';
export const ARCHIVOS_EXPEDIENTE_FAILURE = 'ARCHIVOS_EXPEDIENTE_FAILURE';

export const ELIMIAR_ARCHIVO_EXPEDIENTE_REQUEST = 'ELIMIAR_ARCHIVO_EXPEDIENTE_REQUEST';
export const ELIMIAR_ARCHIVO_EXPEDIENTE_SUCCESS = 'ELIMIAR_ARCHIVO_EXPEDIENTE_SUCCESS';
export const ELIMIAR_ARCHIVO_EXPEDIENTE_FAILURE = 'ELIMIAR_ARCHIVO_EXPEDIENTE_FAILURE';

export const NUEVO_EXPEDIENTE_REQUEST = 'NUEVO_EXPEDIENTE_REQUEST';
export const NUEVO_EXPEDIENTE_SUCCESS = 'NUEVO_EXPEDIENTE_SUCCESS';
export const NUEVO_EXPEDIENTE_FAILURE = 'NUEVO_EXPEDIENTE_FAILURE';

export const EDITAR_EXPEDIENTE_REQUEST = 'EDITAR_EXPEDIENTE_REQUEST';
export const EDITAR_EXPEDIENTE_SUCCESS = 'EDITAR_EXPEDIENTE_SUCCESS';
export const EDITAR_EXPEDIENTE_FAILURE = 'EDITAR_EXPEDIENTE_FAILURE';

export const SHOW_MODAL_ARCHIVOS_EXPEDIENTE = 'SHOW_MODAL_ARCHIVOS_EXPEDIENTE';

//ACTIONS CONTRATOS
export const LISTA_CONTRATOS_REQUEST = 'LISTA_CONTRATOS_REQUEST';
export const LISTA_CONTRATOS_SUCCESS = 'LISTA_CONTRATOS_SUCCESS';
export const LISTA_CONTRATOS_FAILURE = 'LISTA_CONTRATOS_FAILURE';

export const LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_REQUEST = 'LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_REQUEST';
export const LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_SUCCESS = 'LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_SUCCESS';
export const LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_FAILURE = 'LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_FAILURE';

export const LISTA_CONTRATOS_BUSQUEDA_REQUEST = 'LISTA_CONTRATOS_BUSQUEDA_REQUEST';
export const LISTA_CONTRATOS_BUSQUEDA_SUCCESS = 'LISTA_CONTRATOS_BUSQUEDA_SUCCESS';
export const LISTA_CONTRATOS_BUSQUEDA_FAILURE = 'LISTA_CONTRATOS_BUSQUEDA_FAILURE';

export const LISTA_CONTRATOS_SIN_INFO_REQUEST = 'LISTA_CONTRATOS_SIN_INFO_REQUEST';
export const LISTA_CONTRATOS_SIN_INFO_SUCCESS = 'LISTA_CONTRATOS_SIN_INFO_SUCCESS';
export const LISTA_CONTRATOS_SIN_INFO_FAILURE = 'LISTA_CONTRATOS_SIN_INFO_FAILURE';

export const LISTA_CONTRATOS_POR_SERVICIO_REQUEST = 'LISTA_CONTRATOS_POR_SERVICIO_REQUEST';
export const LISTA_CONTRATOS_POR_SERVICIO_SUCCESS = 'LISTA_CONTRATOS_POR_SERVICIO_SUCCESS';
export const LISTA_CONTRATOS_POR_SERVICIO_FAILURE = 'LISTA_CONTRATOS_POR_SERVICIO_FAILURE';

export const ESTADO_CONTRATO_REQUEST = 'ESTADO_CONTRATO_REQUEST';
export const ESTADO_CONTRATO_SUCCESS = 'ESTADO_CONTRATO_SUCCESS';
export const ESTADO_CONTRATO_FAILURE = 'ESTADO_CONTRATO_FAILURE';

export const SHOW_MODAL_NUEVO_CONTRATO = 'SHOW_MODAL_NUEVO_CONTRATO';
export const SHOW_MODAL_EDITAR_CONTRATO = 'SHOW_MODAL_EDITAR_CONTRATO';

export const NUEVO_CONTRATO_REQUEST = 'NUEVO_CONTRATO_REQUEST';
export const NUEVO_CONTRATO_SUCCESS = 'NUEVO_CONTRATO_SUCCESS';
export const NUEVO_CONTRATO_FAILURE = 'NUEVO_CONTRATO_FAILURE';

export const EDITAR_CONTRATO_REQUEST = 'EDITAR_CONTRATO_REQUEST';
export const EDITAR_CONTRATO_SUCCESS = 'EDITAR_CONTRATO_SUCCESS';
export const EDITAR_CONTRATO_FAILURE = 'EDITAR_CONTRATO_FAILURE';

export const SHOW_MODAL_ARCHIVOS_CONTRATO = 'SHOW_MODAL_ARCHIVOS_CONTRATO';

//ACTIONS PROVEEDORES
export const GET_PROVEEDORES_REQUEST = 'GET_PROVEEDORES_REQUEST';
export const GET_PROVEEDORES_SUCCESS = 'GET_PROVEEDORES_SUCCESS';
export const GET_PROVEEDORES_FAILURE = 'GET_PROVEEDORES_FAILURE';

export const LISTA_PROVEEDORES_PARAM_REQUEST = 'LISTA_PROVEEDORES_PARAM_REQUEST';
export const LISTA_PROVEEDORES_PARAM_SUCCESS = 'LISTA_PROVEEDORES_PARAM_SUCCESS';
export const LISTA_PROVEEDORES_PARAM_FAILURE = 'LISTA_PROVEEDORES_PARAM_FAILURE';

export const LISTA_PROVEEDORES_BUSQUEDA_REQUEST = 'LISTA_PROVEEDORES_BUSQUEDA_REQUEST';
export const LISTA_PROVEEDORES_BUSQUEDA_SUCCESS = 'LISTA_PROVEEDORES_BUSQUEDA_SUCCESS';
export const LISTA_PROVEEDORES_BUSQUEDA_FAILURE = 'LISTA_PROVEEDORES_BUSQUEDA_FAILURE';

export const ESTADO_PROVEEDOR_REQUEST = 'ESTADO_PROVEEDOR_REQUEST';
export const ESTADO_PROVEEDOR_SUCCESS = 'ESTADO_PROVEEDOR_SUCCESS';
export const ESTADO_PROVEEDOR_FAILURE = 'ESTADO_PROVEEDOR_FAILURE';

export const SHOW_MODAL_NUEVO_PROVEEDOR = 'SHOW_MODAL_NUEVO_PROVEEDOR';
export const SHOW_MODAL_EDITAR_PROVEEDOR = 'SHOW_MODAL_EDITAR_PROVEEDOR';

export const NUEVO_PROVEEDOR_REQUEST = 'NUEVO_PROVEEDOR_REQUEST';
export const NUEVO_PROVEEDOR_SUCCESS = 'NUEVO_PROVEEDOR_SUCCESS';
export const NUEVO_PROVEEDOR_FAILURE = 'NUEVO_PROVEEDOR_FAILURE';

export const EDITAR_PROVEEDOR_REQUEST = 'EDITAR_PROVEEDOR_REQUEST';
export const EDITAR_PROVEEDOR_SUCCESS = 'EDITAR_PROVEEDOR_SUCCESS';
export const EDITAR_PROVEEDOR_FAILURE = 'EDITAR_PROVEEDOR_FAILURE';

export const LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST = 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST';
export const LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS = 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS';
export const LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_FAILURE = 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_FAILURE';

export const LISTA_PARTIDAS_BY_SERVICIOS_REQUEST = 'LISTA_PARTIDAS_BY_SERVICIOS_REQUEST';
export const LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS = 'LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS';
export const LISTA_PARTIDAS_BY_SERVICIOS_FAILURE = 'LISTA_PARTIDAS_BY_SERVICIOS_FAILURE';

export const LISTA_SERVICIOS_PROVEEDORES_REQUEST = 'LISTA_SERVICIOS_PROVEEDORES_REQUEST';
export const LISTA_SERVICIOS_PROVEEDORES_SUCCESS = 'LISTA_SERVICIOS_PROVEEDORES_SUCCESS';
export const LISTA_SERVICIOS_PROVEEDORES_FAILURE = 'LISTA_SERVICIOS_PROVEEDORES_FAILURE';

export const LISTA_AREAS_PROVEEDORES_REQUEST = 'LISTA_AREAS_PROVEEDORES_REQUEST';
export const LISTA_AREAS_PROVEEDORES_SUCCESS = 'LISTA_AREAS_PROVEEDORES_SUCCESS';
export const LISTA_AREAS_PROVEEDORES_FAILURE = 'LISTA_AREAS_PROVEEDORES_FAILURE';

//CATÁLOGO DE PARTIDAS
export const LISTA_PARTIDAS_BUSQUEDA_REQUEST = 'LISTA_PARTIDAS_BUSQUEDA_REQUEST';
export const LISTA_PARTIDAS_BUSQUEDA_SUCCESS = 'LISTA_PARTIDAS_BUSQUEDA_SUCCESS';
export const LISTA_PARTIDAS_BUSQUEDA_FAILURE = 'LISTA_PARTIDAS_BUSQUEDA_FAILURE';

export const CAMBIAR_ESTADO_PARTIDA_REQUEST = 'CAMBIAR_ESTADO_PARTIDA_REQUEST';
export const CAMBIAR_ESTADO_PARTIDA_SUCCESS = 'CAMBIAR_ESTADO_PARTIDA_SUCCESS';
export const CAMBIAR_ESTADO_PARTIDA_FAILURE = 'CAMBIAR_ESTADO_PARTIDA_FAILURE';

export const SHOW_MODAL_NUEVA_PARTIDA = 'SHOW_MODAL_NUEVA_PARTIDA';

export const NUEVA_PARTIDA_REQUEST = 'NUEVA_PARTIDA_REQUEST';
export const NUEVA_PARTIDA_SUCCESS = 'NUEVA_PARTIDA_SUCCESS';
export const NUEVA_PARTIDA_FAILURE = 'NUEVA_PARTIDA_FAILURE';

export const SHOW_MODAL_EDITAR_PARTIDA = 'SHOW_MODAL_EDITAR_PARTIDA';

export const EDITAR_PARTIDA_REQUEST = 'EDITAR_PARTIDA_REQUEST';
export const EDITAR_PARTIDA_SUCCESS = 'EDITAR_PARTIDA_SUCCESS';
export const EDITAR_PARTIDA_FAILURE = 'EDITAR_PARTIDA_FAILURE';
