import React, { useEffect } from 'react';
import {Row, Col, Modal, Spin,Form, Select, Tooltip, DatePicker, Button, Input, InputNumber} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;
const { Option } = Select;

const ModalAgregarProductivdad = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const showModalAgregarProductividadVariable = useSelector((state) =>state.ServiciosVariablesReducer.showModalAgregarProductividadVariable);
    const registroSeleccionado = useSelector((state) =>state.ServiciosVariablesReducer.registroSeleccionado)
    const fetchingListaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro)
    const listaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.listaUbicacionesFiltro)
    const listSubvariablesByVariable = useSelector((state) => state.ServiciosVariablesReducer.listSubvariablesByVariable);
    const formulario = useSelector((state) => state.ServiciosVariablesReducer.formulario);

    useEffect(() => {
        if(showModalAgregarProductividadVariable==false){
            form.resetFields();
        }
        dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: 'adm'})
    }, [form, showModalAgregarProductividadVariable]);    
    
    //funciones
    const onFinish = formularioModal => {
        console.log('formularioModal, formulario', formularioModal, formulario)
        for (const k in formularioModal.variablesServiciosVariablesByIdVariable) {
            formularioModal.variablesServiciosVariablesByIdVariable[k].idVariableServicioVariable = registroSeleccionado.idVariableServicioVariable;
            formularioModal.variablesServiciosVariablesByIdVariable[k].fechaProductividad = formularioModal.fechaProductividad.format('YYYY-MM-DD');
            formularioModal.variablesServiciosVariablesByIdVariable[k].activo = true
        }
        let formularioNuevo = formularioModal;
        if(formulario){
            formularioNuevo = formulario;
            for(let i in formularioModal.variablesServiciosVariablesByIdVariable){
                formularioNuevo.variablesServiciosVariablesByIdVariable.push(formularioModal.variablesServiciosVariablesByIdVariable[i]);
            }
        }
        
        console.log('formularioNuevo', formularioNuevo)
        dispatch({ type: "GUARDAR_PRODUCTIVIDAD_VARIABLE", formulario: formularioNuevo, productividadAgregada: true 
            // idServicioVariable: registroSeleccionado.idServicioVariable, idVariableServicioVariable:registroSeleccionado.idVariableServicioVariable  
        })
        let listSubvariablesByVariableLocal = listSubvariablesByVariable;
        dispatch({ type: 'GET_SUBVARIABLE_BY_IDS_SUCCESS', 'listSubvariablesByVariable': []})
        for(let i in listSubvariablesByVariable){
            if(listSubvariablesByVariable[i].idVariableServicioVariable === registroSeleccionado.idVariableServicioVariable){
                listSubvariablesByVariableLocal[i].productividadAgregada = true;
                listSubvariablesByVariableLocal[i].cantidad = formularioModal.variablesServiciosVariablesByIdVariable[formularioModal.variablesServiciosVariablesByIdVariable.length - 1].cantidad;
                listSubvariablesByVariableLocal[i].idUbicacion = formularioModal.variablesServiciosVariablesByIdVariable[formularioModal.variablesServiciosVariablesByIdVariable.length - 1].idUbicacion;
                listSubvariablesByVariableLocal[i].fechaProductividad = formularioModal.variablesServiciosVariablesByIdVariable[formularioModal.variablesServiciosVariablesByIdVariable.length - 1].fechaProductividad.split('-').reverse().join('-');;
            }
        }
        dispatch({ type: 'GET_SUBVARIABLE_BY_IDS_SUCCESS', 'listSubvariablesByVariable': listSubvariablesByVariableLocal })
        dispatch({ type: 'SHOW_MODAL_AGREGAR_PRODUCTIVDAD_VARIABLE', showModalAgregarProductividadVariable: false })

    }; 
        
    const handleCancel = () => {
        dispatch({ type: "SHOW_MODAL_AGREGAR_PRODUCTIVDAD_VARIABLE", showModalAgregarProductividadVariable: false})
        form.resetFields();
    };

    const handleSearch = (param) =>{
        if(param){
            dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: param})
        }
    }

    return ( 
        <Modal
            visible={showModalAgregarProductividadVariable}
            title='Agregar Productividad de Variable'
            onCancel={handleCancel}
            footer={false}
            width='85%'
        >
            
            <Form 
                name='formulario'
                onFinish={onFinish}
                preserve={false}
                layout="vertical"
                form={form} 
                initialValues={{ variablesServiciosVariablesByIdVariable: [""] }}
            >
                <Row type='flex'>
                    <Col span={8} offset={16}>
                        <Form.Item
                            label="Fecha de Productividad"
                            name="fechaProductividad"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                        <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' 
                            allowClear={false}
                            showToday={false}
                            placeholder={["Selecciona fecha"]}
                        />
                        </Form.Item>
                    </Col>
                </Row>
                <Row type='flex'>
                    <Form.List name="variablesServiciosVariablesByIdVariable">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map((field) => (
                                <>
                                <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                    prevValues.nombre !== curValues.nombre || prevValues.idUnidadMedida !== curValues.idUnidadMedida
                                }
                                >
                                {() => (
                                    <Col span={3}>
                                        <Form.Item
                                        {...field}
                                        label="Cantidad"
                                        name={[field.name, 'cantidad']}
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Favor de llenar el campo',
                                            },
                                        ]}
                                        >
                                            <InputNumber min={1} />
                                        </Form.Item>
                                    </Col>
                                )}
                                </Form.Item>
                                <Col span={9} offset={1}>
                                    <Form.Item
                                    label="Ubicación"
                                    name={[field.name, 'idUbicacion']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una ubicación"
                                            showSearch
                                            loading={fetchingListaUbicacionesFiltro}
                                            filterOption={false}
                                            onSearch={handleSearch}
                                        >
                                            {listaUbicacionesFiltro.map(option => <Option key={option.idUbicacion}> {option.todoUbicacion}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={9} offset={1}>
                                    <Form.Item
                                        label="Asignado a"
                                        name={[field.name, 'asignadoA']}
                                    >
                                        <TextArea rows={4} placeholder="Agrega las asignaciones"  />
                                    </Form.Item>
                                </Col>
                                <Tooltip placement="topLeft" title="Eliminar"><MinusCircleOutlined onClick={() => remove(field.name)} /></Tooltip>
                            </>
                            ))}
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Agregar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    </Form.List>
                    <Col span={24}>
                        <Row justify='space-around'>
                            <Col span={8}>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
export default ModalAgregarProductivdad;