import React, { Component } from 'react';
import { Modal, Row, Col, Table, Typography, Tag, Badge, Select, Tooltip, Input, DatePicker } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const {Text} = Typography;
const {Option} = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

class ModalHistiricoResponsables extends Component {
     state={  page: 1, busqueda: { nombreServicio: null, nombreResponsable: null, fechaInicialSuplenciaIn: null, fechaInicialSuplenciaFn: null, fechaFinalSuplenciaIn: null, fechaFinalSuplenciaFn: null, fechaInicioIn: null, fechaInicioFn: null, fechaFinIn: null, fechaFinFn: null }, orden:{ field: 'hrse.FechaMovimiento', order: 'DESC' }, }
     render() {
          const handleCancel = () => {
               this.props.onCloseModal();
               this.setState({  page: 1, busqueda: { nombreServicio: null, nombreResponsable: null, fechaInicialSuplenciaIn: null, fechaInicialSuplenciaFn: null, fechaFinalSuplenciaIn: null, fechaFinalSuplenciaFn: null, fechaInicioIn: null, fechaInicioFn: null, fechaFinIn: null, fechaFinFn: null }, orden:{ field: 'hrse.FechaMovimiento', order: 'DESC' }, });
          };

          const handleTableChange = (pagination, filtro, orden)=>{
               this.setState({  page: pagination.current, });
               
               if(orden){
                    let field = orden.columnKey;
                    let order = 'DESC';
                
                    if(orden.order === 'ascend'){
                        order = 'ASC';                
                    }

                    if(orden.columnKey === 'servicioTabla'){
                         field = 'se.Nombre';
                    } else if(orden.columnKey === 'usuarioResponsableTabla'){
                         field = 'ue.Nombre';
                    } else if(orden.columnKey === 'fechaAlta'){
                         field = 'hrse.FechaInicio';
                    } else if(orden.columnKey === 'fechaActu'){
                         field = 'hrse.FechaFin';
                    } else if(orden.columnKey === 'fechaInicioSuplenciaTabla'){
                         field = 'hrse.FechaInicialSuplencia';
                    } else if(orden.columnKey === 'fechaFinSuplenciaTabla'){
                         field = 'hrse.FechaFinalSuplencia';
                    } else if(orden.columnKey === 'fechaMovimientoTabla'){
                         field = 'hrse.FechaMovimiento';
                    }
                    
                    const ordenState = { field, order }
                    this.props.onGetHistorico(pagination.current, this.state.busqueda, ordenState);
                    this.setState({ orden: ordenState });
               }
               else{
                    const ordenState = { field: 'DESC', order: 'hrse.FechaInicio' }
                    this.setState({ orden: ordenState });
                    this.props.onGetHistorico(pagination.current, this.state.busqueda, ordenState);
               }
          };

          const columns = [
               { dataIndex: 'activo',  key: 'activo', align:'center', 
                    render: (text, record) => (
                         // text === true ?  <Tag color="#ff8c62" style={{borderRadius:'1em'}}> ACTIVO </Tag> : <Tag color="#dddddd" style={{borderRadius:'1em'}}> INACTIVO </Tag> 
                         text === true ?  <Tooltip placement="left" title='Activo'> <Badge status="success"/> </Tooltip> : <Tooltip placement="left" title='Inactivo'> <Badge status="default"/>  </Tooltip>
               ),},
               { title: 'Tipo movimiento', dataIndex: 'tipoMovimiento',  key: 'tipoMovimiento',} ,
               { title: 'Fecha movimiento', dataIndex: 'fechaMovimientoTabla',  key: 'fechaMovimientoTabla', sorter: true,},
               { title: 'Servicio', dataIndex: 'servicioTabla',  key: 'servicioTabla', sorter: true,} ,
               { title: 'Partida', dataIndex: 'partidaTabla',  key: 'partidaTabla',
               render: (text, record) => (
                    text === 'Sin partida' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
               ), },
               { title: 'Tipo de responsable', dataIndex: 'tipoRespo',  key: 'tipoRespo'},
               
               { title: 'Responsable', dataIndex: 'usuarioResponsableTabla',  key: 'usuarioResponsableTabla', sorter: true,},
               // { title: 'Fecha alta', dataIndex: 'fechaAlta',  key: 'fechaAlta', sorter: true,},
               // { title: 'Fecha actualización', dataIndex: 'fechaActu',  key: 'fechaActu', sorter: true,},
               
               { title: 'Suplente', dataIndex: 'usuarioSuplenteTabla',  key: 'usuarioSuplenteTabla',
                    render: (text, record) => (
                         text === 'Sin suplente' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
                    ),   
               },
               { title: 'Fecha inicio', dataIndex: 'fechaInicioSuplenciaTabla',  key: 'fechaInicioSuplenciaTabla', sorter: true,
                    render: (text, record) => (
                         text === 'N/A' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
                    ),   
               },
               { title: 'Fecha fin', dataIndex: 'fechaFinSuplenciaTabla',  key: 'fechaFinSuplenciaTabla', sorter: true,
                    render: (text, record) => (
                         text === 'N/A' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
                    ),   
               },
          ];

          const serviciosSeleccionados = (e) => {
               if(e){
                    let busqueda = this.state.busqueda;
                    let servicio = e.split(' (')[1]
                    busqueda.nombreServicio = servicio.split(')')[0];
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({busqueda});
               } else {
                    let busqueda = this.state.busqueda;
                    busqueda.nombreServicio = null;
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({busqueda});
               }
          };

          const onSearch = (responsable) => {
               if(responsable){
                    let busqueda = this.state.busqueda;
                    busqueda.nombreResponsable = responsable;
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               } else {
                    let busqueda = this.state.busqueda;
                    busqueda.nombreResponsable = null;
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               }
          };

          const  disabledDate = (fecha) => {
               if( fecha && moment(fecha.format('YYYY-MM-DD')) < this.props.listaInfoProyecto.fechaInicioOperacion) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < this.props.listaInfoProyecto.fechaInicioOperacion;
               } else {
                   if(this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                    return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((this.props.listaInfoProyecto.vigencia +  parseInt(this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' + this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + this.props.listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
                    }
               }
          };

          const fechasInicio = (o, s) => {
               if(o){
                    let busqueda = this.state.busqueda;
                    busqueda.fechaInicialSuplenciaIn = s[0].split('-').reverse().join('-');
                    busqueda.fechaInicialSuplenciaFn = s[1].split('-').reverse().join('-');
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               } else {
                    let busqueda = this.state.busqueda;
                    busqueda.fechaInicialSuplenciaIn = null;
                    busqueda.fechaInicialSuplenciaFn = null;
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               }
          };

          const fechasFinal = (o, s) => {
               if(o){
                    let busqueda = this.state.busqueda;
                    busqueda.fechaFinalSuplenciaIn = s[0].split('-').reverse().join('-');
                    busqueda.fechaFinalSuplenciaFn = s[1].split('-').reverse().join('-');
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               } else {
                    let busqueda = this.state.busqueda;
                    busqueda.fechaFinalSuplenciaIn = null;
                    busqueda.fechaFinalSuplenciaFn = null;
                    this.props.onGetHistorico(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               }
          };

          return (
               <Modal visible={this.props.showModalHistoricoResponsable} title='Histórico de Responsables' onCancel={handleCancel} footer={false} width={'70%'}>
                    <Row justify='space-around' gutter={[8,8]}>
                         <Col span={12}>
                              <Text type='secondary'>Servicio</Text>
                              <Select
                                   allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona el servicio a filtrar"
                                   loading={this.props.fetchingListaServiciosActivosF}
                                   showSearch
                                   onChange={serviciosSeleccionados}
                              >
                                        {this.props.listaServiciosActivosF.map(option => 
                                            <Option key={option.nomenclatura + ': ' + option.nombre + ' (' + option.nombreC + ')'}>
                                                <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                    {option.nomenclatura + ': ' + option.nombre} <Text italic type='secondary'> {' (' + option.nombreC + ')'} </Text>
                                                </Tooltip>
                                            </Option>
                                        )}
                              </Select>
                         </Col>
                         <Col span={12}>
                              <Text type='secondary'>Responsable</Text>
                              <Search allowClear className='buscarModulos' placeholder='Escribe el responsable a filtrar' onSearch={value=> onSearch(value)}  />
                         </Col>
                         <Col span={12}>
                              <Text type='secondary'>Rango fecha inicio de suplencia</Text>
                              <RangePicker
                                   className='input'
                                   style={{width: '100%'}} 
                                   format='DD-MM-YYYY'
                                   disabledDate={disabledDate}
                                   placeholder={["Fecha inicio","Fecha fin"]}
                                   onChange={fechasInicio}
                              />
                         </Col>
                         <Col span={12}>
                              <Text type='secondary'>Rango fecha final de suplencia</Text>
                              <RangePicker
                                   className='input'
                                   style={{width: '100%'}} 
                                   format='DD-MM-YYYY'
                                   disabledDate={disabledDate}
                                   placeholder={["Fecha inicio","Fecha fin"]}
                                   onChange={fechasFinal}
                              />
                         </Col>
                         <Col span={24} style={{paddingTop:'1em'}}>
                              <Table 
                                   size="small" 
                                   rowKey='idsServicioPartidaResponsable'
                                   columns={columns}
                                   dataSource={this.props.listaBusquedaHistoricoResponsable}
                                   loading={this.props.fetchingListaBusquedaHistoricoResponsable}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: this.props.listaBusquedaHistoricoResponsableCantidad, simple: true, current: this.state.page }}
                                   scroll={{x:2200}}
                              />         
                         </Col>
                    </Row>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          showModalHistoricoResponsable: state.ConfiguracionYSeguridadReducer.showModalHistoricoResponsable,
          listaBusquedaHistoricoResponsable: state.ConfiguracionYSeguridadReducer.listaBusquedaHistoricoResponsable,
          listaBusquedaHistoricoResponsableCantidad: state.ConfiguracionYSeguridadReducer.listaBusquedaHistoricoResponsableCantidad,
          fetchingListaBusquedaHistoricoResponsable: state.ConfiguracionYSeguridadReducer.fetchingListaBusquedaHistoricoResponsable,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModal: () => {
               dispatch({ type: 'MODAL_HISTORICO_RESPONSABLES', showModalHistoricoResponsable: false })
          },
          onGetHistorico: (page, busqueda, orden ) => {
               dispatch({ type: 'LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_REQUEST', page, busqueda, orden  });
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalHistiricoResponsables);