import React from 'react';
import { Row, Col, Switch, Modal, Table, Button, Input, Form, Tooltip, message, Select } from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined, FileExcelFilled } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalNuevaFalla from './ModalNuevaFalla';
import ModalEditarFalla from './ModalEditarFalla';

const confirm = Modal.confirm;
const { Option } = Select;
class FallasContainer extends React.Component {
    componentDidMount(){
        this.props.onListaTiposFallasActivas();
        this.props.onGetListaFallas(1, this.state.filtros, this.state.orden);
    }
    componentWillUnmount(){
        this.props.onListaServiciosFiltroLimpiar();
    }
    formRef = React.createRef();
    state={
        page: 1,
        filtros: {
            param: null,
            servicio: null,
            estandar: null,
            activo: true,
            visible: true,
        },
        orden: {
            field: 'nombre',
            order: 'ASC',
        }
    }
    render() {
        const { fetchingListaFallasBusqueda, listaFallasBusqueda, onEstadoFalla, fetchingExportarFallasExcel,
            onGetListaFallas, onModalEditarFalla, onModalNuevaFalla, listaFallasBusquedaCantidad, onExportarExcel,
            onListaServiciosFiltro, onListaServiciosFiltroLimpiar, fetchingListaServiciosFiltro, listaServiciosFiltro,
            onListaEstandarFiltro, onListaEstandarFiltroLimpiar, fetchingListaEstandaresFiltro, listaEstandaresFiltroActivos, onObtenerPartidasByidServicio, listaPartidasByServicio4Fallas,
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = (formulario) => {
            this.setState({
                filtros: formulario,
                page: 1,
            })
            console.log(formulario);
            onGetListaFallas(1, formulario, this.state.orden);
        }

        const columns = [{
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                sorter: true,
            },{
                title: 'Tipo de falla',
                dataIndex: 'tipoFalla',
                key: 'tipoFalla',
                sorter: true,
            },{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                sorter: true,
            },{
                title: 'Conversión',
                dataIndex: 'conversion',
                key: 'conversion',
                align: 'center',
                sorter: true,
                render: (text, record) => (
                    <Switch checked={text} disabled={true}/>
                ),
            },{
                title: 'Visible',
                dataIndex: 'visible',
                key: 'visible',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={true}/>
                ),
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.fallaActivoInactivo} onChange={() => onCambiarEstado(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarFalla} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            }
        ];

        const onEditar = (key) => {
            console.log('fallaSeleccionada');
            console.log(key);
            key.servicioEdit = key.idServicio + ' - ' + key.nomenclaturaServicio + ' (' + key.servicio + ')';
            key.estandarDesempenoEdit = key.idEstandarDesempeno + ' - ' + key.referenciaEstandar + ' (' + key.descripcionEstandar + ')';
            key.tipoFallaEdit = key.claveTipoFalla + ' - ' + key.nombreTipoFalla + ' (' + key.tipoTipoFalla + ')';

            key.partidasEdit = [];
            if(key.partidasByIdFalla){
                for(let i in key.partidasByIdFalla){
                    key.partidasEdit.push(key.partidasByIdFalla[i].idPartida + ' - ' + key.partidasByIdFalla[i].nomenclatura  + ' (' + key.partidasByIdFalla[i].nombre + ')');
                }
            }

            onObtenerPartidasByidServicio(key.idServicio);
            
            const busqueda = {
                filtros: this.state.filtros,
                orden: this.state.orden,
            }
            onModalEditarFalla(key, this.state.page, busqueda, key.tipoTipoFalla, key.idServicio, listaPartidasByServicio4Fallas)
        }

        const onCambiarEstado = (key) => {
            console.log('rutina seleccionado');
            console.log(key);

            const page = this.state.page;
            const filtros = this.state.filtros;
            const orden = this.state.orden;

            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del tipo de falla "'+ key.nombre + '"?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoFalla(key.idFalla, page, filtros, orden);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
            
        }
        const handleTableChange = (pagination, filtro, orden)=>{
            console.log(orden);
            console.log('orden');
            this.setState({
                page: pagination.current,
            })
            if(orden){
                let field = orden.columnKey;
                let order = 'ASC';
            
                if(orden.order === 'descend'){
                    order = 'DESC';
                }

                if(orden.columnKey === 'tipoFalla'){
                    field = 'tfe.Descripcion';
                }else if(orden.columnKey === 'servicio'){
                    field = 'ser.Nombre';
                }
                const ordenState = {
                    field,
                    order,
                }
                onGetListaFallas(pagination.current, this.state.filtros, ordenState);
                this.setState({
                    orden: ordenState,
                });
                
            }
            else{
                onGetListaFallas(pagination.current, this.state.filtros, this.state.orden);
            }
        }
        
        const onNuevaFalla = () => {
            const busqueda = {
                filtros: this.state.filtros,
                orden: this.state.orden,
            }
            onModalNuevaFalla(this.state.page, busqueda);
        }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }
        const buscarEstandar = value => {
            if (value.length >= 3) {
                console.log(value);
                onListaEstandarFiltro(value)
            } else {
                onListaEstandarFiltroLimpiar();
            }
        }

        const exportarExcel = () => {
            onExportarExcel(this.state.page, this.state.filtros, this.state.orden);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                        <Form
                        name="formulario"
                        onFinish={onFinish}
                        initialValues={this.state.filtros}
                        ref={this.formRef}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                <Col span={5}>
                                    <Tooltip title='Ingresa servicio a buscar'>
                                        <Form.Item
                                            name="servicios"
                                        >
                                            <Select
                                                mode='multiple'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa el servicio a buscar"
                                                loading={fetchingListaServiciosFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={buscarServicios}
                                                notFoundContent={null}
                                            >
                                                {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nomenclatura + '_' + option.nombre}>{option.idServicio + '-' + option.nomenclatura + '_' + option.nombre}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={5}>
                                    <Tooltip title='Ingresa tipo de falla/falla a buscar' >
                                        <Form.Item
                                            name="param"
                                        >
                                            <Input className='inputFiltro' placeholder="Ingresa tipo de falla/falla a buscar"/>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={5}>
                                    <Tooltip title='Ingresa descripción del estándar de desempeño a buscar'>
                                        <Form.Item
                                            name="estandares"
                                        >
                                            <Select
                                                mode='multiple'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa el estándar a buscar"
                                                loading={fetchingListaEstandaresFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={buscarEstandar}
                                                notFoundContent={null}
                                            >
                                                {listaEstandaresFiltroActivos.map(option => <Option key={option.idEstandarDesempeno + '-' + option.referencia + ' _ ' + option.descripcion}>
                                                <Tooltip placement="topLeft" title={option.idEstandarDesempeno + '-' + option.referencia + ' _ ' + option.descripcion}>
                                                    {option.idEstandarDesempeno + '-' + option.referencia + ' _ ' + option.descripcion}
                                                </Tooltip>
                                                    </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={2}>
                                    <Form.Item
                                        name="activo"
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item
                                        name="visible"
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Visible" unCheckedChildren="No visible" />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                </Col>
                                <Col span={1}>
                                    <Tooltip title='Nueva Falla' placement="topRight">
                                        <Button className="iconTer" shape="circle" onClick={onNuevaFalla} disabled={!permisosUsuario.crearFalla} icon={<PlusOutlined />}/>
                                    </Tooltip>
                                </Col>

                                <Col span={1}>
                                    <Tooltip title='Exportar archivo .xls' placement="topRight">
                                        <Button loading={fetchingExportarFallasExcel} disabled={!permisosUsuario.exportarExcelInformaciónDeFallas || !listaFallasBusqueda[0]} className="iconSecundary" shape='circle' onClick={exportarExcel} icon={<FileExcelFilled /> } />
                                    </Tooltip>
                                </Col>
                            </Row>
                            {/* <Row justify='end'>
                                <Col span={6}>
                                    <Button onClick={onNuevaFalla} disabled={!permisosUsuario.crearFalla} className='nuevoUsuario'>Nueva Falla</Button>
                                </Col>
                            </Row> */}
                        </Form>
                    </Col>
                    
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idFalla" 
                            columns={columns}
                            dataSource={listaFallasBusqueda}
                            loading={fetchingListaFallasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaFallasBusquedaCantidad, simple: true, current: this.state.page}}
                        /> 
                    </Col>
                    <ModalNuevaFalla/>
                    <ModalEditarFalla/>
                </Row>
                {/* <Row justify='space-around'>
                    <Col span={12}>
                        <Button loading={fetchingExportarFallasExcel} disabled={!permisosUsuario.exportarExcelInformaciónDeFallas || !listaFallasBusqueda[0]} className='btnCargaArchivos' onClick={exportarExcel}>Exportar a Excel</Button>
                    </Col>
                </Row> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaFallasBusqueda: state.SolicitudDeServiciosReducer.listaFallasBusqueda,
        fetchingListaFallasBusqueda: state.SolicitudDeServiciosReducer.fetchingListaFallasBusqueda,
        listaFallasBusquedaCantidad: state.SolicitudDeServiciosReducer.listaFallasBusquedaCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingListaEstandaresFiltro: state.MecanismosDePagosReducer.fetchingListaEstandaresFiltro,
        listaEstandaresFiltroActivos: state.MecanismosDePagosReducer.listaEstandaresFiltroActivos,
        fetchingExportarFallasExcel: state.SolicitudDeServiciosReducer.fetchingExportarFallasExcel,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetListaFallas: (page, filtros, orden) => {
	    	dispatch({ type: "LISTA_FALLAS_BUSQUEDA_REQUEST", page, filtros, orden });
        },
        onExportarExcel: (page, filtros, orden) => {
            dispatch({ type: "EXPORTAR_FALLAS_EXCEL_REQUEST", page, filtros, orden });
        },
        onEstadoFalla: (idFalla, page, filtros, orden) => {
	    	dispatch({ type: "ESTADO_FALLA_REQUEST", idFalla, page, filtros, orden });
        },
        onModalNuevaFalla: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_FALLA", showModalNuevaFalla: true, page, busqueda });
        },
        onModalEditarFalla: (fallaSeleccionada, page, busqueda, tipo, idServicio, listaPartidasByServicio4Fallas) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_FALLA", showModalEditarFalla: true, fallaSeleccionada, page, busqueda, tipo, idServicio, listaPartidasByServicio4Fallas });
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onListaEstandarFiltro: (param) => {
            dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_REQUEST", param})
        },
        onListaEstandarFiltroLimpiar: () => {
            dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_SUCCESS", listaEstandaresFiltroActivos: []})
        },
        onListaTiposFallasActivas: () => {
            dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
        },
        onObtenerPartidasByidServicio: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idsServicios });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(FallasContainer);