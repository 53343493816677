import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Card,message, Row, Col,Button, Table, Input, DatePicker, Select, Tooltip} from 'antd';
import { EditOutlined, CloseCircleOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoFolioEO from './ModalNuevoFolioEO'
import ModalEditarFolioEO from './ModalEditarFolioEO';
import ModalCerrarFolioEO from './ModalCerrarFolioEO';
import ModalDetalleFolioEO from './ModalDetalleFolioEO';

const Search = Input.Search;
const { Option } = Select;
const {RangePicker} = DatePicker;

const FolioErrorOmision = (props) => {
    const {fetchingFoliosErroresOmisiones, listaFoliosErroresOmisiones, listaFoliosErroresOmisionesCantidad, fetchingListaServiciosFiltro, listaServiciosFiltro,
        onListaFoliosEO, onModalNuevoFolioEO, onModalEditarFolioEO, onModalDetalleFolioEO, onModalCerrarFolioEO, onListaServiciosFiltro,onListaServiciosFiltroLimpiar, onGetFallas,
    } = props;

    const [state, setState] = useState({page:1, busqueda: "", fecha: null, servicio: null});
    const {page, busqueda, fecha, servicio} = state;

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if (permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    useEffect (() => {
      onGetFallas();
    },[])

    useEffect (() => {
      onListaFoliosEO(busqueda, fecha, servicio, page);
    },[state])

    const columns = [
        {title: 'Folio Error/Omisión', dataIndex: 'folioErrorOmision', key: 'folioErrorOmision'},
        {title: 'Tipo ', dataIndex: 'tipoFolio', key: 'tipoFolio'},
        {title: 'Fecha EOI', dataIndex: 'fechaRegistroErrorOmisionGrid', key: 'fechaRegistroErrorOmisionGrid'},
        {title: 'No. Documento fiscalización', dataIndex: 'noDocFiscalizacion', key: 'noDocFiscalizacion'},
        {title: 'Estatus ', dataIndex: 'estado', key: 'estado'},
        {title: 'Detalle', key: 'detalle',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono' disabled={!permisosUsuario.detalleDeFolioPorErrorUOmisión}  onClick={() => detalleFolio(record)} ><FileSearchOutlined /></a>
        },
        {title: 'Editar', key: 'editar',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono' disabled={!permisosUsuario.editarFolioPorErrorUOmisión ? true : record.estado !== 'Abierto'}  onClick={() => editarFolio(record)} ><EditOutlined /></a>
        },
        {title: 'Cerrar', key: 'cerrar',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono' disabled={!permisosUsuario.cerrarFolioPorErrorUOmisión ? true : record.estado !== 'Abierto'}  onClick={() => cerrarFolio(record)} ><CloseCircleOutlined /></a>
        }
    ];

    const onBuscar = ( value ) => {
        const busqueda = value;
        setState({...state, busqueda});
    }

    const buscarServicios = value => {
        if (value.length >= 3) {
            if (value) {
                console.log(value);
                onListaServiciosFiltro(value)
            } else {
                message.warning('No se encontró servicio con esas palabras')
            }
        } else {
            onListaServiciosFiltroLimpiar();
        }
    }

    const handleServicio = value => {
      setState({...state, servicio: value})
    }

    const handleFecha = value => {
        setState({...state, fecha: value});
    }

    const disabledRange = fecha => {
        const currentYear = new Date();
        let year = currentYear.getFullYear();
        let month = currentYear.getMonth()
        month = month === 11 ? 1 : month + 2;
        year = month === 11 ? year + 1 : year;
        const fechaInicio = moment(`1/01/${year}`, 'DD-MM-YYYY');
        const fechaFin = moment(`1/${month}/${year}`, 'DD-MM-YYYY');
        if( fecha && fecha < fechaInicio) {
            return fecha && fecha < fechaInicio;
        } else {
            return fecha && fecha > fechaFin;
        }
    }

    const onNuevoFolioEO = () => {
        onModalNuevoFolioEO(busqueda, page, fecha, servicio)
    }

    const detalleFolio = record => {
      onModalDetalleFolioEO(record)
    }

    const editarFolio = record => {
      onModalEditarFolioEO(record, busqueda, page)
    }

    const cerrarFolio = record => {
      onModalCerrarFolioEO(record, busqueda, page)
    }

    const handleTable = (pagination) => {
        const page = pagination.current;
        setState({...state, page});
        onListaFoliosEO(busqueda, fecha, servicio, pagination.current);
    }

    return (
      <>
        <Card
          className="cardCatalogos"
          title={
            <div className="headerLista">Folio por Errores u Omisiones</div>
          }
        >
          <Row justify="space-between" gutter={[8,8]}>
            <Col span={5}>
              <Search
                className="buscarModulos"
                placeholder="Ingrese numero de folio"
                onSearch={(value) => onBuscar(value)}
              />
            </Col>
            <Col span={5}>
              <RangePicker
                format="DD-MM-YYYY"
                placeholder={["Fecha Inicio", "Fecha Fin"]}
                style={{ width: "100%" }}
                disabledDate={disabledRange}
                onChange={handleFecha}
              />
            </Col>
            <Col span={12}>
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Selecciona un servicio"
                loading={fetchingListaServiciosFiltro}
                showSearch
                filterOption={false}
                onSearch={buscarServicios}
                onChange={handleServicio}
                notFoundContent={null}
              >
                {listaServiciosFiltro.map((option) => (
                  <Option key={option.idServicio}>{option.nombre}</Option>
                ))}
              </Select>
            </Col>
            {/* <Col span={5}>
              <Button
                onClick={onNuevoFolioEO}
                disabled={!permisosUsuario.crearFolioPorErrorUOmisión}
                className="nuevoUsuario"
              >
                Nuevo Folio Error/Omisión
              </Button>
            </Col> */}
            <Col span={1}>
                <Tooltip title='Nuevo Folio Error/Omisión' placement="topRight">
                    <Button className="iconTer" shape="circle" onClick={onNuevoFolioEO} disabled={!permisosUsuario.crearFolioPorErrorUOmisión} icon={<PlusOutlined />}/>
                </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                size="small"
                rowKey="folioErrorOmision"
                columns={columns}
                dataSource={listaFoliosErroresOmisiones}
                loading={fetchingFoliosErroresOmisiones}
                locale={{ emptyText: "Sin datos" }}
                onChange={handleTable}
                pagination={{
                  total: listaFoliosErroresOmisionesCantidad,
                  simple: true,
                  current: state.page,
                }}
              />
            </Col>
          </Row>
        </Card>
        <ModalNuevoFolioEO />
        <ModalEditarFolioEO />
        <ModalCerrarFolioEO />
        <ModalDetalleFolioEO />
      </>
    );
}

const mapStateToProps = state => {
    return {
        fetchingFoliosErroresOmisiones: state.MecanismosDePagosReducer.fetchingFoliosErroresOmisiones,
        listaFoliosErroresOmisiones: state.MecanismosDePagosReducer.listaFoliosErroresOmisiones,
        listaFoliosErroresOmisionesCantidad: state.MecanismosDePagosReducer.listaFoliosErroresOmisionesCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaFoliosEO: (busqueda, fecha, servicio, page) => {
            dispatch({type:'FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST', busqueda, fecha, servicio, page});
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onModalNuevoFolioEO: (busqueda, page) => {
            dispatch({type:'MODAL_NUEVO_FOLIO_EO', showModalNuevoFolioEO: true, busqueda, page})
        },
        onModalEditarFolioEO: (folioErrorOmisionSeleccionado, busqueda, page) => {
            dispatch({type:'MODAL_EDITAR_FOLIO_EO', showModalEditarFolioEO: true, folioErrorOmisionSeleccionado, busqueda, page})
        },
        onModalDetalleFolioEO: (folioErrorOmisionSeleccionado) => {
            dispatch({type:'MODAL_DETALLE_FOLIO_EO', showModalDetalleFolioEO: true, folioErrorOmisionSeleccionado})
        },
        onModalCerrarFolioEO: (folioErrorOmisionSeleccionado, busqueda, page) => {
            dispatch({type:'MODAL_CERRAR_FOLIO_EO', showModalCerrarFolioEO: true, folioErrorOmisionSeleccionado, busqueda, page})
        },
        onShowModalDetalleFolio: (folioErrorOmisionSeleccionado) => {
          dispatch({ type:'MODAL_DETALLE_FOLIO_EO', showModalDetalleFolioEO: true, folioErrorOmisionSeleccionado})
      },
        onGetFallas: () => {
          dispatch({ type: 'LISTA_FALLAS_ACTIVAS_REQUEST' });
      },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (FolioErrorOmision);
