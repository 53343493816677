import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Checkbox, Card} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';
import moment from 'moment';
const { Step } = Steps;

const ReporteDinamicoES = props => {
    const { listaReporteDinamicoES, formularioReporte, onGetReporteDinamico, onLimpiarFormulario, onGetReporteDinamicoAgrupado } = props;

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if (permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    const [state, setState] = useState({page: 1, currentStep:0, activarGerencia: false, activarGrupo: false});
    const [order, setOrder] = useState({field:'ser.Nombre',order:'ASC'});
    const [checkAll, setCheckAll] = useState (false)
    const [checkArray, setCheckArray] = useState([]);
    const [checkbox, setCheckbox] = useState ({
        folioC: false, gerenciaC: false, grupoC: false, servicioC: false,
        mesC: false, calificacionC: false, comentarioC: false, 
    });

    useEffect(()=> {
        const arrayCheckbox = checkboxArray();
        setCheckArray(arrayCheckbox);
    },[checkbox]);

    
    const checkboxArray = () => {
        let fieldArray = []
        for (var key in checkbox) {
            if(Object.prototype.hasOwnProperty.call(checkbox, key)) {
                if(checkbox[key] === true){
                    fieldArray.push(key);
                }
            }
        }
        return fieldArray;
    }

    const handleSteps = value => {
        if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

        setOrder({field:'ser.Nombre',order:'ASC'});
        setState({page:1, currentStep: state.currentStep -1});
        console.log(`formularioReporte`, formularioReporte)
        onLimpiarFormulario(formularioReporte);
    }

    const onActivarGerencia = (value, tipo) => {
        if(tipo === 'gerencia') {
            setState({...state, activarGerencia: value})
        }
        else{
            setState({...state, activarGrupo: value})
        }
    }

    const selectHandle = (event,field) => {
        const checked = event.target.checked
        setCheckAll(false);

        if(checked) return setCheckbox({...checkbox,[field]:event.target.checked })
        setCheckbox({...checkbox,[field]:event.target.checked});
    }

    const selectAll = event => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            return setCheckbox({
                folioC: true, gerenciaC: true, grupoC: true, servicioC: true, mesC: true, calificacionC: true, comentarioC: true 
            });
        }
        setCheckbox({
            folioC: false, gerenciaC: false, grupoC: false, servicioC: false, mesC: false, calificacionC: false, comentarioC: false
        })
    }

    const handleTableChange = (pagination, filter, orden) => {
        setState({...state, page: pagination.current});
        console.log(`orden`, orden)
        const orderFields = { servicioC:'ser.Nombre', folioC:'ei.idFolioEncuesta', calificacionC:'ctf.Calificacion', estadoC: 'ei.Estado' }

        if(orden) {
            const field = orden.columnKey ? orderFields[orden.columnKey] : 'ser.Nombre';
            const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
            const sorter = {field: field, order: orderField}
            setOrder({field: field, order: orderField});
            console.log(`sorter`, sorter)
            return onGetReporteDinamico(formularioReporte, pagination.current, checkbox, sorter, moment().format('YYYY'), permisosUsuario.especialGestiónDeCalidad );
        }
        onGetReporteDinamico(formularioReporte, pagination.current, checkbox, order, moment().format('YYYY'), permisosUsuario.especialGestiónDeCalidad );
    }

    const handleOrden = (field, order) => {
        console.log(`field, orden`, field, order)
        const sorter = {field, order}
        setOrder({field, order});
        onGetReporteDinamicoAgrupado(formularioReporte, state.page, checkbox, sorter, moment().format('YYYY'), permisosUsuario.especialGestiónDeCalidad)
    }

    const checkFields = [{field: 'folioC', title:'Folio'}, {field: 'gerenciaC', title: 'Gerencia'}, 
        {field:'grupoC', title:'Grupo de Servicios'}, {field:'servicioC', title: 'Servicio'},{field: 'mesC', title: 'Mes'},
        {field:'calificacionC', title: 'Calificación'}, {field:'comentarioC', title: 'Comentario'}
    ];
    
    const SelectSection = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Alert message="Selecciona las columnas a incluir en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
                </Row>
                <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px' }}>
                    <Col span={11}>
                        <Checkbox defaultChecked={checkAll}  onChange={selectAll} key='todos'><strong>Todas</strong></Checkbox>
                    </Col>
                </Row>
                <Row style={{ width: '100%', textAlign: 'left' }}>
                    {checkFields.map( item => {
                        return (
                            <Col key={item.field} span={11}>
                                <Checkbox defaultChecked={checkbox[item.field]} onChange={(event)=>selectHandle(event,item.field)} key={item.field}>{item.title}</Checkbox>
                            </Col>
                        )
                    })}
                </Row>
                <Row justify='end'>
                    <Col span={6}>
                        <Button className="btnFiltrar" disabled={!checkArray.length>0} onClick={()=> handleSteps('siguiente')} style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                    </Col>
                </Row>
            </>
        )
    }
    
    const dataProps = { checkbox, checkArray, state, order}
    const handleProps = {handleTableChange , handleSteps, handleOrden}
    return (
        <Card className="cardCatalogos" title={<div className='headerLista'>Reporte Dinámico para Encuestas de Satisfacción</div>}>
            <Steps
                type='navigation'
                size='small'
                current={state.currentStep}
                style={{marginBottom:'20px', paddingTop:'0px'}}
            >
                <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
                <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
                <Step status={listaReporteDinamicoES.length>0? 'finish':'process'} title='Exportar Reporte' />
            </Steps>
            {state.currentStep === 0 ? <SelectSection/> : false}
            {state.currentStep === 1 ? <FilterSection state={state} activarGerencia={onActivarGerencia} handleSteps={handleSteps} checkboxData={checkbox} /> : false}
            {state.currentStep === 2 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        listaReporteDinamicoES: state.GestionDeCalidadReducer.listaReporteDinamicoES,
        formularioReporte: state.GestionDeCalidadReducer.formularioReporte,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetReporteDinamico: (formulario, page, checkboxData, order, año, especial) => {
            dispatch({ type: 'REPORTE_DINAMICO_ES_REQUEST', formulario, page, checkboxData, order, año, especial});
        },
        onGetReporteDinamicoAgrupado: (formulario, page, checkboxData, order, año, especial) => {
            dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_ES_REQUEST', formulario, page, checkboxData, order, año, especial });
        },
        onLimpiarFormulario: (formularioReporte) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO_REPORTE_ES', formularioReporte, listaReportesDinamicoES: [], listaReportesDinamicoESCantidad: 0})
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDinamicoES);