import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoTiempoDeGarantia from './ModalNuevoTiempoDeGarantia';
import ModaEditarTiempoDeGarantia from './ModalEditarTiempoDeGarantia';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoTiemposDeGarantia extends Component { 
    componentDidMount(){
        this.props.onGetTiempoDeGarantia(1, '')
    }

    state={
        page: 1,
        busqueda: '',
    }
    render() { 

        const {  onGetTiempoDeGarantia,
                fetchingListaTiempoDeGarantiaBusqueda,
                 listaTiempoDeGarantiaBusqueda,
                 listaTiempoDeGarantiaBusquedaCantidad,
                 onModalNuevoTiempoDeGarantia,
                 onModalEditarTiempoDeGarantia,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onBuscarTiempoDeGarantia = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetTiempoDeGarantia(1, busqueda);
        };

        const columns = [{
            title: 'Servicio',
            dataIndex: 'nombre',
            key: 'nombre',
        }, {
            title: 'Tiempo de garantia',
            dataIndex: 'tiempoGarantia',
            key: 'tiempoGarantia',
        }, {
            title: 'Unidad de medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
        }, {
            title: 'Fecha alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
        }, {
            title: 'Editar',
            key: 'editar',
            render: (text, record) => (
                  <a className='editarIcono' disabled={!permisosUsuario.editarTiempoDeGarantia} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onEditar = (key) => {
            console.log(key);
            onModalEditarTiempoDeGarantia(key, this.state.page);
        }
        
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetTiempoDeGarantia(pagination.current, this.state.busqueda);
        };


        const onNuevoTiempoDeGarantia = () => {
            onModalNuevoTiempoDeGarantia(this.state.page);
        }


        return ( 
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                     <Search className='buscarModulos' placeholder="Buscar tiempo de garantia/servicio" onSearch={value => onBuscarTiempoDeGarantia(value)} style={{ width: '100%' }}/> 
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoTiempoDeGarantia} disabled={!permisosUsuario.crearTiempoDeGarantia} className='nuevoUsuario'>Nuevo Tiempo de Garantia</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Tiempo de Garantia' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoTiempoDeGarantia} disabled={!permisosUsuario.crearTiempoDeGarantia} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="tiempoGarantia" 
                            columns={columns}
                            dataSource={listaTiempoDeGarantiaBusqueda}
                            loading={fetchingListaTiempoDeGarantiaBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaTiempoDeGarantiaBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoTiempoDeGarantia/>
                <ModaEditarTiempoDeGarantia/>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        fetchingListaTiempoDeGarantiaBusqueda:  state.MecanismosDePagosReducer.fetchingListaTiempoDeGarantiaBusqueda,
        listaTiempoDeGarantiaBusqueda: state.MecanismosDePagosReducer.listaTiempoDeGarantiaBusqueda,
        listaTiempoDeGarantiaBusquedaCantidad: state.MecanismosDePagosReducer.listaTiempoDeGarantiaBusquedaCantidad
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onGetTiempoDeGarantia: (page, busqueda) => {
	    	dispatch({ type: "LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onModalNuevoTiempoDeGarantia: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_TIEMPODEGARANTIA", showModalNuevoTiempoDeGarantia: true, page: page });
        },
        onModalEditarTiempoDeGarantia: (tiempoDeGarantiaSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_TIEMPODEGARANTIA", showModalEditarTiempoDeGarantia: true, tiempoDeGarantiaSeleccionado: tiempoDeGarantiaSeleccionado, page: page})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoTiemposDeGarantia);